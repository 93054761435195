import { useTranslation } from 'react-i18next';
import ProjectHanseiComment from 'src/components/projects/projectForm/ProjectHanseiComment';
import ProjectObtainedBenefits from 'src/components/projects/projectForm/ProjectObtainedBenefitsComments';
import ProjectSectionTitle from 'src/components/shared/components/ProjectSectionTitle/ProjectSectionTitle';
import { useAppSelector } from 'src/redux/store';

interface ProjectObjectivesProps {
  hasUserAccess: boolean;
}

const ProjectHanseiPage: React.FC<ProjectObjectivesProps> = ({ hasUserAccess }) => {
  const project = useAppSelector((state) => state.project.currentProject);

  const { t } = useTranslation();

  return (
    <div>
      <ProjectSectionTitle title={t('proggetiPage.hensei')} />

      {project && (
        <div className="tw-grid tw-mt-4 lg:tw-grid-cols-2 tw-gap-8">
          <ProjectHanseiComment
            isEditable={!hasUserAccess}
            isPresentationMode={false}
          />
          <ProjectObtainedBenefits
            isEditable={!hasUserAccess}
            isPresentationMode={false}
          />
        </div>
      )}
    </div>
  );
};

export default ProjectHanseiPage;
