import { Form } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import '../projects/projectForm/projectActivities/activities.scss';

import { Card, Col, DatePicker, Input, Modal, Row, Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import CommonService from '../../services/commonService';
import ModalFooterButtons from '../shared/components/modal/modalFooterButtons';
import UserSelectDropDown from '../shared/components/userSelect/userSelect';
import { activityStatus, formatOfDate, statusPickListCodes } from '../shared/utils/constants';
import { areDatesValid, showErrorNotification } from '../shared/utils/functions';
import { requiredFields } from '../shared/utils/notifyMessages';
import { useAppSelector } from 'src/redux/store';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const UpdateActivity = ({ handleUpdateActivity, showEditModal, handleEditModalClose, loadingButton, modalData }) => {
  const { t } = useTranslation();
  const userData = useAppSelector((state) => state.userData.userData);
  const activeXmatrix = useAppSelector((state) => state.activeXMatrix.activeXMatrix);

  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [projectPicklist, setProjectPicklist] = useState([]);
  const [statusPicklistLoading, setStatusPicklistLoading] = useState(true);
  const [projectPicklistLoading, setProjectPicklistLoading] = useState(true);

  useEffect(() => {
    void retrieveStatus();
    void retrieveUserProjects();
  }, []);

  const retrieveStatus = async () => {
    const objectCode = statusPickListCodes.activityStatus;

    setStatusPicklistLoading(true);

    try {
      const response = await CommonService.getStatusData(objectCode);
      const resp = response.data;

      if (resp.success) {
        setStatusList(resp.responseObject.value);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setStatusPicklistLoading(false);
    }
  };

  const retrieveUserProjects = async () => {
    setProjectPicklistLoading(true);

    if (activeXmatrix.xMatrixID && userData?.userId) {
      try {
        const response = await CommonService.getUserProjects(activeXmatrix.xMatrixID, userData.userId);
        const resp = response.data;

        if (!resp.success) {
          return;
        }

        setProjectPicklist(resp.responseObject.value);
      } catch (error) {
        console.error(error);
      } finally {
        setProjectPicklistLoading(false);
      }
    }
  };

  const isStatusClosedOrCancel = (status) => {
    return status === activityStatus.closed || status === activityStatus.cancelled;
  };

  const editData = (values, activityID, deliverableID, projectId) => {
    handleUpdateActivity(values, activityID, deliverableID, projectId);
  };

  const limitDateSelection = (current, modalData) => {
    if (modalData) {
      if (modalData.parentDeliverableID) {
        const curr = moment(current).endOf('day');
        const startDate = moment(modalData.parentDeliverableStartDate).endOf('day');
        const endDate = moment(modalData.parentDeliverableEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      } else {
        const curr = moment(current).endOf('day');
        const startDate = moment(modalData.projectStartDate).endOf('day');
        const endDate = moment(modalData.projectEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      }
    }
  };

  const checkValidityBeforeSave = (values, activityID, deliverableID, projectId) => {
    if (areDatesValid(values.startDate, values.endDate)) {
      editData(values, activityID, deliverableID, projectId);
    } else {
      showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
    }
  };

  const isClosedOrCancelled = isStatusClosedOrCancel(modalData.status);
  const isClosed = modalData.status === activityStatus.closed;

  return (
    <Modal
      className="add-kpi-modal"
      width={800}
      title={t('leMieAttivitaPage.aggiornamentoAttivita')}
      open={showEditModal}
      destroyOnClose={true}
      onCancel={handleEditModalClose}
      footer={[
        <ModalFooterButtons
          disabled={!fieldsChanged}
          loadingButton={loadingButton}
          modalClose={handleEditModalClose}
          formId={'editMyActivityForm'}
        />,
      ]}>
      <Card>
        <Form
          id="editMyActivityForm"
          layout="vertical"
          onFinish={(values) =>
            checkValidityBeforeSave(values, modalData.activityID, modalData.deliverableID, modalData.projectId)
          }
          onValuesChange={() => {
            setFieldsChanged(true);
          }}>
          {isClosed && (
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <div className="button-wrapper">
                  <Text strong>
                    {t('general.dataChiusura')}:{' '}
                    {modalData.actualCloseDate ? moment(modalData.actualCloseDate).format(formatOfDate) : '-'}
                  </Text>
                </div>
              </Col>
            </Row>
          )}
          <Row
            gutter={{ lg: 24 }}
            className="row-margin">
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}>
              <Form.Item
                className="main-container"
                label={t('leMieAttivitaPage.soggetto')}
                name="subject"
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={modalData.subject}>
                <Input disabled={isClosedOrCancelled} />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}>
              <UserSelectDropDown
                disabled={isClosedOrCancelled}
                allowClear={false}
                fieldLabel={t('general.responsabile')}
                formName={'ownerID'}
                required={true}
                initValue={modalData.ownerID}
              />
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}>
              <Form.Item
                className="main-container"
                label={t('general.stato')}
                name="status"
                hasFeedback
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={modalData.status}>
                <Select
                  disabled={isClosedOrCancelled}
                  className="tw-w-full"
                  loading={statusPicklistLoading}
                  placeholder={t('general.seleziona')}>
                  {statusList.map((item) => (
                    <Option
                      value={item.statusID}
                      key={item.statusID}>
                      {item.statusDescription}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}>
              <Form.Item
                className="main-container"
                label={t('general.progetto')}
                name="projectID"
                hasFeedback
                initialValue={modalData.projectID}>
                <Select
                  disabled={isClosedOrCancelled}
                  loading={projectPicklistLoading}
                  className="tw-w-full"
                  placeholder={t('general.seleziona')}>
                  {projectPicklist.map((item) => (
                    <Option
                      value={item.projectID}
                      key={item.projectID}>
                      {item.projectName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}>
              <Form.Item
                className="main-container"
                label={t('general.start')}
                name="startDate"
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={modalData.startDate ? moment(modalData.startDate) : modalData.startDate}>
                <DatePicker
                  style={{ width: '100%' }}
                  format="DD/MM/YYYY"
                  disabled={isClosedOrCancelled}
                  disabledDate={modalData ? (current) => limitDateSelection(current, modalData) : null}
                />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}>
              <Form.Item
                className="main-container"
                label={t('general.end')}
                name="endDate"
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={modalData.endDate ? moment(modalData.endDate) : modalData.endDate}>
                <DatePicker
                  style={{ width: '100%' }}
                  format="DD/MM/YYYY"
                  disabled={isClosedOrCancelled}
                  disabledDate={modalData ? (current) => limitDateSelection(current, modalData) : null}
                />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}>
              <Form.Item
                className="main-container"
                label={t('general.descrizione')}
                name="description"
                initialValue={modalData.description}>
                <TextArea
                  rows={4}
                  disabled={isClosedOrCancelled}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
};

export default UpdateActivity;
