import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, List, Tag, message } from 'antd';
import type React from 'react';
import { useEffect, useState } from 'react';
import { ArcherContainer, ArcherElement } from 'react-archer';
import { useTranslation } from 'react-i18next';
import ProjectSectionTitle from 'src/components/shared/components/ProjectSectionTitle/ProjectSectionTitle';
import type { GetRiskPrecautionResponseDto } from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import RiskService from '../../../../services/pages/projectServices/riskServices';
import TableLayout from '../../../shared/tableLayout';
import { checkRiskSeverity, checkStatus } from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import AddCriticModal from './addCritic';
import EditCriticModal from './editCriticModal';
import './riskAndPrecaution.scss';

interface RiskAndPrecautionsProps {
  hasUserAccess: boolean;
  readOnly?: boolean;
}

const RiskAndPrecautions: React.FC<RiskAndPrecautionsProps> = ({ hasUserAccess, readOnly }) => {
  const { t } = useTranslation();
  const project = useAppSelector((state) => state.project.currentProject);
  const projectId = project?.projectID || '';

  const [riskAndPrecautionList, setRiskAndPrecautionList] = useState<GetRiskPrecautionResponseDto[]>([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [addCriticModal, setAddCriticModal] = useState(false);
  const [editCriticModal, setEditCriticModal] = useState(false);
  const [editModalData, setEditModalData] = useState<GetRiskPrecautionResponseDto | null>(null);

  useEffect(() => {
    retrieveRiskAndPrecautionList();
  }, []);

  const retrieveRiskAndPrecautionList = async () => {
    try {
      const response = await RiskService.getRiskAndPrecaution(projectId);
      const data = response.data;
      if (data.success) {
        setRiskAndPrecautionList(data.responseObject?.value || []);
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch {
      message.error(notifyMessages.retrieveFailed);
    }
  };

  const addCritic = async (values: any) => {
    setLoadingButton(true);
    try {
      const response = await RiskService.addCritic({ ...values, projectID: projectId });
      if (response.data.success) {
        message.success(notifyMessages.addSuccess);
        setAddCriticModal(false);
        retrieveRiskAndPrecautionList();
      } else {
        message.error(notifyMessages.addFailed);
      }
    } catch {
      message.error(notifyMessages.addFailed);
    } finally {
      setLoadingButton(false);
    }
  };

  const editCritic = async (values: any, riskID: string) => {
    setLoadingButton(true);
    try {
      const response = await RiskService.updateCritic({ ...values, riskID });
      if (response.data.success) {
        message.success(notifyMessages.updateSuccess);
        setEditCriticModal(false);
        retrieveRiskAndPrecautionList();
      } else {
        message.error(notifyMessages.updateFailed);
      }
    } catch {
      message.error(notifyMessages.updateFailed);
    } finally {
      setLoadingButton(false);
    }
  };

  const onRemoveCritic = async (riskID?: string) => {
    if (!riskID) {
      return;
    }

    try {
      const response = await RiskService.deleteRisk(riskID);
      if (response.data.success) {
        message.success(notifyMessages.deleteSuccess);
        retrieveRiskAndPrecautionList();
      } else {
        message.error(notifyMessages.deleteFailed);
      }
    } catch {
      message.error(notifyMessages.deleteFailed);
    }
  };

  const items = (data: GetRiskPrecautionResponseDto) => [
    {
      key: 'edit',
      label: t('buttons.modifica'),
      onClick: () => {
        setEditModalData(data);
        setEditCriticModal(true);
      },
    },
    {
      key: 'delete',
      label: t('buttons.elimina'),
      danger: true,
      onClick: () => onRemoveCritic(data.riskID || undefined),
    },
  ];

  return (
    <TableLayout title={<ProjectSectionTitle title={t('proggetiPage.criticitaRischi')} />}>
      <TableLayout.Actions>
        {addCriticModal && (
          <AddCriticModal
            showCriticModal={addCriticModal}
            handleCriticModalClose={() => setAddCriticModal(false)}
            handleCriticModalSave={addCritic}
            loadingButton={loadingButton}
            projectId={projectId}
            t={t}
          />
        )}

        {editCriticModal && (
          <EditCriticModal
            showEditCriticModal={editCriticModal}
            handleEditCriticModalClose={() => setEditCriticModal(false)}
            handeEditCriticModalSave={editCritic}
            loadingButton={loadingButton}
            data={editModalData}
            t={t}
          />
        )}

        {!hasUserAccess && !readOnly ? (
          <Button
            data-testid="add_risk_btn"
            onClick={() => setAddCriticModal(true)}
            type="primary"
          >
            <PlusOutlined />
            {t('buttons.aggiungiNuovo')}
          </Button>
        ) : null}
      </TableLayout.Actions>
      <TableLayout.Content>
        {riskAndPrecautionList && (
          <div className="tw-border tw-rounded-md tw-p-4">
            <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-mb-4">
              <div>
                <div>
                  <h5
                    className="ant-list-item-meta-title"
                    style={{ marginBottom: 0 }}
                  >
                    {t('proggetiPage.criticitaRischi')}
                  </h5>
                  <div
                    className="ant-list-item-meta-description"
                    style={{ fontSize: 11 }}
                  >
                    {t('a3Page.criticitaRischiDescription')}
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <h5
                    className="ant-list-item-meta-title"
                    style={{ marginBottom: 0 }}
                  >
                    {t('proggetiPage.contromisureIdentificate')}
                  </h5>
                  <div
                    className="ant-list-item-meta-description"
                    style={{ fontSize: 11 }}
                  >
                    {t('a3Page.contrimisureIdentificateDescription')}
                  </div>
                </div>
              </div>
            </div>

            {riskAndPrecautionList.map((critic, index) => (
              <div key={index}>
                <div className={(critic.status === 1 ? ' red' : '') + (critic.status === 2 ? ' green' : '')}>
                  <ArcherContainer>
                    <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-w-full">
                      <ArcherElement
                        id="root"
                        relations={critic.precautions?.map((el) => {
                          return {
                            targetId: el.precautionID || '',
                            targetAnchor: 'left',
                            sourceAnchor: 'right',
                            style: { strokeColor: 'gray', strokeWidth: 1 },
                          };
                        })}
                      >
                        <div className="tw-flex tw-h-fit tw-p-3 tw-mb-4 tw-flex-col tw-bg-neutral-100 tw-rounded-lg">
                          <div className="tw-w-full tw-flex tw-items-center tw-mb-3 tw-justify-between tw-gap-2">
                            <div className="tw-flex tw-items-center tw-gap-1">
                              <Tag className="tw-pt-1">{checkRiskSeverity(critic)}</Tag>
                              <Tag className="tw-pt-1">{checkStatus(critic)}</Tag>
                            </div>
                            <Dropdown
                              menu={{ items: items(critic) }}
                              trigger={['click']}
                            >
                              <Button icon={<EllipsisOutlined />}></Button>
                            </Dropdown>
                          </div>

                          <div className="tw-flex tw-items-center tw-gap-4">
                            <p className="tw-text-sm"> {critic.riskDescription}</p>
                          </div>
                        </div>
                      </ArcherElement>

                      <div className="tw-ml-12">
                        {critic.precautions && critic.precautions.length > 0 && critic.precautions && (
                          <List
                            dataSource={critic.precautions}
                            renderItem={(item) => (
                              <List.Item>
                                <div>
                                  <ArcherElement
                                    id={item.precautionID || ''}
                                    key={item.precautionID}
                                  >
                                    <div>
                                      <p> {item.precautionDescription}</p>
                                    </div>
                                  </ArcherElement>
                                </div>
                              </List.Item>
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </ArcherContainer>
                </div>
              </div>
            ))}
          </div>
        )}
      </TableLayout.Content>
    </TableLayout>
  );
};

export default RiskAndPrecautions;
