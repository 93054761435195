import {
  CommentOutlined,
  EditOutlined,
  LeftCircleOutlined,
  MinusSquareOutlined,
  RightCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, DatePicker, Input, Select, Space, Table, Tooltip, Typography, message } from 'antd';
import ButtonGroup from 'antd/es/button/button-group';
import type { BaseOptionType } from 'antd/es/select';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import { enumCast } from 'src/config/connectors';
import {
  type BowlingChartFilters,
  type GroupedPlanningsDto,
  KpiDetailParentType,
  type KpiDto,
  ProjectParentship,
  ProjectType,
} from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import MatrixService from 'src/services/matrix/matrixService';
import CommitieService from 'src/services/pages/commitieServices';
import { getSemaphore } from 'src/utils/kpi-utils';
import DashboardService from '../../services/pages/dashboardService';
import KpiService from '../../services/pages/kpiService';
import { addQueryParam, getQueryParam, hasQueryParam, removeQueryParam } from '../../utils/url-utils';
import TableLayout from '../shared/tableLayout';
import { italianNumberFormat } from '../shared/utils/functions';
import { notifyMessages } from '../shared/utils/notifyMessages';
import EditKpiCommentModal from './kpiDetailCommentModal';

type PickListEntry = {
  value: string;
  label: string;
};

export enum KpiSummaryType {
  AnnualGoals = 'annualGoal',
  Committees = 'committee',
  Projects = 'project',
}

type TableDataEntry = KpiDto & {
  values: GroupedPlanningsDto;
};

const KpiSummary: FC<{
  relationId?: string;
  onlyGlobals?: boolean;
  summaryType: KpiSummaryType;
}> = ({ relationId, summaryType, onlyGlobals }) => {
  const { t } = useTranslation();
  const activeXmatrixInfo = useAppSelector((state) => state.activeXMatrix.activeXMatrix);
  const companyData = useAppSelector((state) => state.companyData.companyData);

  const [kpiList, setKpiList] = useState<TableDataEntry[]>([]);
  const [pickListData, setPicklistData] = useState<BaseOptionType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [relationQueryParamName, setRelationQueryParamName] = useState<string>('relationId');
  const [relationNameKey, setRelationNameKey] = useState<string>('');
  const [selectedRelation, setSelectedRelation] = useState<string>();
  const [selectedYear, setSelectedYear] = useState<number>();
  const [selectedMonth, setSelectedMonth] = useState<number>();

  const [showCommentModal, setShowCommentModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<TableDataEntry>();
  const [loadingSaveComment, setLoadingSaveComment] = useState(false);

  const [disableNextButton, setDisableNextButton] = useState(false);
  const [disablePreviousButton, setDisablePreviousButton] = useState(true);

  const KpiSemaphore: FC<{ data: TableDataEntry }> = ({ data }) =>
    companyData && (
      <span
        className="tw-block tw-size-5 tw-rounded-full"
        style={{
          backgroundColor: getSemaphore(
            data.kpiDetail,
            // biome-ignore lint/style/noNonNullAssertion: <explanation>
            data.monthlyPlannings!,
            enumCast(KpiDetailParentType, data.kpiDetail.parentType),
            (selectedMonth || new Date().getMonth() + 1) - 1,
            selectedYear || new Date().getFullYear(),
            companyData,
          ),
        }}
      />
    );

  useEffect(() => {
    void getFilters();

    setSelectedMonth(
      getQueryParam('selectedMonth') ? Number.parseInt(getQueryParam('selectedMonth')) : moment().month() + 1,
    );
    setSelectedYear(getQueryParam('selectedYear') ? Number.parseInt(getQueryParam('selectedYear')) : moment().year());

    if (summaryType === KpiSummaryType.AnnualGoals) {
      setRelationNameKey('general.obiettiviAnno');
      setRelationQueryParamName('selectedAnnualGoal');
      setSelectedRelation(relationId || getQueryParam('selectedAnnualGoal'));
    }
    if (summaryType === KpiSummaryType.Committees) {
      setRelationNameKey('general.comitato');
      setRelationQueryParamName('selectedCommittee');
      setSelectedRelation(relationId || getQueryParam('selectedCommittee'));
    }
    if (summaryType === KpiSummaryType.Projects) {
      setRelationNameKey('general.progetti');
      setRelationQueryParamName('selectedProject');

      if (relationId || hasQueryParam('selectedProject')) {
        setSelectedRelation(relationId || getQueryParam('selectedProject'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationId, summaryType]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (selectedMonth && selectedYear) {
      void retrieveKpiList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth, selectedYear, selectedRelation]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (selectedRelation) {
      addQueryParam(relationQueryParamName, selectedRelation);
    } else {
      removeQueryParam(relationQueryParamName);
    }

    setDisablePreviousButton(pickListData[0]?.value === selectedRelation);
    setDisableNextButton(pickListData.slice(-1)[0]?.value === selectedRelation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRelation]);

  const getFilters = async () => {
    try {
      if (summaryType === KpiSummaryType.AnnualGoals) {
        const { data } = await MatrixService.getAnnualGoals();
        if (data.responseObject?.value) {
          setPicklistData(
            data.responseObject.value.map((e) => ({ value: e.annualGoalId, label: e.description ?? e.annualGoalId })),
          );
        }
      }

      if (summaryType === KpiSummaryType.Committees) {
        const { data } = await CommitieService.getCommitiesList();
        setPicklistData(data.map((c) => ({ value: c.id, label: c.name })));
      }

      if (summaryType === KpiSummaryType.Projects) {
        const [operationalProjectsResponse, nonOperationalProjectsResponse] = await Promise.all([
          DashboardService.getDashboardProjects({
            projectParentship: ProjectParentship.All,
            selectNotRelatedProjects: true,
            type: ProjectType.Operational,
          }),
          DashboardService.getDashboardProjects({
            projectParentship: ProjectParentship.All,
            selectNotRelatedProjects: false,
            type: ProjectType.NonOperational,
            xMatrixIds: [activeXmatrixInfo?.xMatrixID as string],
          }),
        ]);

        const operationalProjects = operationalProjectsResponse.data;
        const nonOperationalProjects = nonOperationalProjectsResponse.data;

        setPicklistData(
          [...operationalProjects, ...nonOperationalProjects].map((project) => ({
            value: project.id,
            label: project.name ?? project.code ?? project.id,
          })),
        );
      }
    } catch {}
  };

  const retrieveKpiList = async (xMatrixId?: string) => {
    const xMatrix = xMatrixId ?? activeXmatrixInfo?.xMatrixID;

    if (!xMatrix) {
      return;
    }

    const relationKey = {
      [KpiSummaryType.AnnualGoals]: 'annualGoalId',
      [KpiSummaryType.Committees]: 'committeeId',
      [KpiSummaryType.Projects]: 'projectId',
    }[summaryType] as keyof BowlingChartFilters;

    setIsLoading(true);

    try {
      const { data } = await KpiService.getKpiListData({
        includePlannings: true,
        planningYear: selectedYear ?? moment().year(),
        isAnnualGoalRelated: !selectedRelation && summaryType === KpiSummaryType.AnnualGoals,
        isCommitteeRelated: !selectedRelation && summaryType === KpiSummaryType.Committees,
        isProjectRelated: !selectedRelation && summaryType === KpiSummaryType.Projects,
        kpiDetailType: onlyGlobals && !selectedRelation ? 'global' : undefined,
        [relationKey]: selectedRelation,
      });

      const parentData = data.map((e) => ({
        ...e,
        values: Object.values(e.monthlyPlannings!)[selectedMonth ?? moment().month() - 1],
      }));

      setKpiList(parentData);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleKpiComments = (rowData: TableDataEntry) => {
    setSelectedRowData(rowData);
    setShowCommentModal(true);
  };

  const handleSaveComments = async (payload: { kpiDetailId: string; comment: string }) => {
    setLoadingSaveComment(true);

    const relationKey: keyof BowlingChartFilters & ('annualGoalId' | 'committeeId' | 'projectId') = (() => {
      if (summaryType === KpiSummaryType.AnnualGoals) {
        return 'annualGoalId';
      }
      if (summaryType === KpiSummaryType.Committees) {
        return 'committeeId';
      }
      if (summaryType === KpiSummaryType.Projects) {
        return 'projectId';
      }

      throw new Error(`Invalid summaryType ${summaryType}`);
    })();

    try {
      await KpiService.onCommentSave(payload.kpiDetailId, {
        comment: payload.comment,
        [relationKey]: selectedRelation,
      });

      setShowCommentModal(false);
      void message.success(notifyMessages.updateSuccess);
      void retrieveKpiList();
    } catch {
      void message.error(notifyMessages.updateFailed);
    }

    setLoadingSaveComment(false);
  };

  const onNextObject = () => {
    const currentIndex = pickListData.findIndex((item) => item.value === selectedRelation);
    currentIndex + 1 < pickListData.length && setSelectedRelation(pickListData[currentIndex + 1].value);
  };

  const onPreviousObject = () => {
    const currentIndex = pickListData.findIndex((item) => item.value === selectedRelation);
    currentIndex - 1 >= 0 && setSelectedRelation(pickListData[currentIndex - 1].value);
  };

  const kpiColumns: ColumnsType<TableDataEntry> = [
    {
      title: `${t('general.nome')}`,
      dataIndex: ['kpiDetail', 'name'],
      ellipsis: {
        showTitle: true,
      },
      align: 'left',
      width: '30%',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters?.()}
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />
      ),
      shouldCellUpdate: (a, b) => !shallowEqual(a.kpiDetail, b.kpiDetail),
      onFilter: (value, record) => record.kpiDetail.name.toLowerCase().includes(`${value}`.trim().toLowerCase()),
      render: (text, record) => {
        const urlParts = {
          baseRoute: '/kpiDashboard/kdid',
          kpiDetailId: record.kpiDetail.id,
          projectId: summaryType === KpiSummaryType.Projects && selectedRelation ? selectedRelation : 0,
          annualGoalId: summaryType === KpiSummaryType.AnnualGoals && selectedRelation ? selectedRelation : 0,
          xMatrixId: activeXmatrixInfo?.xMatrixID ?? 0,
          year: selectedYear,
          month: selectedMonth,
          part: `${summaryType.charAt(0)}/`,
        };

        const pathname = Object.values(urlParts).map(String).join('/');

        return (
          <Space>
            <Link to={{ pathname }}>{text}</Link>
          </Space>
        );
      },
    },
    {
      title: `${t('kpiPage.unitaMisura')}`,
      dataIndex: ['kpiDetail', 'unitOfMeasure'],
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: `${t('general.targetYtd')}`,
      dataIndex: ['values', 'targetYTD'],
      key: 'targetYTD',
      ellipsis: {
        showTitle: false,
      },
      render: italianNumberFormat,
    },
    {
      title: `${t('general.actualYtd')}`,
      dataIndex: ['values', 'actualYTD'],
      key: 'actualYTD',
      ellipsis: {
        showTitle: false,
      },
      render: italianNumberFormat,
    },
    {
      title: `${t('kpiDashboard.semaphore')}`,
      dataIndex: 'semaforo',
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => <KpiSemaphore data={record} />,
    },
    {
      title: `${t('kpiPage.annualTarget')}`,
      dataIndex: 'finalTarget',
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => {
        const target = record.monthlyPlannings!['12'].targetYTD;
        return italianNumberFormat(target);
      },
    },
    {
      title: `${t('general.commenti')}`,
      dataIndex: ['notes'],
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => {
        return (
          <div className="tw-flex tw-gap-2 tw-items-center">
            <Button
              icon={<EditOutlined />}
              onClick={() => toggleKpiComments(record)}
            />
            {record.notes && record.notes !== '<p></p>' && <CommentOutlined className="tw-text-green-600" />}
          </div>
        );
      },
    },
  ];

  return (
    <>
      {showCommentModal && selectedRowData && (
        <EditKpiCommentModal
          showCommentModal={showCommentModal}
          handleCommentModalClose={() => setShowCommentModal(false)}
          onCommentUpdate={handleSaveComments}
          loadingCommentSave={loadingSaveComment}
          rowData={selectedRowData}
        />
      )}

      <TableLayout className="project-card-wrapper">
        <TableLayout.Actions>
          <DatePicker
            allowClear={true}
            placeholder={t('general.anno')}
            picker="year"
            style={{ width: '100%' }}
            value={moment(selectedYear, 'YYYY')}
            format="YYYY"
            onChange={(date) => {
              setSelectedYear(date.year());
              addQueryParam('selectedYear', selectedYear);
            }}
            className="tw-max-w-32"
          />
          <Select
            placeholder={t('general.mese')}
            value={selectedMonth}
            allowClear={true}
            onChange={(value) => {
              setSelectedMonth(value);
              addQueryParam('selectedMonth', selectedMonth);
            }}
            className="tw-min-w-32"
            options={Array.from({ length: 12 }).map((_, i) => ({
              label: moment(`1987-${i + 1}-27`).format('MMMM'),
              value: i + 1,
            }))}
          />
          <Select
            value={selectedRelation === '' ? null : selectedRelation}
            placeholder={t(relationNameKey)}
            onChange={setSelectedRelation}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.trim().toLowerCase())}
            showSearch
            allowClear={true}
            labelRender={({ label }) => (
              <Tooltip
                title={label}
                placement="top"
              >
                <Typography.Text className="tw-shrink tw-max-w-16 tw-truncate">
                  {`${label ?? ''}`.replace('...', '')}
                </Typography.Text>
              </Tooltip>
            )}
            className="tw-shrink-0 tw-w-[500px]"
            options={pickListData}
            optionRender={(option) => (
              <Space>
                <Tooltip
                  title={option.label}
                  placement="top"
                >
                  <span className="tw-shrink tw-max-w-16 tw-truncate">{option.label}</span>
                </Tooltip>
              </Space>
            )}
          />

          <ButtonGroup>
            <Tooltip title={t('general.previous')}>
              <Button
                disabled={disablePreviousButton || isLoading}
                icon={<LeftCircleOutlined />}
                className="tw-shrink-0"
                onClick={() => onPreviousObject()}
              />
            </Tooltip>
            <Tooltip title={t('general.next')}>
              <Button
                className="tw-shrink-0"
                disabled={disableNextButton || isLoading}
                icon={<RightCircleOutlined />}
                onClick={() => onNextObject()}
              />
            </Tooltip>
          </ButtonGroup>

          <Tooltip title={t('general.clearFilters')}>
            <Button
              disabled={isLoading}
              className="tw-shrink-0 tw-ml-8"
              icon={<MinusSquareOutlined />}
              onClick={() => {
                setSelectedMonth(moment().month() + 1);
                setSelectedYear(moment().year());
                setSelectedRelation(undefined);

                removeQueryParam('selectedAnnualGoal');
                removeQueryParam('selectedProject');
                removeQueryParam('selectedCommittee');
                removeQueryParam('selectedMonth');
                removeQueryParam('selectedYear');
              }}
            />
          </Tooltip>
        </TableLayout.Actions>

        <TableLayout.Content>
          <Table
            className="kpiSintesiTable"
            size="small"
            sticky
            loading={isLoading}
            columns={kpiColumns}
            dataSource={kpiList}
            rowKey={(obj) => obj.id}
            pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
          />
        </TableLayout.Content>
      </TableLayout>
    </>
  );
};

export default KpiSummary;
