import { Form } from 'antd';

import { Card, Col, Input, Modal, Row } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalFooterButtons from '../components/shared/components/modal/modalFooterButtons';
import { httpLinkRegex, httpsLinkRegex } from '../components/shared/utils/constants';
import { requiredFields } from '../components/shared/utils/notifyMessages';

const NewDocumentationLinkModal: FC<{
  showModal: boolean;
  handleModalClose: () => void;
  handleInsertLink: (values: any) => void;
  loadingInsertLink: boolean;
  projectLinkData: any;
}> = ({
  handleInsertLink,
  showModal,
  handleModalClose,
  loadingInsertLink,
  projectLinkData,
}) => {
  const { t } = useTranslation();
  const [fieldsChanged, setFieldsChanged] = useState(false);

  const onSave = (values: any) => {
    handleInsertLink(values);
  };

  return (
    <Modal
      className="add-kpi-modal"
      width={550}
      title={t('proggetiPage.addProjectFileRepo')}
      open={showModal}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={handleModalClose}
      footer={[
        <ModalFooterButtons
          disabled={!fieldsChanged}
          loadingButton={loadingInsertLink}
          modalClose={handleModalClose}
          formId={'newLink'}
        />,
      ]}>
      <Card>
        <Form
          id="newLink"
          layout="vertical"
          onFinish={(values) => onSave(values)}
          onValuesChange={() => {
            setFieldsChanged(true);
          }}>
          <Row
            gutter={{ lg: 24 }}
            className="row-margin">
            <Col
              className="gutter-row"
              xs={{ span: 24 }}>
              <Form.Item
                className="main-container"
                label={t('general.link')}
                name="link"
                rules={[
                  { required: true, message: requiredFields.required },
                  {
                    validator: async (_, value) => {
                      if (!httpLinkRegex.test(value)) {
                        return Promise.reject(new Error('Link not valid'));
                      }
                      if (!httpsLinkRegex.test(value)) {
                        return Promise.reject(new Error('Link not valid'));
                      }
                    },
                  },
                ]}
                extra="Link format: http://example.com/"
                initialValue={projectLinkData ? projectLinkData.link : null}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
};

export default NewDocumentationLinkModal;
