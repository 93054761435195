import { DeleteOutlined, EditFilled, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Popconfirm, Space, Table, Tooltip, message } from 'antd';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { ArrowRightIcon } from '../../../assets/icons';
import AddChildCommititeModal from '../../../dialogs/AddChildCommitieModal';
import MatrixService from '../../../services/matrix/matrixService';
import CommitieService from '../../../services/pages/commitieServices';
import TableLayout from '../../shared/tableLayout';
import { notifyMessages } from '../../shared/utils/notifyMessages';

const RelatedComities = ({ comitieId, onlyView = false, userHasAccess = false, ...props }) => {
  const history = useHistory();

  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const [loadingSave, setLoadingSave] = useState(false);
  const [relatedComities, setRelatedComities] = useState(null);
  const [loadingRelatedComities, setLoadingRelatedComities] = useState(false);
  const [availableChildComitiesModal, setAvailableChildComitiesModal] = useState(false);
  const [tableExpendedRowKeys, setTableExpendedRowKeys] = useState([]);
  const [expendedComitieProjects, setExpendedComitieProjects] = useState(null);
  const [loadingExpendedProjects, setLoadingExpendedProjects] = useState(false);
  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState(null);

  useEffect(() => {
    getActiveXmatrix();
    getCommitieRealatedComitie(comitieId);
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    clearFilters();
    setSearchText('');
    handleSearch('', confirm, dataIndex);
  };

  const getActiveXmatrix = async () => {
    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          setActiveXmatrixInfo(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const getCommitieRealatedComitie = (id) => {
    setLoadingRelatedComities(true);
    CommitieService.getComitieRelatedComities(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          setRelatedComities(respData);
          setLoadingRelatedComities(false);
        } else {
          setLoadingRelatedComities(false);
        }
      })
      .catch((error) => {
        setLoadingRelatedComities(false);
      });
  };

  const getCommitieRealatedProjects = async (id) => {
    if (activeXmatrixInfo) {
      setLoadingExpendedProjects(true);
      await CommitieService.getComitieRelatedProjects(id, activeXmatrixInfo?.xMatrixID)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            const respData = resp.responseObject.value;
            setExpendedComitieProjects(respData);
            setLoadingExpendedProjects(false);
          } else {
            setLoadingExpendedProjects(false);
          }
        })
        .catch((error) => {
          setLoadingExpendedProjects(false);
        });
    } else {
    }
  };

  const saveChildToComitie = (values) => {
    values['committeeID'] = comitieId;

    setLoadingSave(true);
    CommitieService.addCommitteeChildRelation(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          setLoadingSave(false);
          setAvailableChildComitiesModal(false);
          getCommitieRealatedComitie(comitieId);
        } else {
          message.error(notifyMessages.addFailed);
          setLoadingSave(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        setLoadingSave(false);
      });
  };

  const removeChildComitie = (childId) => {
    CommitieService.removeChildComitie(childId, comitieId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          getCommitieRealatedComitie(comitieId);
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  const expandedRowRender = () => {
    const columns = [
      {
        title: `${t('proggetiPage.codice')}`,
        dataIndex: 'projectCode',
        width: '140px',
        fixed: 'left',
        showSorterTooltip: [true],
      },
      {
        title: `${t('general.nome')}`,
        dataIndex: 'projectName',
        ellipsis: {
          showTitle: true,
        },
        render: (title) => <Tooltip title={title}>{title}</Tooltip>,
      },
      {
        title: `${t('general.teamLeader')}`,
        dataIndex: 'teamLeaderFullName',
        width: '250px',
        fixed: 'left',
      },
      {
        key: 'action',
        width: '30px',
        render: (text, record) => (
          <Space>
            <Link to={{ pathname: `/progetti/id/${record.projectID}` }}>
              <EditFilled />
            </Link>
          </Space>
        ),
      },
    ];
    const data = expendedComitieProjects;
    return (
      <Table
        className="expededTable"
        rowKey={(obj) => obj.projectID}
        size="small"
        columns={columns}
        loading={loadingExpendedProjects}
        dataSource={data}
        pagination={false}
      />
    );
  };

  const getExpendedComitiesProject = (comitieId) => {
    getCommitieRealatedProjects(comitieId);
  };

  const handleChildComitieModal = () => {
    setAvailableChildComitiesModal(false);
  };

  const relatedCommitiesColumns = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'name',
      ellipsis: {
        showTitle: true,
      },
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
      showSorterTooltip: [false],
      ...getColumnSearchProps('name'),
    },
    {
      key: 'action',
      width: '30px',
      render: (title, record) => {
        if (userHasAccess) {
          return (
            <Space>
              <Link to={{ pathname: `/committee-dashboard/${record.id}` }}>
                <EditFilled />
              </Link>
            </Space>
          );
        }
      },
    },
    {
      key: 'action',
      width: '30px',
      render: (text, record) => {
        return (
          <>
            {!onlyView && (
              <Space>
                <Popconfirm
                  title={t('comitatiPage.removeChildComitie')}
                  onConfirm={() => removeChildComitie(record.id)}
                  okText={t('general.si')}
                  cancelText={t('general.no')}
                  className="long-term-button remove"
                >
                  <DeleteOutlined />
                </Popconfirm>
              </Space>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      {availableChildComitiesModal && (
        <AddChildCommititeModal
          showModal={availableChildComitiesModal}
          handleModalClose={handleChildComitieModal}
          saveChildToComitie={saveChildToComitie}
          committeeID={comitieId}
          loadingSave={loadingSave}
        />
      )}

      <TableLayout
        title={
          <div
            onClick={() => onlyView && history.push(`?tab=2`)}
            className={classNames('tw-text-lg tw-font-semibold tw-flex tw-gap-1 tw-items-center', {
              'tw-text-primary tw-cursor-pointer': onlyView,
            })}
          >
            {t('comitatiPage.comitatiCollegati')}
            <ArrowRightIcon className={onlyView ? 'tw-block' : 'tw-hidden'} />
          </div>
        }
      >
        <TableLayout.Actions>
          {!onlyView ? (
            <Button
              type="primary"
              onClick={() => setAvailableChildComitiesModal(true)}
              icon={<PlusOutlined />}
            >
              {t('buttons.aggiungiNuovo')}
            </Button>
          ) : null}
        </TableLayout.Actions>
        <TableLayout.Content>
          <Table
            className="table-height"
            columns={relatedCommitiesColumns}
            dataSource={relatedComities}
            rowKey={(obj) => obj.id}
            size="small"
            loading={loadingRelatedComities}
            pagination={false}
            expandable={{
              expandedRowRender,
              expandedRowKeys: tableExpendedRowKeys,
              onExpand: (expanded, record) => {
                var keys = [];
                if (expanded) {
                  setExpendedComitieProjects(null);
                  keys.push(record.id);
                }
                setTableExpendedRowKeys(keys);
                if (expanded) {
                  getExpendedComitiesProject(record.id);
                }
              },
            }}
          />
        </TableLayout.Content>
      </TableLayout>
    </>
  );
};

export default RelatedComities;
