import { Component } from 'react';

import { Card, Col, DatePicker, Form, Input, Modal, Row, Select, Switch } from 'antd';
import moment from 'moment';
import ProgettiService from '../../../../services/pages/projectServices/progettiService';
import ModalFooterButtons from '../../../shared/components/modal/modalFooterButtons';
import UserSelectDropDown from '../../../shared/components/userSelect/userSelect';
import { formItemLayout, projectStatusAsNumber } from '../../../shared/utils/constants';
import { areDatesValid, showErrorNotification, validateProjectCode } from '../../../shared/utils/functions';
import { requiredFields } from '../../../shared/utils/notifyMessages';
import '../../matrix.scss';

const { Option } = Select;
const { TextArea } = Input;

class AddProgettiModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statusList: null,
      isMandatory: false,
      parentProjectList: null,
      preDefinedProjectCode: null,
      projectCodeValue: null,
      selectedProjectData: null,
      divisionProjectList: null,
    };
  }

  componentDidMount() {
    const { selectedXmatrixInfo } = this.props;

    this.getParentProjects();

    if (selectedXmatrixInfo) {
      this.getSecondLevelParentProject(selectedXmatrixInfo.xMatrixID);
    }
  }

  async getSecondLevelParentProject(xmId) {
    if (!xmId) {
      return;
    }

    await ProgettiService.getDivisionProjects(xmId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ divisionProjectList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async getParentProjects() {
    await ProgettiService.getParentProjects()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ parentProjectList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  getProjectData = (id) => {
    ProgettiService.getProgettiByiD(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ selectedProjectData: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  };

  onProjectClear = () => {
    this.setState({ preDefinedProjectCode: null });
    this.setState({ selectedProjectData: null });
  };

  onProjectSelect = (option) => {
    const { parentProjectList } = this.state;
    var selectedProject = parentProjectList.filter((obj) => {
      return obj.projectID === option;
    })[0];

    this.getProjectData(option);
    this.setState({ preDefinedProjectCode: selectedProject.projectCode });
  };

  onProjectCodeChange = (e) => {
    this.setState({ projectCodeValue: e.target.value });
  };

  addData = (values) => {
    const { preDefinedProjectCode, projectCodeValue } = this.state;
    values['code'] = preDefinedProjectCode ? preDefinedProjectCode + '-' + projectCodeValue : projectCodeValue;

    this.props.handleObProgettiSave(values);
  };

  onStatusChange = (value, option) => {
    if (option.children === 'Attivo') {
      this.setState({ isMandatory: true });
    } else {
      this.setState({ isMandatory: false });
    }
  };

  disabledStartDate(current) {
    const { selectedProjectData } = this.state;

    if (selectedProjectData) {
      const curr = moment(current).endOf('day');
      const startDate = moment(selectedProjectData.startDate).endOf('day');
      const endDate = moment(selectedProjectData.endDate).endOf('day');
      return curr < startDate || curr > endDate;
    }
  }

  checkValidityBeforeSave = (values) => {
    const { t } = this.props;
    if (areDatesValid(values.startDate, values.endDate)) {
      this.addData(values);
    } else {
      showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
    }
  };

  render() {
    const {
      showObProgetti,
      handleObProgettiClose,
      commitiePickList,
      statusPickList,
      loadingButton,
      divisionsList,
      isSecondLevelXmatrix,
      selectedXmatrixInfo,
      t,
    } = this.props;
    const { isMandatory, parentProjectList, preDefinedProjectCode, projectCodeValue, divisionProjectList } = this.state;

    return (
      <Modal
        className="add_ob_modal"
        width={800}
        title={t('proggetiPage.aggiungiNuovoProgetto')}
        open={showObProgetti}
        destroyOnClose={true}
        onCancel={handleObProgettiClose}
        footer={[
          <ModalFooterButtons
            key={'modalButtons'}
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleObProgettiClose}
            formId={'newProgettoXmatrix'}
          />,
        ]}
      >
        <Card>
          <Form
            id="newProgettoXmatrix"
            name="add_ob_project"
            layout="vertical"
            {...formItemLayout}
            onFinish={this.checkValidityBeforeSave}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}
          >
            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Form.Item
                  label={t('proggetiPage.nomeProgetto')}
                  name="name"
                  rules={[{ required: true, message: requiredFields.required }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Form.Item
                  label={t('proggetiPage.progettoPrincipale')}
                  name="parentProjectID"
                  hasFeedback
                >
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    style={{ width: '100%' }}
                    placeholder="Seleziona"
                    onSelect={(option) => this.onProjectSelect(option)}
                    onClear={() => this.onProjectClear()}
                  >
                    {!isSecondLevelXmatrix && (
                      <>
                        {parentProjectList &&
                          parentProjectList.map((item) => (
                            <Option
                              value={item.projectID}
                              key={item.projectID}
                            >
                              {item.name} ({item.projectCode})
                            </Option>
                          ))}
                      </>
                    )}

                    {isSecondLevelXmatrix && (
                      <>
                        {divisionProjectList &&
                          divisionProjectList.map((item) => (
                            <Option
                              value={item.projectID}
                              key={item.projectID}
                            >
                              {item.name} ({item.projectCode})
                            </Option>
                          ))}
                      </>
                    )}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  extra={t('proggetiPage.codiceLungeza')}
                  label={t('proggetiPage.codiceProgetto')}
                  name="projectCode"
                  rules={[
                    {
                      required: true,
                      message: requiredFields.required,
                    },
                    {
                      max: 10,
                      message: `${t('proggetiPage.codiceLungeza')}`,
                    },
                    {
                      validator: validateProjectCode,
                    },
                  ]}
                  //initialValue={preDefinedProjectCode}
                >
                  <Input
                    // value={preDefinedProjectCode ? `${preDefinedProjectCode} - ` : ""}
                    addonBefore={preDefinedProjectCode ? `${preDefinedProjectCode}-` : null}
                    value={projectCodeValue}
                    onChange={(e) => this.onProjectCodeChange(e)}
                    maxLength={10}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  label={t('general.stato')}
                  name="status"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={projectStatusAsNumber.draft}
                >
                  <Select
                    allowClear
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                    onChange={(value, option) => this.onStatusChange(value, option)}
                  >
                    {statusPickList &&
                      statusPickList.map((item) => (
                        <Option
                          value={item.statusID}
                          key={item.statusID}
                        >
                          {item.statusDescription}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  label={t('proggetiPage.revisioneEsterna')}
                  name="hasExternalReview"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Switch />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 6 }}
              >
                <UserSelectDropDown
                  disabled={false}
                  allowClear={true}
                  fieldLabel={t('general.teamLeader')}
                  formName={'teamLeaderID'}
                  required={isMandatory}
                  initValue={null}
                />

                {/* <Form.Item
                  label={t("general.teamLeader")}
                  name="teamLeaderID"
                  hasFeedback
                  rules={isMandatory ? [{ required: true, message: requiredFields.required }]  : ""}
                >
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: "100%" }}
                    placeholder={t("general.seleziona")}
                  >
                    {usersPickList &&
                      usersPickList.map((item) => (
                        <Option value={item.userID} key={item.userID}>
                          {item.fullName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item> */}
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 6 }}
              >
                <UserSelectDropDown
                  disabled={false}
                  allowClear={true}
                  fieldLabel={t('general.sponsor')}
                  formName={'sponsorID'}
                  required={isMandatory}
                  initValue={null}
                />

                {/* <Form.Item
                  label={t("general.sponsor")}
                  name="sponsorID"
                  hasFeedback
                  rules={isMandatory ? [{ required: true, message: requiredFields.required }] : "" }
                >
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: "100%" }}
                    placeholder={t("general.seleziona")}
                  >
                    {usersPickList &&
                      usersPickList.map((item) => (
                        <Option value={item.userID} key={item.userID}>
                          {item.fullName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item> */}
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 6 }}
              >
                <Form.Item
                  label={t('general.comitato')}
                  name="committeeID"
                  hasFeedback
                  // rules={isMandatory ? [{ required: true, message: requiredFields.required }] : ""}
                >
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                  >
                    {commitiePickList &&
                      commitiePickList.map((item) => (
                        <Option
                          disabled={item.hasAccess === 1 ? false : true}
                          value={item.committeeID}
                          key={item.committeeID}
                        >
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 6 }}
              >
                <Form.Item
                  labelAlign="left"
                  label={t('general.divisione')}
                  name="divisionID"
                  hasFeedback
                  initialValue={
                    isSecondLevelXmatrix ? (selectedXmatrixInfo ? selectedXmatrixInfo.divisionID : null) : null
                  }
                >
                  <Select
                    disabled={isSecondLevelXmatrix ? true : false}
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                  >
                    {divisionsList &&
                      divisionsList.map((item) => (
                        <Option
                          disabled={item.hasAccess === 1 ? false : true}
                          value={item.divisionID}
                          key={item.divisionID}
                        >
                          {item.divisionName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  label={t('general.start')}
                  name="startDate"
                  rules={[{ required: true, message: requiredFields.required }]}
                  // initialValue = {currentDate}
                >
                  <DatePicker
                    disabledTime
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                    disabledDate={(current) => this.disabledStartDate(current)}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  label={t('general.end')}
                  name="endDate"
                  rules={[{ required: true, message: requiredFields.required }]}
                  // initialValue = {lastDayOfYear}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                    disabledDate={(current) => this.disabledStartDate(current)}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Form.Item
                  label={t('general.nota')}
                  name="note"
                  initialValue=""
                >
                  <TextArea rows={4} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default AddProgettiModal;
