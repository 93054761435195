import { Form } from 'antd';
import type { DataIndex } from 'rc-table/lib/interface';
import { type FC, type Key, useEffect, useState } from 'react';

import { CheckOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Select, Switch, Table, Tooltip, message } from 'antd';

import type { TableColumnType } from 'antd/lib';
import type { ColumnType } from 'antd/lib/table';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'src/components/shared/utils/functions';
import type { GetProjectMembersResponseDto } from 'src/connectors/backend';
import removeDuplicates from 'src/utils/removeDuplicates';
import ModalFooterButtons from '../components/shared/components/modal/modalFooterButtons';
import { userRolesCodes } from '../components/shared/utils/constants';
import { notifyMessages } from '../components/shared/utils/notifyMessages';
import CommonService from '../services/commonService';

type ProjectTeamMember = {
  FullName?: string | null;
  Availability?: number;
  JobTitle?: string | null;
  UserID: string;
  onCall?: string | null;
  HasOpenActivities: boolean;
  IsDeleted: boolean;
};

const adaptProjectTeamMembers: (member: GetProjectMembersResponseDto) => ProjectTeamMember = (member) => {
  return {
    FullName: member.fullName ?? '',
    JobTitle: member.jobTitle ?? '',
    UserID: member.userID!,
    onCall: member.onCall ? member.onCall.toString() : undefined,
    IsDeleted: member.isDeleted,
    HasOpenActivities: false,
    Availability: 0,
  };
};

const ManageTeamMembersModal: FC<{
  showTeamMemberModal: boolean;
  handleTeamMemberModalClose: () => void;
  loadingButton: boolean;
  handleTeamMemberModalSave: (values: Key[]) => void;
  projectId: string;
  teamMembers: GetProjectMembersResponseDto[];
}> = ({
  showTeamMemberModal,
  handleTeamMemberModalClose,
  loadingButton,
  handleTeamMemberModalSave,
  projectId,
  teamMembers,
}) => {
  const { t } = useTranslation();

  const sponsorAndTeamLeader = teamMembers.filter(
    (obj) => obj.roleCode === userRolesCodes.sponsor || obj.roleCode === userRolesCodes.teamLeader,
  );

  const [columns, setColumns] = useState<TableColumnType<ProjectTeamMember>[]>([]);
  const [loadingUsersAvailability, setLoadingUsersAvailability] = useState(false);
  const [userAvailabilityList, setUserAvailabilityList] = useState<ProjectTeamMember[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState(
    removeDuplicates(teamMembers, 'userID').map((a) => a.userID as Key),
  );

  useEffect(() => {
    void retrieveUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getColumnSearchProps = (
    dataIndex: DataIndex<ProjectTeamMember>,
  ): Pick<ColumnType<ProjectTeamMember>, 'filterDropdown' | 'filterIcon' | 'onFilter'> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div className="tw-p-2 tw-grid tw-grid-cols-2 tw-gap-2">
        <Input
          placeholder={t('general.search')}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          className="tw-col-span-2"
        />
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
        >
          {t('general.search')}
        </Button>
        <Button
          onClick={() => {
            if (clearFilters) {
              clearFilters();
              confirm();
            }
          }}
        >
          {t('Common.reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) => {
      if (dataIndex === 'fullName') {
        return record.FullName?.toLowerCase().includes((value as string).toLowerCase()) ?? true;
      }

      return true;
    },
  });

  const retrieveUsers = async () => {
    setLoadingUsersAvailability(true);

    try {
      const { data } = await CommonService.getUsersAvailability(projectId);

      if (!data.success || !data.responseObject?.value) {
        throw new Error('Failed loading content');
      }

      const availableUsers = data.responseObject.value as ProjectTeamMember[];

      for (const user of availableUsers) {
        user.Availability = Object.entries(user)
          .filter(([k]) => /\d/.test(k[0]))
          .map((kv) => kv as [string, number])
          .reduce((total, [, v]) => v + total, 0);
      }

      const result = [...availableUsers, ...sponsorAndTeamLeader.map(adaptProjectTeamMembers)];

      // sort the result for better user experience
      result.sort((a, b) => (a.FullName ?? '').localeCompare(b.FullName ?? ''));

      const availabilities = Object.keys(availableUsers[0] || {}).filter((k) => /\d/.test(k[0]));

      setColumns([
        {
          title: `${t('general.nome')}`,
          dataIndex: ['FullName'],
          key: 'FullName',
          width: '170px',
          fixed: 'left',
          sorter: (a, b) => {
            return (a.FullName ?? '').localeCompare(b.FullName ?? '');
          },
          showSorterTooltip: false,
          ellipsis: {
            showTitle: false,
          },
          defaultSortOrder: 'ascend',
          render: (_, { FullName }) => <Tooltip title={FullName}>{FullName}</Tooltip>,
          ...getColumnSearchProps('fullName'),
        },
        {
          title: `${t('utentiPage.titoloLavoro')}`,
          dataIndex: ['JobTitle'],
          key: 'JobTitle',
          width: '150px',
          sorter: (a, b) => {
            return (a.JobTitle ?? '').localeCompare(b.JobTitle ?? '');
          },
          showSorterTooltip: false,
          ellipsis: {
            showTitle: false,
          },
          render: (_, { JobTitle }) => <Tooltip title={JobTitle}>{JobTitle}</Tooltip>,
        },
        {
          title: `${t('utentiPage.disponibilita')}`,
          dataIndex: ['Availability'],
        },
        ...availabilities.map((availability) => {
          return {
            dataIndex: availability,
            title: capitalizeFirstLetter(moment(availabilities).format('MMM YYYY')),
          };
        }),
      ] as TableColumnType<ProjectTeamMember>[]);

      setUserAvailabilityList(removeDuplicates(result, 'UserID'));
    } catch {
      void message.error(notifyMessages.retrieveFailed);
    } finally {
      setLoadingUsersAvailability(false);
    }
  };

  const updateMembers = (values: Key[]) => {
    handleTeamMemberModalSave(values);
  };

  const toggleAvailableUsers = (checked: boolean) => {
    if (checked) {
      const filteredData = userAvailabilityList.filter((item) => {
        return (item.Availability ?? 0) > 0 || sponsorAndTeamLeader.map((u) => u.userID).includes(item.UserID);
      });
      setUserAvailabilityList(filteredData);
    } else {
      void retrieveUsers();
    }
  };

  return (
    <Modal
      className="team_modal"
      width={1100}
      title={t('proggetiPage.gestireMembriProgetto')}
      open={showTeamMemberModal}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={handleTeamMemberModalClose}
      footer={[
        <ModalFooterButtons
          disabled={selectedRowKeys.length === 0}
          loadingButton={loadingButton}
          modalClose={handleTeamMemberModalClose}
          formId={'projectMembersForm'}
          key={'projectMembersForm'}
        />,
      ]}
    >
      {userAvailabilityList && (
        <Form
          id="projectMembersForm"
          name="add_ob_lungo"
          layout="vertical"
          onFinish={() => updateMembers(selectedRowKeys)}
          className="tw-flex tw-flex-col tw-gap-3"
        >
          <div className="tw-flex tw-flex-col tw-gap-2 tw-pr-2">
            <label title={t('proggetiPage.membriProgetto')}>{t('proggetiPage.membriProgetto')}</label>
            <Select
              value={selectedRowKeys}
              loading={loadingUsersAvailability}
              onChange={setSelectedRowKeys}
              options={userAvailabilityList.map((item) => ({
                value: item.UserID,
                disabled:
                  sponsorAndTeamLeader.map((u) => u.userID).includes(item.UserID) ||
                  item.HasOpenActivities ||
                  item.IsDeleted,
                key: item.UserID,
                label: item.FullName ?? '',
              }))}
              showSearch
              mode="multiple"
              className="tw-w-full"
              placeholder={t('general.seleziona')}
              optionFilterProp="label"
            />
          </div>

          <div className="tw-flex tw-gap-2">
            <span>{t('proggetiPage.soloDisponibili')} </span>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked={false}
              onChange={(checked) => toggleAvailableUsers(checked)}
            />
          </div>

          <Table
            rowSelection={{
              selectedRowKeys,
              onChange: (keys: Key[]) => setSelectedRowKeys(keys),
              hideSelectAll: true,
              getCheckboxProps: (record) => ({
                disabled:
                  sponsorAndTeamLeader.map((u) => u.userID).includes(record.UserID) ||
                  record.HasOpenActivities ||
                  record.IsDeleted,
              }),
            }}
            dataSource={userAvailabilityList}
            columns={columns}
            scroll={{ x: 'auto' }}
            size="small"
            bordered={true}
            rowKey={(record) => record.UserID}
            loading={loadingUsersAvailability}
            className="tw-max-h-[500px]"
          />
        </Form>
      )}
    </Modal>
  );
};

export default ManageTeamMembersModal;
