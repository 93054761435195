import { Form } from 'antd';
import { type FC, useState } from 'react';

import { Card, Col, Modal, Row } from 'antd';
import BraftEditor from 'braft-editor';
import { useTranslation } from 'react-i18next';
import type { KpiDto } from 'src/connectors/backend';
import ModalFooterButtons from '../shared/components/modal/modalFooterButtons';
import { formItemLayout, richTectEditoControllsNoImage } from '../shared/utils/constants';

const EditKpiCommentModal: FC<{
  handleCommentModalClose: () => void;
  loadingCommentSave: boolean;
  onCommentUpdate: (payload: { kpiDetailId: string; comment: string }) => Promise<void>;
  rowData: {
    kpiDetail: Pick<KpiDto['kpiDetail'], 'id' | 'name' | 'type'>;
    notes?: string | null;
  };
  showCommentModal: boolean;
  kpiName?: string;
}> = ({ handleCommentModalClose, loadingCommentSave, onCommentUpdate, rowData, showCommentModal, kpiName }) => {
  const { t } = useTranslation();
  type FormData = { comment: any };

  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [editorState, setEditorState] = useState(BraftEditor.createEditorState(rowData.notes));

  const onSave = () => {
    onCommentUpdate({
      comment: editorState.toHTML(),
      kpiDetailId: rowData?.kpiDetail.id,
    });
  };

  return (
    <Modal
      className="add-comment-modal"
      width={800}
      title={`${t('general.commenti')} : ${kpiName ?? rowData.kpiDetail.name}`}
      open={showCommentModal}
      destroyOnClose={true}
      onCancel={handleCommentModalClose}
      footer={[
        <ModalFooterButtons
          disabled={!fieldsChanged}
          loadingButton={loadingCommentSave}
          modalClose={handleCommentModalClose}
          key={kpiName ?? rowData.kpiDetail.name}
          formId={'addKpiComment'}
        />,
      ]}>
      <Card>
        <Form<FormData>
          id="addKpiComment"
          layout="vertical"
          {...formItemLayout}
          onFinish={onSave}
          onValuesChange={() => setFieldsChanged(true)}>
          <Row
            gutter={{ lg: 24 }}
            className="row-margin">
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}>
              <Form.Item<FormData>
                className="main-container"
                name="comment"
                initialValue={BraftEditor.createEditorState(rowData.notes)}>
                <div className="tw-rounded-lg tw-grow tw-overflow-hidden">
                  <BraftEditor
                    value={editorState}
                    onChange={(value) => {
                      setEditorState(value);
                      setFieldsChanged(true);
                    }}
                    language="en"
                    id="editor-text"
                    controls={richTectEditoControllsNoImage}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
};

export default EditKpiCommentModal;
