import { EditOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Row, Space, Typography, message } from 'antd';
import Parser from 'html-react-parser';
import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  type GetAnualGoalDetailsDto,
  type GetKPIDetailDto,
  type GetKPIsDetailDto,
  type GetProjectResponseDto,
  KpiDetailType,
} from 'src/connectors/backend';
import KpiService from '../../../services/pages/kpiService';
import ObiettiviAnnoService from '../../../services/pages/obiettiviAnnoService';
import ProgettiService from '../../../services/pages/projectServices/progettiService';
import '../../kpiListPage/kpi.scss';
import Loader2 from '../../shared/components/loader2/loader2';
import TableLayout from '../../shared/tableLayout';
import { kpiTypeRealtion } from '../../shared/utils/constants';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import '../kpiDashboars.scss';
import EditKpiCommentModal from '../kpiDetailCommentModal';
import KpiBowlingChartGraph from './kpiBowlingChartGraph';
import KpiDetailGraph from './kpiDetailGraph';
import PreviousYearChart from './PreviousYearChart';

const { Text } = Typography;

const KpiDetails: FC = () => {
  const { kdid, pid, aid, xmatrix, type, month, year } = useParams();
  const { t } = useTranslation();

  const [kpiData, setKpiData] = useState<GetKPIsDetailDto>();
  const [kpiComments, setKpiComments] = useState<string>('');
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [loadingSaveComment, setLoadingSaveComment] = useState(false);
  const [selectedKpiData, setSelectedKpiData] = useState<GetKPIDetailDto>();
  const [projectInfo, setProjectInfo] = useState<GetProjectResponseDto>();
  const [annualGoalInfo, setAnnualGoalInfo] = useState<GetAnualGoalDetailsDto>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (kdid) {
      getKpiData(kdid);
      getKpiDetailComment(kdid);
    }

    if (pid && pid !== '0') {
      getProjectData(pid);
    } else if (aid && aid !== '0') {
      if (xmatrix) {
        getObiettiviAnnoData(aid, xmatrix);
      }
    }
  }, []);

  const getKpiData = async (id: string) => {
    try {
      const response = await KpiService.getKpiByiD(id);
      const resp = response.data;

      if (resp.success) {
        setKpiData(resp.responseObject?.value);
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
    }
  };

  const getKpiDetailComment = async (id: string) => {
    let objectId = '';
    let kpiType = '';

    if (pid && pid !== '0') {
      objectId = pid;
      kpiType = kpiTypeRealtion.project;
    }
    if (aid && aid !== '0') {
      objectId = aid;
      kpiType = kpiTypeRealtion.annualGoal;
    }

    if (type === 'p') {
      kpiType = kpiTypeRealtion.project;
    }

    if (type === 'a') {
      kpiType = kpiTypeRealtion.annualGoal;
    }

    try {
      const response = await KpiService.getKpiComments(id, objectId, kpiType);
      const resp = response.data;

      if (resp.success) {
        const respData = resp.responseObject?.value?.comment;

        if (respData) {
          setKpiComments(respData);
        }
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
    }
  };

  const getProjectData = async (id: string) => {
    try {
      const response = await ProgettiService.getProgettiByiD(id);
      const resp = response.data;

      if (resp.success) {
        setProjectInfo(resp.responseObject?.value);
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
    }
  };

  const getObiettiviAnnoData = async (id: string, xmatrixId: string) => {
    try {
      const response = await ObiettiviAnnoService.getObiettiviAnnoByiD(id, xmatrixId);
      const resp = response.data;

      if (resp.success) {
        setAnnualGoalInfo(resp.responseObject?.value);
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
    }
  };

  const handleKpiCommentClose = () => {
    setShowCommentModal(false);
    setLoadingSaveComment(false);
  };

  const toggleKpiComments = (data?: GetKPIDetailDto) => {
    // data['kpiName'] = data?.name;

    if (data) {
      data.additionalNote = kpiComments;

      setSelectedKpiData(data);
      setShowCommentModal(true);
    }
  };

  const onCommentSave = async (payload: any) => {
    const kpiDetailId = kpiData?.getKPIDetail?.kpiDetailID;

    if (!kpiDetailId || !kdid) return;

    setLoadingSaveComment(true);

    try {
      await KpiService.onCommentSave(kpiDetailId, {
        annualGoalId: aid && aid !== '0' ? aid : null,
        committeeId: null, // TODO: implement backend logic in future
        longTermGoalId: null, // TODO: implement backend logic in future
        projectId: pid && pid !== '0' ? pid : null,
        comment: payload.comment,
      });

      message.success(notifyMessages.updateSuccess);
      handleKpiCommentClose();
      await getKpiDetailComment(kdid);
    } catch (error) {
      setLoadingSaveComment(false);
      message.error(notifyMessages.updateFailed);
    }
  };

  return (
    <>
      {showCommentModal && selectedKpiData && (
        <EditKpiCommentModal
          showCommentModal={showCommentModal}
          handleCommentModalClose={handleKpiCommentClose}
          onCommentUpdate={onCommentSave}
          loadingCommentSave={loadingSaveComment}
          kpiName={''}
          rowData={{
            kpiDetail: {
              id: kpiData?.getKPIDetail?.kpiDetailID as string,
              type: KpiService.isGlobal(kpiData?.getKPIDetail?.type ?? undefined)
                ? KpiDetailType.Global
                : KpiDetailType.Specific,
              name: kpiData?.getKPIDetail?.name as string,
            },
            notes: kpiComments,
          }}
        />
      )}

      {!kpiData && <Loader2 />}

      {kpiData && (
        <div className="dash-kpi-wrapper">
          <div className="kpiSintesi">
            <div className="site-card-wrapper">
              <h3 className="objective-item">{kpiData.getKPIDetail?.name}</h3>
              <span>{t('general.kpi')}</span>
            </div>

            <Row
              className="das-header"
              gutter={{ lg: 24 }}
              style={{ alignItems: 'center', padding: '0px 30px 0px 30px' }}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Space direction="horizontal">
                  <Text> {t('kpiDashboard.kpiDetails')}: </Text>
                  <Divider
                    type="vertical"
                    className="kpiInfoDivider" />

                  <Text> {kpiData.getKPIDetail?.parentTypeDescription}</Text>
                  <Divider
                    type="vertical"
                    className="kpiInfoDivider" />

                  <Text> {kpiData.getKPIDetail?.calculationTypeDescription}</Text>
                  <Divider
                    type="vertical"
                    className="kpiInfoDivider" />

                  <Text> {kpiData.getKPIDetail?.typeDescription}</Text>
                  <Divider
                    type="vertical"
                    className="kpiInfoDivider" />

                  {projectInfo && (
                    <>
                      <Text> {t('general.progetto')}: </Text>
                      <Text>
                        {' '}
                        {projectInfo?.name} ({projectInfo.projectCode})
                      </Text>
                    </>
                  )}

                  {annualGoalInfo?.getAnnualGoalDto && (
                    <>
                      <Text> {t('general.obiettiviAnno')}: </Text>
                      <Text> {annualGoalInfo.getAnnualGoalDto.description} </Text>
                    </>
                  )}
                </Space>
              </Col>

              {/* <Col xs={{ span: 8 }} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                          <Text level={5}> {t('kpiDashboard.kpiDetails')}</Text>
                                      </Col> */}
              {/* <Col xs={{ span: 8 }} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                          <Text>{t('general.progetto')}: </Text>
                                      </Col> */}

              {/* <Col xs={{ span: 8 }} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }}>
                              <Text> {kpiData.getKPIDetail.parentTypeDescription}</Text>
                          </Col>

                          <Col xs={{ span: 8 }} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }}>
                              <Text> {kpiData.getKPIDetail.typeDescription}</Text>
                          </Col>

                          <Col xs={{ span: 8 }} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }}>
                              <Text> {kpiData.getKPIDetail.calculationTypeDescription}</Text>
                          </Col> */}
            </Row>

            <Row gutter={{ lg: 24 }}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Card
                  className="sub500-delChart-card-body"
                  title={t('kpiDashboard.kpiIndicatori')}>
                  <KpiDetailGraph
                    xmatrix={xmatrix}
                    kpiDetailId={kdid}
                    annualId={aid}
                    projectId={pid}
                    month={month}
                    year={year}
                    t={t}
                  />
                </Card>
              </Col>
            </Row>

            <Row gutter={{ lg: 24 }}>
              {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 9 }}>
                              <Card size='small' className='sub460-delChart-card-body' title={t('kpiDashboard.kpiGraph')}>
                                  <KpiGraph xmatrix={xmatrix} kpiDetailId={kdid} annualId={aid} projectId={pid} month={month} year={year} t={t} />
                              </Card>
                          </Col> */}

              <Col
                xs={{ span: 24 }}
                sm={{ span: 18 }}
                md={{ span: 18 }}
                lg={{ span: 18 }}>
                <TableLayout className='tw-px-6' title={t('kpiDashboard.anniPrecedenti')}>
                  <TableLayout.Content>
                    {xmatrix && kdid && month && year && (
                      <PreviousYearChart
                        xmatrix={xmatrix}
                        kpiDetailId={kdid}
                        annualId={aid}
                        projectId={pid}
                        month={month}
                        year={year}
                      />
                    )}
                  </TableLayout.Content>
                </TableLayout>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 6 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}>
                <Card
                  className="sub460-delChart-card-body"
                  title={t('general.commenti')}
                  extra={<EditOutlined onClick={() => toggleKpiComments(kpiData.getKPIDetail)} />}>
                  {Parser(kpiComments)}
                </Card>
              </Col>
            </Row>

            <TableLayout title={t('bowlingChartPage.bowlingChart')} className='tw-px-6 tw-pb-4'> 
              <TableLayout.Content>
                <div className="tw-overflow-x-auto">
                  <KpiBowlingChartGraph
                    xmatrix={xmatrix}
                    kpiDetailId={kdid}
                    annualId={aid}
                    projectId={pid}
                    month={month}
                    year={year}
                    t={t}
                  />
                </div>
              </TableLayout.Content>
            </TableLayout>
          </div>
        </div>
      )}
    </>
  );
};

export default KpiDetails;
