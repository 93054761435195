import { Form } from 'antd';
import { type FC, useEffect, useState } from 'react';

import { Button, Input, InputNumber, Popconfirm, Select, Switch, message } from 'antd';
import { endOfDay, isBefore, parse, parseISO } from 'date-fns';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ProjectSectionTitle from 'src/components/shared/components/ProjectSectionTitle/ProjectSectionTitle';
import Loader from 'src/components/shared/components/loader/loader';
import TableLayout from 'src/components/shared/tableLayout';
import { enumCast } from 'src/config/connectors';
import {
  type GetCommitteePicklistDto,
  type GetDivisionPicklistDto,
  type GetProjectCreationPicklistResponseDto,
  type GetProjectResponseDto,
  type GetXMatrixPicklistResponseDto,
  type ProjectDto,
  ProjectStatus,
  type StatusPicklistResponseDto,
} from 'src/connectors/backend';
import { getProjectById } from 'src/redux/slices/projectSlice';
import { useAppSelector } from 'src/redux/store';
import MatrixService from 'src/services/matrix/matrixService';
import CommonService from '../../../../services/commonService';
import DashboardService from '../../../../services/pages/dashboardService';
import ProgettiService from '../../../../services/pages/projectServices/progettiService';
import UserSelectDropDown from '../../../shared/components/userSelect/userSelect';
import { userIsAdminOrProjectTeam } from '../../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { DatePickerWithDateFns, projectStatusAsNumber, statusPickListCodes } from '../../../shared/utils/constants';
import {
  areDatesValid,
  handleDateConvert,
  showErrorNotification,
  updateNotificationBell,
} from '../../../shared/utils/functions';
import { notifyMessages, requiredFields } from '../../../shared/utils/notifyMessages';
import '../../project.scss';

const { Option } = Select;

const SettingsAndTeam: FC<{
  projectId: string;
  hasUserAccess: boolean;
  currentProject: GetProjectResponseDto | null;
}> = ({ projectId, hasUserAccess, currentProject: propsCurrentProject }) => {
  const { t } = useTranslation();
  const userData = useAppSelector((state) => state.userData.userData);
  const [form] = Form.useForm<GetProjectResponseDto>();
  const dispatch = useDispatch();

  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingButtonDelete, setLoadingButtonDelete] = useState(false);
  const [xmatrixPicklist, setXmatrixPicklist] = useState<GetXMatrixPicklistResponseDto[]>([]);
  const [committeesPicklist, setCommitteesPicklist] = useState<GetCommitteePicklistDto[]>([]);
  const [projectPickList, setProjectPickList] = useState<GetProjectCreationPicklistResponseDto[]>([]);
  const [divisionsList, setDivisionsList] = useState<GetDivisionPicklistDto[]>([]);
  const [statusList, setStatusList] = useState<StatusPicklistResponseDto[]>([]);
  const [currentProject, setCurrentProject] = useState<ProjectDto>();
  const [projectFieldsChanged, setProjectFieldsChanged] = useState(false);
  const [selectedParentProjectData, setSelectedParentProjectData] = useState<GetProjectResponseDto>();
  const [isMandatory, setIsMandatory] = useState(false);

  useEffect(() => {
    if (projectId) {
      void fetchComponentData(projectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (currentProject) {
      void fetchCommittees();
    }

    if (currentProject?.parentProjectId) {
      void fetchParentProject(currentProject.parentProjectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  const fetchComponentData = async (projectId: string) => {
    await Promise.all([
      fetchStatusPicklist(),
      fetchProjectsPicklist(projectId),
      fetchDivisionsPicklist(),
      fetchXmatrixPicklist(),
      fetchProjectById(projectId),
    ]);
  };

  const fetchParentProject = async (id: string) => {
    try {
      const response = await ProgettiService.getParentProjectByID(id);
      const resp = response.data;

      if (resp.success && resp.responseObject?.value) {
        setSelectedParentProjectData(resp.responseObject.value);
      }
    } catch {
      void message.error(notifyMessages.retrieveFailed);
    }
  };

  const fetchProjectById = async (id: string) => {
    try {
      const response = await ProgettiService.getProjectById(id);
      const resp = response.data;

      if (resp.success && resp.responseObject?.value) {
        setCurrentProject(resp.responseObject.value);
      } else {
        void message.error(notifyMessages.retrieveFailed);
      }
    } catch (error) {
      void message.error(notifyMessages.retrieveFailed);
    }
  };

  const fetchCommittees = async () => {
    try {
      const response = await CommonService.getCommitiePicklist(currentProject?.type);
      const resp = response.data;

      if (resp.success && resp.responseObject?.value) {
        setCommitteesPicklist(resp.responseObject.value);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchXmatrixPicklist = async () => {
    const response = await MatrixService.getXmatrixPicklist();
    const resp = response.data;

    if (resp.success && resp.responseObject?.value) {
      setXmatrixPicklist(resp.responseObject.value);
    }
  };

  const fetchStatusPicklist = async () => {
    try {
      const objectCode = statusPickListCodes.statusProjects;
      const response = await CommonService.getStatusData(objectCode);
      const resp = response.data;

      if (resp.success) {
        setStatusList(resp.responseObject?.value ?? []);
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchProjectsPicklist = async (projectId: string) => {
    try {
      const response = await ProgettiService.getParentProjects(projectId);
      const resp = response.data;

      if (resp.success) {
        setProjectPickList(resp.responseObject?.value ?? []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDivisionsPicklist = async () => {
    try {
      const response = await DashboardService.getDivisionFilterData();
      const resp = response.data;

      if (resp.success) {
        setDivisionsList(resp.responseObject?.value ?? []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteProject = async (projectId: string) => {
    setLoadingButtonDelete(true);

    try {
      const response = await ProgettiService.deleteProject(projectId);
      const resp = response.data;

      if (resp.success) {
        setCurrentProject(undefined);
        message.success(notifyMessages.deleteSuccess);

        // TODO: handle it correctly
        window.location.replace('/progetti');
      } else {
        message.error(notifyMessages.deleteFailed);
      }
    } catch (error) {
      message.error(notifyMessages.deleteFailed);
    } finally {
      setLoadingButtonDelete(false);
    }
  };

  const disabledDate = (current: Date, dateType: 'start' | 'end') => {
    if (dateType === 'start') {
      if (selectedParentProjectData) {
        const currProject = moment(current).endOf('day');
        const startDate = moment(selectedParentProjectData.startDate).endOf('day');
        const endDate = moment(selectedParentProjectData.endDate).endOf('day');

        return currProject < startDate || currProject > endDate;
      }
    } else {
      if (selectedParentProjectData) {
        const currProject = moment(current).endOf('day');
        const startDate = moment(selectedParentProjectData.startDate).endOf('day');
        const endDate = moment(selectedParentProjectData.endDate).endOf('day');

        return currProject < startDate || currProject > endDate;
      }

      const currentProjectDate = endOfDay(current);
      const startDate = endOfDay(parse(currentProject?.startDate as string, 'yyyy-MM-dd', new Date()));

      return isBefore(currentProjectDate, startDate);
    }

    return false;
  };

  const checkProjectEndDateValidity = (endDate: string) => {
    const currentEndOfDay = endOfDay(new Date());
    const projectEndDate = endOfDay(parseISO(endDate));

    return isBefore(projectEndDate, currentEndOfDay);
  };

  const updateProjectForm = async (values: GetProjectResponseDto) => {
    setLoadingButton(true);

    try {
      const response = await ProgettiService.updateProject(projectId, {
        name: values.name as string,
        code: values.projectCode as string,
        parentProjectId: values.parentProjectID,
        teamLeaderId: values.teamLeaderID,
        sponsorId: values.sponsorID,
        committeeId: values.committeeID,
        divisionId: values.divisionID,
        hasExternalReview: !!values.hasExternalReview,
        budget: values.budget,
        status: enumCast(ProjectStatus, values.status as number),
        masterplanDuration: values.masterplanDuration,
        endDate: handleDateConvert(values.endDate) as string,
        startDate: handleDateConvert(values.startDate) as string,
      });
      const resp = response.data;

      if (resp.success) {
        message.success(notifyMessages.updateSuccess);
        updateNotificationBell();
        void fetchProjectById(projectId);
        dispatch(getProjectById({ projectId }));
        setLoadingButton(false);
      } else {
        message.error(notifyMessages.updateFailed);
        setLoadingButton(false);
      }
    } catch (error) {
      message.error(notifyMessages.updateFailed);
      setLoadingButton(false);
    }
  };

  const editIsDisabled =
    !hasUserAccess && !userIsAdminOrProjectTeam(userData, currentProject?.teamLeader?.id, currentProject?.sponsor?.id);

  if (!currentProject) {
    return <Loader />;
  }

  return (
    <TableLayout title={<ProjectSectionTitle title={t('proggetiPage.projectSettings')} />}>
      <TableLayout.Actions>
        <Button
          loading={loadingButton}
          type="primary"
          htmlType="submit"
          onClick={() => form.submit()}
          key="add"
          disabled={!projectFieldsChanged}
        >
          {t('buttons.salva')}
        </Button>
      </TableLayout.Actions>

      <TableLayout.Content>
        <Form<GetProjectResponseDto>
          className="!tw-max-w-5xl"
          name="project_details_formData"
          layout="vertical"
          form={form}
          onFinish={(values) => {
            if (areDatesValid(values.startDate, values.endDate)) {
              void updateProjectForm(values);
            } else {
              showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
            }
          }}
          onValuesChange={() => {
            setProjectFieldsChanged(true);
          }}
        >
          <div className="tw-grid tw-grid-cols-2 tw-gap-x-6">
            <Form.Item<GetProjectResponseDto>
              label={t('proggetiPage.nomeProgetto')}
              name="name"
              rules={[{ required: true, message: requiredFields.required }]}
              initialValue={currentProject?.name}
            >
              <Input disabled={editIsDisabled} />
            </Form.Item>

            <Form.Item<GetProjectResponseDto>
              label={t('proggetiPage.progettoPrincipale')}
              name="parentProjectID"
              initialValue={currentProject?.parentProjectId}
            >
              <Select
                disabled={editIsDisabled}
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children?.[0].toLowerCase().indexOf(input.trim().toLowerCase()) >= 0
                }
                placeholder={t('general.seleziona')}
                onSelect={(option) => {
                  void fetchParentProject(option);
                }}
                onClear={() => {
                  setSelectedParentProjectData(undefined);
                }}
              >
                {projectPickList.map((item) => (
                  <Option
                    value={item.projectID}
                    key={item.projectID}
                  >
                    {item.name} ({item.projectCode})
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="tw-grid tw-grid-cols-2 tw-gap-x-6">
            <Form.Item<GetProjectResponseDto>
              className="projectCode"
              extra={t('proggetiPage.codiceLungeza')}
              label={t('proggetiPage.codiceProgetto')}
              name="projectCode"
              rules={[
                { required: true, message: requiredFields.required },
                {
                  max: 10,
                  message: `${t('proggetiPage.codiceLungeza')}`,
                },
              ]}
              initialValue={currentProject?.code}
            >
              <Input
                maxLength={10}
                disabled={editIsDisabled}
              />
            </Form.Item>

            <Form.Item<GetProjectResponseDto>
              label={t('general.xmatrix')}
              name="xMatrixID"
              initialValue={
                ProgettiService.isOperational(currentProject?.type) ? undefined : propsCurrentProject?.xMatrixID
              }
            >
              <Select
                disabled={
                  true || // TODO: fix this whenever the API to change xMatrix is available
                  editIsDisabled
                }
                showSearch
                filterOption={(input, option) => option?.key.toLowerCase().includes(input.trim().toLowerCase())}
                placeholder={t('general.seleziona')}
                style={{ width: '100%' }}
              >
                {xmatrixPicklist.map((item) => (
                  <Option
                    value={item.xMatrixID}
                    key={item.hkCode}
                  >
                    {item.hkCode}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="tw-grid tw-grid-cols-2 tw-gap-x-6">
            <UserSelectDropDown
              disabled={editIsDisabled || checkProjectEndDateValidity(currentProject.endDate as string)}
              allowClear={false}
              fieldLabel={t('general.teamLeader')}
              formName={'teamLeaderID'}
              required={isMandatory ? true : enumCast(ProjectStatus, currentProject.statusId) === ProjectStatus.Active}
              initValue={currentProject?.teamLeader?.id}
            />

            <UserSelectDropDown
              disabled={editIsDisabled || checkProjectEndDateValidity(currentProject.endDate as string)}
              allowClear={false}
              fieldLabel={t('general.sponsor')}
              formName={'sponsorID'}
              required={isMandatory ? true : enumCast(ProjectStatus, currentProject.statusId) === ProjectStatus.Active}
              initValue={currentProject?.sponsor?.id}
            />
          </div>

          <div className="tw-grid tw-grid-cols-2 tw-gap-x-6">
            <Form.Item<GetProjectResponseDto>
              label={t('general.comitato')}
              name="committeeID"
              hasFeedback
              // rules={[{ required: currentProject?.status === projectStatusAsNumber.active ? true : false, message: requiredFields.required }]}
              // rules={isMandatory ? [{ required: true, message: requiredFields.required }] : [{ required: currentProject?.status === projectStatusAsNumber.active ? true : false, message: requiredFields.required }]}
              initialValue={currentProject?.committee?.id}
            >
              <Select
                disabled={editIsDisabled}
                allowClear
                placeholder={t('general.seleziona')}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children?.[0]?.toLowerCase()?.indexOf(input.trim().toLowerCase()) >= 0
                }
              >
                {committeesPicklist.map((item) => (
                  <Option
                    disabled={item.hasAccess !== 1}
                    value={item.committeeID}
                    key={item.committeeID}
                  >
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item<GetProjectResponseDto>
              label={t('general.divisione')}
              name="divisionID"
              hasFeedback
              initialValue={currentProject?.division?.id}
            >
              <Select
                disabled={editIsDisabled || !!currentProject.parentProjectId}
                allowClear
                placeholder={t('general.seleziona')}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children?.[0]?.toLowerCase()?.indexOf(input.trim().toLowerCase()) >= 0
                }
              >
                {divisionsList.map((item) => (
                  <Option
                    disabled={item.hasAccess !== 1}
                    value={item.divisionID}
                    key={item.divisionID}
                  >
                    {item.divisionName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="tw-grid tw-grid-cols-4 tw-gap-x-6">
            <Form.Item<GetProjectResponseDto>
              label={t('proggetiPage.revisioneEsterna')}
              name="hasExternalReview"
              valuePropName="checked"
              initialValue={propsCurrentProject?.hasExternalReview}
            >
              <Switch disabled={editIsDisabled} />
            </Form.Item>
            <Form.Item<GetProjectResponseDto>
              label={t('general.budget')}
              name="budget"
              initialValue={propsCurrentProject?.budget}
            >
              <InputNumber
                min={0}
                className="tw-w-full"
                disabled={editIsDisabled}
              />
            </Form.Item>

            <Form.Item<GetProjectResponseDto>
              label={t('general.stato')}
              name="status"
              hasFeedback
              rules={[{ required: true, message: requiredFields.required }]}
              initialValue={currentProject?.statusId}
            >
              <Select
                disabled={editIsDisabled}
                placeholder={t('general.seleziona')}
                onChange={(value) => {
                  setIsMandatory(value === projectStatusAsNumber.active);
                }}
              >
                {statusList.map((item) => (
                  <Option
                    value={item.statusID}
                    key={item.statusID}
                  >
                    {item.statusDescription}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item<GetProjectResponseDto>
              label={t('a3Page.monthsNumber')}
              name="masterplanDuration"
              initialValue={propsCurrentProject?.masterplanDuration}
            >
              <InputNumber
                className="tw-w-full"
                min={0}
                disabled={editIsDisabled}
              />
            </Form.Item>
          </div>

          <div className="tw-grid tw-grid-cols-2 tw-gap-x-6">
            <Form.Item<GetProjectResponseDto>
              label={t('general.start')}
              name="startDate"
              rules={[{ required: true, message: requiredFields.required }]}
              initialValue={parse(currentProject.startDate as string, 'yyyy-MM-dd', new Date())}
            >
              <DatePickerWithDateFns
                className="tw-w-full"
                disabled={propsCurrentProject?.status !== projectStatusAsNumber.draft}
                format="DD/MM/YYYY"
                disabledDate={(current) => disabledDate(current, 'start')}
              />
            </Form.Item>

            <Form.Item<GetProjectResponseDto>
              label={t('general.end')}
              name="endDate"
              rules={[{ required: true, message: requiredFields.required }]}
              initialValue={parse(currentProject.endDate as string, 'yyyy-MM-dd', new Date())}
            >
              <DatePickerWithDateFns
                className="tw-w-full"
                disabled={editIsDisabled}
                format="DD/MM/YYYY"
                disabledDate={(current) => disabledDate(current, 'end')}
              />
            </Form.Item>
          </div>

          <div>
            <Form.Item
              label={t('proggetiPage.isOperative')}
              name="isOperational"
              valuePropName="checked"
              initialValue={ProgettiService.isOperational(currentProject?.type)}
            >
              <Switch disabled={true} />
            </Form.Item>
          </div>

          {propsCurrentProject?.status === projectStatusAsNumber.draft && (
            <Form.Item<GetProjectResponseDto> className="tw-bg-red-50 tw-p-3 tw-rounded-lg">
              <h2 className="tw-text-sm tw-font-semibold tw-mb-4 tw-text-slate-700">{t('proggetiPage.dangerZone')}</h2>

              <Popconfirm
                placement="topRight"
                title={t('proggetiPage.eliminaProgetto')}
                onConfirm={() => {
                  void deleteProject(projectId);
                }}
                okText={t('general.si')}
                cancelText={t('general.no')}
              >
                <Button
                  className="tw-font-semibold"
                  loading={loadingButtonDelete}
                  key="delete"
                  danger
                  type="primary"
                >
                  {t('proggetiPage.deleteProject')}
                </Button>
              </Popconfirm>
            </Form.Item>
          )}
        </Form>
      </TableLayout.Content>
    </TableLayout>
  );
};

export default SettingsAndTeam;
