import { Table, Tooltip, Typography } from 'antd';
import classNames from 'classnames';
import { type FC, useEffect, useRef, useState } from 'react';
import FixedSelectedAsset from 'src/components/xMatrix/FixedSelectedAsset';
import type { XMatrixDto } from 'src/connectors/backend';
import '../../matrix.scss';

interface UsersXmatrixProps {
  usersXmatrix: XMatrixDto['users'];
  onRowClick: (id: string) => void;
  selection: 'primary' | 'secondary';
  selectedIds: string[];
}

const UsersXmatrix: FC<UsersXmatrixProps> = ({ usersXmatrix, onRowClick, selection, selectedIds }) => {
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const rowRefs = useRef<any[]>([]);

  useEffect(() => {
    if (selection !== 'primary') {
      setSelectedRecord(null);
    }
  }, [selection]);

  const selectRow = (record: any, index: number) => {
    onRowClick(record.userID);
    setSelectedRecord(record);
    setSelectedIndex(index);
  };

  const columns = [
    {
      key: 'combinedColumn',
      render: (_: any, record: any, index: number) => ({
        children: (
          <div
            ref={(el) => (rowRefs.current[index] = el)}
            onClick={() => selectRow(record, index)}
            className={`tw-flex tw-py-1 tw-h-full tw-w-[32px] tw-items-center tw-justify-end ${classNames({
              'tw-bg-primary selected-row': selection === 'primary' && selectedIds?.includes(record.userID),
              'tw-bg-primary/30 selected-row': selection === 'secondary' && selectedIds?.includes(record.userID),
            })}`}>
            <div className="tw-flex tw-items-center tw-gap-2">
              <Tooltip title={record.title}>
                <Typography.Text className="tw-text-sm tw-rotate-180 tw-truncate tw-text-black">
                  {record.title}
                </Typography.Text>
              </Tooltip>
              <div
                className={`xMatrix-square-label -tw-rotate-90 !tw-bg-zinc-100`}
                key={index}>
                {usersXmatrix!.length - index}
              </div>
            </div>
          </div>
        ),
      }),
    },
  ];

  if (!usersXmatrix) return null;

  return (
    <>
      {selectedRecord && (
        <FixedSelectedAsset
          side="right"
          listOfRefs={rowRefs}
          selectedIndex={selectedIndex}>
          <p>{selectedRecord.title}</p>
        </FixedSelectedAsset>
      )}

      <Table
        className="table-full-height"
        dataSource={[...usersXmatrix.toReversed()]}
        pagination={false}
        bordered
        size="small"
        showHeader={false}
        columns={columns}
        rowKey={(obj: any) => obj.userID}></Table>
    </>
  );
};

export default UsersXmatrix;
