import { trackPromise } from 'react-promise-tracker';
import { type AdminInsertCompanyRequestDto, GeneralApi, authenticatedApi } from 'src/config/connectors';

const CompaniesListPageService = {
  // get companies
  getCompaniesList: async () => {
    const api = await authenticatedApi(GeneralApi);
    return trackPromise(api.generalGetCompanyListByUserID());
  },

  // add company
  addCompany: async (payload: AdminInsertCompanyRequestDto) => {
    const api = await authenticatedApi(GeneralApi);
    return trackPromise(api.generalInsertCompany(payload));
  },
};

export default CompaniesListPageService;
