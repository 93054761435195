import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Modal, Popconfirm, Statistic } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import '../components/xMatrix/matrix.scss';
import { KPIs } from 'src/connectors/backend';

interface KpiDetailsModalProps {
  show: boolean;
  handleModalCancel: () => void;
  data: KPIs;
  loadingButton: boolean;
  deleteKpi: (id: string) => void;
}

const KpiDetailsModal: FC<KpiDetailsModalProps> = ({
  deleteKpi,
  show,
  handleModalCancel,
  data,
  loadingButton
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="add_ob_modal"
      width={800}
      title={`${t('general.detagli')}: ${data.title}`}
      open={show}
      destroyOnClose={true}
      onCancel={handleModalCancel}
      footer={
        <div className='tw-flex tw-justify-between tw-items-center tw-gap-2'>
          <Popconfirm
            title={t('xMatrixPage.rimuoviIndicatoriKpi')}
            icon={<WarningOutlined />}
            okText={t('general.si')}
            cancelText={t('general.no')}
            onConfirm={() => deleteKpi(data.kpiid ?? '')}>
            <Button
              loading={loadingButton}
              icon={<DeleteOutlined />}
              danger>
              {t('buttons.elimina')}
            </Button>
          </Popconfirm>
          <Button onClick={handleModalCancel}>{t('buttons.chiudi')}</Button>
        </div>
      }>
      <Card>
        <div className='tw-flex tw-justify-between tw-items-center'>
          <div className='tw-flex tw-gap-1'>
            <span>{t('general.stato')}:</span>
            <span className='tw-font-semibold'>{data.statusDescription}</span>
          </div>
        </div>

        <Divider />

        <div className='tw-grid sm:tw-grid-cols-4 tw-grid-cols-2 tw-gap-y-3'>
          <Statistic
            title={t('general.jumpOffPoint')}
            value={data.jumpOffPoint || data.jumpOffPoint === 0 ? data.jumpOffPoint : '-'}
          />

          <Statistic
            title={t('general.target')}
            value={data.target || data.target === 0 ? data.target : '-'}
          />

          <Statistic
            title={t('general.actualYtd')}
            value={data.actualYTD || data.actualYTD === 0 ? data.actualYTD : '-'}
          />

          <Statistic
            title={t('general.targetYtd')}
            value={data.targetYTD || data.targetYTD === 0 ? data.targetYTD : '-'}
          />
        </div>
      </Card>
    </Modal>
  );

}

export default KpiDetailsModal;
