import { FolderOpenOutlined, InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Popover, Space, Table, Tag, Tooltip, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { FilterDropdownProps, Key } from 'antd/es/table/interface';
import moment from 'moment';
import type { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { capitalizeFirstLetter, numberWithCommas } from 'src/components/shared/utils/functions';
import { enumCast } from 'src/config/connectors';
import {
  type BowlingChartEntry,
  type GroupedPlanningsDto,
  KpiDetailCalculationType,
  KpiDetailParentType,
  KpiDetailType,
} from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';

const { Text } = Typography;

export const BowlingChartTable: FC<{
  data: BowlingChartEntry[];
  isLoadingData: boolean;
  showKpiRelationDetails: boolean;
  toggleKpiDetailModal: (kpiData: BowlingChartEntry) => Promise<void>;
  year: number;
}> = ({ data, isLoadingData, showKpiRelationDetails, toggleKpiDetailModal, year }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const companyData = useAppSelector((state) => state.companyData.companyData);

  const handleReset = (clearFilters: ({ confirm }: { confirm: boolean }) => void) => {
    clearFilters({ confirm: true });
  };

  const checkCellColor = (
    month: number,
    record: BowlingChartEntry,
    planningType: KpiDetailParentType,
  ): CSSProperties | undefined => {
    const parentType = enumCast(KpiDetailParentType, record.details.parentType);
    if (planningType !== parentType) {
      return undefined;
    }

    const currentYear = moment().year();
    const lastMonthIsPrevious = !companyData?.startFromCurrentMonth;
    const lastMonth = lastMonthIsPrevious ? moment().month() : moment().month() + 1;

    if (year > currentYear || (year === currentYear && month > lastMonth)) {
      return undefined;
    }

    const planning = record.monthlyPlannings[month.toString()];
    const actual = parentType === KpiDetailParentType.Cumulative ? planning?.actualYTD : planning?.actual;
    const target = parentType === KpiDetailParentType.Cumulative ? planning?.targetYTD : planning?.target;

    const tolerance = (companyData?.kpiTolerance ?? 0) / 100;
    const calculationType = enumCast(KpiDetailCalculationType, record.details.calculationType);

    let targetDistance = 0;
    let targetTolerance = 0;
    if (calculationType === KpiDetailCalculationType.IsCompleted) {
      targetDistance = target - actual;
    } else if (calculationType === KpiDetailCalculationType.Minimum) {
      targetDistance = actual - target;
      targetTolerance = Math.abs(target * tolerance);
    } else {
      targetDistance = target - actual;
      targetTolerance = Math.abs(target * tolerance);
    }

    const greenColor = '#6ABF6A';
    const redColor = '#DC2626';

    return {
      backgroundColor: targetDistance > targetTolerance ? redColor : greenColor,
      color: 'white',
    };
  };

  const renderedColumns = () => {
    const columns: ColumnsType<BowlingChartEntry> = [
      {
        shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search"
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => confirm()}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => confirm()}
                icon={<SearchOutlined />}
                style={{ width: 90 }}>
                Search
              </Button>
              <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered: boolean) => (
          <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />
        ),
        onFilter: (value: Key | boolean, record: BowlingChartEntry) =>
          record.details.name.toLowerCase().includes(`${value}`.trim().toLowerCase()),

        dataIndex: ['details', 'name'],
        title: 'KPI',
        fixed: 'left',
        width: '7%',
        ellipsis: {
          showTitle: false,
        },
        render: (text: string, record: BowlingChartEntry, _: number) => {
          const showRelations =
            showKpiRelationDetails && enumCast(KpiDetailType, record.details.type) !== KpiDetailType.Global;
          const val = (
            <Space direction="vertical">
              <Tooltip title={text}>{text}</Tooltip>
              <Popover
                content={
                  <Space direction="vertical">
                    <Text style={{ fontSize: 14 }}>
                      <span className="header-text"> {t('kpiPage.cumulatoPuntuale')}: </span>
                      <u>
                        <b>{t(`kpi.parentTypes.${enumCast(KpiDetailParentType, record.details.parentType)}`)}</b>
                      </u>
                    </Text>
                    <Text style={{ fontSize: 14 }}>
                      <span className="header-text"> {t('kpiPage.tipoCalcolo')}: </span>
                      <u>
                        <b>
                          {t(
                            `kpi.calculationTypes.${enumCast(KpiDetailCalculationType, record.details.calculationType)}`,
                          )}
                        </b>
                      </u>
                    </Text>
                  </Space>
                }
                placement="right"
                trigger="click">
                <InfoCircleOutlined style={{ fontSize: 13 }} />
              </Popover>

              {showRelations && (
                <>
                  {record.annualGoalName && (
                    <Text
                      style={{ width: 100 }}
                      ellipsis={{ tooltip: record.annualGoalName }}>
                      {record.annualGoalName}
                    </Text>
                  )}
                  {record.committeeName && (
                    <Text
                      style={{ width: 100 }}
                      ellipsis={{ tooltip: record.committeeName }}>
                      {record.committeeName}
                    </Text>
                  )}
                  {record.projectName && (
                    <Text
                      style={{ width: 100 }}
                      ellipsis={{ tooltip: record.projectName }}>
                      {record.projectName}
                    </Text>
                  )}
                </>
              )}

              {record.details.isRollupKpi && (
                <Button
                  className="tw-m-0 tw-p-0"
                  data-action="kpi-details"
                  type="text"
                  size="small"
                  onClick={() => toggleKpiDetailModal(record)}>
                  <Tag>
                    <Text className="tw-text-green-700">{t('kpiPage.gerarchicho')}</Text>
                    <FolderOpenOutlined />
                  </Tag>
                </Button>
              )}
            </Space>
          );

          return {
            children: val,
          };
        },
      },
      {
        dataIndex: 'headings',
        title: '',
        width: '6%',
        ellipsis: {
          showTitle: false,
        },
        shouldCellUpdate: () => false,
        render() {
          return {
            props: { style: { borderBottom: '4px solid #d6d3d1' } },
            children: (
              <Space
                className="tw-w-full"
                direction="vertical"
                size={0}
                split={
                  <Divider
                    type="horizontal"
                    className="tw-m-1"
                  />
                }>
                <Text>Actual</Text>
                <Text>Target</Text>
                <Text>ActualYTD</Text>
                <Text>TargetYTD</Text>
              </Space>
            ),
          };
        },
      },
    ];

    for (let i = 1; i <= 12; i++) {
      const monthName = moment()
        .year(year)
        .month(i - 1)
        .format('MMM YYYY');

      columns.push({
        align: 'right',
        dataIndex: ['monthlyPlannings', `${i}`],
        title: capitalizeFirstLetter(monthName),
        width: '6%',
        shouldCellUpdate: (record, prevRecord) => !shallowEqual(record.monthlyPlannings, prevRecord.monthlyPlannings),
        render: (planning: GroupedPlanningsDto, record: BowlingChartEntry) => {
          return {
            props: { style: { borderBottom: '4px solid #d6d3d1', padding: 0 } },
            children: (
              <Space
                className="tw-w-full tw-p-0 tw-m-0"
                direction="vertical"
                data-month={i}
                size={0}
                split={
                  <Divider
                    type="horizontal"
                    className="tw-m-0"
                  />
                }>
                <Text
                  className="tw-block tw-w-full tw-p-1"
                  data-value="actual"
                  style={checkCellColor(i, record, KpiDetailParentType.Single)}>
                  {numberWithCommas(planning?.actual)}
                </Text>

                <Text
                  className="tw-block tw-w-full tw-p-1"
                  data-value="target">
                  {numberWithCommas(planning?.target)}
                </Text>
                <Text
                  className="tw-block tw-w-full tw-p-1"
                  data-value="actualYTD"
                  style={checkCellColor(i, record, KpiDetailParentType.Cumulative)}>
                  {numberWithCommas(planning?.actualYTD)}
                </Text>
                <Text
                  className="tw-block tw-w-full tw-p-1"
                  data-value="targetYTD">
                  {numberWithCommas(planning?.targetYTD)}
                </Text>
              </Space>
            ),
          };
        },
      });
    }

    return columns;
  };

  return (
    <Form
      form={form}
      component={false}>
      <Table<BowlingChartEntry>
        bordered
        size="small"
        dataSource={data}
        columns={renderedColumns()}
        pagination={false}
        loading={isLoadingData}
        rowKey={({ kpiId }) => kpiId}
      />
    </Form>
  );
};
