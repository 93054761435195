import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import Loader from 'src/components/shared/components/loader/loader';
import KpiService from '../../../services/pages/kpiService';
import '../../kpiListPage/kpi.scss';
import { kpiDetailsPasYearsChartOptions } from '../../personalDashbard/chartsConfig';

interface PreviousYearChartProps {
  xmatrix: string;
  kpiDetailId: string;
  projectId?: string;
  month: string;
  year: string;
  annualId?: string;
}

const PreviousYearChart: React.FC<PreviousYearChartProps> = ({ xmatrix, kpiDetailId, projectId, year, annualId }) => {
  const [chartData, setChartData] = useState<{ series: any; xAxis: any }>(kpiDetailsPasYearsChartOptions);
  const [chartHasData, setChartHasData] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);

  useEffect(() => {
    getChartData();
  }, []);

  const getChartData = () => {
    const filters = {
      XMatrixID: xmatrix,
      KPIDetailID: kpiDetailId,
      year: year,
      ProjectID: projectId && projectId !== '0' ? projectId : null,
      AnnualGoalID: annualId && annualId !== '0' ? annualId : null,
    };

    KpiService.getKpiDetailsPastYears(filters)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          const series: any = [];

          const dataActual = respData.map((item: any) => ({ name: item.year, y: item.actual }));
          const dataTarget = respData.map((item: any) => ({ name: item.year, y: item.target }));

          const seriesObjActual = { name: 'Actual', data: dataActual };
          const seriesObjTarget = { name: 'Target', data: dataTarget };

          series.push(seriesObjActual, seriesObjTarget);

          setChartHasData(respData.length > 0);
          setChartData((prevData) => ({ ...prevData, series, xAxis: { categories: respData.map((a: any) => a.year) } }));
          setLoadingChart(false);
        } else {
          setLoadingChart(false);
        }
      })
      .finally(() => {
        setLoadingChart(false);
      });
  };

  return (
    <>
      {!loadingChart && !chartHasData && <Loader />}
      {chartHasData && <HighchartsReact highcharts={Highcharts} options={chartData} />}
    </>
  );
};

export default PreviousYearChart;