import { trackPromise } from 'react-promise-tracker';
import {
  BowlingChartApi,
  type BowlingChartFilters,
  type BulkUpdateProjectPlanningsRequest,
  type CreateKpiRequest,
  type InsertUserFavouriteKPIRequest,
  KPIApi,
  KpiDetailApi,
  KpiDetailType,
  KpiPlanningApi,
  type UpdateGlobalKPIBowlingChartImportRequestDto,
  type UpdateKPIDetailRequestDto,
  type UpdateKpiDetailCommentRequest,
  XMatrixApi,
  authenticatedApi,
} from 'src/config/connectors';
import { acquireToken } from '../../config/auth/clientAuthProvider';
import axiosInstance from '../../config/axiosConfig/interceptors';

const KpiService = {
  // create kpi (returns kpi dto)
  createKpi: async (payload: CreateKpiRequest) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPICreateKpi(payload));
  },

  // get kpi list data
  getKpiList: async (xmatrixId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetKPIXMAtrixPicklist(xmatrixId));
  },
  //get to populate kpi table
  getKpiListData: async (params?: {
    isProjectRelated?: boolean | null;
    isCommitteeRelated?: boolean | null;
    isAnnualGoalRelated?: boolean | null;
    projectId?: string | null;
    committeeId?: string | null;
    annualGoalId?: string | null;
    includePlannings?: boolean | null;
    planningYear?: number | null;
    kpiDetailType?: KpiDetailType;
  }) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(
      api.kPIListKpis(
        params?.isProjectRelated,
        params?.isCommitteeRelated,
        params?.isAnnualGoalRelated,
        params?.projectId,
        params?.committeeId,
        params?.annualGoalId,
        params?.includePlannings,
        params?.planningYear,
        params?.kpiDetailType,
      ),
    );
  },

  // get kpi data by id
  getKpiByiD: async (id: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIDetail(id));
  },

  // update kpi
  updateKpi: async (updateKpiPayload: UpdateKPIDetailRequestDto) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIUpdateKPIDetail(updateKpiPayload));
  },

  // add new kpi
  addKpi: async (payload: CreateKpiRequest) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPICreateKpi(payload));
  },

  //delete kpi
  deleteKPI: async (id: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIDeleteKPI(id));
  },

  // check if kpi name exist
  checkIfKpiNameExist: async (name: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPICheckDuplicateKPI(name));
  },

  getKpiSpecificoExcelData: async (kPIProjectRelationShipID: string, projectID: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetSpecificKPIBowlingChartExport(kPIProjectRelationShipID, projectID));
  },

  getKpiGlobaleExcelData: async (kPIDetailID: string, year: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetGlobalKPIBowlingChartExport(kPIDetailID, year));
  },

  updateGlobalKpiFromExcel: async (payload: Array<UpdateGlobalKPIBowlingChartImportRequestDto>) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIUpdateGlobalKPIBowlingChartImport(payload));
  },

  bulkUpdateAnnualGoalPlannings: async (relationId: string, payload: BulkUpdateProjectPlanningsRequest) => {
    const api = await authenticatedApi(KpiPlanningApi);

    return trackPromise(api.kpiPlanningBulkUpdateAnnualGoalPlannings(relationId, payload));
  },

  bulkUpdateCommitteePlannings: async (relationId: string, payload: BulkUpdateProjectPlanningsRequest) => {
    const api = await authenticatedApi(KpiPlanningApi);

    return trackPromise(api.kpiPlanningBulkUpdateCommitteePlannings(relationId, payload));
  },

  bulkUpdateProjectPlannings: async (relationId: string, payload: BulkUpdateProjectPlanningsRequest) => {
    const api = await authenticatedApi(KpiPlanningApi);

    return trackPromise(api.kpiPlanningBulkUpdateProjectPlannings(relationId, payload));
  },

  getKpiSpecificoExcelDataAnualGoal: async (annualGoalKPIRelationShipID: string, year: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetSpecificAnnualGoalKPIBowlingChartExport(annualGoalKPIRelationShipID, year));
  },

  // get kpi child Data
  getKpiChildList: async (parentKpiId?: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetRollupKPIChild(parentKpiId));
  },

  // get kpi child Data
  getAnnualGoalList: async (kpiDetailId: string, xmatrixId: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetAnnualGoalByKPI(kpiDetailId, xmatrixId));
  },

  // get kpi list data
  getKpiProjectPicklist: async (parentKpiId: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIChildPicklist(parentKpiId));
  },

  insertChildKpi: async (parentKpiId: string, childKpiId: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIInsertKPIChild(parentKpiId, childKpiId));
  },

  setKpiPrefered: async (payload: InsertUserFavouriteKPIRequest) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIInsertUserFavouriteKPI(payload));
  },

  removeKpiPrefered: async (kpiId: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIDeleteUserFavouriteKPI(kpiId));
  },

  getBowlingChartData: async (filters: BowlingChartFilters) => {
    const api = await authenticatedApi(BowlingChartApi);

    return trackPromise(api.bowlingChartQueryBowlingChart(filters));
  },

  getKpiDetailsPastYears: async (filters: { [s: string]: unknown } | ArrayLike<unknown>) => {
    const { accessToken } = (await acquireToken()) as any;
    const queryString = Object.entries({ ...filters })
      .filter(([_, value]) => value !== null && value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const optionsVal = {
      method: 'GET',
      url: `api/KPI/KPIDetailsPastYears?${queryString}`,
      data: filters,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return trackPromise(axiosInstance(optionsVal));
  },

  getKpiGraph: async (filters: { [s: string]: unknown } | ArrayLike<unknown>) => {
    const { accessToken } = (await acquireToken()) as any;
    const queryString = Object.entries({ ...filters })
      .filter(([_, value]) => value !== null && value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const optionsVal = {
      method: 'GET',
      url: `api/KPI/DetailsKPI?${queryString}`,
      data: filters,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return trackPromise(axiosInstance(optionsVal));
  },

  getKpiGraphAsInA3: async (filters: { [s: string]: unknown } | ArrayLike<unknown>) => {
    const { accessToken } = (await acquireToken()) as any;
    const queryString = Object.entries({ ...filters })
      .filter(([_, value]) => value !== null && value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const optionsVal = {
      method: 'GET',
      url: `api/KPI/KPIDetailsGraficoA3?${queryString}`,
      data: filters,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return trackPromise(axiosInstance(optionsVal));
  },

  getKpiComments: async (kpiDetailId: string, projectAnnualGoalId: string, type: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetCommentForKPI(kpiDetailId, projectAnnualGoalId, type));
  },

  onCommentSave: async (kpiDetailId: string, payload: UpdateKpiDetailCommentRequest) => {
    const api = await authenticatedApi(KpiDetailApi);

    return trackPromise(api.kpiDetailUpdateKpiDetailComment(kpiDetailId, payload));
  },

  onKpiChildRemove: async (kpiId: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIRemoveKpiChildParentRelations(kpiId));
  },

  checkKpiYear: async (kpiId: string, type: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPICheckKpiTypePlanningStartDate(kpiId, type));
  },

  getKPIPlanningByID: async (projectId: string, annualGoalId: string, year: number, kpiId: string) => {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIPlanningByID(projectId, annualGoalId, year, kpiId));
  },

  isGlobal(type?: number | KpiDetailType) {
    return type === 1 || type === KpiDetailType.Global;
  },
};

export default KpiService;
