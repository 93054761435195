import { Form } from 'antd';

import { Input, Modal, Select } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalFooterButtons from '../components/shared/components/modal/modalFooterButtons';
import { formItemLayout, longTermGoalStatus } from '../components/shared/utils/constants';
import { requiredFields } from '../components/shared/utils/notifyMessages';

const { Option } = Select;

const AddLongTermGoalModal: FC<{
  showObLungo: boolean;
  handleObLungoClose: () => void;
  statusPickList: any;
  handleObLungoSave: (data: any) => void;
}> = ({ showObLungo, handleObLungoClose, statusPickList, handleObLungoSave }) => {
  const { t } = useTranslation();
  const [loadingButton, setLoadingButton] = useState(false);
  const [fieldsChanged, setFieldsChanged] = useState(false);

  const addData = (values: any) => {
    handleObLungoSave(values);
  };

  return (
    <Modal
      className="add_obLungo_modal"
      width={700}
      title={t('obiettiviLungoPage.aggiungiObjLungo')}
      open={showObLungo}
      destroyOnClose={true}
      onCancel={handleObLungoClose}
      footer={[
        <ModalFooterButtons
          disabled={!fieldsChanged}
          loadingButton={loadingButton}
          modalClose={handleObLungoClose}
          formId={'newObLungoXmatrix'}
        />,
      ]}>
      <Form
        id="newObLungoXmatrix"
        name="add_ob_lungo"
        layout="vertical"
        {...formItemLayout}
        onFinish={addData}
        onValuesChange={() => {
          setFieldsChanged(true);
        }}>
        <Form.Item
          label={t('general.nome')}
          name="title"
          rules={[{ required: true, message: requiredFields.required }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('general.stato')}
          name="status"
          hasFeedback
          rules={[{ required: true, message: requiredFields.required }]}
          initialValue={longTermGoalStatus.active}>
          <Select
            style={{ width: '100%' }}
            placeholder={t('general.seleziona')}>
            {statusPickList &&
              statusPickList.map((item: any) => (
                <Option
                  value={item.statusID}
                  key={item.statusID}>
                  {item.statusDescription}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddLongTermGoalModal;
