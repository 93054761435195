import { Form, Input, Modal, Select } from 'antd';
import { differenceInMinutes, format, parse, parseISO, roundToNearestMinutes } from 'date-fns';
import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TimeRangePicker from 'src/components/shared/TimeRangePicker';
import ModalFooterButtons from 'src/components/shared/components/modal/modalFooterButtons';
import {
  DatePickerWithDateFns,
  formatOfDate,
  formatOfHHMM,
  sessionStatus,
  statusPickListCodes,
} from 'src/components/shared/utils/constants';
import { handleDateConvert } from 'src/components/shared/utils/functions';
import { requiredFields } from 'src/components/shared/utils/notifyMessages';
import type {
  CommitteeDto,
  GetCommitteeSessionsResponseDto,
  InsertUpdateCommitteeSessionRequestDto,
  StatusPicklistResponseDto,
} from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import CommonService from 'src/services/commonService';

interface UpsertSessionModalProps {
  isOpen: boolean;
  onClose: () => void;
  loadingSave: boolean;
  committee: CommitteeDto;
  sessions: GetCommitteeSessionsResponseDto[];
  sessionData?: GetCommitteeSessionsResponseDto;
  upsertSession: (values: InsertUpdateCommitteeSessionRequestDto) => Promise<void>;
}

interface UpsertSessionFormValues {
  code: string;
  date: string;
  state: number;
  startTime: string;
  endTime: string;
}

const UpsertSessionModal: FC<UpsertSessionModalProps> = ({
  isOpen,
  onClose,
  loadingSave,
  committee,
  sessions,
  upsertSession,
  sessionData,
}) => {
  const { t } = useTranslation();
  const activeXmatrix = useAppSelector((state) => state.activeXMatrix.activeXMatrix);

  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [statusPicklist, setSessionStatusList] = useState<StatusPicklistResponseDto[]>([]);

  useEffect(() => {
    void fetchStatusPicklist();
  }, []);

  const findMaxProgressiveNumber = (sessions: GetCommitteeSessionsResponseDto[]) => {
    let maxProgressiveNumber = 0;

    for (const session of sessions) {
      const sessionCode = session.codice;

      if (sessionCode) {
        const regex = /ID (\d+)/;
        const match = sessionCode.match(regex);

        if (match?.[1]) {
          const progressiveNumber = Number.parseInt(match[1], 10);

          if (!Number.isNaN(progressiveNumber) && progressiveNumber > maxProgressiveNumber) {
            maxProgressiveNumber = progressiveNumber;
          }
        }
      }
    }

    return maxProgressiveNumber + 1;
  };

  const fetchStatusPicklist = async () => {
    const objectCode = statusPickListCodes.sessionStatus;

    try {
      const response = await CommonService.getStatusData(objectCode);
      const resp = response.data;

      if (resp.success) {
        setSessionStatusList(resp.responseObject?.value ?? []);
      }
    } catch (error) {}
  };

  const convertTime = (time: string) => {
    const parsedTime = parse(time, 'HH:mm:ss', new Date());
    const roundedTime = roundToNearestMinutes(parsedTime, { nearestTo: 15 });

    return format(roundedTime, formatOfHHMM);
  };

  const saveData = (values: UpsertSessionFormValues) => {
    console.log('🚀 ~ saveData ~ values:', values.endTime);
    console.log(
      '🚀 ~ saveData ~   parse(values.endTime, formatOfHHMM, new Date()):',
      parse(values.endTime, formatOfHHMM, new Date()),
    );
    upsertSession({
      sessionID: sessionData?.sessionID,
      committeeID: committee.id,
      xMatrixID: activeXmatrix?.xMatrixID as string,
      codice: values.code,
      date: handleDateConvert(values.date) as string,
      state: values.state,
      startTime: values.startTime,
      endTime: values.endTime,
      duration: differenceInMinutes(
        parse(values.endTime, `${formatOfHHMM}:ss`, new Date()),
        parse(values.startTime, `${formatOfHHMM}:ss`, new Date()),
      ),
    });
  };

  return (
    <Modal
      width={800}
      title={sessionData ? t('comitatiPage.editSession') : t('comitatiPage.newSession')}
      open={isOpen}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={onClose}
      footer={[
        <ModalFooterButtons
          disabled={!fieldsChanged}
          loadingButton={loadingSave}
          modalClose={onClose}
          formId={'upsertSession'}
          key="upsertSession"
        />,
      ]}
    >
      <Form<UpsertSessionFormValues>
        id="upsertSession"
        layout="vertical"
        onValuesChange={() => {
          setFieldsChanged(true);
        }}
        onFinish={(values) => {
          saveData(values);
        }}
      >
        <div>
          <Form.Item<UpsertSessionFormValues>
            label={t('proggetiPage.codice')}
            name="code"
            rules={[{ required: true, message: requiredFields.required }]}
            initialValue={sessionData?.codice ?? `${committee.name} ID ${findMaxProgressiveNumber(sessions)}`}
          >
            <Input value={`${committee.name} ID ${findMaxProgressiveNumber(sessions)}`} />
          </Form.Item>
        </div>

        <div className="tw-grid tw-grid-cols-2 tw-gap-4">
          <Form.Item<UpsertSessionFormValues>
            label={t('comitatiPage.date')}
            name="date"
            initialValue={sessionData?.date ? parseISO(sessionData.date) : new Date()}
            rules={[{ required: true, message: requiredFields.required }]}
          >
            <DatePickerWithDateFns
              className="tw-w-full"
              format={formatOfDate}
            />
          </Form.Item>

          <Form.Item<UpsertSessionFormValues>
            labelAlign="left"
            label={t('general.stato')}
            name="state"
            rules={[{ required: true, message: requiredFields.required }]}
            initialValue={sessionData?.stateID ?? sessionStatus.toBeConfirmed}
          >
            <Select
              className="tw-w-full"
              placeholder={t('general.seleziona')}
            >
              {statusPicklist.map((item) => (
                <Select.Option
                  value={item.statusID}
                  key={item.statusID}
                >
                  {item.statusDescription}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <TimeRangePicker
          required={true}
          startTime={{
            name: 'startTime',
            defaultValue: sessionData?.startTime ? convertTime(sessionData?.startTime) : undefined,
          }}
          endTime={{
            name: 'endTime',
            defaultValue: sessionData?.endTime ? convertTime(sessionData?.endTime) : undefined,
          }}
        />
      </Form>
    </Modal>
  );
};

export default UpsertSessionModal;
