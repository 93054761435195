import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi } from 'src/config/connectors';
import {
  FeedActivityApi,
  FeedApi,
  type InsertFeedActivityLikeRequestDto,
  type InsertFeedCommentRequestDto,
  type InsertFeedFileRequestDto,
  type InsertFeedMeetingRequestDto,
  type UpdateFeedActivityRequestDto,
} from 'src/connectors/backend';

const FeedService = {
  // get feed list
  getFeedData: async (regardingObjectId: string, userId: string, isProject: string) => {
    const api = await authenticatedApi(FeedApi);

    return api.feedGetFeedActivityList(regardingObjectId, userId, isProject);
  },

  // get feed activity list
  getFeedActivity: async (activityId: string) => {
    const api = await authenticatedApi(FeedApi);

    return api.feedGetFeedActivity(activityId);
  },

  // feed activity like button
  addLike: async (payload: InsertFeedActivityLikeRequestDto) => {
    const api = await authenticatedApi(FeedApi);

    return api.feedInsertFeedActivityLike(payload);
  },

  // add comment
  addFeedComment: async (payload: InsertFeedCommentRequestDto) => {
    const api = await authenticatedApi(FeedApi);

    return api.feedInsertFeedComment(payload);
  },

  // add comment
  addFeedFile: async (payload: InsertFeedFileRequestDto) => {
    const api = await authenticatedApi(FeedApi);

    return api.feedInsertFeedFile(payload);
  },

  // add feed meeting
  addFeedMeeting: async (payload: InsertFeedMeetingRequestDto) => {
    const api = await authenticatedApi(FeedApi);

    return api.feedInsertFeedMeeting(payload);
  },

  // update feed item
  updateFeedItem: async (payload: UpdateFeedActivityRequestDto) => {
    const api = await authenticatedApi(FeedApi);

    return api.feedUpdateFeedActivity(payload);
  },

  // delete feed item
  removeFeedItem: async (activityId: string, outlookEventId: string) => {
    const api = await authenticatedApi(FeedActivityApi);

    return api.feedActivityDeleteFeedActivity(activityId, outlookEventId);
  },

  getFeedNotificationBellItems: async () => {
    const api = await authenticatedApi(FeedApi);

    return trackPromise(api.feedGetFeedMonthlyAlertByUser());
  },

  updateFeedNotificationBell: async () => {
    const api = await authenticatedApi(FeedApi);

    return api.feedUpdateMonthlyAlertFeed();
  },
};

export default FeedService;
