import moment from 'moment';
import { enumCast } from 'src/config/connectors';
import {
  CompanyResponseDto,
  GroupedPlanningsDto,
  KpiDetailCalculationType,
  KpiDetailDto,
  KpiDetailParentType,
  KpiDto,
} from 'src/connectors/backend';

export const getSemaphore = (
  kpiDetails: KpiDetailDto,
  monthlyPlannings: { [key: string]: GroupedPlanningsDto },
  planningType: KpiDetailParentType,
  month: number,
  year?: number,
  companyData?: CompanyResponseDto | null,
): string | undefined => {
  const parentType = enumCast(KpiDetailParentType, kpiDetails.parentType);
  if (planningType !== parentType || !companyData || !year) {
    return undefined;
  }

  const currentYear = moment().year();
  const lastMonthIsPrevious = !companyData?.startFromCurrentMonth;
  const lastMonth = lastMonthIsPrevious ? moment().month() : moment().month() + 1;

  if (year > currentYear || (year === currentYear && month > lastMonth)) {
    return undefined;
  }

  const planning = monthlyPlannings[month.toString()];
  const actual = parentType === KpiDetailParentType.Cumulative ? planning?.actualYTD : planning?.actual;
  const target = parentType === KpiDetailParentType.Cumulative ? planning?.targetYTD : planning?.target;

  const tolerance = (companyData?.kpiTolerance ?? 0) / 100;
  const calculationType = enumCast(KpiDetailCalculationType, kpiDetails.calculationType);

  let targetDistance = 0;
  let targetTolerance = 0;
  if (calculationType === KpiDetailCalculationType.IsCompleted) {
    targetDistance = target - actual;
  } else if (calculationType === KpiDetailCalculationType.Minimum) {
    targetDistance = actual - target;
    targetTolerance = Math.abs(target * tolerance);
  } else {
    targetDistance = target - actual;
    targetTolerance = Math.abs(target * tolerance);
  }

  const greenColor = '#6ABF6A';
  const redColor = '#DC2626';

  return targetDistance > targetTolerance ? redColor : greenColor;
};

export const lastMonthlyPlanning = (record: KpiDto) =>
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  record.monthlyPlannings![Object.entries(record.monthlyPlannings!).length - 1];
