import { FolderOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, TableColumnType, message } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ProgettiService from '../../../services/pages/projectServices/progettiService';
import TableLayout from '../../shared/tableLayout';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import '../project.scss';
import ProjectSectionTitle from 'src/components/shared/components/ProjectSectionTitle/ProjectSectionTitle';
import { useTranslation } from 'react-i18next';
import { GetProjectChildsResponseDto } from 'src/connectors/backend';

const ChildProjects: FC<{ projectId: string }> = ({ projectId }) => {
  const { t } = useTranslation();

  const [loadingData, setLoadingData] = useState(false);
  const [childProjectList, setChildProjectList] = useState<GetProjectChildsResponseDto[]>([]);

  useEffect(() => {
    void fetchChildProjects();
  }, []);

  const getColumnSearchProps: (dataIndex: keyof GetProjectChildsResponseDto) => Pick<TableColumnType<GetProjectChildsResponseDto>, 'filterDropdown' | 'filterIcon' | 'onFilter'> = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div className='tw-grid tw-grid-cols-2 tw-gap-2 tw-p-2'>
        <Input
          placeholder={t('general.search')}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => {
            confirm();
          }}
          className="tw-min-w-44 tw-col-span-2"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}>
            {t('general.search')}
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) {
                clearFilters();
                confirm();
              }
            }}>
            {t('Common.reset')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined className='tw-text-lg' style={{ color: filtered ? '#1890ff' : '#677582' }} />,
    onFilter: (value, record) => {
      if (dataIndex === 'name' || dataIndex === 'projectCode') {
        return (record[dataIndex] ?? '').toString().toLowerCase().includes(value.toString().toLowerCase());
      }

      return true;
    }
  });

  const fetchChildProjects = async () => {
    setLoadingData(true);

    try {
      const { data } = await ProgettiService.getChildProjects(projectId);
      if (data.success) {
        setChildProjectList(data.responseObject?.value ?? []);
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
    } finally {
      setLoadingData(false);
    }
  };

  const columns: TableColumnType<GetProjectChildsResponseDto>[] = [
    {
      title: `${t('general.nome')}`,
      dataIndex: ['name'],
      ellipsis: {
        showTitle: true,
      },
      sorter: (a, b) => {
        return (a.name ?? '').localeCompare(b.name ?? '');
      },
      showSorterTooltip: true,
      ...getColumnSearchProps('name'),
    },
    {
      title: `${t('proggetiPage.codice')}`,
      dataIndex: ['projectCode'],
      sorter: (a, b) => {
        return (a.projectCode ?? '').localeCompare(b.projectCode ?? '');
      },
      showSorterTooltip: true,
    },
    {
      key: 'action',
      width: '60px',
      render: (_, record) => (
          <Button href={`/progetti/id/${record.projectID}`} icon={<FolderOutlined />}></Button>
      ),
    },
  ];

  return (
    <TableLayout title={<ProjectSectionTitle title={t('proggetiPage.childrenProjects')} />}>
      <TableLayout.Content>
        <Table
          sticky
          columns={columns}
          dataSource={childProjectList}
          size="small"
          rowKey={(project) => project.projectID}
          loading={loadingData}
        />
      </TableLayout.Content>
    </TableLayout>
  );
};

export default ChildProjects;
