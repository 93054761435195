import { DeleteOutlined, SelectOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Modal, Popconfirm, Row, Select, message } from 'antd';
import BraftEditor from 'braft-editor';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isTeamleaderOrTeamMemberOrObAnnoResponsible } from 'src/components/shared/utils/authRolesProvilege/authRolesPrivilege';
import type { GetAnualGoalDetailsDto } from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import ObiettiviAnnoService from 'src/services/pages/obiettiviAnnoService';
import ObiettiviLongService from 'src/services/pages/obiettiviLongService';
import ModalFooterButtons from '../../../shared/components/modal/modalFooterButtons';
import { RichTextEditorControls, formItemLayout, goalStatusList } from '../../../shared/utils/constants';
import { notifyMessages, requiredFields } from '../../../shared/utils/notifyMessages';
import '../../matrix.scss';

const { Option } = Select;

interface Props {
  show: boolean;
  handleModalCancel: () => void;
  data: any;
  loadingButton: boolean;
  selectedXmatrix: string;
  handleSave: (values: any, id: string) => void;
  removeAnualGoal: (id: string) => void;
}

const EditYearObjectiveModal: React.FC<Props> = ({
  show,
  handleModalCancel,
  data,
  loadingButton,
  selectedXmatrix,
  handleSave,
  removeAnualGoal,
}) => {
  const userData = useAppSelector((state) => state.userData.userData);

  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [editorValue, setEditorValue] = useState<string>();
  const [isEditorDisabled, setIsEditorDisabled] = useState<boolean>(false);

  const [annualGoalData, setAnnualGoalData] = useState<GetAnualGoalDetailsDto | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    const getObiettiviAnnoData = () => {
      if (!selectedXmatrix || !data.annualGoalID) return;

      ObiettiviAnnoService.getObiettiviAnnoByiD(data.annualGoalID, selectedXmatrix)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            setEditorValue(resp.responseObject?.value?.getAnnualGoalDto?.notes || '');
            setAnnualGoalData(resp.responseObject?.value || null);
          } else {
            message.error((notifyMessages as any).retrieveFailed);
          }
        })
        .catch((error) => {
          message.error((notifyMessages as any).retrieveFailed);
        });
    };

    if (data && selectedXmatrix) {
      getObiettiviAnnoData();
    }
  }, [data, selectedXmatrix]);

  useEffect(() => {
    if (userData && annualGoalData) {
      const isRoleTeamMemberOrTeamLeader =
        userData && Object.keys(userData).length > 0 && data
          ? isTeamleaderOrTeamMemberOrObAnnoResponsible(userData, annualGoalData)
          : false;

      setIsEditorDisabled(isRoleTeamMemberOrTeamLeader);
    }
  }, [userData, annualGoalData]);

  const updateData = (values: any) => {
    handleSave(values, data.annualGoalID);

    if (editorValue) {
      const editorContentToHtml = BraftEditor.createEditorState(editorValue).toHTML();

      // update annual or long term
      ObiettiviLongService.updateCommentOrNameField({
        itemID: data.annualGoalID,
        nameOrNote: editorContentToHtml,
        updateCase: 2,
      });
    }
  };

  const handleRemoveGoal = () => {
    removeAnualGoal(data.annualGoalID);
  };

  return (
    <Modal
      className="edit_ob_modal"
      width={800}
      title={`${data.title}`}
      open={show}
      destroyOnClose={true}
      onCancel={handleModalCancel}
      footer={
        <div className="tw-flex tw-justify-between tw-w-full">
          <div className="tw-flex tw-items-center tw-gap-2">
            <Button
              icon={<SelectOutlined />}
              href={`/obiettiviannuali/details/id/${data.annualGoalID}/2/${selectedXmatrix}`}
            ></Button>
            <Popconfirm
              title={t('obiettiviAnnoPage.eliminareObjAnnoXmatrix')}
              icon={<WarningOutlined />}
              okText={t('general.si')}
              cancelText={t('general.no')}
              onConfirm={handleRemoveGoal}
            >
              <Button
                danger
                loading={loadingButton}
                icon={<DeleteOutlined />}
                type="dashed"
              >
                {t('buttons.rimozioneObiettivoAnnuale')}
              </Button>
            </Popconfirm>
          </div>

          <ModalFooterButtons
            disabled={!fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleModalCancel}
            formId={'editObAnnoXmatrix'}
          />
        </div>
      }
    >
      <Card>
        <Form
          id="editObAnnoXmatrix"
          name="edit_ob_anno"
          layout="vertical"
          {...formItemLayout}
          onFinish={updateData}
          onValuesChange={() => setFieldsChanged(true)}
        >
          <Row gutter={{ lg: 24 }}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 18 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
            >
              <Form.Item
                label={t('general.nome')}
                name="description"
                rules={[{ required: true, message: (requiredFields as any).required }]}
                initialValue={data.title}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 6 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                label={t('general.stato')}
                name="status"
                hasFeedback
                rules={[{ required: true, message: (requiredFields as any).required }]}
                initialValue={data.status}
              >
                <Select style={{ width: '100%' }}>
                  {goalStatusList.map((item) => (
                    <Option
                      value={item.statusID}
                      key={item.statusID}
                    >
                      {item.statusDescription}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {!isEditorDisabled ? (
              <Col xs={{ span: 24 }}>
                <div className="tw-rounded-lg tw-grow tw-overflow-hidden">
                <BraftEditor
                  language="en"
                  id="editor-text"
                  controls={RichTextEditorControls}
                  value={BraftEditor.createEditorState(editorValue)}
                  onChange={(value) => {
                    setEditorValue(value);
                    setFieldsChanged(true);
                  }}
                />
                </div>
              </Col>
            ) : null}
          </Row>
        </Form>
      </Card>
    </Modal>
  );
};

export default EditYearObjectiveModal;
