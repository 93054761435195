import { Button, Divider, Tabs, Tooltip, message } from 'antd';
import { useEffect, useState } from 'react';

import { DeleteOutlined, PlusOutlined, SwapOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd/lib';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Calendar2Fill, CalendarScheduleLine, Group2Line } from 'src/assets/icons';
import ProjectSectionTitle from 'src/components/shared/components/ProjectSectionTitle/ProjectSectionTitle';
import UserBadge from 'src/components/shared/userBadge/userBadge';
import { userIsAdminOrProjectTeam } from 'src/components/shared/utils/authRolesProvilege/authRolesPrivilege';
import { updateNotificationBell } from 'src/components/shared/utils/functions';
import TooltipTruncated from 'src/components/utils/tooltip-truncated';
import type {
  GetProjectMembersResponseDto,
  ProjectRoutineDto,
  SwapTeamMembersActivitiesRequest,
} from 'src/connectors/backend';
import ManageTeamMembersModal from 'src/dialogs/ManageTeamMembersModal';
import SwapTeamMembersModal from 'src/dialogs/SwapTeamMembersModal';
import { useAppSelector } from 'src/redux/store';
import RoutineService from 'src/services/pages/projectServices/routineServices';
import ProgettiService from '../../../../services/pages/projectServices/progettiService';
import Loader from '../../../shared/components/loader/loader';
import { formatOfDate } from '../../../shared/utils/constants';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import '../../project.scss';
import RoutineFormModal from '../mainInfo/projectRoutine/routineFormModal';

type ProjectTeamAndRoutinesProps = {
  hasUserAccess: boolean;
  isPresentationMode: boolean;
};

const ProjectTeamAndRoutines = ({ hasUserAccess, isPresentationMode }: ProjectTeamAndRoutinesProps) => {
  const { t } = useTranslation();

  const userData = useAppSelector((state) => state.userData.userData);
  const currentProject = useAppSelector((state) => state.project.currentProject);

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [teamMembers, setTeamMembers] = useState<GetProjectMembersResponseDto[]>();
  const [filteredTeamMembers, setFilteredTeamMembers] = useState<GetProjectMembersResponseDto[]>([]);
  const [sponsor, setSponsor] = useState<GetProjectMembersResponseDto>();
  const [teamLeader, setTeamLeader] = useState<GetProjectMembersResponseDto>();
  const [routinesList, setRoutinesList] = useState<ProjectRoutineDto[]>([]);
  const [selectedRoutine, setSelectedRoutine] = useState<ProjectRoutineDto>();
  const [showEditRoutineModal, setShowEditRoutineModal] = useState(false);
  const [showNewRoutineModal, setShowNewRoutineModal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [showTeamMemberModal, setShowTeamMemberModal] = useState(false);
  const [showSwapTeamMemberModal, setShowSwapTeamMemberModal] = useState(false);
  const [hoveringDelete, setHoveringDelete] = useState(false);

  useEffect(() => {
    if (!currentProject?.projectID) {
      return;
    }

    void Promise.all([fetchProjectMembers(currentProject?.projectID), fetchProjectRoutines()]);
  }, [currentProject]);

  const fetchProjectMembers = async (id: string) => {
    setIsDataLoading(true);

    try {
      const response = await ProgettiService.getProjectTeamMembers(id);
      const resp = response.data;

      if (resp.success && resp.responseObject?.value) {
        setTeamMembers(resp.responseObject.value);
        setFilteredTeamMembers(
          resp.responseObject.value.filter((m) => m.roleCode !== 'Sponsor' && m.roleCode !== 'TeamLeader'),
        );
        setTeamLeader(resp.responseObject.value.find((member) => member.roleCode === 'TeamLeader'));
        setSponsor(resp.responseObject.value.find((member) => member.roleCode === 'Sponsor'));
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
    } finally {
      setIsDataLoading(false);
    }
  };

  const fetchProjectRoutines = async () => {
    try {
      const { data } = await RoutineService.listProjectRoutines(currentProject?.projectID as string);

      if (!data.success || !data.responseObject?.value) {
        throw new Error('Fetch failed');
      }

      setRoutinesList(data.responseObject.value);
    } catch {}
  };

  const handleNewRoutineModalClose = async (routine?: ProjectRoutineDto) => {
    if (routine) {
      fetchProjectRoutines();
    }

    setShowNewRoutineModal(false);
  };

  const handleUpdateRoutineModalClose = async (routine?: ProjectRoutineDto) => {
    if (routine) {
      fetchProjectRoutines();
      updateNotificationBell();
    }

    setShowEditRoutineModal(false);
  };

  const reloadUsers = () => {
    fetchProjectMembers(currentProject?.projectID as string);
  };

  const handleTeamMemberModalClose = () => {
    setShowTeamMemberModal(false);
    setShowSwapTeamMemberModal(false);
  };

  const addTeamMembers = async (values: string[]) => {
    setLoadingButton(true);

    try {
      const { status, data } = await ProgettiService.updateTeamMembers({
        userIds: values,
        projectID: currentProject?.projectID as string,
      });

      if (status >= 400 && status <= 499) {
        if ('errorDescription' in data) {
          message.error(t(`projectMembers.errors.${data.errorDescription}`));
        }
        setLoadingButton(false);
        return;
      }

      message.success(notifyMessages.updateSuccess);
      updateNotificationBell();
      handleTeamMemberModalClose();
      reloadUsers();
    } catch {
      message.error(notifyMessages.updateFailed);
    } finally {
      setLoadingButton(false);
    }
  };

  const manageSwapTeamMembers = async (values: SwapTeamMembersActivitiesRequest) => {
    setLoadingButton(true);

    try {
      const response = await ProgettiService.swapProjectTeamMembers({
        ...values,
        projectID: currentProject?.projectID as string,
      });
      if (response.data.success) {
        message.success(notifyMessages.updateSuccess);
        handleTeamMemberModalClose();
        reloadUsers();
      } else {
        message.error(notifyMessages.updateFailed);
      }
    } catch {
      message.error(notifyMessages.updateFailed);
    } finally {
      setLoadingButton(false);
    }
  };

  const handleRemoveRoutine = async (projectId: string, routineId: string) => {
    try {
      const { data } = await RoutineService.removeRoutine(projectId, routineId);

      if (!data.success) {
        throw new Error('Removal failed');
      }

      void message.success(notifyMessages.deleteSuccess);

      fetchProjectRoutines();
    } catch {
      void message.error(notifyMessages.deleteFailed);
    }
  };

  const toggleTeamMemberModal = () => setShowTeamMemberModal(true);
  const toggleManageTeamMemberModal = () => setShowSwapTeamMemberModal(true);

  const isAdminOrProjectTeam = userIsAdminOrProjectTeam(
    userData,
    currentProject?.teamLeaderID,
    currentProject?.sponsorID,
  );

  return (
    <>
      <Loader />

      {showNewRoutineModal && (
        <RoutineFormModal
          endDateProject={currentProject?.endDate ?? ''}
          handleClose={handleNewRoutineModalClose}
          loadingButton={loadingButton}
          projectId={currentProject?.projectID ?? ''}
          setLoading={setLoadingButton}
          showModal={showNewRoutineModal}
          startDateProject={currentProject?.startDate ?? ''}
          users={teamMembers ?? []}
        />
      )}
      {showEditRoutineModal && (
        <RoutineFormModal
          routine={selectedRoutine}
          endDateProject={currentProject?.endDate ?? ''}
          handleClose={handleUpdateRoutineModalClose}
          loadingButton={loadingButton}
          projectId={currentProject?.projectID as string}
          setLoading={setLoadingButton}
          showModal={showEditRoutineModal}
          startDateProject={currentProject?.startDate ?? ''}
          users={teamMembers ?? []}
        />
      )}

      {!isPresentationMode && showTeamMemberModal && (
        <ManageTeamMembersModal
          showTeamMemberModal={showTeamMemberModal}
          handleTeamMemberModalClose={handleTeamMemberModalClose}
          handleTeamMemberModalSave={(values) => addTeamMembers(values as string[])}
          projectId={currentProject?.projectID as string}
          teamMembers={teamMembers?.filter((x) => !x.isDeleted) ?? []}
          loadingButton={loadingButton}
        />
      )}

      {!isPresentationMode && showSwapTeamMemberModal && (
        <SwapTeamMembersModal
          showModal={showSwapTeamMemberModal}
          handleModalClose={handleTeamMemberModalClose}
          handleModalSave={manageSwapTeamMembers}
          projectTeam={teamMembers}
          loadingSave={loadingButton}
        />
      )}

      {currentProject && teamMembers && (
        <>
          <ProjectSectionTitle
            title={t('proggetiPage.team')}
            isPresentationMode={true}
          />

          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: t('proggetiPage.teamMembers'),
                children: (
                  <>
                    {(hasUserAccess || isAdminOrProjectTeam) && !isPresentationMode && (
                      <div className="tw-flex tw-justify-end tw-gap-x-2">
                        <Tooltip title={t('proggetiPage.changeTeamMembers')}>
                          <Button
                            onClick={toggleManageTeamMemberModal}
                            type="dashed"
                            icon={<SwapOutlined />}
                          />
                        </Tooltip>

                        <Tooltip
                          placement="topRight"
                          title={t('proggetiPage.gestireMembriProgetto')}>
                          <Button
                            onClick={toggleTeamMemberModal}
                            type="primary"
                            data-testid="addMemberBtn"
                            icon={<PlusOutlined />}>
                            {t('buttons.gestisci')}
                          </Button>
                        </Tooltip>
                      </div>
                    )}
                    <div
                      className="tw-flex tw-flex-col tw-gap-y-6 tw-mt-4"
                      data-testid="teamMembers">
                      <div className="tw-flex tw-gap-x-3 tw-flex-wrap tw-bg-neutral-50 tw-rounded-lg tw-p-4">
                        {teamLeader && (
                          <UserBadge
                            user={teamLeader}
                            projectId={currentProject?.projectID as string}
                          />
                        )}
                        {sponsor && (
                          <UserBadge
                            user={sponsor}
                            projectId={currentProject?.projectID as string}
                          />
                        )}
                      </div>
                      <div className="tw-flex tw-gap-x-3 tw-flex-wrap tw-bg-neutral-50 tw-rounded-lg tw-p-4">
                        {filteredTeamMembers.map((member) => (
                          <div key={member.userID}>
                            {member && (
                              <UserBadge
                                user={member}
                                projectId={currentProject?.projectID as string}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                ),
              },
              {
                key: '2',
                label: t('proggetiPage.routneGestioneProgetto'),
                children: (
                  <div>
                    {!isPresentationMode && (
                      <>
                        <div className="tw-flex tw-justify-end">
                          <Button
                            data-testid="openAddRoutineModal"
                            onClick={() => setShowNewRoutineModal(true)}
                            type="primary"
                            icon={<PlusOutlined />}>
                            {t('buttons.aggiungiNuovo')}
                          </Button>
                        </div>

                        <Divider className="tw-my-3" />
                      </>
                    )}

                    <div className="tw-flex tw-gap-4 tw-flex-wrap">
                      {routinesList.map((routine) => (
                        <button
                          type="button"
                          key={routine.id}
                          onClick={() => {
                            if (!hoveringDelete) {
                              setSelectedRoutine(routine);
                              setShowEditRoutineModal(true);
                            }
                          }}
                          className="tw-bg-zinc-100 hover:tw-bg-zinc-200/50 tw-transition-all hover:tw-shadow-md tw-border tw-rounded-md tw-w-64 tw-shrink-0 border-b">
                          <div className="tw-flex tw-gap-2 tw-border-b tw-w-full tw-p-2 tw-items-center">
                            <TooltipTruncated className="tw-text-lg tw-truncate tw-grow tw-text-left">
                              {routine.name}
                            </TooltipTruncated>
                            <span className="tw-text-lg tw-bg-white tw-text-zinc-500 tw-rounded-md tw-py-0.5 tw-px-2">
                              {routine?.duration}h
                            </span>
                            <span
                              onPointerEnter={() => setHoveringDelete(true)}
                              onPointerLeave={() => setHoveringDelete(false)}>
                              <Popconfirm
                                placement="topRight"
                                title={t('proggetiPage.rimuoviRoutine')}
                                onPopupClick={(e) => {
                                  e?.stopPropagation();
                                  e?.preventDefault();
                                }}
                                onConfirm={(e) => {
                                  e?.stopPropagation();
                                  e?.preventDefault();
                                  handleRemoveRoutine(routine.projectId, routine.id);
                                }}
                                okText={t('general.si')}
                                cancelText={t('general.no')}>
                                <Button
                                  size="middle"
                                  className="tw-text-zinc-500"
                                  icon={<DeleteOutlined />}
                                />
                              </Popconfirm>
                            </span>
                          </div>

                          <div className="tw-flex tw-text-sm tw-flex-col tw-gap-1 tw-p-2">
                            <div className="tw-flex tw-gap-2 tw-items-center">
                              <Group2Line />
                              {routine.typeDescription}
                            </div>
                            <div className="tw-flex tw-text-rest tw-gap-2 tw-items-center">
                              <CalendarScheduleLine />
                              <div>
                                {routine?.schedule} - {routine?.scheduleDescription}
                              </div>
                            </div>
                            <div className="tw-flex tw-text-rest tw-gap-2 tw-items-center">
                              <Calendar2Fill />
                              <div>
                                {moment(routine.startDate).format(formatOfDate)} {'<->'}{' '}
                                {moment(routine.endDate).format(formatOfDate)}
                              </div>
                            </div>
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </>
      )}
    </>
  );
};

export default ProjectTeamAndRoutines;
