import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Card, Empty, Select, Spin, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiCollapseDiagonal2Fill, RiExpandDiagonal2Fill } from 'react-icons/ri';
import {
  type GetXMatrixPicklistResponseDto,
  type ProjectDto,
  ProjectParentship,
  ProjectStatus,
  ProjectType,
  type QueryProjectFilters,
} from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import MatrixService from 'src/services/matrix/matrixService';
import ProgettiService from 'src/services/pages/projectServices/progettiService';
import { getQueryParam, hasQueryParam } from 'src/utils/url-utils';
import DashboardService from '../../services/pages/dashboardService';
import TreeXmatrixFilter from '../shared/components/treeXmatrixFilter/treeXmatrixFilter';
import TableLayout from '../shared/tableLayout';
import './dashboard.scss';
import DashboardFiltersModal from './dashboardFiltersModal';
import ProjectCard from './projectCard';

const { Option } = Select;

interface SortField {
  label: string;
  value: string;
}

const QP_KEYS = {
  projectType: 'projectType',
  committee: 'committee',
  xmatrix: 'xmatrix',
} as const;

export const initialFilterState: QueryProjectFilters = {
  selectNotRelatedProjects: false,
  projectStatuses: [ProjectStatus.Active, ProjectStatus.Draft],
  projectParentship: ProjectParentship.All,
  type: ProjectType.NonOperational,
};

const ProjectsDashboard = () => {
  const { t } = useTranslation();
  const activeXmatrix = useAppSelector((state) => state.activeXMatrix.activeXMatrix);

  const [sortField, setSortField] = useState<keyof ProjectDto>('code');
  const [sortType, setSortType] = useState<'Sort A to Z' | 'Sort Z to A'>('Sort A to Z');
  const [dashboardData, setDashboardData] = useState<ProjectDto[]>([]);
  const [sortFields] = useState<SortField[]>([
    { label: `${t('proggetiPage.nomeProgetto')}`, value: 'name' },
    { label: `${t('proggetiPage.codiceProgetto')}`, value: 'code' },
    { label: `${t('general.start')}`, value: 'startDate' },
    { label: `${t('general.end')}`, value: 'endDate' },
  ]);
  const [sortTypes] = useState<string[]>(['Sort A to Z', 'Sort Z to A']);
  const [allExpanded, setAllExpanded] = useState<boolean>(false);
  const [dashboardFilters, setDashboardFilters] = useState<QueryProjectFilters>(initialFilterState);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [xmatrixPicklist, setXmatrixPicklist] = useState<GetXMatrixPicklistResponseDto[]>([]);
  const [selectedXmatrixIds, setSelectedXmatrixIds] = useState<string[]>([activeXmatrix?.xMatrixID as string]);

  useEffect(() => {
    void fetchXmatrixPicklist();
  }, []);

  useEffect(() => {
    if (xmatrixPicklist.length > 0) {
      const committeeId = hasQueryParam(QP_KEYS.committee) ? getQueryParam(QP_KEYS.committee) : undefined;
      const xmatrix = hasQueryParam(QP_KEYS.xmatrix) ? getQueryParam(QP_KEYS.xmatrix) : undefined;
      const projectType = hasQueryParam(QP_KEYS.projectType) ? getQueryParam(QP_KEYS.projectType) : undefined;

      setDashboardFilters((prev) => ({
        ...prev,
        commiteeIds: committeeId ? [committeeId] : undefined,
        xMatrixIds: xmatrix ? [xmatrix] : [activeXmatrix?.xMatrixID as string],
        type: projectType ? (projectType as ProjectType) : ProjectType.NonOperational,
        selectNotRelatedProjects: projectType === ProjectType.Operational,
      }));
      setSelectedXmatrixIds(xmatrix ? [xmatrix] : [activeXmatrix?.xMatrixID as string]);
    }
  }, [activeXmatrix, xmatrixPicklist]);

  useEffect(() => {
    if (dashboardFilters.xMatrixIds?.length) {
      void retrieveDashboardData(dashboardFilters);
    }
  }, [dashboardFilters]);

  const fetchXmatrixPicklist = async () => {
    const response = await MatrixService.getXmatrixPicklist();
    const resp = response.data;

    if (resp.success) {
      const respData = resp.responseObject?.value;
      setXmatrixPicklist(respData ?? []);
    }
  };

  const retrieveDashboardData = async (dashboardFilters: QueryProjectFilters) => {
    try {
      setIsLoading(true);
      const response = await DashboardService.getDashboardProjects(dashboardFilters);

      const data = response.data;
      setDashboardData(data);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSortedDashboard = () => {
    const tempDashboardData = [...(dashboardData || [])] as ProjectDto[];

    return tempDashboardData.sort((a, b) => {
      if (sortType === 'Sort A to Z') {
        return a?.[sortField] && b && b[sortField] ? (a[sortField] > b[sortField] ? 1 : -1) : 0;
      }
      return a?.[sortField] && b && b[sortField] ? (a[sortField] > b[sortField] ? -1 : 1) : 0;
    });
  };

  const handleModalFiltersClose = () => {
    setIsFilterModalVisible(false);
  };

  const toggleFiltersModal = () => {
    setIsFilterModalVisible(true);
  };

  const handleToggle = () => {
    setAllExpanded(!allExpanded);
  };

  const onTreeXmatrixChange = (xmatrixIds: string[]) => {
    if (xmatrixIds.length > 0) {
      setSelectedXmatrixIds(xmatrixIds);
      setDashboardFilters((prev) => ({
        ...prev,
        xMatrixIds: xmatrixIds,
      }));
    }
  };

  const sortedDashboardData = (dashboardData?.length || 0) > 0 ? getSortedDashboard() : [];

  return (
    <Card
      className="!tw-shadow-none projects-dashboard"
      bordered={false}
    >
      <DashboardFiltersModal
        isVisible={isFilterModalVisible}
        handleModalFiltersClose={handleModalFiltersClose}
        onChange={(filters) => {
          setDashboardFilters(filters);
        }}
        onClearFilters={() => {
          setSelectedXmatrixIds([activeXmatrix?.xMatrixID as string]);
          setDashboardFilters({
            ...initialFilterState,
            xMatrixIds: [activeXmatrix?.xMatrixID as string],
          });
        }}
        dashboardFilters={dashboardFilters}
      />

      <TableLayout
        className="[&_.ant-select]:tw-h-[38px] [&_.ant-select-selector]:!tw-rounded-lg"
        title={
          <div className="tw-flex tw-items-center tw-gap-4">
            <h2 className="tw-text-2xl tw-flex-1 tw-font-medium">{t('dashboardProgettiPage.dashboardProgetti')}</h2>
          </div>
        }
      >
        <TableLayout.Actions>
          <div className="tw-flex tw-items-center tw-gap-1">
            <Switch
              checked={dashboardFilters.type === ProjectType.Operational}
              loading={isLoading}
              onClick={(checked) => {
                setDashboardFilters((prev) => ({
                  ...prev,
                  type: checked ? ProjectType.Operational : ProjectType.NonOperational,
                  selectNotRelatedProjects: checked,
                  xMatrixIds: prev?.xMatrixIds ? prev?.xMatrixIds : undefined,
                }));
              }}
            />
            <span className="tw-w-48">{t('proggetiPage.showOperationalProjects')}</span>
          </div>

          {!ProgettiService.isOperational(dashboardFilters.type) && xmatrixPicklist.length > 0 && (
            <TreeXmatrixFilter
              selectedXmatrixIds={selectedXmatrixIds}
              xmatrixPicklist={xmatrixPicklist}
              onTreeXmatrixChange={onTreeXmatrixChange}
            />
          )}

          <Select
            value={sortField}
            placeholder={t('dashboardProgettiPage.ordinaPer')}
            onChange={(val) => setSortField(val)}
          >
            {sortFields.map((item) => (
              <Option
                value={item.value}
                key={item.value}
              >
                {item.label}
              </Option>
            ))}
          </Select>

          <Select
            value={sortType}
            placeholder={t('dashboardProgettiPage.tipoOrdinamento')}
            onChange={(val) => setSortType(val)}
          >
            {sortTypes.map((item) => (
              <Option
                value={item}
                key={item}
              >
                {item}
              </Option>
            ))}
          </Select>

          <Button
            icon={<FilterOutlined />}
            onClick={() => toggleFiltersModal()}
          >
            {t('dashboardProgettiPage.dashboardFilter')}
          </Button>

          <Button
            icon={!allExpanded ? <RiExpandDiagonal2Fill /> : <RiCollapseDiagonal2Fill />}
            onClick={handleToggle}
          >
            {!allExpanded ? t('buttons.expandAll') : t('buttons.collapseAll')}
          </Button>
        </TableLayout.Actions>

        <TableLayout.SubHeader>
          <div className="empty:tw-hidden tw-gap-2 tw-flex tw-items-center">
            {dashboardFilters.teamLeaderIds?.length ? (
              <Button
                iconPosition="end"
                icon={<CloseOutlined />}
                onClick={() =>
                  setDashboardFilters((prev) => ({
                    ...prev,
                    teamLeaderIds: [],
                  }))
                }
              >
                {t('dashboardProgettiPage.teamLeader')}
              </Button>
            ) : null}

            {dashboardFilters.projectStatuses?.length ? (
              <Button
                iconPosition="end"
                icon={<CloseOutlined />}
                onClick={() =>
                  setDashboardFilters((prev) => ({
                    ...prev,
                    projectStatuses: [],
                  }))
                }
              >
                {t('dashboardProgettiPage.statoProgetto')}
              </Button>
            ) : null}

            {dashboardFilters.projectCodes?.length ? (
              <Button
                iconPosition="end"
                icon={<CloseOutlined />}
                onClick={() => setDashboardFilters((prev) => ({ ...prev, projectCodes: [] }))}
              >
                {t('general.projectCode')}
              </Button>
            ) : null}

            {dashboardFilters.sponsorIds?.length ? (
              <Button
                iconPosition="end"
                icon={<CloseOutlined />}
                onClick={() => setDashboardFilters((prev) => ({ ...prev, sponsorIds: [] }))}
              >
                {t('general.sponsor')}
              </Button>
            ) : null}

            {dashboardFilters.comboSemaphores?.length ? (
              <Button
                iconPosition="end"
                icon={<CloseOutlined />}
                onClick={() =>
                  setDashboardFilters((prev) => ({
                    ...prev,
                    comboSemaphores: [],
                  }))
                }
              >
                {t('dashboardProgettiPage.analisiAllarmi')}
              </Button>
            ) : null}

            {dashboardFilters.commiteeIds?.length ? (
              <Button
                iconPosition="end"
                icon={<CloseOutlined />}
                onClick={() => setDashboardFilters((prev) => ({ ...prev, commiteeIds: [] }))}
              >
                {t('general.comitato')}
              </Button>
            ) : null}

            {dashboardFilters.divisionIds?.length ? (
              <Button
                iconPosition="end"
                icon={<CloseOutlined />}
                onClick={() => setDashboardFilters((prev) => ({ ...prev, divisionIds: [] }))}
              >
                {t('dashboardProgettiPage.divisioniReparti')}
              </Button>
            ) : null}
          </div>
        </TableLayout.SubHeader>

        <TableLayout.Content>
          {!dashboardData && null}

          {isLoading ? (
            <div className="tw-flex tw-justify-center tw-w-full">
              <Spin />
            </div>
          ) : sortedDashboardData.length > 0 ? (
            <div className="tw-grid tw-mt-4 tw-gap-x-4 tw-gap-y-6 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4">
              {sortedDashboardData.map((project, index) => {
                return (
                  <ProjectCard
                    project={project}
                    key={project.id}
                    expanded={allExpanded}
                  />
                );
              })}
            </div>
          ) : (
            <div className="tw-flex tw-justify-center tw-w-full">
              <Empty />
            </div>
          )}
        </TableLayout.Content>
      </TableLayout>
    </Card>
  );
};

export default ProjectsDashboard;
