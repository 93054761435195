import { type FC, useEffect, useState } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Col, Form, Input, InputNumber, Modal, Popover, Row, Select, Switch, message } from 'antd';
import type { Rule } from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import {
  type CreateKpiRequest,
  type GetKPIXMatrixPicklistResponseDto,
  KpiDetailCalculationType,
  KpiDetailParentType,
} from 'src/config/connectors';
import { useAppSelector } from 'src/redux/store';
import KpiService from '../../../../services/pages/kpiService';
import ModalFooterButtons from '../../../shared/components/modal/modalFooterButtons';
import { formItemLayout, kpiPuntualeAcumulato } from '../../../shared/utils/constants';
import { notifyMessages, requiredFields } from '../../../shared/utils/notifyMessages';
import '../../matrix.scss';

type FormData = CreateKpiRequest | { kpiId: string };

const AddKpiModal: FC<{
  handleObKpiClose: () => void;
  handleObKpiSave: (values: any, isNewKpi: boolean) => Promise<void>;
  loadingButton: boolean;
  selectedXmatrix: string;
  showObKpi: boolean;
}> = ({ handleObKpiClose, handleObKpiSave, loadingButton, selectedXmatrix, showObKpi }) => {
  const { t } = useTranslation();
  const companyData = useAppSelector((state) => state.companyData.companyData);

  const NormalJumpOffPoint = () => {
    return (
      <Form.Item<FormData>
        label={t('general.jumpOffPoint')}
        name="jumpOffPoint"
        initialValue={0}
        rules={[
          { required: true, message: requiredFields.required },
          {
            validator: async (_, jumpOffPoint) => {
              if (typeof jumpOffPoint !== 'number') {
                return Promise.reject(new Error(`${t('kpiPage.jopNumber')}`));
              }
            },
          },
        ]}
      >
        <InputNumber
          style={{ width: '100%' }}
          min="0"
        />
      </Form.Item>
    );
  };

  const PercentageJumpOffPoint = () => {
    return (
      <Form.Item<FormData>
        label={t('general.jumpOffPoint')}
        name="jumpOffPoint"
        initialValue={0}
        rules={[
          { required: true, message: requiredFields.required },
          {
            validator: async (_, jumpOffPoint) => {
              if (jumpOffPoint > 100 || jumpOffPoint < 0 || isNaN(jumpOffPoint) || typeof jumpOffPoint !== 'number') {
                return Promise.reject(new Error(`${t('kpiPage.jopMax100Number')}`));
              }
            },
          },
        ]}
      >
        <InputNumber
          style={{ width: '100%' }}
          min={0}
          max={100}
          formatter={(value) => `${value} %`}
        />
      </Form.Item>
    );
  };

  const BooleanJumpOffPoint = () => {
    return (
      <Form.Item<FormData>
        label={t('general.jumpOffPoint')}
        name="jumpOffPoint"
        valuePropName="checked"
        initialValue={false}
      >
        <Switch
          checkedChildren={t('kpiPage.fatto')}
          unCheckedChildren={t('kpiPage.nonFatto')}
        />
      </Form.Item>
    );
  };

  const DisabledJumpOfPoint = () => {
    return (
      <Form.Item<FormData>
        label={t('general.jumpOffPoint')}
        name="jumpOffPoint"
        initialValue={null}
      >
        <InputNumber
          value={null}
          style={{ width: '100%' }}
          min="0"
          disabled={true}
        />
      </Form.Item>
    );
  };

  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [items, setItems] = useState<GetKPIXMatrixPicklistResponseDto[]>([]);
  const [isNewKpi, setIsNewKpi] = useState(false);
  const [disableJumpOfPointResetGlobalKpi, setDisableJumpOfPointResetGlobalKpi] = useState(false);
  const [selectedCumulatoPuntuale, setSelectedCumulatoPuntuale] = useState<number>();
  const [jumpOffPointComponent, setJumpOffPointComponent] = useState(<NormalJumpOffPoint />);

  useEffect(() => {
    void retrieveKpiData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retrieveKpiData = async () => {
    try {
      const { data } = await KpiService.getKpiList(selectedXmatrix);
      if (data.success && data.responseObject?.value) {
        setItems(data.responseObject.value);
      }
    } catch {}
  };

  const addData = async (values: FormData, isNewKpi: boolean) => {
    if ('calculationType' in values) {
      if (disableJumpOfPointResetGlobalKpi) {
        values.jumpOffPoint = 0;
      } else {
        values.jumpOffPoint = +values.jumpOffPoint;
      }
    }

    await handleObKpiSave(values, isNewKpi);
  };

  const _validateKpiName = (_rule: Rule, value: string, callback: (error?: string) => void) =>
    KpiService.checkIfKpiNameExist(value)
      .then(({ data: { success, responseObject } }) => {
        if (success && responseObject?.value) {
          callback(requiredFields.validateKpiName);
        }
        callback();
      })
      .catch(() => {
        message.error(notifyMessages.errorAccured);
      });

  const onCalculationTypeChange = (type: KpiDetailCalculationType) => {
    if (type === KpiDetailCalculationType.IsCompleted) {
      setJumpOffPointComponent(<BooleanJumpOffPoint />);
    } else if (type === KpiDetailCalculationType.CompletionPercentage) {
      setJumpOffPointComponent(<PercentageJumpOffPoint />);
    } else {
      setJumpOffPointComponent(<NormalJumpOffPoint />);
    }
  };

  const onKpiSelect = (option?: string) => {
    if (option && option === 'newKpi') {
      setIsNewKpi(!isNewKpi);
    } else {
      setIsNewKpi(false);
    }
  };

  const checkDisableJumpOfPointResetGlobale = () => {
    if (companyData?.resetGlobalKPI && selectedCumulatoPuntuale === kpiPuntualeAcumulato.acumulato) {
      setDisableJumpOfPointResetGlobalKpi(true);
    } else {
      setDisableJumpOfPointResetGlobalKpi(false);
    }
  };

  const onCumulatoPuntualeChange = (value: number) => {
    setSelectedCumulatoPuntuale(value);
    checkDisableJumpOfPointResetGlobale();
  };

  return (
    <Modal
      className="add_ob_modal"
      width={800}
      title={t('kpiPage.aggiungiKpi')}
      open={showObKpi}
      destroyOnClose={true}
      onCancel={handleObKpiClose}
      footer={[
        <ModalFooterButtons
          disabled={!fieldsChanged}
          loadingButton={loadingButton}
          modalClose={handleObKpiClose}
          formId={'newKpiXmatrix'}
        />,
      ]}
    >
      <Card>
        <Form<FormData>
          id="newKpiXmatrix"
          name="add_ob_lungo"
          layout="vertical"
          {...formItemLayout}
          onFinish={(values) => addData(values, isNewKpi)}
          onValuesChange={() => {
            setFieldsChanged(true);
          }}
        >
          <Row gutter={{ lg: 24 }}>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <Form.Item<FormData>
                name="kpiId"
                label={t('xMatrixPage.selezionareKpi')}
                hasFeedback
                rules={[{ required: !isNewKpi, message: requiredFields.required }]}
              >
                <Select
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={t('general.seleziona')}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    if (typeof option?.children?.[0] === 'string') {
                      const value = option?.children?.[0] as unknown as string;

                      return value.toLowerCase().includes(input.trim().toLowerCase());
                    }

                    return false;
                  }}
                  onChange={onKpiSelect}
                >
                  {(items ?? []).map((item) => (
                    <Select.Option
                      value={item.kpiDetailID}
                      key={item.kpiDetailID}
                    >
                      {item.name}
                      <Popover
                        content={
                          <div>
                            <p>
                              {' '}
                              <b>{t('kpiPage.tipoCalcolo')}: </b> {item.globaleType}
                            </p>
                            <p>
                              {' '}
                              <b>{t('kpiPage.cumulatoPuntuale')}: </b> {item.puntualeType}
                            </p>
                          </div>
                        }
                        title={t('xMatrixPage.kpiInfo')}
                      >
                        <InfoCircleOutlined
                          style={{
                            fontSize: 12,
                            color: '#1f85e4',
                            marginLeft: '15px',
                          }}
                        />
                      </Popover>
                    </Select.Option>
                  ))}
                  <Select.Option
                    value={'newKpi'}
                    key={'newKpi'}
                    style={{ backgroundColor: '#f5f5e7' }}
                  >
                    {t('kpiPage.aggiungiKpi')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {isNewKpi && (
            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Card title={t('general.informazioniGenerali')}>
                  <Row gutter={{ lg: 24 }}>
                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}
                    >
                      <Form.Item<FormData>
                        label={t('general.nome')}
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: requiredFields.required,
                          },
                          {
                            validator: _validateKpiName,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 8 }}
                    >
                      <Form.Item<FormData>
                        label={t('kpiPage.tipoCalcolo')}
                        name="calculationType"
                        hasFeedback
                        rules={[{ required: true, message: requiredFields.required }]}
                      >
                        <Select
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}
                          onChange={onCalculationTypeChange}
                        >
                          {Object.values(KpiDetailCalculationType).map((value) => (
                            <Select.Option
                              value={value}
                              key={value}
                            >
                              {t(`kpi.calculationTypes.${value}`)}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 8 }}
                    >
                      {jumpOffPointComponent && !disableJumpOfPointResetGlobalKpi && jumpOffPointComponent}
                      {disableJumpOfPointResetGlobalKpi && <DisabledJumpOfPoint />}
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 8 }}
                    >
                      <Form.Item<FormData>
                        label={t('kpiPage.cumulatoPuntuale')}
                        name="parentType"
                        hasFeedback
                        rules={[{ required: true, message: requiredFields.required }]}
                      >
                        <Select
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}
                          onChange={onCumulatoPuntualeChange}
                        >
                          {Object.values(KpiDetailParentType).map((value) => (
                            <Select.Option
                              value={value}
                              key={value}
                            >
                              {t(`kpi.parentTypes.${value}`)}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          )}
        </Form>
      </Card>
    </Modal>
  );
};

export default AddKpiModal;
