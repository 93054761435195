import {
  CheckOutlined,
  DeleteFilled,
  EditFilled,
  ExportOutlined,
  FilterOutlined,
  MinusSquareOutlined,
  PlusOutlined,
  SearchOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Input,
  Popconfirm,
  Popover,
  Space,
  Table,
  type TableColumnType,
  type TableProps,
  Tooltip,
  Typography,
  message,
} from 'antd';
import ButtonGroup from 'antd/es/button/button-group';
import type { FilterValue } from 'antd/es/table/interface';
import moment from 'moment';
import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type {
  GetTeamLeaderActivityMenuFormResponseDto,
  InsertMyActivityRequestDto,
  UpdateMyActivityRequestDto,
} from 'src/connectors/backend';
import CommonService from '../../services/commonService';
import ActivityService from '../../services/pages/activitiesServices';
import '../projects/project.scss';
import TableLayout from '../shared/tableLayout';
import { DatePickerWithDateFns, activityStatus, formatOfDate, statusPickListCodes } from '../shared/utils/constants';
import {
  ResponsiblePopoverContent,
  checkMyActivitiesStatus,
  handleDateConvert,
  projectTypeColumnValues,
  validateGuid,
} from '../shared/utils/functions';
import { notifyMessages } from '../shared/utils/notifyMessages';
import NewActivity from './newActivity';
import UpdateActivity from './updateActivity';

const { Text } = Typography;

const TeamLeaderActivities: FC<{
  acid: string;
  changeVisualizationButton: JSX.Element;
}> = ({ acid, changeVisualizationButton }) => {
  const { t } = useTranslation();

  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingActivities, setLoadingActivities] = useState(false);
  const [teamLeaderActivityList, setTeamLeaderActivityList] = useState<GetTeamLeaderActivityMenuFormResponseDto[]>([]);
  const [teamLeaderActivityListFiltered, setTeamLeaderActivityListFiltered] = useState<
    GetTeamLeaderActivityMenuFormResponseDto[]
  >([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [modalData, setModalData] = useState<GetTeamLeaderActivityMenuFormResponseDto>();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [clearFilters, setClearFilters] = useState(false);
  const [statusListFilter, setStatusListFilter] = useState<{ text: string; value: string }[]>([]);
  const [filteredInfo, setFilteredInfo] = useState<FilterValue>([`${t('status.draft')}`, `${t('status.attivo')}`]);
  const [activityTypeFilter, setActivityTypeFilter] = useState([
    {
      text: `${t('leMieAttivitaPage.activity')}`,
      value: 'activity',
    },
    {
      text: `${t('leMieAttivitaPage.macroActivity')}`,
      value: 'macroActivity',
    },
    {
      text: `${t('leMieAttivitaPage.deliverable')}`,
      value: 'deliverable',
    },
  ]);

  useEffect(() => {
    void retrieveStatusList();
    void retrieveMyActivityList();
    void filterActivities('all');
  }, []);

  const retrieveStatusList = async () => {
    const objectCode = statusPickListCodes.activityStatus;

    try {
      const response = await CommonService.getStatusData(objectCode);
      const resp = response.data;

      if (resp.success) {
        const obj = (resp.responseObject?.value ?? []).map((item) => {
          return {
            text: item.statusDescription ?? '',
            value: item.statusDescription ?? '',
          };
        });

        setStatusListFilter(obj);
      }
    } catch (error) {}
  };

  const toggleEditActivityModal = (rowData: GetTeamLeaderActivityMenuFormResponseDto) => {
    setModalData(rowData);
    setShowEditModal(true);
  };

  const retrieveMyActivityList = async (hideActivityPopUp = false) => {
    setLoadingActivities(true);

    let formattedStartDate = handleDateConvert(startDate) ?? undefined;
    let formattedEndDate = handleDateConvert(endDate) ?? undefined;

    if (clearFilters) {
      setStartDate(undefined);
      setEndDate(undefined);
      formattedStartDate = undefined;
      formattedEndDate = undefined;
    }

    try {
      const response = await ActivityService.getTeamLeaderActivities(formattedStartDate, formattedEndDate);
      const resp = response.data;

      if (resp.success) {
        const respData = resp.responseObject?.value ?? [];

        setTeamLeaderActivityList(respData);
        setTeamLeaderActivityListFiltered(respData);

        if (acid && validateGuid(acid)) {
          const findSelectedActivity = respData.filter((obj) => obj.activityID === acid);

          if (findSelectedActivity && findSelectedActivity.length > 0) {
            if (!hideActivityPopUp) {
              toggleEditActivityModal(findSelectedActivity[0]);
            }
          }
        }
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
    } finally {
      setLoadingActivities(false);
    }
  };

  const filterActivities = (type: 'activities' | 'prs' | 'all') => {
    if (type === 'activities') {
      const filteredData = teamLeaderActivityList.filter((obj) => obj.activityType === 'activity');
      setTeamLeaderActivityListFiltered(filteredData);
    } else if (type === 'prs') {
      const filteredData = teamLeaderActivityList.filter(
        (obj) => obj.activityType === 'deliverable' || obj.activityType === 'macroActivity',
      );
      setTeamLeaderActivityListFiltered(filteredData);
    } else {
      setTeamLeaderActivityListFiltered(teamLeaderActivityList);
    }
  };

  const ActivityName = ({
    record,
    title,
  }: {
    record: GetTeamLeaderActivityMenuFormResponseDto;
    title: string;
  }) => {
    return (
      <Text
        className="tw-cursor-pointer"
        onClick={() => toggleEditActivityModal(record)}
      >
        {title}
      </Text>
    );
  };

  const onRemoveActivity = async (id: string) => {
    try {
      const response = await ActivityService.removeActivity(id);
      const resp = response.data;

      if (resp.success) {
        message.success(notifyMessages.deleteSuccess);
        await retrieveMyActivityList(true);
      } else {
        message.error(notifyMessages.deleteFailed);
      }
    } catch (error) {
      message.error(notifyMessages.deleteFailed);
    }
  };

  const handleCloseActivity = async (record: GetTeamLeaderActivityMenuFormResponseDto) => {
    try {
      setLoadingButton(true);
      const response = await ActivityService.updateActivity({
        activityID: record.activityID,
        deliverableID: record.deliverableID,
        description: record.description,
        endDate: handleDateConvert(record.endDate),
        ownerID: record.ownerID,
        projectID: record.projectID,
        startDate: handleDateConvert(record.startDate),
        status: activityStatus.closed,
        subject: record.subject,
      });
      const resp = response.data;

      if (!resp.success) {
        return;
      }

      message.success(notifyMessages.updateSuccess);
      await retrieveMyActivityList(true);
    } catch (error) {
      message.error(notifyMessages.updateFailed);
    } finally {
      setLoadingButton(false);
    }
  };

  const getColumnSearchProps: (
    dataIndex: keyof GetTeamLeaderActivityMenuFormResponseDto,
  ) => Pick<TableColumnType<GetTeamLeaderActivityMenuFormResponseDto>, 'filterDropdown' | 'filterIcon' | 'onFilter'> = (
    dataIndex,
  ) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={t('general.search')}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            style={{ width: 90 }}
          >
            {t('general.search')}
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) {
                clearFilters();
                confirm();
              }
            }}
            style={{ width: 90 }}
          >
            {t('Common.reset')}
          </Button>
        </Space>
      </div>
    ),
  });

  const onStartDateChange = (date: Date) => {
    setStartDate(date);
  };

  const onEndDateChange = (date: Date) => {
    setEndDate(date);
  };

  const onFilter = () => {
    setClearFilters(false);
    void retrieveMyActivityList(true);
  };

  const onClearFilter = () => {
    setClearFilters(true);
    void retrieveMyActivityList(true);
  };

  const dateFilters = () => {
    return (
      <>
        <DatePickerWithDateFns
          placeholder={t('general.start')}
          value={startDate}
          style={{ width: '100%' }}
          format="DD/MM/YYYY"
          onChange={(date) => onStartDateChange(date)}
        />
        <DatePicker
          placeholder={t('general.end')}
          value={endDate}
          style={{ width: '100%' }}
          format="DD/MM/YYYY"
          onChange={(date) => onEndDateChange(date)}
        />
        <Tooltip title={t('general.filter')}>
          <Button
            icon={<FilterOutlined />}
            onClick={() => onFilter()}
            disabled={!startDate && !endDate}
          />
        </Tooltip>
        <Tooltip title={t('general.clearFilters')}>
          <Button
            icon={<MinusSquareOutlined />}
            onClick={() => onClearFilter()}
          />
        </Tooltip>
      </>
    );
  };

  const myActivitiesColumns: TableColumnType<GetTeamLeaderActivityMenuFormResponseDto>[] = [
    {
      title: `${t('general.tipo')}`,
      dataIndex: ['activityType'],
      width: '10%',
      showSorterTooltip: false,
      filters: activityTypeFilter,
      onFilter: (value, record) => (record.activityType ?? '').includes(value.toString()),
      onCell: (record) => ({
        style: {
          borderLeft: `7px solid ${checkMyActivitiesStatus(record)}`,
          borderRadius: '5px 0 0 5px',
        },
      }),
      render(title, record) {
        return <Text>{projectTypeColumnValues(record, t)}</Text>;
      },
    },
    {
      title: `${t('general.descrizione')}`,
      dataIndex: ['subject'],
      sorter: (a, b) => {
        return (a.subject ?? '').localeCompare(b.subject ?? '');
      },
      showSorterTooltip: false,
      ...getColumnSearchProps('subject'),
      render(title, record) {
        return (
          <ActivityName
            title={title}
            record={record}
          />
        );
      },
    },
    {
      title: `${t('general.responsabile')}`,
      dataIndex: ['fullName'],
      width: '10%',
      sorter: (a, b) => {
        return (a.fullName ?? '').localeCompare(b.fullName ?? '');
      },
      showSorterTooltip: false,
      render: (title, record) => (
        <Popover
          content={
            <ResponsiblePopoverContent
              data={record}
              t={t}
            />
          }
          title={title}
        >
          <span style={record.isGenericResponsabile === true ? { color: '#f2883b' } : {}}>{title}</span>
        </Popover>
      ),
    },
    {
      title: `${t('general.stato')}`,
      dataIndex: ['statusDescription'],
      width: '14ch',
      key: 'statusDescription',
      sorter: (a, b) => {
        return (a.statusDescription ?? '').localeCompare(b.statusDescription ?? '');
      },
      showSorterTooltip: true,
      filters: statusListFilter,
      defaultFilteredValue: filteredInfo,
      onFilter: (value, record) => (record.statusDescription ?? '').includes(value.toString()),
      render(text, record) {
        return <div>{text}</div>;
      },
    },
    {
      title: `${t('general.progetto')}`,
      dataIndex: ['projectName'],
      sorter: (a, b) => {
        return (a.projectName ?? '').localeCompare(b.projectName ?? '');
      },
      showSorterTooltip: false,
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => (
        <Tooltip title={text}>
          <Link to={{ pathname: `/progetti/id/${record.projectID}` }}> {text} </Link>
        </Tooltip>
      ),
    },
    {
      title: `${t('general.start')}`,
      dataIndex: ['startDate'],
      width: '150px',
      sorter: (a, b) => {
        return (a.startDate ?? '').localeCompare(b.startDate ?? '');
      },
      showSorterTooltip: false,
      render: (text, record) => moment(text).format(formatOfDate),
    },
    {
      title: `${t('general.end')}`,
      dataIndex: ['endDate'],
      width: '150px',
      sorter: (a, b) => {
        return (a.endDate ?? '').localeCompare(b.endDate ?? '');
      },
      showSorterTooltip: false,
      render: (text, record) => moment(text).format(formatOfDate),
    },
    {
      title: `${t('general.dataChiusura')}`,
      dataIndex: ['actualCloseDate'],
      width: '150px',
      sorter: (a, b) => {
        return (a.actualCloseDate ?? '').localeCompare(b.actualCloseDate ?? '');
      },
      showSorterTooltip: false,
      render: (text) => (text ? moment(text).format(formatOfDate) : '-'),
    },
    {
      key: 'action',
      width: '150px',
      render: (_, record) => {
        const isClosed = record.status === activityStatus.closed;
        const quickClose = record.status === activityStatus.active || record.status === activityStatus.draft;

        if (record.deliverableID) {
          return (
            <div className="tw-flex tw-items-center tw-h-10 tw-gap-2">
              <Button
                icon={<ExportOutlined />}
                href={`/deliverables/id/${record.deliverableID}`}
              />

              {!isClosed && (
                <Popconfirm
                  key="remove"
                  title={
                    record.parentDeliverableID
                      ? t('leMieAttivitaPage.removeDeliverable')
                      : t('leMieAttivitaPage.removePhase')
                  }
                  icon={<WarningOutlined />}
                  okText={t('general.si')}
                  cancelText={t('general.no')}
                  onConfirm={() => {
                    void onRemoveActivity(record.activityID as string);
                  }}
                >
                  <Button
                    danger
                    icon={<DeleteFilled />}
                    title={t('buttons.rimuoveAttivita')}
                  />
                </Popconfirm>
              )}

              {!isClosed && quickClose && (
                <Popconfirm
                  key="close"
                  placement="topRight"
                  title={
                    record.parentDeliverableID
                      ? t('leMieAttivitaPage.closeDeliverable')
                      : t('leMieAttivitaPage.closePhase')
                  }
                  icon={<WarningOutlined />}
                  okText={t('general.si')}
                  cancelText={t('general.no')}
                  onConfirm={() => handleCloseActivity(record)}
                >
                  <Button
                    icon={<CheckOutlined />}
                    title={t('buttons.chiusuraRapida')}
                  />
                </Popconfirm>
              )}
            </div>
          );
        }

        return (
          <div className="tw-flex tw-items-center tw-h-10 tw-gap-2">
            <Button
              icon={<EditFilled />}
              onClick={() => toggleEditActivityModal(record)}
              title={t('buttons.modificaAttivita')}
            />

            {!isClosed && (
              <Popconfirm
                key="remove"
                title={t('buttons.rimuoveAttivita')}
                icon={<WarningOutlined />}
                okText={t('general.si')}
                cancelText={t('general.no')}
                onConfirm={() => {
                  void onRemoveActivity(record.activityID as string);
                }}
              >
                <Button
                  danger
                  icon={<DeleteFilled />}
                  title={t('buttons.rimuoveAttivita')}
                />
              </Popconfirm>
            )}

            {!isClosed && quickClose && (
              <Popconfirm
                key="close"
                placement="topRight"
                title={t('leMieAttivitaPage.chiudiQuestaAttivita')}
                icon={<WarningOutlined />}
                okText={t('general.si')}
                cancelText={t('general.no')}
                onConfirm={() => handleCloseActivity(record)}
              >
                <Button
                  icon={<CheckOutlined />}
                  title={t('buttons.chiusuraRapida')}
                />
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];

  const toggleCreateActivityModal = () => {
    setShowCreateModal(true);
  };

  const handleChange: TableProps['onChange'] = (pagination, filters, sorter) => {
    setFilteredInfo(filters.statusDescription ?? []);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setLoadingButton(false);
  };

  const handleUpdateActivity = async (
    values: UpdateMyActivityRequestDto,
    activityID: string,
    deliverableID: string,
    projectId: string,
  ) => {
    setLoadingButton(true);
    try {
      const response = await ActivityService.updateActivity({
        ...values,
        activityID: activityID,
        deliverableID: deliverableID,
        startDate: handleDateConvert(values.startDate),
        endDate: handleDateConvert(values.endDate),
      });
      const resp = response.data;

      if (resp.success) {
        handleEditModalClose();
        message.success(notifyMessages.updateSuccess);
        await retrieveMyActivityList(true);
      } else {
        message.error(notifyMessages.updateFailed);
      }
    } catch (error) {
      message.error(notifyMessages.updateFailed);
    } finally {
      setLoadingButton(false);
    }
  };

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
    setLoadingButton(false);
  };

  const handleSaveActivity = async (values: InsertMyActivityRequestDto) => {
    values.startDate = handleDateConvert(values.startDate);
    values.endDate = handleDateConvert(values.endDate);

    setLoadingButton(true);

    try {
      const response = await ActivityService.insertActivity(values);
      const resp = response.data;

      if (!resp.success) {
        return;
      }

      handleCreateModalClose();
      message.success(notifyMessages.addSuccess);
      await retrieveMyActivityList(true);
    } catch (error) {
      message.error(notifyMessages.addFailed);
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <>
      {showCreateModal && (
        <NewActivity
          showCreateModal={showCreateModal}
          handleCreateModalClose={handleCreateModalClose}
          handleSaveActivity={handleSaveActivity}
          loadingButton={loadingButton}
          t={t}
        />
      )}

      {showEditModal && (
        <UpdateActivity
          showEditModal={showEditModal}
          handleEditModalClose={handleEditModalClose}
          handleUpdateActivity={handleUpdateActivity}
          loadingButton={loadingButton}
          modalData={modalData}
        />
      )}

      <TableLayout title={t('proggetiPage.toDoProject')}>
        <TableLayout.Actions>
          {dateFilters()}

          <ButtonGroup>
            <Button onClick={() => filterActivities('activities')}>{t('proggetiPage.attivitaFilter')}</Button>
            <Button onClick={() => filterActivities('prs')}>{t('proggetiPage.prsFilter')}</Button>
            <Button onClick={() => filterActivities('all')}>{t('proggetiPage.allFilter')}</Button>
          </ButtonGroup>

          {changeVisualizationButton}
          <Button
            type="primary"
            onClick={() => toggleCreateActivityModal()}
          >
            <PlusOutlined />
            {t('buttons.aggiungiNuovo')}
          </Button>
        </TableLayout.Actions>
        <TableLayout.Content>
          <Table
            tableLayout="fixed"
            size="small"
            columns={myActivitiesColumns}
            dataSource={teamLeaderActivityListFiltered}
            rowKey={(obj) => obj.activityID as string}
            loading={loadingActivities}
            onChange={handleChange}
            pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
          />
        </TableLayout.Content>
      </TableLayout>
    </>
  );
};

export default TeamLeaderActivities;
