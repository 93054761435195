import { FC, useState } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Form, Input, Modal, Select, Table, TableColumnType, Tooltip } from 'antd';
import ModalFooterButtons from '../components/shared/components/modal/modalFooterButtons';
import { annualGoalStatus } from '../components/shared/utils/constants';
import { requiredFields } from '../components/shared/utils/notifyMessages';
import { useTranslation } from 'react-i18next';
import { AnnualGoal } from 'src/connectors/backend';
import { WithOrder } from 'src/services/matrix/matrixService';

const { Option } = Select;

interface AddAnnualGoalModalProps {
  showObAnno: boolean;
  handleObAnnoClose: () => void;
  handleObAnnoSave: (values: any) => void;
  loadingButton: boolean;
  statusPickList: any[];
  annualGoals: WithOrder<AnnualGoal>[];
}

const AddAnnualGoalModal: FC<AddAnnualGoalModalProps> = ({
  showObAnno, handleObAnnoClose, loadingButton, statusPickList, annualGoals, handleObAnnoSave
}) => {
  const { t } = useTranslation();

  const [fieldsChanged, setFieldsChanged] = useState(false);

  const getColumnSearchProps: (dataIndex: keyof WithOrder<AnnualGoal>) => Pick<TableColumnType<WithOrder<AnnualGoal>>, 'filterDropdown' | 'filterIcon' | 'onFilter'> = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div className='tw-grid tw-grid-cols-2 tw-gap-2 tw-p-2'>
        <Input
          placeholder={t('general.search')}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys([e.target.value])}
          onPressEnter={() => confirm()}
          className="tw-min-w-44 tw-col-span-2" />
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}>
          {t('general.search')}
        </Button>
        <Button
          onClick={() => {
            if (clearFilters) {
              clearFilters();
              confirm();
            }
          }}>
          {t('Common.reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined className='tw-text-lg' style={{ color: filtered ? '#1890ff' : '#677582' }} />,
    onFilter: (value, record) => {
      if (dataIndex === 'title') {
        return (record[dataIndex] ?? '').toString().toLowerCase().includes(value.toString().toLowerCase());
      }

      return true;
    }
  });

  const columns: TableColumnType<WithOrder<AnnualGoal>>[] = [
    {
      title: `${t('general.nome')}`,
      width: '100%',
      ellipsis: {
        showTitle: true,
      },
      dataIndex: ['title'],
      sorter: (a, b) => {
        return (a.title ?? '').localeCompare(b.title ?? '');
      },
      showSorterTooltip: true,
      render: (title) => (
        <Tooltip
          placement="bottom"
          title={title}>
          {title}
        </Tooltip>
      ),
      ...getColumnSearchProps('title'),
    },
  ];


  return (
    <Modal
      width={800}
      title={t('obiettiviAnnoPage.aggioungiObjAanuali')}
      open={showObAnno}
      destroyOnClose={true}
      onCancel={handleObAnnoClose}
      footer={[
        <ModalFooterButtons
          disabled={!fieldsChanged}
          loadingButton={loadingButton}
          modalClose={handleObAnnoClose}
          formId={'newObAnnoXmatrix'} />,
      ]}>
      <Card>
        <Form
          id="newObAnnoXmatrix"
          name="add_ob_lungo"
          layout="vertical"
          onFinish={(values: { description: string; status: string }) => {
            handleObAnnoSave(values);
          }}
          onValuesChange={() => {
            setFieldsChanged(true);
          }}>
          <div className='tw-flex tw-flex-col tw-gap-2'>
            <Form.Item
              label={t('general.nome')}
              name="description"
              rules={[{ required: true, message: requiredFields.required }]}>
              <Input />
            </Form.Item>

            <Form.Item
              label={t('general.stato')}
              name="status"
              hasFeedback
              rules={[{ required: true, message: requiredFields.required }]}
              initialValue={annualGoalStatus.active}>
              <Select
                className='tw-w-full'
                placeholder={t('general.seleziona')}>
                {statusPickList.map((item) => (
                  <Option
                    value={item.statusID}
                    key={item.statusID}>
                    {item.statusDescription}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Form>

        {annualGoals.length > 0 && (
          <>
            <Divider />

            <Card title={t('xMatrixPage.objAnno')}>
              <Table
                size="small"
                columns={columns}
                dataSource={annualGoals}
                bordered={true}
                rowKey={(obj) => obj.annualGoalID as string}
              />
            </Card>
          </>
        )}
      </Card>
    </Modal>
  );

}

export default AddAnnualGoalModal;
