import { trackPromise } from 'react-promise-tracker';
import { A3PageApi, authenticatedApi } from 'src/config/connectors';

const Section2Service = {
  // get section 2 data
  getMasterPlanParentTEST: async (id: string, masterPlanDuration: number, masterPlanStart: number) => {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageGetMasterplanParentDeliverableTest(id, masterPlanDuration, masterPlanStart));
  },

  getMasterPlanDeliverable: async (
    id: string,
    masterPlanDuration: number,
    masterPlanStart: number,
    deliverable: boolean,
  ) => {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageGetMasterPlanDeliverable(id, masterPlanDuration, masterPlanStart, deliverable));
  },
};

export default Section2Service;
