import { message } from 'antd';
import FileSaver from 'file-saver';

import moment from 'moment';
import type { GetXMatrixInfoResponseDto } from 'src/connectors/backend';
import type { XMatrixDtoWithOrder } from 'src/services/matrix/matrixService';
import * as XLSX from 'xlsx';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const fileName = 'X-Matrix';

export const itemRelationsName = (relation: number, t: (key: string) => string) => {
  if (relation === 1) {
    return t('xMatrixPage.primaria');
  }
  if (relation === 2) {
    return t('xMatrixPage.secondaria');
  }
  return t('xMatrixPage.nessuna');
};

export const exportXmatrix = (
  xmatrixData: XMatrixDtoWithOrder,
  selectedXmatrixInfo: GetXMatrixInfoResponseDto,
  t: (key: string) => string,
) => {
  const wb = XLSX.utils.book_new();

  if (xmatrixData) {
    //generate long terms data sheet
    const longTermsDataList: any[] = [];
    const longTermsHeaders = [
      ['X-Matrix', 'Numero', 'Nome', 'Stato', 'Categoria', 'Tipo', 'Sponsor', 'Coerenza Envisioning'],
    ];

    // biome-ignore lint/complexity/noForEach: <explanation>
    xmatrixData.longTermGoals?.forEach((item: any) => {
      longTermsDataList.push({
        xmatrix: selectedXmatrixInfo.hkCode,
        orderingValue: item.orderingValue,
        title: item.title,
        statusDescription: item.statusDescription,
        category: item.category,
        type: item.longTermGoalType,
        sponsor: item.sponsor,
        coerenza: item.isRelatedVMV ? 'True' : 'False',
      });
    });

    const longTermSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(longTermSheet, longTermsDataList, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(longTermSheet, longTermsHeaders);
    XLSX.utils.book_append_sheet(wb, longTermSheet, 'Strategia Lungo Periodo');

    //generate long terms - annual goal relation data sheet
    const annulLongRelationData: any[] = [];
    const annulLongrrelationsOnly = xmatrixData.longTermAnnualGoalRelationships?.filter(
      (obj) => obj.correlationCoefficient !== null,
    );
    const annulLongRelationHeaders = [['X-Matrix', 'Numero SLP', 'Nome SLP', 'Numero OA', 'Nome OA', 'Relazione']];

    // biome-ignore lint/complexity/noForEach: <explanation>
    annulLongrrelationsOnly?.forEach((item: any) => {
      if (
        xmatrixData.annualGoals.some((el: any) => el.annualGoalID === item.highestIDValue) &&
        xmatrixData.longTermGoals.some((el: any) => el.longTermGoalID === item.lowestIDValue)
      ) {
        annulLongRelationData.push({
          xmatrix: selectedXmatrixInfo.hkCode,
          numeroSLP: item.lowestNumberValue,
          nomeSLP: item.lowestNameValue,
          numeroOA: item.highestNumberValue,
          nomeOA: item.highestNameValue,
          correlationCoefficient: itemRelationsName(item.correlationCoefficient, t),
        });
      }
    });

    const annulLongRelationSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(annulLongRelationSheet, annulLongRelationData, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(annulLongRelationSheet, annulLongRelationHeaders);
    XLSX.utils.book_append_sheet(wb, annulLongRelationSheet, 'REL_SLP_OA');

    //generate annual goal data sheet
    const annualGoalHeaders = [['X-Matrix', 'Numero', 'Nome', 'Stato', 'Responsabile']];
    const annualGoalDataList = xmatrixData.annualGoals.map((item: any) => ({
      xmatrix: selectedXmatrixInfo.hkCode,
      orderingValue: item.orderingValue,
      title: item.title,
      statusDescription: item.statusDescription,
      responsible: item.responsabile,
    }));

    const annualGoalSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(annualGoalSheet, annualGoalDataList, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(annualGoalSheet, annualGoalHeaders);
    XLSX.utils.book_append_sheet(wb, annualGoalSheet, 'Obiettivi anno');

    //generate projects data sheet
    const projectsDataList: any[] = [];
    const projectsHeaders = [
      [
        'X-Matrix',
        'Codice',
        'Nome',
        'Comitato',
        'Divisione',
        'Stato',
        'Data Inizio',
        'Data Fine',
        'Team Leader',
        'Sponsor',
        'Progetto Principale',
        'Commenti',
      ],
    ];

    // biome-ignore lint/complexity/noForEach: <explanation>
    xmatrixData.projects?.forEach((item: any) => {
      projectsDataList.push({
        xmatrix: selectedXmatrixInfo.hkCode,
        projectCode: item.projectCode,
        title: item.title,
        committeeName: item.committeeName,
        divisionName: item.divisionName,
        statusDescription: item.statusDescription,
        startDate: item.projectStartDate ? moment(item.projectStartDate).format('DD/MM/YYYY') : '',
        endDate: item.projectEndDate ? moment(item.projectEndDate).format('DD/MM/YYYY') : '',
        teamLeaderFullName: item.teamLeaderFullName,
        sponsorFullName: item.sponsorFullName,
        parentProject: item.parentProject,
        notes: item.note,
      });
    });

    const projectsSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(projectsSheet, projectsDataList, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(projectsSheet, projectsHeaders);
    XLSX.utils.book_append_sheet(wb, projectsSheet, 'Progetti');

    //generate annual goal - projects relation data sheet
    const annulProjectRelationData: any[] = [];
    const annulProjectrelationsOnly = xmatrixData.goalProjectRelationship?.filter(
      (obj: any) => obj.correlationCoefficient !== null,
    );
    const annulProjectHeaders = [['X-Matrix', 'Numero OA', 'Nome OA', 'Codice Progetto', 'Nome Progetto', 'Relazione']];

    // biome-ignore lint/complexity/noForEach: <explanation>
    annulProjectrelationsOnly?.forEach((item: any) => {
      if (
        xmatrixData.annualGoals.some((el: any) => el.annualGoalID === item.lowestIDValue) &&
        xmatrixData.projects.some((el: any) => el.projectID === item.highestIDValue)
      ) {
        annulProjectRelationData.push({
          xmatrix: selectedXmatrixInfo.hkCode,
          numeroSLP: item.lowestNumberValue,
          nomeSLP: item.lowestNameValue,
          projectCode: item.highestObjectCode,
          projectName: item.highestNameValue,
          correlationCoefficient: itemRelationsName(item.correlationCoefficient, t),
        });
      }
    });

    const annulProjectsRelationSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(annulProjectsRelationSheet, annulProjectRelationData, {
      origin: 'A2',
      skipHeader: true,
    });
    XLSX.utils.sheet_add_aoa(annulProjectsRelationSheet, annulProjectHeaders);
    XLSX.utils.book_append_sheet(wb, annulProjectsRelationSheet, 'REL_OA_PRJ');

    const wsColsWidthFormatter = [];
    for (let i = 0; i < 30; i++) {
      wsColsWidthFormatter.push({ wpx: 150 });
    }
    longTermSheet['!cols'] = wsColsWidthFormatter;
    annualGoalSheet['!cols'] = wsColsWidthFormatter;
    annulLongRelationSheet['!cols'] = wsColsWidthFormatter;
    projectsSheet['!cols'] = wsColsWidthFormatter;
    annulProjectsRelationSheet['!cols'] = wsColsWidthFormatter;

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  } else {
    message.error(t('general.noDataFound'));
  }
};

export const exportSecondLevelXmatrix = (
  xmatrixData: XMatrixDtoWithOrder,
  selectedXmatrixInfo: GetXMatrixInfoResponseDto,
  t: (key: string) => string,
) => {
  const wb = XLSX.utils.book_new();

  if (xmatrixData) {
    //generate long terms data sheet
    const longTermsHeaders = [
      ['X-Matrix', 'Numero', 'Nome', 'Stato', 'Categoria', 'Tipo', 'Sponsor', 'Coerenza Envisioning'],
    ];
    const longTermsDataList = xmatrixData.longTermGoals.map((item: any) => ({
      xmatrix: selectedXmatrixInfo.hkCode,
      orderingValue: item.orderingValue,
      title: item.title,
      statusDescription: item.statusDescription,
      category: item.category,
      type: item.longTermGoalType,
      sponsor: item.sponsor,
      coerenza: item.isRelatedVMV ? 'True' : 'False',
    }));

    const longTermSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(longTermSheet, longTermsDataList, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(longTermSheet, longTermsHeaders);
    XLSX.utils.book_append_sheet(wb, longTermSheet, 'Strategia Lungo Periodo');

    //generate annual goal data sheet
    const annualGoalHeaders = [['X-Matrix', 'Numero', 'Nome', 'Stato', 'Responsabile']];
    const annualGoalDataList = xmatrixData.annualGoals.map((item: any) => ({
      xmatrix: selectedXmatrixInfo.hkCode,
      orderingValue: item.orderingValue,
      title: item.title,
      statusDescription: item.statusDescription,
      responsible: item.responsabile,
    }));

    const annualGoalSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(annualGoalSheet, annualGoalDataList, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(annualGoalSheet, annualGoalHeaders);
    XLSX.utils.book_append_sheet(wb, annualGoalSheet, 'Obiettivi anno');

    //generate annual goal - project relation data sheet
    const annualProjectRelationData: any[] = [];
    const annualProjectRelationsOnly = xmatrixData.goalProjectRelationship?.filter(
      (obj: any) => obj.correlationCoefficient !== null,
    );
    const annualProjectRelationHeaders = [
      ['X-Matrix', 'Numero OA', 'Nome OA', 'Codice Progetto', 'Nome Progetto', 'Relazione'],
    ];

    // biome-ignore lint/complexity/noForEach: <explanation>
    annualProjectRelationsOnly?.forEach((item: any) => {
      if (
        xmatrixData.annualGoals.some((el: any) => el.annualGoalID === item.lowestIDValue) &&
        xmatrixData.projects.some((el: any) => el.projectID === item.highestIDValue)
      ) {
        annualProjectRelationData.push({
          xmatrix: selectedXmatrixInfo.hkCode,
          numeroOA: item.lowestNumberValue,
          nomeOA: item.lowestNameValue,
          projectCode: item.highestObjectCode,
          nomeProject: item.highestNameValue,
          correlationCoefficient: itemRelationsName(item.correlationCoefficient, t),
        });
      }
    });

    const annualProjectRelationSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(annualProjectRelationSheet, annualProjectRelationData, {
      origin: 'A2',
      skipHeader: true,
    });
    XLSX.utils.sheet_add_aoa(annualProjectRelationSheet, annualProjectRelationHeaders);
    XLSX.utils.book_append_sheet(wb, annualProjectRelationSheet, 'REL_OA_PRJ');

    //generate projects data sheet
    const projectsHeaders = [
      [
        'X-Matrix',
        'Codice',
        'Nome',
        'Comitato',
        'Divisione',
        'Stato',
        'Data Inizio',
        'Data Fine',
        'Team Leader',
        'Sponsor',
        'Progetto Principale',
        'Commenti',
      ],
    ];
    const projectsDataList = xmatrixData.projects.map((item: any) => ({
      xmatrix: selectedXmatrixInfo.hkCode,
      projectCode: item.projectCode,
      title: item.title,
      committeeName: item.committeeName,
      divisionName: item.divisionName,
      statusDescription: item.statusDescription,
      startDate: item.projectStartDate ? moment(item.projectStartDate).format('DD/MM/YYYY') : '',
      endDate: item.projectEndDate ? moment(item.projectEndDate).format('DD/MM/YYYY') : '',
      teamLeaderFullName: item.teamLeaderFullName,
      sponsorFullName: item.sponsorFullName,
      parentProject: item.parentProject,
      notes: item.note,
    }));

    const projectsSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(projectsSheet, projectsDataList, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(projectsSheet, projectsHeaders);
    XLSX.utils.book_append_sheet(wb, projectsSheet, 'Iniziative');

    //generate CHILD PROJECTS data sheet
    const childProjectsHeaders = [
      [
        'X-Matrix',
        'Codice',
        'Nome',
        'Comitato',
        'Divisione',
        'Stato',
        'Data Inizio',
        'Data Fine',
        'Team Leader',
        'Sponsor',
        'Progetto Principale',
        'Commenti',
      ],
    ];
    const childProjectsDataList = xmatrixData.projectChild?.map((item: any) => ({
      xmatrix: selectedXmatrixInfo.hkCode,
      projectCode: item.projectCode,
      title: item.title,
      committeeName: item.committeeName,
      division: item.divisionName,
      statusDescription: item.statusDescription,
      startDate: item.subProjectStartDate ? moment(item.subProjectStartDate).format('DD/MM/YYYY') : '',
      endDate: item.subProjectEndDate ? moment(item.subProjectEndDate).format('DD/MM/YYYY') : '',
      teamLeaderFullName: item.teamLeaderFullName,
      sponsor: item.sponsorFullName,
      parentProject: item.subProjectParentProject,
      notes: item.note,
    }));

    const childProjectsSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(childProjectsSheet, childProjectsDataList || [], { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(childProjectsSheet, childProjectsHeaders);
    XLSX.utils.book_append_sheet(wb, childProjectsSheet, 'Progetti Strategici');

    //generate project - child projects relation data sheet
    const projectChildProjectsRelationData: any[] = [];
    const projectChildProjectsRelationsOnly = xmatrixData.projectChildProjectRelationship?.filter(
      (obj: any) => obj.correlationCoefficient !== null,
    );
    const projectChildProjectsHeaders = [
      [
        'X-Matrix',
        'Codice Iniziative',
        'Nome Iniziative',
        'Codice Progetti Strategici',
        'Nome Progetti Strategici',
        'Relazione',
      ],
    ];

    // biome-ignore lint/complexity/noForEach: <explanation>
    projectChildProjectsRelationsOnly?.forEach((item: any) => {
      if (
        xmatrixData.projectChild?.some((el: any) => el.projectID === item.highestIDValue) &&
        xmatrixData.projects?.some((el: any) => el.projectID === item.lowestIDValue)
      ) {
        projectChildProjectsRelationData.push({
          xmatrix: selectedXmatrixInfo.hkCode,
          numberIniziative: item.lowestObjectCode,
          nameIniziative: item.lowestNameValue,
          numberProgettiStrategici: item.highestObjectCode,
          nameProgettiStrategici: item.highestNameValue,
          correlationCoefficient: itemRelationsName(item.correlationCoefficient, t),
        });
      }
    });

    const projectChildProjectsRelationSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(projectChildProjectsRelationSheet, projectChildProjectsRelationData, {
      origin: 'A2',
      skipHeader: true,
    });
    XLSX.utils.sheet_add_aoa(projectChildProjectsRelationSheet, projectChildProjectsHeaders);
    XLSX.utils.book_append_sheet(wb, projectChildProjectsRelationSheet, 'REL_PRJ_PRJ_STRATEGICI');

    const wsColsWidthFormatter = [];
    for (let i = 0; i < 30; i++) {
      wsColsWidthFormatter.push({ wpx: 150 });
    }
    longTermSheet['!cols'] = wsColsWidthFormatter;
    annualGoalSheet['!cols'] = wsColsWidthFormatter;
    annualProjectRelationSheet['!cols'] = wsColsWidthFormatter;
    projectsSheet['!cols'] = wsColsWidthFormatter;
    projectChildProjectsRelationSheet['!cols'] = wsColsWidthFormatter;
    childProjectsSheet['!cols'] = wsColsWidthFormatter;

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  } else {
    message.error(t('general.noDataFound'));
  }
};
