import { EditOutlined } from '@ant-design/icons';
import { DndContext, type DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { Button, Table, Tooltip, message } from 'antd';
import classNames from 'classnames';
import { type FC, useEffect, useRef, useState } from 'react';
import FixedSelectedAsset from 'src/components/xMatrix/FixedSelectedAsset';
import { DndKitRow, sensorOptions } from 'src/components/xMatrix/utils/x-matrix-dnd-kit-common';
import type { XMatrixDto } from 'src/connectors/backend';
import ObAnnoService from '../../../../services/matrix/obAnnoService';
import { obAnualSemaphoreColor, statusClass } from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import '../../matrix.scss';
import EditYearObjectiveModal from './editYearObjectiveModal';

interface YearObjectivesProps {
  annualGoals: XMatrixDto['annualGoals'];
  selectedXmatrix: string;
  reloadMatrix: () => void;
  selection: 'primary' | 'secondary';
  selectedIds: string[];
  onRowClick: (id: string) => void;
  onSort: (oldIndex: number, newIndex: number) => void;
}

const YearObjectives: FC<YearObjectivesProps> = ({
  annualGoals,
  selectedXmatrix,
  reloadMatrix,
  selection,
  selectedIds,
  onRowClick,
  onSort,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({});
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const rowRefs = useRef<any[]>([]);

  const sensors = useSensors(useSensor(PointerSensor, sensorOptions));

  const onDragEnd = (event: DragEndEvent) => {
    if (event.active.id !== event.over?.id) {
      const items: string[] = event.active.data.current?.sortable.items;
      const activeIndex = items.length - items.findIndex((i: string) => i === event.active.id) - 1;
      const overIndex = items.length - items.findIndex((i: string) => i === event.over?.id) - 1;

      return onSort(activeIndex, overIndex);
    }
  };

  useEffect(() => {
    if (selection !== 'primary') {
      setSelectedRecord(null);
    }
  }, [selection]);

  const toggleModal = (rowData?: any) => {
    setOpenModal((prev) => !prev);
    setModalData(rowData || {});
  };

  const handleModalCancel = () => {
    setOpenModal(false);
    setLoadingButton(false);
  };

  const updateData = (values: any, id: string) => {
    setLoadingButton(true);
    values['annualGoalID'] = id;
    const payload = values;

    ObAnnoService.updateObiettivoAnnoxMartix(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          handleModalCancel();
          reloadMatrix();
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch(() => {
        message.error(notifyMessages.updateFailed);
      });
  };

  const removeAnnualGoal = async (id: string) => {
    setLoadingButton(true);

    try {
      await ObAnnoService.removeObAnno(id, selectedXmatrix);
      void message.success(notifyMessages.deleteSuccess);
      handleModalCancel();
      reloadMatrix();
    } catch {
      void message.error(notifyMessages.deleteFailed);
    }
  };

  const selectRow = (record: any, index: number) => {
    onRowClick(record.annualGoalID);
    setSelectedRecord(record);
    setSelectedIndex(index);
  };

  const columns = [
    {
      render: (_: any, record: any, index: number) => ({
        children: (
          <div
            ref={(el) => (rowRefs.current[index] = el)}
            onClick={() => {
              if (annualGoals) {
                selectRow(record, annualGoals?.length - index - 1);
              } else {
                selectRow(record, index);
              }
            }}
            className={`tw-flex tw-py-1 tw-gap-2 matrix-row-vertical tw-w-[32px] tw-items-center tw-justify-between ${classNames(
              {
                'tw-bg-primary selected-row': selection === 'primary' && selectedIds?.includes(record.annualGoalID),
                'tw-bg-primary/30 selected-row':
                  selection === 'secondary' && selectedIds?.includes(record.annualGoalID),
              },
            )}`}
          >
            <Tooltip title={record.statusDescription}>
              <Button
                type="text"
                icon={<EditOutlined />}
                onClick={() => toggleModal(record)}
              />
            </Tooltip>
            <div className="tw-flex tw-items-center tw-gap-2 tw-truncate">
              <Tooltip title={record.title}>
                <p className="tw-size-full tw-rotate-180 text-sm tw-truncate tw-text-black">{record.title}</p>
              </Tooltip>
              <div
                className={`xMatrix-square-label -tw-rotate-90 ${statusClass(record.status)}`}
                style={{
                  borderLeft: '3px solid',
                  borderColor: obAnualSemaphoreColor(record.qualitySemaphore),
                }}
                key={index}
              >
                {annualGoals && annualGoals.length - index}
              </div>
            </div>
          </div>
        ),
      }),
    },
  ];

  if (!annualGoals) return null;

  return (
    <>
      <EditYearObjectiveModal
        show={openModal}
        // toggle={toggleModal}
        handleModalCancel={handleModalCancel}
        data={modalData}
        handleSave={updateData}
        loadingButton={loadingButton}
        removeAnualGoal={removeAnnualGoal}
        selectedXmatrix={selectedXmatrix}
      />

      {selectedRecord && selectedIndex && (
        <FixedSelectedAsset
          side="left"
          listOfRefs={rowRefs}
          selectedIndex={selectedIndex}
        >
          <div className={`xMatrix-square-label ${statusClass(selectedRecord.status)}`}>{selectedIndex + 1}</div>
          <span>{selectedRecord.title}</span>
        </FixedSelectedAsset>
      )}

      <DndContext
        sensors={sensors}
        modifiers={[restrictToHorizontalAxis]}
        onDragEnd={onDragEnd}
      >
        <SortableContext
          items={[...annualGoals].reverse().map((i) => i.annualGoalID!)}
          strategy={horizontalListSortingStrategy}
        >
          <Table
            className="[&_table]:tw-h-full"
            dataSource={[...annualGoals].reverse()}
            components={{
              body: { row: DndKitRow },
            }}
            pagination={false}
            bordered
            size="small"
            showHeader={false}
            columns={columns}
            rowKey={(obj: any) => obj.annualGoalID}
          ></Table>
        </SortableContext>
      </DndContext>
    </>
  );
};

export default YearObjectives;
