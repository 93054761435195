import { type FC, useEffect, useState } from 'react';

import { DatePicker, Select, Space, Tooltip, message } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ProjectParentship, ProjectType, type BowlingChartEntry, type BowlingChartFilters } from 'src/connectors/backend';
import MatrixService from 'src/services/matrix/matrixService';
import CommitieService from 'src/services/pages/commitieServices';
import KpiService from 'src/services/pages/kpiService';
import DashboardService from '../../services/pages/dashboardService';
import TableLayout from '../shared/tableLayout';
import { notifyMessages } from '../shared/utils/notifyMessages';
import BowlingChartChildrenDetailsModal from './bowlingChartChildrenDetailsModal';
import { BowlingChartTable } from './components/bowlingChartTable';
import { useAppSelector } from 'src/redux/store';

export enum BowlingChartType {
  AnnualGoals = 'annualGoals',
  Committees = 'committees',
  Projects = 'projects',
}

type PickListEntry = {
  key: string;
  name: string;
};

const BowlingChart: FC<{
  chartType: BowlingChartType;
}> = ({ chartType }) => {
  const { t } = useTranslation();
  const activeXmatrix = useAppSelector((state) => state.activeXMatrix.activeXMatrix);

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [data, setData] = useState<BowlingChartEntry[]>([]);
  const [picklistData, setPicklistData] = useState<PickListEntry[]>([]);
  const [year, setYear] = useState(moment().year());

  const [relationKey, setRelationKey] = useState<
    keyof BowlingChartFilters & ('annualGoalId' | 'committeeId' | 'projectId')
  >('annualGoalId');
  const [relationId, setRelationId] = useState<string>();
  const [relationName, setRelationName] = useState<string>();

  const [showModal, setShowModal] = useState(false);
  const [modalKpi, setModalKpi] = useState<BowlingChartEntry>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    void fetchFilterData();

    if (chartType === BowlingChartType.AnnualGoals) {
      setRelationKey('annualGoalId');
      setRelationName(t('general.obiettiviAnno'));
    } else if (chartType === BowlingChartType.Committees) {
      setRelationKey('committeeId');
      setRelationName(t('general.comitato'));
    } else if (chartType === BowlingChartType.Projects) {
      setRelationKey('projectId');
      setRelationName(t('general.progetti'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartType, activeXmatrix]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    void getBowlingChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, relationId]);

  const fetchFilterData = async () => {
    try {
      if (chartType === BowlingChartType.AnnualGoals) {
        const { data } = await MatrixService.getAnnualGoals();
        if (data.responseObject?.value) {
          setPicklistData(
            data.responseObject.value.map((e) => ({ key: e.annualGoalId, name: e.description ?? e.annualGoalId })),
          );
        }
      }

      if (chartType === BowlingChartType.Committees) {
        const { data } = await CommitieService.getCommitiesList();
        setPicklistData(data.map((c) => ({ key: c.id, name: c.name })));
      }

      if (chartType === BowlingChartType.Projects) {
        const [operationalProjectsResponse, nonOperationalProjectsResponse] = await Promise.all([
          DashboardService.getDashboardProjects({
            projectParentship: ProjectParentship.All,
            selectNotRelatedProjects: true,
            type: ProjectType.Operational,
          }),
          DashboardService.getDashboardProjects({
            projectParentship: ProjectParentship.All,
            selectNotRelatedProjects: false,
            type: ProjectType.NonOperational,
            xMatrixIds: [activeXmatrix?.xMatrixID as string],
          }),
        ]);

        const operationalProjects = operationalProjectsResponse.data;
        const nonOperationalProjects = nonOperationalProjectsResponse.data;

        setPicklistData([...operationalProjects, ...nonOperationalProjects].map((project) => ({ key: project.id, name: project.name ?? project.code ?? project.id })));
      }
    } catch {}
  };

  const getBowlingChartData = async () => {
    setIsLoadingData(true);

    try {
      const {
        data: { entries },
      } = await KpiService.getBowlingChartData({
        year,
        isAnnualGoalRelated: !relationId && chartType === BowlingChartType.AnnualGoals,
        isCommitteeRelated: !relationId && chartType === BowlingChartType.Committees,
        isProjectRelated: !relationId && chartType === BowlingChartType.Projects,
        [relationKey]: relationId,
      });

      setData(entries);
    } catch {
      void message.error(notifyMessages.retrieveFailed);
    }

    setIsLoadingData(false);
  };

  const toggleKpiDetailModal = async (kpiData: BowlingChartEntry) => {
    setModalKpi(kpiData);
    setShowModal(true);
  };

  return (
    <>
      {showModal && modalKpi && (
        <BowlingChartChildrenDetailsModal
          showModal={showModal}
          handleModalClose={() => setShowModal(false)}
          kpi={modalKpi}
          showKpiRelationDetails={true}
          year={year}
        />
      )}

      <TableLayout data-chart-type={chartType}>
        <TableLayout.Actions>
          <Space>
            <DatePicker
              allowClear={false}
              className="tw-w-24"
              placeholder={t('general.anno')}
              picker="year"
              value={moment(year, 'YYYY')}
              format="YYYY"
              onChange={(moment) => setYear(moment.year())}
            />

            <Select
              className="tw-min-w-96"
              value={relationId}
              id={`${chartType}-relation`}
              allowClear
              disabled={!picklistData.length}
              showSearch
              optionFilterProp="name"
              placeholder={relationName}
              onChange={setRelationId}
              filterOption={(input, option) => option?.name?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
            >
              {picklistData
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((item) => (
                  <Select.Option
                    name={item.name}
                    value={item.key}
                    key={item.key}
                  >
                    <Tooltip
                      placement="left"
                      title={item.name}
                    >
                      {item.name}
                    </Tooltip>
                  </Select.Option>
                ))}
            </Select>
          </Space>
        </TableLayout.Actions>

        <TableLayout.Content>
          <BowlingChartTable
            data={data}
            isLoadingData={isLoadingData}
            showKpiRelationDetails={false}
            toggleKpiDetailModal={toggleKpiDetailModal}
            year={year}
          />
        </TableLayout.Content>
      </TableLayout>
    </>
  );
};

export default BowlingChart;
