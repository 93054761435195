import { Col, Divider, Row, Spin, Typography, message } from 'antd';
import classNames from 'classnames';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import CommonService from '../../../services/commonService';
import MatrixService from '../../../services/matrix/matrixService';
import RelationsService from '../../../services/matrix/relationsService';
import DashboardService from '../../../services/pages/dashboardService';
import { addQueryParam, getQueryParam } from '../../../utils/url-utils';
import { statusPickListCodes, xmatrixStatus } from '../../shared/utils/constants';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import ZoomDataModal from '../data_tables/center/zoomDataModal';
import '../matrix.scss';
import ProjectUserRelation from '../relation_tables/XmatrixProjectUserRelationTable';
import RelationTable from '../relation_tables/XmatrixRelationTable';
import SecondLevelFiltersMatrixLocal from './secondLevelFilterXmatrixLocal';
import AnnualObjectivesLevel2 from './sectionsData/annualObjectives/annualObjectives';
import CenterSectionLevel2 from './sectionsData/centerSection/centerSection';
import KpiLevel2 from './sectionsData/kpis/secondLevelKpi';
import LongObjectivesLevel2 from './sectionsData/longObjectives/longObjectives';
import ProjectsLevel2 from './sectionsData/projects/seconLevelProjects';
import SubProjectsLevel2 from './sectionsData/subProjects/subProjects';
import UsersLevel2 from './sectionsData/users/users';

const { Text } = Typography;

class SecondLevelXmatrix extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton1: false,
      loadingButton2: false,
      loading: true,
      loadingMatrix: false,
      commitiePickList: null,
      projetStatusList: null,
      goalStatusList: null,
      divisionsList: null,
      secondLevelMatrixData: {},
      usersList: null,
      selectedXmatrixInfoHeader: null,
      activeXmatrixInfo: null,
      showZoomModal: false,
      selectedZoomModalData: null,

      selectedXmatrixInfo: null,

      isSecondLevelXmatrix: true,

      userSectionVisibility: false,
      kpiSectionVisibility: false,

      xmatrixRootList: null,
      loadingRootList: false,
      xmFiltersL2: {},
    };
    this.filterComp = React.createRef();
  }

  componentDidMount() {
    const localFilters = localStorage.getItem('xmFiltersL2');

    if (localFilters) {
      this.setState({ xmFiltersL2: JSON.parse(localFilters) }, () => {
        this.retrieveComponentData();
      });
    } else {
      this.retrieveComponentData();
    }

    this.retrieveCommities();
    this.retrieveProjectStatuses();
    this.retrieveGoalStatuses();
    this.retrieveDivisions();
  }

  retrieveComponentData = async () => {
    await this.getActiveXmatrix();
    await this.getXmatrixRootList();
    await this.retrievexMatrixData();
    await this.getSelectedXmatrixInfo();

    // Sync kpi & user visibility with qp
    const userSectionVisibility = getQueryParam('userSectionVisibility') === 'true';
    const kpiSectionVisibility = getQueryParam('kpiSectionVisibility') === 'true';
    this.setState({ userSectionVisibility, kpiSectionVisibility });
  };

  filterIds(filterArray, fullArray) {
    return filterArray.filter((id) => fullArray.includes(id));
  }

  updateProjectChildProjectRelation = (selection, rowId, colId) => {
    const payload = {
      correlationCoefficient: selection.correlationCoefficient,
      xMatrixID: this.state.selectedXmatrixInfo.xMatrixID,
      projectID: colId,
      secondaryProjectID: rowId,
    };

    // this.setState({ loadingIcon: true });
    RelationsService.updateProjectChildProjectRelation(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          // this.setState({ loadingIcon: false });
          this.onReload();
        } else {
          message.error(notifyMessages.updateFailed);
          // this.setState({ loadingIcon: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        // this.setState({ loadingIcon: false });
      });
  };

  updateProjectKpiRelation = (selection, rowId, colId) => {
    const payload = {
      correlationCoefficient: selection.correlationCoefficient,
      xMatrixID: this.state.selectedXmatrixInfo.xMatrixID,
      kpiid: colId,
      projectID: rowId,
    };

    // this.setState({ loadingIcon: true });
    RelationsService.updateProjectKpiRelation(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          // this.setState({ loadingIcon: false });
          this.onReload();
        } else {
          message.error(notifyMessages.updateFailed);
          // this.setState({ loadingIcon: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        // this.setState({ loadingIcon: false });
      });
  };

  updateAnnualProjectRelation = (selection, rowId, colId) => {
    const payload = {
      correlationCoefficient: selection.correlationCoefficient,
      xMatrixID: this.state.selectedXmatrixInfo.xMatrixID,
      projectID: colId,
      annualGoalID: rowId,
    };

    // this.setState({ loadingIcon: true });
    RelationsService.updateAnnualProjectRelation(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          // this.setState({ loadingIcon: false });
          this.onReload();
        } else {
          message.error(notifyMessages.updateFailed);
          // this.setState({ loadingIcon: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        // this.setState({ loadingIcon: false });
      });
  };

  updateAnnualGoalKpiRelation = (selection, rowId, colId) => {
    const payload = {
      correlationCoefficient: selection.correlationCoefficient,
      xMatrixID: this.state.selectedXmatrixInfo.xMatrixID,
      kpiid: colId,
      annualGoalID: rowId,
    };

    // this.setState({ loadingIcon: true });
    RelationsService.updateAnnualGoalKpiRelation(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          // this.setState({ loadingIcon: false });
          this.onReload();
        } else {
          message.error(notifyMessages.updateFailed);
          // this.setState({ loadingIcon: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        // this.setState({ loadingIcon: false });
      });
  };

  updateProjectUserRelation = (selection, rowId, colId) => {
    const payload = {
      correlationCoefficient: selection.correlationCoefficient,
      xMatrixID: this.state.selectedXmatrixInfo.xMatrixID,
      userID: colId,
      projectID: rowId,
    };

    // this.setState({ loadingIcon: true });
    RelationsService.updateProjectUserRelation(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          // this.setState({ loadingIcon: false });
          this.onReload();
        } else {
          message.error(notifyMessages.updateFailed);
          // this.setState({ loadingIcon: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        // this.setState({ loadingIcon: false });
      });
  };

  selectAnnualObjectives(selection = { projectIds: [], kpiIds: [] }) {
    const selectedAnnualObjectiveIds = this.filterIds(
      this.state.secondLevelMatrixData.annualGoals.map(({ annualGoalID }) => annualGoalID),
      [
        ...this.state.secondLevelMatrixData.goalProjectRelationship
          ?.filter((ag) => ag.correlationCoefficient !== 3 && selection.projectIds?.includes(ag.highestIDValue))
          .map(({ lowestIDValue }) => lowestIDValue),
        ...this.state.secondLevelMatrixData.annualGoalKPIRelationship
          ?.filter((ag) => ag.correlationCoefficient !== 3 && selection.kpiIds?.includes(ag.highestIDValue))
          .map(({ lowestIDValue }) => lowestIDValue),
      ],
    );

    this.setState({ selectedAnnualObjectiveIds });
    return selectedAnnualObjectiveIds;
  }

  selectProjects(selection = { projectChildIds: [], annualGoalIds: [] }) {
    const selectedProjectIds = this.filterIds(
      this.state.secondLevelMatrixData.projects.map(({ projectID }) => projectID),
      [
        ...this.state.secondLevelMatrixData.goalProjectRelationship
          ?.filter((ag) => ag.correlationCoefficient !== 3 && selection.annualGoalIds?.includes(ag.lowestIDValue))
          .map(({ highestIDValue }) => highestIDValue),
        ...this.state.secondLevelMatrixData.projectChildProjectRelationship
          ?.filter((ag) => ag.correlationCoefficient !== 3 && selection.projectChildIds?.includes(ag.highestIDValue))
          .map(({ lowestIDValue }) => lowestIDValue),
      ],
    );

    this.setState({ selectedProjectIds });
    return selectedProjectIds;
  }

  selectProjectChildren(selection = { projectIds: [], kpiIds: [], userIds: [] }) {
    const selectedProjectChildIds = this.filterIds(
      this.state.secondLevelMatrixData.projectChild.map(({ projectID }) => projectID),
      [
        ...this.state.secondLevelMatrixData.projectChildProjectRelationship
          ?.filter((ag) => ag.correlationCoefficient !== 3 && selection.projectIds?.includes(ag?.lowestIDValue))
          .map(({ highestIDValue }) => highestIDValue),
        ...this.state.secondLevelMatrixData.kpiProjectRelationships
          ?.filter((ag) => ag.correlationCoefficient !== 3 && selection.kpiIds?.includes(ag.highestIDValue))
          .map(({ lowestIDValue }) => lowestIDValue),
        ...this.state.secondLevelMatrixData.userProjectRelationship
          ?.filter((ag) => selection.userIds?.includes(ag.highestIDValue))
          .map(({ lowestIDValue }) => lowestIDValue),
      ],
    );

    this.setState({ selectedProjectChildIds });
    return selectedProjectChildIds;
  }

  selectKpis(selection = { projectChildIds: [], annualGoalIds: [] }) {
    const selectedKpiIds = this.filterIds(
      this.state.secondLevelMatrixData.kpIs.map(({ kpiid }) => kpiid),
      [
        ...this.state.secondLevelMatrixData.kpiProjectRelationships
          ?.filter((ag) => ag.correlationCoefficient !== 3 && selection.projectChildIds?.includes(ag.lowestIDValue))
          .map(({ highestIDValue }) => highestIDValue),
        ...this.state.secondLevelMatrixData.annualGoalKPIRelationship
          ?.filter((ag) => ag.correlationCoefficient !== 3 && selection.annualGoalIds?.includes(ag.lowestIDValue))
          .map(({ highestIDValue }) => highestIDValue),
      ],
    );

    this.setState({ selectedKpiIds });
    return selectedKpiIds;
  }

  selectUsers(selection = { projectChildIds: [] }) {
    const selectedUserIds = this.filterIds(
      this.state.secondLevelMatrixData.users.map(({ userID }) => userID),
      [
        ...this.state.secondLevelMatrixData.userProjectRelationship
          ?.filter((ag) => ag.correlationCoefficient !== 4 && selection.projectChildIds?.includes(ag.lowestIDValue))
          .map(({ highestIDValue }) => highestIDValue),
      ],
    );

    this.setState({ selectedUserIds });
    return selectedUserIds;
  }

  selectKpi = (selectedKpiId) => {
    this.setState({ selection: 'kpis', selectedKpiIds: [selectedKpiId] });

    // Clockwise
    const annualGoalIds1 = this.selectAnnualObjectives({ kpiIds: [selectedKpiId] });
    const projectIds1 = this.selectProjects({ annualGoalIds: annualGoalIds1 });
    this.selectProjectChildren({ projectIds: projectIds1 });

    // Anti clockwise
    const projectChildIds2 = this.selectProjectChildren({ kpiIds: [selectedKpiId], projectIds: projectIds1 });
    const projectIds2 = this.selectProjects({ projectChildIds: projectChildIds2, annualGoalIds: annualGoalIds1 });
    this.selectAnnualObjectives({ projectIds: projectIds2, kpiIds: [selectedKpiId] });
  };

  selectUser = (selectedUserId) => {
    this.setState({ selection: 'users', selectedUserIds: [selectedUserId] });

    const projectChildIds = this.selectProjectChildren({ userIds: [selectedUserId] });

    // Clockwise
    const kpiIds1 = this.selectKpis({ projectChildIds });
    const annualGoalIds1 = this.selectAnnualObjectives({ kpiIds: kpiIds1 });
    this.selectProjects({ annualGoalIds: annualGoalIds1 });

    // Anti clockwise
    const projectIds2 = this.selectProjects({
      projectChildIds: projectChildIds,
      annualGoalIds: annualGoalIds1,
    });
    const annualGoalIds2 = this.selectAnnualObjectives({ projectIds: projectIds2, kpiIds: kpiIds1 });
    this.selectKpis({ annualGoalIds: annualGoalIds2, projectIds: projectChildIds });
  };

  selectProject = (selectedProjectId) => {
    this.setState({ selection: 'projects', selectedProjectIds: [selectedProjectId] });

    // Clockwise
    const projectChildIds1 = this.selectProjectChildren({ projectIds: [selectedProjectId] });
    const kpiIds1 = this.selectKpis({ projectChildIds: projectChildIds1 });
    this.selectUsers({ projectChildIds: projectChildIds1 });
    this.selectAnnualObjectives({ kpiIds: kpiIds1 });

    // Anti clockwise
    const annualGoalIds2 = this.selectAnnualObjectives({ projectIds: [selectedProjectId], kpiIds: kpiIds1 });
    const kpiIds2 = this.selectKpis({ annualGoalIds: annualGoalIds2, projectChildIds: projectChildIds1 });
    this.selectProjectChildren({ kpiIds: kpiIds2, projectIds: [selectedProjectId] });
  };

  selectProjectChild = (selectedProjectChildId) => {
    this.setState({ selection: 'projectChildren', selectedProjectChildIds: [selectedProjectChildId] });

    this.selectUsers({ projectChildIds: [selectedProjectChildId] });

    // Clockwise
    const kpiIds1 = this.selectKpis({ projectChildIds: [selectedProjectChildId] });
    const annualGoalIds1 = this.selectAnnualObjectives({ kpiIds: kpiIds1 });
    this.selectProjects({ annualGoalIds: annualGoalIds1 });

    // Anti clockwise
    const projectIds2 = this.selectProjects({
      projectChildIds: [selectedProjectChildId],
      annualGoalIds: annualGoalIds1,
    });
    const annualGoalIds2 = this.selectAnnualObjectives({ projectIds: projectIds2, kpiIds: kpiIds1 });
    this.selectKpis({ annualGoalIds: annualGoalIds2, projectChildIds: [selectedProjectChildId] });
  };

  selectAnnualObjective = (selectedAnnualObjectiveId) => {
    this.setState({ selection: 'annualObjectives', selectedAnnualObjectiveIds: [selectedAnnualObjectiveId] });

    // Clockwise
    const projectIds1 = this.selectProjects({ annualGoalIds: [selectedAnnualObjectiveId] });
    const projectChildIds1 = this.selectProjectChildren({ projectIds: projectIds1 });
    this.selectKpis({ projectChildIds: projectChildIds1 });
    this.selectUsers({ projectChildIds: projectChildIds1 });

    // AntiClockwise
    const selectedKpiIds2 = this.selectKpis({
      annualGoalIds: [selectedAnnualObjectiveId],
      projectChildIds: projectChildIds1,
    });
    const projectChildIds2 = this.selectProjectChildren({ kpiIds: selectedKpiIds2, projectIds: projectIds1 });
    this.selectProjects({
      projectChildIds: projectChildIds2,
      annualGoalIds: [selectedAnnualObjectiveId],
    });
  };

  async retrieveCommities() {
    await CommonService.getCommitiePicklist()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ commitiePickList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  // Retrieve list of divisions
  async retrieveDivisions() {
    await DashboardService.getDivisionFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ divisionsList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  //retrieve project status
  async retrieveProjectStatuses() {
    const objectCode = statusPickListCodes.statusProjects;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ projetStatusList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveGoalStatuses() {
    const objectCode = statusPickListCodes.statusObAnnoLong;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ goalStatusList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async getUsers() {
    await MatrixService.getUsersXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ usersList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async getSelectedXmatrixInfo() {
    const { id } = this.props.match.params;
    await MatrixService.getSelectedXmatrixInfo(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ selectedXmatrixInfo: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async getXmatrixRootList() {
    this.setState({ loadingRootList: true });
    await MatrixService.getXmatrixDivisionRootList()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ loadingRootList: false });
          this.setState({ xmatrixRootList: resp.responseObject.value });
        } else {
          this.setState({ loadingRootList: false });
        }
      })
      .catch((error) => {
        this.setState({ loadingRootList: false });
      });
  }

  onReload = () => {
    this.retrievexMatrixData();
  };

  manageFilterReset = () => {
    this.setState({ xmFiltersL2: {} }, () => {
      localStorage.setItem('xmFiltersL2', JSON.stringify({}));
      this.retrievexMatrixData();
      this.setState({ loadingButton2: true });
    });
  };

  manageFiltersChanged = (obj) => {
    const { xmFiltersL2 } = this.state;
    if (obj.value === '' || obj.value?.length === 0) {
      delete xmFiltersL2[obj.filter];
    } else {
      xmFiltersL2[obj.filter] = obj.value;
    }
    this.setState({ xmFiltersL2 });
    localStorage.setItem('xmFiltersL2', JSON.stringify(xmFiltersL2));
  };

  manageFilterApplied = () => {
    this.retrievexMatrixData();
    this.setState({ loadingButton1: true });
  };

  async retrievexMatrixData() {
    const { activeXmatrixInfo, xmFiltersL2 } = this.state;
    const { id } = this.props.match.params;

    if (id) {
      const xmFilterObj = { filter: 'XMatrixID', value: id };
      this.manageFiltersChanged(xmFilterObj);
    } else {
      const xmFilterObj = { filter: 'XMatrixID', value: activeXmatrixInfo?.xMatrixID };
      this.manageFiltersChanged(xmFilterObj);
    }

    this.setState({ loadingMatrix: true });
    const locakStorageFilters = xmFiltersL2;

    await MatrixService.getSecondLevelXmatrixData(locakStorageFilters)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ secondLevelMatrixData: resp.responseObject.value });
          this.setState({ loading: false });
          this.setState({ loadingMatrix: false });
          this.setState({ loadingButton1: false });
          this.setState({ loadingButton2: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingMatrix: false });
          this.setState({ loadingButton1: false });
          this.setState({ loadingButton2: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingMatrix: false });
        this.setState({ loadingButton1: false });
        this.setState({ loadingButton2: false });
      });
  }

  async getActiveXmatrix() {
    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          this.setState({ activeXmatrixInfo: respData }, () => {});
        } else {
        }
      })
      .catch((error) => {});
  }

  toggleUsersSection = () => {
    const { userSectionVisibility } = this.state;
    this.setState({ userSectionVisibility: !userSectionVisibility });
    addQueryParam('userSectionVisibility', !userSectionVisibility);
  };

  toggleKpiSection = () => {
    const { kpiSectionVisibility } = this.state;
    this.setState({ kpiSectionVisibility: !kpiSectionVisibility });
    addQueryParam('kpiSectionVisibility', !kpiSectionVisibility);
  };

  handleZoomModalClose = () => {
    this.setState({ showZoomModal: false });
  };

  toggleZoomModal = (data, area, label) => {
    this.setState({ showZoomModal: true });
    const dataObj = {};
    dataObj['data'] = data;
    dataObj['area'] = area;
    dataObj['label'] = label;
    this.setState({ selectedZoomModalData: dataObj });
  };

  render() {
    const { id } = this.props.match.params;
    const t = this.props.t;
    const {
      secondLevelMatrixData,
      loading,
      loadingButton1,
      loadingButton2,
      commitiePickList,
      projetStatusList,
      goalStatusList,
      divisionsList,
      activeXmatrixInfo,
      selectedXmatrixInfo,
      loadingRootList,
      xmatrixRootList,
      isSecondLevelXmatrix,
      kpiSectionVisibility,
      userSectionVisibility,
      selectedZoomModalData,
      xmFiltersL2,
    } = this.state;

    return (
      <div className="x-matrix tw-flex tw-flex-col tw-h-full tw-overflow-hidden">
        {this.state.showZoomModal && (
          <ZoomDataModal
            showZoomModal={this.state.showZoomModal}
            handleZoomModalClose={this.handleZoomModalClose}
            modalData={selectedZoomModalData}
            t={t}
            xMatrixCycleName={selectedXmatrixInfo.xMatrixCycleName}
          />
        )}

        {/* print */}
        <Row
          className="matrixNameOnPrint"
          gutter={{ lg: 24 }}
        >
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
          >
            <Text
              level={2}
              className={'menu xmatrixTitle'}
            >
              {selectedXmatrixInfo ? selectedXmatrixInfo.hkCode : ''}{' '}
            </Text>
            <Divider
              type="vertical"
              className="xmatrixTitleDivider"
            />

            {selectedXmatrixInfo && selectedXmatrixInfo.xMatrixName && (
              <>
                <Text
                  level={2}
                  className={'menu xmatrixTitle'}
                >
                  {selectedXmatrixInfo ? selectedXmatrixInfo.xMatrixName : ''}{' '}
                </Text>
                <Divider
                  type="vertical"
                  className="xmatrixTitleDivider"
                />
              </>
            )}
            {selectedXmatrixInfo && (
              <>
                {selectedXmatrixInfo.status === xmatrixStatus.active && (
                  <>
                    <Text className="activeText xmatrixTitle">{selectedXmatrixInfo.statusDescription}</Text>
                  </>
                )}
                {selectedXmatrixInfo.status !== xmatrixStatus.active && (
                  <>
                    <Text className="inactiveText xmatrixTitle">{selectedXmatrixInfo.statusDescription}</Text>
                  </>
                )}
              </>
            )}
          </Col>
        </Row>

        {selectedXmatrixInfo && (
          <SecondLevelFiltersMatrixLocal
            ref={this.filterComp}
            manageFilterReset={this.manageFilterReset}
            loadingButton1={loadingButton1}
            loadingButton2={loadingButton2}
            manageFilterApplied={this.manageFilterApplied}
            manageFiltersChanged={this.manageFiltersChanged}
            selectedXmatrix={selectedXmatrixInfo}
            t={t}
            // xmatrix header props
            selectedXmatrixInfo={selectedXmatrixInfo}
            isSecondLevelXmatrix={isSecondLevelXmatrix}
            reloadMatrixOnEdit={this.onReload}
            reloadXmInfo={() => this.getSelectedXmatrixInfo()}
            //left panel xmatrix list props
            activeXmatrixInfo={activeXmatrixInfo}
            loadingRootList={loadingRootList}
            xmatrixRootList={xmatrixRootList}
            filters={xmFiltersL2}
            xmatrixData={secondLevelMatrixData}
          />
        )}

        {loading && (
          <div className="tw-min-h-full empty:tw-hidden tw-w-full tw-flex tw-justify-center tw-items-center">
            <Spin
              size="large"
              tip={t('xMatrixPage.xmatrixLoading')}
            ></Spin>
          </div>
        )}

        {secondLevelMatrixData && !loading && (
          <div className="tw-grow tw-p-4 tw-overflow-auto tw-flex">
            <div
              className={`${classNames('tw-overflow-hidden tw-shrink-0 tw-m-auto', {
                'grid-container-level2-noKpiAndUserCols': !kpiSectionVisibility && !userSectionVisibility,
                'grid-container-level2': kpiSectionVisibility && userSectionVisibility,
                'grid-container-level2-noKpiCol': !kpiSectionVisibility && userSectionVisibility,
                'grid-container-level2-noUserCol': kpiSectionVisibility && !userSectionVisibility,
              })}`}
            >
              {/* row 1 */}

              <div id="ProjectChildProjectRelationLevel2">
                <RelationTable
                  t={t}
                  colList={secondLevelMatrixData.projects}
                  rowList={secondLevelMatrixData.projectChild}
                  colItemId={'projectID'}
                  rowItemId={'projectID'}
                  origin={'topLeft'}
                  direction={'bottomRight'}
                  relationList={secondLevelMatrixData.projectChildProjectRelationship}
                  colSelectionIds={this.state.selectedProjectIds}
                  rowSelectionIds={this.state.selectedProjectChildIds}
                  onUpdateRelation={this.updateProjectChildProjectRelation}
                />
              </div>

              <div
                className="projects"
                id="SubProjectsLevel2"
              >
                <SubProjectsLevel2
                  t={t}
                  projects={secondLevelMatrixData.projectChild}
                  reloadMatrix={this.onReload}
                  comittieList={commitiePickList}
                  statusList={projetStatusList}
                  divisionsList={divisionsList}
                  selectedXmatrix={id}
                  selectedXmatrixInfoData={selectedXmatrixInfo}
                  isSecondLevelXmatrix={isSecondLevelXmatrix}
                  onRowClick={this.selectProjectChild}
                  selectedIds={this.state.selectedProjectChildIds}
                  selection={this.state.selection === 'projectChildren' ? 'primary' : 'secondary'}
                />
              </div>

              <div
                id="ChildProjectKpiRelationLevel2"
                className={classNames('', {
                  'tw-hidden': !kpiSectionVisibility,
                })}
              >
                <RelationTable
                  t={t}
                  colList={secondLevelMatrixData.kpIs}
                  rowList={secondLevelMatrixData.projectChild}
                  colItemId={'kpiid'}
                  rowItemId={'projectID'}
                  origin={'topLeft'}
                  direction={'bottomLeft'}
                  relationList={secondLevelMatrixData.kpiProjectRelationships}
                  colSelectionIds={this.state.selectedKpiIds}
                  rowSelectionIds={this.state.selectedProjectChildIds}
                  onUpdateRelation={this.updateProjectKpiRelation}
                />
              </div>

              <div
                className={classNames('rotateProjetUser', {
                  'tw-hidden': !userSectionVisibility,
                })}
                id="UserProjectRelationLevel2"
              >
                <ProjectUserRelation
                  t={t}
                  colList={secondLevelMatrixData.users}
                  rowList={secondLevelMatrixData.projectChild}
                  colItemId={'userID'}
                  rowItemId={'projectID'}
                  origin={'topLeft'}
                  direction={'bottomLeft'}
                  relationList={secondLevelMatrixData.userProjectRelationship}
                  colSelectionIds={this.state.selectedUserIds}
                  rowSelectionIds={this.state.selectedProjectChildIds}
                  onUpdateRelation={this.updateProjectUserRelation}
                />
              </div>

              {/* row 2 */}

              <div
                className="rotateYear"
                id="ProjectsLevel2"
              >
                <ProjectsLevel2
                  t={t}
                  projects={secondLevelMatrixData.projects}
                  reloadMatrix={this.onReload}
                  comittieList={commitiePickList}
                  statusList={projetStatusList}
                  divisionsList={divisionsList}
                  selectedXmatrix={id}
                  selectedXmatrixInfoData={selectedXmatrixInfo}
                  isSecondLevelXmatrix={isSecondLevelXmatrix}
                  onRowClick={this.selectProject}
                  selectedIds={this.state.selectedProjectIds}
                  selection={this.state.selection === 'projects' ? 'primary' : 'secondary'}
                />
              </div>

              <div id="imgCenter">
                <CenterSectionLevel2
                  t={t}
                  reloadMatrix={this.onReload}
                  comittieList={commitiePickList}
                  projectStatusList={projetStatusList}
                  goalStatusList={goalStatusList}
                  divisionsList={divisionsList}
                  selectedXmatrix={id}
                  selectedXmatrixInfo={selectedXmatrixInfo}
                  isSecondLevelXmatrix={isSecondLevelXmatrix}
                  toggleKpiSection={this.toggleKpiSection}
                  toggleUsersSection={this.toggleUsersSection}
                  isKpiSectionVisible={this.state.kpiSectionVisibility}
                  isUsersSectionVisible={this.state.userSectionVisibility}
                  annualGoals={secondLevelMatrixData.annualGoals}
                  kpiSectionVisibility={kpiSectionVisibility}
                  projects={secondLevelMatrixData.projects}
                  childProjects={secondLevelMatrixData.projectChild}
                  longTermGoals={secondLevelMatrixData.longTermGoals}
                />
              </div>

              <div
                id="KpiLevel2"
                className={classNames('rotateKpi', {
                  'tw-hidden': !kpiSectionVisibility,
                })}
              >
                <KpiLevel2
                  t={t}
                  kpi={secondLevelMatrixData.kpIs}
                  reloadMatrix={this.onReload}
                  selectedXmatrix={id}
                  onRowClick={this.selectKpi}
                  selectedIds={this.state.selectedKpiIds}
                  selection={this.state.selection === 'kpis' ? 'primary' : 'secondary'}
                />
              </div>

              <div
                className={classNames('rotateUsers', {
                  'tw-hidden': !userSectionVisibility,
                })}
                id="UsersLevel2"
              >
                <UsersLevel2
                  usersXmatrix={secondLevelMatrixData.users}
                  selectedIds={this.state.selectedUserIds}
                  selection={this.state.selection === 'users' ? 'primary' : 'secondary'}
                  onRowClick={this.selectUser}
                />
              </div>
              {/* </div> */}

              {/* row 3 */}

              <div id="AnnualGoalProjectRelationLevel2">
                <RelationTable
                  t={t}
                  colList={secondLevelMatrixData.projects}
                  rowList={secondLevelMatrixData.annualGoals}
                  colItemId={'projectID'}
                  rowItemId={'annualGoalID'}
                  origin={'topLeft'}
                  direction={'topRight'}
                  relationList={secondLevelMatrixData.goalProjectRelationship}
                  colSelectionIds={this.state.selectedProjectIds}
                  rowSelectionIds={this.state.selectedAnnualObjectiveIds}
                  onUpdateRelation={this.updateAnnualProjectRelation}
                />
              </div>

              <div id="AnnualObjectivesLevel2">
                <AnnualObjectivesLevel2
                  t={t}
                  annualGoals={secondLevelMatrixData.annualGoals}
                  reloadMatrix={this.onReload}
                  goalStatusList={goalStatusList}
                  selectedXmatrix={id}
                  selectedIds={this.state.selectedAnnualObjectiveIds}
                  selection={this.state.selection === 'annualObjectives' ? 'primary' : 'secondary'}
                  onRowClick={this.selectAnnualObjective}
                />
              </div>

              <div
                id="AnnualGoalKpiRelationLevel2"
                className={classNames('', {
                  'tw-hidden': !kpiSectionVisibility,
                })}
              >
                <RelationTable
                  t={t}
                  colList={secondLevelMatrixData.kpIs}
                  rowList={secondLevelMatrixData.annualGoals}
                  colItemId={'kpiid'}
                  rowItemId={'annualGoalID'}
                  origin={'topLeft'}
                  direction={'topLeft'}
                  relationList={secondLevelMatrixData.annualGoalKPIRelationship}
                  colSelectionIds={this.state.selectedKpiIds}
                  rowSelectionIds={this.state.selectedAnnualObjectiveIds}
                  onUpdateRelation={this.updateAnnualGoalKpiRelation}
                />
              </div>

              {/* <div
                className="strategiaLungoPeriodo"
                id="strategiaLungoPeriodo">
                <Text
                  onClick={() =>
                    this.toggleZoomModal(
                      secondLevelMatrixData.longTermGoals,
                      'longTermGoalsL2',
                      t('xMatrixPage.strategiaLungoPeriodo'),
                    )
                  }
                  className="menu hoverZoomCursor">
                  {t('xMatrixPage.strategiaLungoPeriodo')}
                </Text>
                {selectedXmatrixInfo && selectedXmatrixInfo.xMatrixCycleName && (
                  <Text className="menu"> - {selectedXmatrixInfo.xMatrixCycleName}</Text>
                )}
              </div> */}

              {/* row 4 */}

              <div
                className="big-objectives"
                id="LongObjectivesLevel2"
              >
                <LongObjectivesLevel2
                  longTermGoals={secondLevelMatrixData.longTermGoals}
                  reloadMatrix={this.onReload}
                  selectedRow={this.selectedRow}
                  selectedXmatrix={id}
                />
              </div>

              <div
                className={classNames('tw-w-8', {
                  'tw-hidden': !userSectionVisibility,
                })}
                id="space"
              ></div>

              <div
                className="tw-h-8"
                id="vertical-space"
              ></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(SecondLevelXmatrix);
