import { CalendarOutlined, LeftOutlined } from '@ant-design/icons';
import { Avatar, Button, Menu, Tooltip, Typography, message } from 'antd';
import classNames from 'classnames';
import { type FC, useEffect, useMemo, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import {
  RiCalendarLine,
  RiChatFollowUpLine,
  RiDashboard2Line,
  RiDashboardLine,
  RiFileListLine,
  RiFolderLine,
  RiMenu2Fill,
  RiMenuLine,
  RiTaskLine,
  RiTimeLine,
} from 'react-icons/ri';
import { useHistory, useParams } from 'react-router-dom';
import CommitteeKpiList from 'src/components/commitie/comitieDashboard/CommitteeKpiList';
import { type CommitteeDto, type CommitteeMemberDto, ProjectType } from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import { ArchiveStackIcon } from '../../../assets/icons';
import CommitieService from '../../../services/pages/commitieServices';
import { addQueryParam, buildQueryParams, getQueryParam, hasQueryParam } from '../../../utils/url-utils';
import ProjectFeed from '../../projects/projectForm/projectFeed/projectFeed';
import Loader from '../../shared/components/loader/loader';
import { checkUserIsLenovysOrCompanyAdmin } from '../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { feedObjectType, feedRegardingObjectType } from '../../shared/utils/constants';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import CommitteeProjects from './CommitteeProjects';
import ActionPlan from './comitieActivities/activities';
import CalendarIndex from './comitieCalendar/index';
import ProjectRevision from './comitieSintesi/sintesi';
import ComitieSummary from './comitieSummary/comitieSummary';
import RelatedComities from './relatedComities';
import CommitteeSessions from './sessions/CommitteeSessions';

const CommitteesDashboardIndex: FC = () => {
  const currentUser = useAppSelector((state) => state.userData.userData);
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const history = useHistory();

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [committee, setCommittee] = useState<CommitteeDto>();
  const [userHasAccess, setUserHasAccess] = useState(false);
  const [tabActiveKey, setTabActiveKey] = useState('1');

  useEffect(() => {
    setTabActiveKey(getQueryParam('tab') || '1');

    void getCommittee();
  }, [id]);

  const getCommittee = async () => {
    if (!id) return;

    try {
      const { data } = await CommitieService.getById(id);

      setCommittee(data);
      checkIfUserHasAccess(data.members ?? []);
    } catch {
      void message.error(notifyMessages.retrieveFailed);
    }
  };

  const checkIfUserHasAccess = (members: CommitteeMemberDto[]) => {
    const accessByRole = checkUserIsLenovysOrCompanyAdmin(currentUser);
    if (accessByRole) {
      setUserHasAccess(true);

      return;
    }

    setUserHasAccess(!!members.find((m) => m.userId === currentUser?.userId));
  };

  const redirectToProjectDashboard = (tabKey: { key: string }) => {
    const xMatrix = hasQueryParam('xMatrix') ? getQueryParam('xMatrix') : undefined;

    if (tabKey.key === '5') {
      setTabActiveKey('1');
      let url = '/progettihoshinkanri';

      if (committee) {
        const queryParams = buildQueryParams({
          committee: id,
          projectType: CommitieService.isOperational(committee.type)
            ? ProjectType.Operational
            : ProjectType.NonOperational,
          xmatrix: xMatrix,
        });

        url += `?${queryParams}`;
      }

      window.open(url, '_blank');
    } else {
      setTabActiveKey(tabKey.key);
      addQueryParam('tab', tabKey.key);
    }
  };

  const renderComponent = useMemo(() => {
    if (!committee) {
      return <></>;
    }

    switch (tabActiveKey) {
      case '1':
        return <ComitieSummary committee={committee} />;
      case '2':
        return (
          <div className="tw-flex tw-flex-col tw-gap-4">
            <CommitteeProjects committee={committee} />
            <RelatedComities
              comitieId={id}
              userHasAccess={userHasAccess}
            />
          </div>
        );
      case '3':
        return (
          <CalendarIndex
            comitieId={id || ''}
            comitieDetails={committee}
          />
        );
      case '4':
        return (
          <ActionPlan
            comitieId={id}
            comitieDetails={committee}
            userHasAccess={userHasAccess}
            reloadCommitie={getCommittee}
          />
        );
      case '5':
        return <></>;
      case '6':
        // verbali
        return (
          <ProjectFeed
            objectId={id}
            userData={currentUser}
            isProject={0}
            isCommitie={1}
            hasUserAccess={false}
            objectType={feedObjectType.commitieFeed}
            feedRegardingObjType={feedRegardingObjectType.commitie}
            showFeed={true}
          />
        );
      case '7':
        return <ProjectRevision committee={committee} />;
      case '8':
        return (
          <CommitteeSessions
            committee={committee}
            userHasAccess={userHasAccess}
          />
        );
      case '10':
        return <CommitteeKpiList committeeDetails={committee} />;
      // case '9':
      //   return (
      //     <CommitieTeam
      //       comitieId={comitieId}
      //       comitieDetails={comitieDetails}
      //     />
      //   );
      default:
        return null;
    }
  }, [committee, tabActiveKey]);

  const menuItems = [
    { key: '1', label: t('comitatiPage.overview'), icon: <RiDashboardLine /> },
    // { key: '9', label: t('comitatiPage.agenda'), disabled: true, icon: <RiCalendarEventLine /> }, // Es. elemento disabilitato
    { key: '8', label: t('comitatiPage.sessions'), icon: <RiTimeLine /> },
    // { key: '10', label: t('comitatiPage.kpis'), icon: <RiBarChartLine /> },
    { key: '4', label: t('comitatiPage.actionPlan'), icon: <RiTaskLine /> },
    { key: '7', label: t('comitatiPage.projectSintesi'), icon: <RiFileListLine /> },
    { key: '2', label: t('general.progetti'), icon: <RiFolderLine /> },
    { key: '3', label: t('comitatiPage.projectCalendar'), icon: <RiCalendarLine /> },
    { key: '6', label: t('feed.feed'), icon: <RiChatFollowUpLine /> },
    { key: '5', label: t('dashboardProgettiPage.dashboardProgetti'), icon: <RiDashboard2Line /> },
    // { key: '9', label: t('comitatiPage.team'), icon: <RiTeamLine /> }, // Elemento commentato
  ];

  return (
    <>
      <Loader />
      {committee && (
        <div className="tw-flex tw-flex-col tw-overflow-hidden tw-absolute tw-inset-0 tw-size-full">
          <div className="tw-bg-white tw-border-2 tw-shrink-0 tw-gap-8 tw-border-b tw-flex tw-items-center tw-justify-between tw-min-h-6 tw-max-h-14 tw-py-2 tw-px-3">
            <div className="tw-flex tw-gap-2 tw-items-center tw-shrink-0">
              <Button
                type="text"
                onClick={() => history.goBack()}
                icon={<LeftOutlined />}
              ></Button>

              <Typography.Text className="tw-text-xl tw-font-semibold"> {committee.name} </Typography.Text>
            </div>

            <div className="tw-flex tw-gap-4">
              {committee.members?.length && (
                <Avatar.Group
                  max={{
                    count: 4,
                  }}
                >
                  {committee.members.map((p) => (
                    <Tooltip
                      placement="bottom"
                      title={p.userFullName}
                    >
                      <Avatar
                        className="tw-bg-primary"
                        key={p.userId}
                      >
                        {p.userFullName?.at(0)}
                      </Avatar>
                    </Tooltip>
                  ))}
                </Avatar.Group>
              )}
              <div className="tw-flex tw-gap-2 tw-items-center">
                <CalendarOutlined />
                <div>
                  {committee.activitiesDuration}
                  {` `}gg
                </div>
              </div>
              {committee.repositoryLink && committee.repositoryLink !== '' && (
                <Tooltip title={t('comitatiPage.repositoryLink')}>
                  <Button
                    target="_blank"
                    href={committee.repositoryLink}
                    icon={<ArchiveStackIcon />}
                  ></Button>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="tw-flex tw-size-full tw-overflow-y-hidden">
            <div className="tw-flex tw-flex-col tw-h-full">
              <Button
                shape="circle"
                onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
                className={'tw-ml-6 tw-mt-4 tw-mb-2'}
                icon={isSidebarCollapsed ? <RiMenu2Fill /> : <RiMenuLine />}
              ></Button>
              <Menu
                className="[&_svg]:!tw-scale-125 tw-max-w-[250px]"
                onClick={redirectToProjectDashboard}
                items={menuItems}
                inlineCollapsed={isSidebarCollapsed}
                defaultSelectedKeys={['1']}
                selectedKeys={[tabActiveKey]}
              />
            </div>
            <div className={classNames('tw-size-full zoom tw-p-8 tw-overflow-auto tw-h-full', {})}>
              {renderComponent}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withTranslation()(CommitteesDashboardIndex);
