import { trackPromise } from 'react-promise-tracker';
import {
  type CreateProjectRequest,
  type InsertProjectRepositoryRequestDto,
  LegacyProjectApi,
  ProjectApi,
  type ProjectType,
  type SwapTeamMembersActivitiesRequest,
  type UpdateProjectFieldsRequestDto,
  type UpdateProjectRequest,
  type UpdateTeamMembersRequest,
  XMatrixApi,
  authenticatedApi,
  trackApiPromise,
} from 'src/config/connectors';
import { getParentProjects } from './progettiService.ext';

const ProgettiService = {
  // get project by id
  getProgettiByiD: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetProject(projectId));
  },

  getProjectById: async (projectId: string) => {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetProject(projectId));
  },

  getParentProjectByID: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetProject(projectId));
  },

  createProject: async (project: CreateProjectRequest) => {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectCreateProject(project));
  },

  getProjectTeamMembers: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetProjectMembers(projectId));
  },

  getProjectObjectives: async (projectId: string, xmatrixId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetGoalByProject(projectId, xmatrixId));
  },

  checkIfProjectCodeExist: async (projectCode: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectCheckDuplicateProject(projectCode));
  },

  updateProject: async (id: string, updateProjectRequest: UpdateProjectRequest) => {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectUpdateProject(id, updateProjectRequest));
  },

  // update project editor fields
  updateEditorField: async (payload: UpdateProjectFieldsRequestDto) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectUpdateProjectFields(payload));
  },

  getParentProjects,

  getDivisionProjects: async (xmatrixId: string) => {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetDivisionProjectCreationPicklist(xmatrixId));
  },

  updateTeamMembers: async (payload: UpdateTeamMembersRequest) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackApiPromise(api.legacyProjectUpdateTeamMembers(payload));
  },

  // get project deliverables picklist
  getProjectPicklist: async () => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetProjectDeliverablePicklist());
  },

  getKpiProjectPicklist: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetKPIProjectPicklist(projectId));
  },

  deleteProject: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectDeleteProject(projectId));
  },

  getChildProjects: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetProjectChilds(projectId));
  },

  getProjectCostUserAccess: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetUserProjectCostAccess(projectId));
  },

  getProjectLink: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetProjectRepository(projectId));
  },

  updateProjectLink: async (payload: InsertProjectRepositoryRequestDto) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectInsertProjectRepository(payload));
  },

  swapProjectTeamMembers: async (payload: SwapTeamMembersActivitiesRequest) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectSwapTeamMembersActivities(payload));
  },

  isOperational(type?: ProjectType | number) {
    return type === 1 || type === 'operational';
  },

  removeKpiFromProject: async (projectId: string, kpiId: string) => {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectDetachKpi(projectId, kpiId));
  },
};

export default ProgettiService;
