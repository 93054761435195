import { Table, Tag, message } from 'antd';
import { ColumnType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetGoalCategoryResponseDto } from 'src/connectors/backend';
import CategoryService from '../../services/pages/categoryService';
import CategoryIcon from '../shared/CategoryIcon';
import TableLayout from '../shared/tableLayout';
import { notifyMessages } from '../shared/utils/notifyMessages';
import './listCategoryPage.scss';

const ListCategoryPage: React.FC = () => {
  const { t } = useTranslation();
  const [loadingData, setLoadingData] = useState(false);
  const [categoryListData, setCategoryListData] = useState<GetGoalCategoryResponseDto[] | null>(null);

  useEffect(() => {
    retrieveCategoryList();
  }, []);

  // const onNew = () => {
  //   navigate('/categorie/new');
  // };

  const retrieveCategoryList = async () => {
    setLoadingData(true);
    try {
      const response = await CategoryService.getCategoryList();

      if (!response.data.success || !response.data.responseObject?.value) {
        return;
      }

      setCategoryListData(response.data.responseObject.value || []);
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
    } finally {
      setLoadingData(false);
    }
  };

  const columns: ColumnType<GetGoalCategoryResponseDto>[] = [
    {
      title: t('categoriePage.icona'),
      dataIndex: ['iconSource'],
      width: '100px',
      render: (_: any, record) => (
        <Tag className="tw-aspect-square tw-h-10 tw-flex tw-items-center tw-justify-center">{record.iconSource ? <CategoryIcon iconName={record.iconSource.trim()} /> : null}</Tag>
      ),
    },
    {
      title: t('general.nome'),
      dataIndex: ['name'],
      ellipsis: {
        showTitle: true,
      },
      sorter: (a, b) => {
        return a.name!.localeCompare(b.name!);
      },
    },
  ];

  return (
    <TableLayout title={t('categoriePage.categorie')}>
      <TableLayout.Content>
        <Table
          size="small"
          sticky
          columns={columns}
          dataSource={categoryListData || []}
          rowKey={(obj) => obj.goalCategoryID!}
          loading={loadingData}
        />
      </TableLayout.Content>
    </TableLayout>
  );
};

export default ListCategoryPage;
