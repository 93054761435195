import { DeleteOutlined, EditFilled, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Popconfirm, Space, Table, TableColumnType, Tooltip, message } from 'antd';
import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TreeXmatrixFilter from 'src/components/shared/components/treeXmatrixFilter/treeXmatrixFilter';
import { CommitteeDto, CommitteeType, GetXMatrixPicklistResponseDto, ProjectDto, ProjectParentship, ProjectType, UserDto } from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import MatrixService from 'src/services/matrix/matrixService';
import DashboardService from 'src/services/pages/dashboardService';
import { addQueryParam, getQueryParam, hasQueryParam } from 'src/utils/url-utils';
import { ArrowRightIcon } from '../../../assets/icons';
import CommitieService from '../../../services/pages/commitieServices';
import TableLayout from '../../shared/tableLayout';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import AddProjectToCommitteeModal from 'src/components/commitie/comitieDashboard/addProjectToCommitteeModal';

export interface CommitteeProjectsProps {
  onlyView?: boolean;
  committee: CommitteeDto;
  fetchCommittee?: () => Promise<void>;
  showXmatrixFilter?: boolean;
}

const CommitteeProjects: FC<CommitteeProjectsProps> = ({
  committee,
  onlyView = false,
  fetchCommittee = () => Promise.resolve(),
  showXmatrixFilter = true,
}) => {
  const activeXmatrixInfo = useAppSelector((state) => state.activeXMatrix.activeXMatrix);

  const history = useHistory();
  const { t } = useTranslation();

  const [relatedProjects, setRelatedProjects] = useState<ProjectDto[]>([]);
  const [showAddProjectToCommitteeModal, setShowAddProjectToCommitteeModal] = useState(false);
  const [selectedXmatrixId, setSelectedXmatrixId] = useState<string>();
  const [xmatrixPicklist, setXmatrixPicklist] = useState<GetXMatrixPicklistResponseDto[]>([]);
  const [projectsPicklist, setProjectsPicklist] = useState<ProjectDto[]>([]);
  const [loadingRelatedProjects, setLoadingRelatedProjects] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingXmatrixPicklist, setLoadingXmatrixPicklist] = useState(false);

  const searchInput = useRef(null);

  useEffect(() => {
    fetchXmatrixPicklist();
  }, []);

  useEffect(() => {
    if (selectedXmatrixId) {
      void fetchCommitteeRelatedProjects();
    void fetchProjectsPicklist();
    }
  }, [selectedXmatrixId]);

  useEffect(() => {
    if (activeXmatrixInfo) {
      if (
        hasQueryParam('xMatrix') &&
        getQueryParam('xMatrix') !== undefined &&
        getQueryParam('xMatrix') !== 'undefined'
      ) {
        setSelectedXmatrixId(getQueryParam('xMatrix'));
      } else {
        setSelectedXmatrixId(activeXmatrixInfo?.xMatrixID ?? '');
      }
    }
  }, [activeXmatrixInfo]);

  const fetchXmatrixPicklist = async () => {
    setLoadingXmatrixPicklist(true);

    const response = await MatrixService.getXmatrixPicklist();
    const resp = response.data;

    if (resp.success) {
      const respData = resp.responseObject?.value;
      setXmatrixPicklist(respData ?? []);
    }

    setLoadingXmatrixPicklist(false);
  };

  const fetchCommitteeRelatedProjects = async () => {
    setLoadingRelatedProjects(true);

    try {
      const { data } = await DashboardService.getDashboardProjects({
        projectParentship: ProjectParentship.All,
        selectNotRelatedProjects: CommitieService.isOperational(committee.type),
        type: CommitieService.isOperational(committee.type) ? CommitteeType.Operational : CommitteeType.NonOperational,
        commiteeIds: [committee.id],
        xMatrixIds: CommitieService.isOperational(committee.type) || selectedXmatrixId === undefined ? undefined : [selectedXmatrixId]
      });

      setRelatedProjects(data);
      setLoadingRelatedProjects(false);
    } catch (error) {
      setLoadingRelatedProjects(false);
    }
  };

  const fetchProjectsPicklist = async () => {
    try {
      const { data: projects } = await DashboardService.getDashboardProjects({
        projectParentship: ProjectParentship.All,
        selectNotRelatedProjects: true,
        type: committee.type,
        xMatrixIds: CommitieService.isOperational(committee.type) || selectedXmatrixId === undefined ? undefined : [selectedXmatrixId],
      });

      const projectsWithoutCommittee = projects.filter(
        (project) => !project.committee
      );

      setProjectsPicklist(projectsWithoutCommittee);
    } catch { }
  };

  const addProjectToCommittee = async (projectId: string) => {
    setLoadingSave(true);

    try {
      const { data } = await CommitieService.addRemoveProjectToCommitie({
        projectID: projectId,
        committeeID: committee.id,
      });

      if (data.success) {
        message.success(notifyMessages.addSuccess);
        setShowAddProjectToCommitteeModal(false);
        void fetchCommitteeRelatedProjects();
        void fetchCommittee();
      } else {
        throw new Error(notifyMessages.addFailed);
      }
    } catch {
      message.error(notifyMessages.addFailed);
    } finally {
      setLoadingSave(false);
    }
  };

  const removeProjectFromCommittee = async (projectId: string) => {
    setLoadingSave(true);

    try {
      const { data } = await CommitieService.addRemoveProjectToCommitie({
        projectID: projectId,
        committeeID: undefined,
      });

      if (data.success) {
        message.success(notifyMessages.updateSuccess);
        setShowAddProjectToCommitteeModal(false);
        void fetchCommitteeRelatedProjects();
        void fetchCommittee();
      } else {
        throw new Error(notifyMessages.updateFailed);
      }
    } catch {
      // TODO: fix this weird behavior when backend will be updated
      void fetchCommitteeRelatedProjects();
      void fetchCommittee();
      message.success(notifyMessages.updateSuccess);
    } finally {
      setLoadingSave(false);
    }
  };

  const getColumnSearchProps: (dataIndex: keyof ProjectDto) => Partial<TableColumnType<ProjectDto>> = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        className='tw-p-2'
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={t('general.search')}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          className='tw-block tw-mb-2' />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            className='tw-w-24'>
            {t('general.search')}
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) {
                clearFilters();
              }

              confirm({ closeDropdown: true });
            }}
            size="small"
            style={{
              width: 90,
            }}>
            {t('Common.reset')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const fieldValue = record[dataIndex];

      if (typeof fieldValue === 'string') {
        return fieldValue.toLowerCase().includes((value as string).toLowerCase());
      }

      if (dataIndex === 'teamLeader') {
        return (fieldValue as UserDto).fullName?.toLowerCase().includes((value as string).toLowerCase()) ?? true;
      }

      if (dataIndex === 'sponsor') {
        return (fieldValue as UserDto).fullName?.toLowerCase().includes((value as string).toLowerCase()) ?? true;
      }

      return true;
    },
  });

  const relatedProjectsColumns: TableColumnType<ProjectDto>[] = [
    {
      title: `${t('proggetiPage.codice')}`,
      dataIndex: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code),
      width: '110px',
      fixed: 'left',
      showSorterTooltip: true,
      ...getColumnSearchProps('code'),
      defaultSortOrder: 'ascend',
    },
    {
      title: `${t('general.nome')}`,
      dataIndex: 'name',
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
      showSorterTooltip: false,
      ...getColumnSearchProps('name'),
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      title: `${t('general.teamLeader')}`,
      dataIndex: 'teamLeader.FullName',
      sorter: (a, b) => {
        return (a.teamLeader?.fullName ?? '').localeCompare(b.teamLeader?.fullName ?? '');
      },
      showSorterTooltip: true,
      width: '200px',
      ...getColumnSearchProps('teamLeader'),
      render: (title, p) => <Tooltip title={title}>{p.teamLeader?.fullName}</Tooltip>,
    },
    {
      title: `${t('general.sponsor')}`,
      dataIndex: 'sponsor.FullName',
      sorter: (a, b) => {
        return (a.teamLeader?.fullName ?? '').localeCompare((b.teamLeader?.fullName ?? ''));
      },
      width: '200px',
      showSorterTooltip: true,
      ...getColumnSearchProps('sponsor'),
      render: (title, p) => <Tooltip title={title}>{p.sponsor?.fullName}</Tooltip>,
    },
    {
      title: `${t('general.stato')}`,
      dataIndex: 'statusDescription',
      sorter: (a, b) => {
        return (a.statusDescription ?? '').localeCompare((b.statusDescription ?? ''));
      },
      width: '150px',
      showSorterTooltip: true,
      ...getColumnSearchProps('statusDescription'),
    },
    {
      key: 'action',
      width: '100px',
      render: (text, project) => {
        return (
          <div className="tw-flex tw-items-center tw-gap-2">
            {!onlyView && (
              <Popconfirm
                title={t('comitatiPage.removeProjectFromComitie')}
                onConfirm={() => removeProjectFromCommittee(project.id)}
                okText={t('general.si')}
                cancelText={t('general.no')}
                className="long-term-button remove">
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                ></Button>
              </Popconfirm>
            )}
            <Button
              icon={<EditFilled />}
              href={`/progetti/id/${project.id}`}
            ></Button>
          </div>
        );
      },
    },
  ];

  const onAddProjectToCommitteeModalClose = () => {
    setShowAddProjectToCommitteeModal(false);
  };

  const onTreeXmatrixChange = (value: string[]) => {
    if (value.length > 0) {
      const lastValue = value.at(-1);
      
      setSelectedXmatrixId(lastValue);
      addQueryParam('xMatrix', lastValue);
    }
  };
  
  return (
    <TableLayout
      title={
        <div
          onClick={() => onlyView && history.push(`?tab=2`)}
          className={classNames('tw-text-lg tw-font-semibold tw-flex tw-gap-1 tw-items-center', {
            'tw-text-primary tw-cursor-pointer': onlyView,
          })}>
          {t('general.progetti')}
          <ArrowRightIcon className={onlyView ? 'tw-block' : 'tw-hidden'} />
        </div>
      }>
      {activeXmatrixInfo && (
        <TableLayout.Actions>
          {showAddProjectToCommitteeModal && projectsPicklist && (
            <AddProjectToCommitteeModal
              showModal={showAddProjectToCommitteeModal}
              handleModalClose={onAddProjectToCommitteeModalClose}
              addProjectToCommittee={addProjectToCommittee}
              loadingSave={loadingSave}
              committee={committee}
              selectedXmatrixName={xmatrixPicklist.find(x => x.xMatrixID === selectedXmatrixId)?.hkCode ?? ''}
              projectsPicklist={projectsPicklist}/>
          )}

          {showXmatrixFilter && !CommitieService.isOperational(committee.type) && xmatrixPicklist && (
            <TreeXmatrixFilter
              showSearch={false}
              allowClear={false}
              includeChildren={false}
              flat={true}
              disabled={loadingXmatrixPicklist}
              selectedXmatrixIds={selectedXmatrixId ? [selectedXmatrixId] : []}
              xmatrixPicklist={xmatrixPicklist}
              onTreeXmatrixChange={onTreeXmatrixChange}
            />
          )}

          {!onlyView && (
            <Button
              type="primary"
              onClick={() => setShowAddProjectToCommitteeModal(true)}
              icon={<PlusOutlined />}>
              {t('buttons.aggiungi')}
            </Button>
          )}
        </TableLayout.Actions>
      )}

      <TableLayout.Content>
        <Table
          size="small"
          columns={relatedProjectsColumns}
          dataSource={relatedProjects}
          rowKey={(project) => project.id}
          loading={loadingRelatedProjects}
          pagination={false}
        />
      </TableLayout.Content>
    </TableLayout>
  );
};

export default CommitteeProjects;
