import type React from 'react';

import {
  CheckSquareOutlined,
  EditOutlined,
  ExportOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, type MenuProps, message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiMore2Line } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { exportSecondLevelXmatrix, exportXmatrix } from 'src/components/xMatrix/utils/export-xmatrix';
import { LS_XMATRIX_FILTERS_KEY } from 'src/components/xMatrix/xMatrix';
import type {
  CreateXMatrixRequest,
  GetXMatrixInfoResponseDto,
  UpdateXMatrixByIDRequestDto,
} from 'src/connectors/backend';
import CreateNextYearXmatrixModal from 'src/dialogs/CreateNextYearXmatrixModal';
import { useAppSelector } from 'src/redux/store';
import EditXmatrixModal from '../../dialogs/EditXmatrixModal';
import MatrixService, { type XMatrixDtoWithOrder } from '../../services/matrix/matrixService';
import { checkUserIsLenovysAdmin, userIsAdminOrSteering } from '../shared/utils/authRolesProvilege/authRolesPrivilege';
import { notifyMessages } from '../shared/utils/notifyMessages';
import AddNewXmatrix from './insertXmatrix';

interface XmatrixOptionsProps {
  xmatrixData?: XMatrixDtoWithOrder;
  selectedXmatrixInfo: GetXMatrixInfoResponseDto;
  isSecondLevelXmatrix?: boolean;
  reloadMatrix: () => void;
  reloadMatrixOnEdit?: () => void;
  reloadXmInfo?: () => void;
}

const XmatrixOptions: React.FC<XmatrixOptionsProps> = ({
  xmatrixData,
  selectedXmatrixInfo,
  isSecondLevelXmatrix,
  reloadMatrixOnEdit = () => {},
  reloadXmInfo = () => {},
}) => {
  const { t } = useTranslation();
  const userData = useAppSelector((state) => state.userData.userData);
  const history = useHistory();

  // Modal states
  const [showModal, setShowModal] = useState(false);
  const [showModalXmatrix, setShowModalXmatrix] = useState(false);
  const [showEditModalXmatrix, setShowEditModalXmatrix] = useState(false);
  const [optionPanelVisibility, setOptionPanelVisibility] = useState(false);

  // Data states
  const [lastXmatrixYear, setLastXmatrixYear] = useState<string | null>(null);
  const [xMatrixYearsList, setXMatrixYearsList] = useState<string[]>([]);

  useEffect(() => {
    void getXmatrixPicklist();
  }, []);

  const toggleModalXmatrix = () => {
    setShowModalXmatrix(!showModalXmatrix);
  };

  const toggleEditModalXmatrix = () => {
    setShowEditModalXmatrix(!showEditModalXmatrix);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalXmatrixClose = () => {
    setShowModalXmatrix(false);
  };

  const handleEditModalXmatrixClose = () => {
    setShowEditModalXmatrix(false);
  };

  const createXMatrix = async (values: CreateXMatrixRequest) => {
    try {
      const { data: xMatrix } = await MatrixService.createXmatrix({
        ...values,
        cycleId: values.cycleId || null,
      });

      void message.success(notifyMessages.addSuccess);
      handleModalClose();
      hidePanel();
      localStorage.setItem(LS_XMATRIX_FILTERS_KEY, JSON.stringify({}));
      history.push(`/xmatrix/${xMatrix.id}`);
    } catch {
      void message.error(notifyMessages.addFailed);
    }
  };

  const getXmatrixPicklist = async () => {
    try {
      const response = await MatrixService.getXmatrixPicklist();
      const resp = response.data;

      if (!resp.responseObject || !resp.responseObject.value) {
        return;
      }

      setLastXmatrixYear(resp.responseObject.value[0].lastInsertedYear);

      if (resp.responseObject.value.length > 0) {
        const xmatrixYearArray: string[] = [];
        const parentXmatrixList = resp.responseObject.value.filter((item) => item.parentXMatrixID == null);
        parentXmatrixList.map((item) => xmatrixYearArray.push(item.referencePeriod as unknown as string));
        setXMatrixYearsList(xmatrixYearArray);
      }
    } catch (error) {}
  };

  const saveEditXmatrixData = (values: UpdateXMatrixByIDRequestDto) => {
    MatrixService.updateXmatrixData(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          handleEditModalXmatrixClose();
          hidePanel();
          reloadMatrixOnEdit();
          reloadXmInfo();
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
      });
  };

  const hidePanel = () => {
    setOptionPanelVisibility(false);
  };

  const onPrint = () => {
    setOptionPanelVisibility(false);
    setTimeout(() => {
      window.print();
    }, 700);
  };

  const onDataExport = (secondLevelXmatrix?: boolean) => {
    if (!xmatrixData) return;

    if (!secondLevelXmatrix) {
      exportXmatrix(xmatrixData, selectedXmatrixInfo, t);
    } else {
      exportSecondLevelXmatrix(xmatrixData, selectedXmatrixInfo, t);
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const items: MenuProps['items'] = [
    {
      key: '0',
      label: t('xMatrixPage.nuovaAnnoSuccesivoXm'),
      icon: <PlusCircleOutlined />,
      onClick: () => toggleModal(),
    },
    {
      key: '1',
      label: t('xMatrixPage.nuovoCicloXm'),
      icon: <CheckSquareOutlined />,
      onClick: toggleModalXmatrix,
    },
    {
      key: '2',
      label: t('xMatrixPage.modificaXmatrix'),
      icon: <EditOutlined />,
      onClick: toggleEditModalXmatrix,
    },
    {
      key: '3',
      label: t('xMatrixPage.stampaXmatrix'),
      icon: <PrinterOutlined />,
      onClick: () => onPrint(),
    },
    {
      key: '4',
      label: t('xMatrixPage.exportData'),
      icon: <ExportOutlined />,
      disabled: !checkUserIsLenovysAdmin(userData),
      onClick: () => onDataExport(isSecondLevelXmatrix),
    },
  ];

  return (
    <>
      {showModal && (
        <CreateNextYearXmatrixModal
          loadingButton={false}
          isOpen={showModal}
          onClose={handleModalClose}
          handleModalSave={createXMatrix}
          selectedXmatrixValue={selectedXmatrixInfo.xMatrixID as string}
          xMatrixYearsList={xMatrixYearsList}
        />
      )}

      {showModalXmatrix && lastXmatrixYear && (
        <AddNewXmatrix
          loadingButton={false}
          showModalXmatrix={showModalXmatrix}
          handleModalXmatrixClose={handleModalXmatrixClose}
          handleModalXmatrixSave={createXMatrix}
          lastXmatrixYear={lastXmatrixYear}
          xMatrixYearsList={xMatrixYearsList}
        />
      )}

      {showEditModalXmatrix && (
        <EditXmatrixModal
          loadingButton={false}
          showEditModalXmatrix={showEditModalXmatrix}
          handleEditModalXmatrixClose={handleEditModalXmatrixClose}
          handleEditModalXmatrixSave={saveEditXmatrixData}
          selectedXmatrixValue={selectedXmatrixInfo.xMatrixID as string}
        />
      )}

      {userIsAdminOrSteering(userData) && (
        <Dropdown
          arrow
          menu={{ items }}
          trigger={['click']}
        >
          <Button
            data-testid="openOptionsBtn"
            icon={<RiMore2Line />}
            onClick={(e) => e.preventDefault()}
          />
        </Dropdown>
      )}
    </>
  );
};

export default XmatrixOptions;
