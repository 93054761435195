import { DeleteOutlined, SolutionOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Button, Form, Popover, Radio, message } from 'antd';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { UserProjectRelationship } from 'src/connectors/backend';
import RelationsService from '../../../services/matrix/relationsService';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import '../matrix.scss';

interface Cell {
  code: number;
  colId: string;
  rowId: string;
  selected?: boolean;
  passingThrough?: boolean;
  leftLine?: boolean;
  rightLine?: boolean;
  topLine?: boolean;
  bottomLine?: boolean;
}

interface ProjectUserRelationProps {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  rowList: any[];
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  colList: any[];
  relationList: UserProjectRelationship[];
  selectedXmatrix: string;
  reloadMatrix: () => void;
  onUpdateRelation: (value: any, rowId: string, colId: string) => void;
  rowItemId: string;
  colItemId: string;
  rowSelectionIds?: string[];
  colSelectionIds?: string[];
  direction?: string;
  origin?: string;
}

const ProjectUserRelation: FC<ProjectUserRelationProps> = ({
  selectedXmatrix,
  colItemId,
  rowItemId,
  rowSelectionIds,
  colSelectionIds,
  rowList,
  colList,
  relationList,
  onUpdateRelation,
  reloadMatrix,
  direction,
}) => {
  const { t } = useTranslation();

  const onRemoveRelation = (rowId: string, colId: string, text: string) => {
    // setLoadingIcon(true);
    RelationsService.deleteProjectUserRelation(rowId, colId, text, selectedXmatrix)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          // setLoadingIcon(false);
          reloadMatrix();
        } else {
          message.error(notifyMessages.updateFailed);
          // setLoadingIcon(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        // setLoadingIcon(false);
      });
  };

  const onRelationCellForm = (text: number, rowId: string, colId: string) => {
    return (
      <Form
        className="tw-flex tw-flex-col"
        initialValues={{ correlationCoefficient: text }}
        onValuesChange={(value) => onUpdateRelation(value, rowId, colId)}
      >
        <Form.Item
          name="correlationCoefficient"
          noStyle
        >
          <Radio.Group>
            <Radio
              className="tw-flex tw-gap-2 tw-items-center tw-py-1"
              key="1"
              value={1}
            >
              <SolutionOutlined className="tw-text-yellow-600" /> {t('general.sponsor')}
            </Radio>
            <Radio
              className="tw-flex tw-gap-2 tw-items-center tw-py-1"
              key="2"
              value={2}
            >
              <UserSwitchOutlined className="tw-text-green-600" /> {t('general.teamLeader')}
            </Radio>
            <Radio
              className="tw-flex tw-gap-2 tw-items-center tw-py-1"
              key="3"
              value={3}
            >
              <UserOutlined className="tw-text-blue-600" /> {t('general.teamMember')}
            </Radio>
          </Radio.Group>
        </Form.Item>

        {text !== 4 && (
          <Button
            danger
            className="!tw-w-[50px] tw-ml-4 tw-mt-2"
            type="text"
            onClick={() => onRemoveRelation(rowId, colId, text.toString())}
            icon={<DeleteOutlined />}
          >
            {t('buttons.rimuovi')}
          </Button>
        )}
      </Form>
    );
  };

  const renderCell = (cell: Cell) => {
    return (
      <Popover
        key={`${cell.colId}-${cell.rowId}`}
        content={onRelationCellForm(cell.code, cell.rowId, cell.colId)}
        trigger="click"
        placement="bottomLeft"
      >
        <div
          className={`selection-container ${cell.selected ? 'is-selected' : ''} ${cell.passingThrough ? 'is-passing-through' : ''}`}
        >
          {cell.code === 1 && (
            <span style={{ cursor: 'pointer' }}>
              <SolutionOutlined className="tw-text-yellow-600" />
            </span>
          )}
          {cell.code === 2 && (
            <span style={{ cursor: 'pointer' }}>
              <UserSwitchOutlined className="tw-text-green-600" />
            </span>
          )}
          {cell.code === 3 && (
            <span style={{ cursor: 'pointer' }}>
              <UserOutlined className="tw-text-blue-600" />
            </span>
          )}
          {cell.code === 4 && (
            <span
              className="noRelation"
              style={{ cursor: 'pointer' }}
            />
          )}
          {(cell.leftLine || cell.rightLine) && (
            <i
              className={`horizontal-lines ${cell.leftLine ? 'has-left-line' : ''} ${cell.rightLine ? 'has-right-line' : ''}`}
            />
          )}
          {(cell.topLine || cell.bottomLine) && (
            <i
              className={`vertical-lines ${cell.topLine ? 'has-top-line' : ''} ${cell.bottomLine ? 'has-bottom-line' : ''}`}
            />
          )}
        </div>
      </Popover>
    );
  };

  const findRelation = (
    relationList: UserProjectRelationship[],
    colId: string,
    rowId: string,
    rowSelectionIds?: string[],
    colSelectionIds?: string[],
  ) => {
    return relationList.find(
      (item) =>
        (!colSelectionIds || colSelectionIds.includes(colId)) &&
        (!rowSelectionIds || rowSelectionIds.includes(rowId)) &&
        ((item.highestIDValue === colId && item.lowestIDValue === rowId) ||
          (item.highestIDValue === rowId && item.lowestIDValue === colId)) &&
        item.correlationCoefficient !== 4,
    );
  };

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const generateCells = (rowList: any[], colList: any[], relationList: UserProjectRelationship[]) => {
    if (!rowList || rowList.length === 0 || !colList || colList.length === 0) {
      return [];
    }

    const cells: Cell[][] = [];

    // biome-ignore lint/complexity/noForEach: <explanation>
    rowList.forEach((rowItem) => {
      const innerCells: Cell[] = [];
      // biome-ignore lint/complexity/noForEach: <explanation>
      colList.forEach((colItem) => {
        const colId = colItem[colItemId];
        const rowId = rowItem[rowItemId];
        const relation = findRelation(relationList, colId, rowId);
        innerCells.push({
          code: relation?.correlationCoefficient ?? 4,
          colId: colItem[colItemId],
          rowId: rowItem[rowItemId],
        });
      });

      cells.push(innerCells);
    });

    const verticalWalkOnCell = (cell: Cell, topLine: boolean, bottomLine: boolean) => {
      if (colSelectionIds?.includes(cell.colId) && !cell.selected) {
        if (rowSelectionIds?.includes(cell.rowId) && colSelectionIds?.includes(cell.colId)) {
          cell.selected = true;
        } else {
          cell.passingThrough = true;
        }

        cell.bottomLine = bottomLine;
        cell.topLine = topLine;
      }
    };

    const horizontalWalkOnCell = (cell: Cell, leftLine: boolean, rightLine: boolean) => {
      if (rowSelectionIds?.includes(cell.rowId) && colSelectionIds?.includes(cell.colId)) {
        cell.selected = true;
      } else {
        cell.passingThrough = true;
      }

      cell.rightLine = rightLine;
      cell.leftLine = leftLine;
    };

    if (rowSelectionIds && colSelectionIds) {
      // Vertical lines
      colList.forEach((colItem, colIndex) => {
        if (direction?.startsWith('top')) {
          const lastSelectedRowIndex = rowList.findLastIndex((rowItem) =>
            findRelation(relationList, colItem[colItemId], rowItem[rowItemId], rowSelectionIds, colSelectionIds),
          );

          if (lastSelectedRowIndex >= 0) {
            for (let rowIndex = 0; rowIndex <= lastSelectedRowIndex; rowIndex++) {
              const cell = cells[rowIndex][colIndex];
              verticalWalkOnCell(cell, true, rowIndex !== lastSelectedRowIndex);
            }
          }
        } else {
          const firstSelectedRowIndex = rowList.findIndex((rowItem) =>
            findRelation(relationList, colItem[colItemId], rowItem[rowItemId], rowSelectionIds, colSelectionIds),
          );

          if (firstSelectedRowIndex >= 0) {
            for (let rowIndex = firstSelectedRowIndex; rowIndex < rowList.length; rowIndex++) {
              const cell = cells[rowIndex][colIndex];
              verticalWalkOnCell(cell, rowIndex !== firstSelectedRowIndex, true);
            }
          }
        }
      });

      // Horizontal lines
      rowList.forEach((rowItem, rowIndex) => {
        if (rowSelectionIds?.includes(rowItem[rowItemId])) {
          if (direction?.endsWith('Left')) {
            const lastSelectedColIndex = colList.findLastIndex((colItem) =>
              findRelation(relationList, colItem[colItemId], rowItem[rowItemId], rowSelectionIds, colSelectionIds),
            );

            // no matches
            if (lastSelectedColIndex >= 0) {
              for (let colIndex = 0; colIndex <= lastSelectedColIndex; colIndex++) {
                const cell = cells[rowIndex][colIndex];
                horizontalWalkOnCell(cell, true, colIndex !== lastSelectedColIndex);
              }
            }
          } else {
            const firstSelectedColIndex = colList.findIndex((colItem) =>
              findRelation(relationList, colItem[colItemId], rowItem[rowItemId], rowSelectionIds, colSelectionIds),
            );

            // no matches
            if (firstSelectedColIndex >= 0) {
              for (let colIndex = firstSelectedColIndex; colIndex < colList.length; colIndex++) {
                const cell = cells[rowIndex][colIndex];
                horizontalWalkOnCell(cell, colIndex !== firstSelectedColIndex, true);
              }
            }
          }
        }
      });
    }

    return cells;
  };

  const unmutableArrayReverse = <T,>(array: T[]): T[] => {
    return [...array].reverse();
  };

  let cells = generateCells(rowList, colList, relationList);

  if (origin?.startsWith('bottom')) {
    // Revert the matrix columns
    cells = cells.map((columns) => unmutableArrayReverse(columns));
  }

  if (origin?.endsWith('Right')) {
    // Revert the matrix rows
    cells = unmutableArrayReverse(cells);
  }

  return (
    <table className="relation-table tw-border-collapse tw-size-full">
      <tbody>
        {cells.map((columns, i) => (
          <tr key={i}>
            {columns.map((cell, j) => (
              <td
                className="tw-size-8 tw-p-0"
                key={j}
              >
                {renderCell(cell)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ProjectUserRelation;
