import { Card, Tabs } from 'antd';
import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { addQueryParam, getQueryParam, hasQueryParam, removeQueryParam } from '../../utils/url-utils';
import ProjectSectionTitle from '../shared/components/ProjectSectionTitle/ProjectSectionTitle';
import TableLayout from '../shared/tableLayout';
import './kpiDashboars.scss';
import classNames from 'classnames';
import KpiSummary, { KpiSummaryType } from './kpiSummary';

const KpiDashboard: FC<{
  isPresentationMode: boolean;
  presentationProjectId?: string;
  presentationAnnualId?: string;
}> = ({ isPresentationMode, presentationProjectId, presentationAnnualId }) => {
  const urlParams = useParams();
  const { t } = useTranslation();

  const { projectId, annualId } = urlParams;

  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    if (hasQueryParam('kpiTab')) {
      setActiveTab(getQueryParam('kpiTab') ?? '1');
    } else {
      setActiveTab('1');
    }
  }, []);

  const onTabChange = (activeKey: string) => {
    setActiveTab(activeKey);
    addQueryParam('kpiTab', activeKey);

    removeQueryParam('selectedAnnualGoal');
    removeQueryParam('selectedProject');
    removeQueryParam('selectedCommittee');
  };

  const tabItems = [
    {
      label: <>{t('general.progetti')}</>,
      key: '1',
      children: (
        <KpiSummary
          onlyGlobals={!projectId && !presentationProjectId}
          summaryType={KpiSummaryType.Projects}
          relationId={projectId ?? presentationProjectId}
        />
      ),
    },
    {
      label: <>{t('general.obiettiviAnno')}</>,
      key: '2',
      children: (
        <KpiSummary
          summaryType={KpiSummaryType.AnnualGoals}
          onlyGlobals={!annualId && !presentationAnnualId}
          relationId={annualId ?? presentationAnnualId}
        />
      ),
    },
    {
      label: <>{t('general.comitato')}</>,
      key: '3',
      children: (
        <KpiSummary
          // todo showglobals
          summaryType={KpiSummaryType.Committees}
          relationId={''} // annualId ?? presentationAnnualId} // TODO
        />
      ),
    },
  ];

  return (
    <Card
      className={classNames({
        '!tw-shadow-none[&_.ant-card-body]:!tw-p-0': isPresentationMode,
        '!tw-shadow-none': !isPresentationMode,
      })}
      bordered={false}>
      <TableLayout
        title={
          <ProjectSectionTitle
            title={isPresentationMode ? t('proggetiPage.kpiStatus') : t('kpiDashboard.kpiDashboard')}
            isPresentationMode={isPresentationMode}
          />
        }>
        <TableLayout.Content>
          <Tabs
            items={tabItems}
            defaultActiveKey="1"
            activeKey={activeTab}
            onChange={onTabChange}
          />
        </TableLayout.Content>
      </TableLayout>
    </Card>
  );
};

export default KpiDashboard;
