import { Form } from 'antd';
import { type FC, useEffect, useState } from 'react';

import { Divider, Modal, Transfer } from 'antd';
import MatrixService from '../services/matrix/matrixService';
import Loader2 from '../components/shared/components/loader2/loader2';
import ModalFooterButtons from '../components/shared/components/modal/modalFooterButtons';
import { requiredFields } from '../components/shared/utils/notifyMessages';
import type {
  CreateXMatrixRequest,
  GetNewXmatrixAnnualGoalPicklistResponseDto,
  GetNewXmatrixProjectPicklistResponseDto,
} from 'src/connectors/backend';
import { useTranslation } from 'react-i18next';
import { DatePickerWithDateFns } from '../components/shared/utils/constants';

interface CreateNextYearXmatrixModalProps {
  selectedXmatrixValue: string;
  isOpen: boolean;
  onClose: () => void;
  handleModalSave: (values: CreateXMatrixRequest) => void;
  loadingButton: boolean;
  xMatrixYearsList: string[];
}

const CreateNextYearXmatrixModal: FC<CreateNextYearXmatrixModalProps> = ({
  selectedXmatrixValue,
  isOpen,
  onClose,
  handleModalSave,
  loadingButton,
  xMatrixYearsList,
}) => {
  const { t } = useTranslation();

  const [fieldsChanged, setFieldsChanged] = useState(false);

  const [loadingAnnualGoalPicklist, setLoadingAnnualGoalPicklist] = useState(true);
  const [annualGoalPicklist, setAnnualGoalPicklist] = useState<GetNewXmatrixAnnualGoalPicklistResponseDto[]>([]);
  const [selectedAnnualGoalsIds, setSelectedAnnualGoalsIds] = useState<string[]>([]);

  const [loadingProjectPicklist, setLoadingProjectPicklist] = useState(true);
  const [projectPicklist, setProjectPicklist] = useState<GetNewXmatrixProjectPicklistResponseDto[]>([]);
  const [selectedProjectsIds, setSelectedProjectsIds] = useState<string[]>([]);

  useEffect(() => {
    if (selectedXmatrixValue) {
      void fetchAnnualGoalPicklist(selectedXmatrixValue);
      void fetchProjectPicklist(selectedXmatrixValue);
    }
  }, [selectedXmatrixValue]);

  const fetchAnnualGoalPicklist = async (selectedXmatrixId: string) => {
    setLoadingAnnualGoalPicklist(true);

    try {
      const response = await MatrixService.getNewXmatrixAnnualGoals(selectedXmatrixId);

      if (response.data.success) {
        const respData = response.data.responseObject?.value ?? [];
        setAnnualGoalPicklist(respData);
      }
    } finally {
      setLoadingAnnualGoalPicklist(false);
    }
  };

  const fetchProjectPicklist = async (selectedXmatrixId: string) => {
    setLoadingProjectPicklist(true);

    try {
      const response = await MatrixService.getNewXmatrixProjects(selectedXmatrixId);
      const resp = response.data;

      if (resp.success) {
        setProjectPicklist(resp.responseObject?.value ?? []);
      }
    } finally {
      setLoadingProjectPicklist(false);
    }
  };

  const disablePreviousYears = (current: Date) => {
    return xMatrixYearsList.find((year) => year === current.getFullYear().toString()) !== undefined;
  };

  const addData = (values: { year: Date }) => {
    // Needed cause of this: https://github.com/OpenAPITools/openapi-generator/issues/8258#issuecomment-755409548
    handleModalSave({
      year: values.year.getFullYear(),
      annualGoalIds: selectedAnnualGoalsIds as unknown as Set<string>,
      projectIds: selectedProjectsIds as unknown as Set<string>,
      connectLongTermGoals: true,
    });
  };

  return (
    <Modal
      width={1300}
      title={t('xMatrixPage.nuovaAnnoSuccesivoXm')}
      open={isOpen}
      destroyOnClose={true}
      onCancel={onClose}
      footer={
        <ModalFooterButtons
          disabled={!fieldsChanged}
          loadingButton={loadingButton}
          modalClose={onClose}
          formId={'newNextYearXmatrix'}
          key={'newNextYearXmatrix'}
        />
      }
    >
      <Form
        id="newNextYearXmatrix"
        name="add_xmatrix"
        layout="vertical"
        onFinish={addData}
        onValuesChange={() => {
          setFieldsChanged(true);
        }}
      >
        <Form.Item
          label={t('general.anno')}
          name="year"
          className="tw-w-full"
          rules={[{ required: true, message: requiredFields.required }]}
        >
          <DatePickerWithDateFns
            className="tw-w-full"
            placeholder={t('general.seleziona')}
            picker="year"
            format="YYYY"
            disabledDate={(current) => disablePreviousYears(current)}
          />
        </Form.Item>

        <div className="tw-w-full">
          <Divider plain>{t('general.obbietiviAnuali')}</Divider>
          {loadingAnnualGoalPicklist && <Loader2 />}

          {!loadingAnnualGoalPicklist && (
            <Transfer
              dataSource={annualGoalPicklist.map((item) => ({
                key: item.annualGoalID as string,
                description: item.description ?? '',
                orderingValue: item.orderingValue,
              }))}
              titles={['', `${t('xMatrixPage.nuovoXmatrix')}`]}
              showSearch
              listStyle={{
                width: '48.5%',
                height: 300,
              }}
              targetKeys={selectedAnnualGoalsIds}
              onChange={(values) => {
                setSelectedAnnualGoalsIds(values as string[]);
              }}
              render={(item) => `${item.description} (${item.orderingValue})`}
              oneWay={true}
              locale={{
                itemUnit: `${t('general.obbietiviAnuali')}`,
                itemsUnit: `${t('general.obbietiviAnuali')}`,
                notFoundContent: `${t('general.elencoVuoto')}`,
                searchPlaceholder: `${t('buttons.cerca')}`,
              }}
            />
          )}
        </div>

        <div className="tw-w-full">
          <Divider plain>{t('general.progetti')}</Divider>
          {loadingProjectPicklist && <Loader2 />}

          {!loadingProjectPicklist && (
            <Transfer
              dataSource={projectPicklist.map((item) => ({
                key: item.projectID as string,
                description: item.name ?? '',
                projectCode: item.projectCode as string,
              }))}
              titles={['', `${t('xMatrixPage.nuovoXmatrix')}`]}
              showSearch
              listStyle={{
                width: '48.5%',
                height: 300,
              }}
              targetKeys={selectedProjectsIds}
              onChange={(values) => {
                setSelectedProjectsIds(values as string[]);
              }}
              render={(item) => `${item.description} (${item.projectCode})`}
              oneWay={true}
              locale={{
                itemUnit: `${t('general.progetti')}`,
                itemsUnit: `${t('general.progetti')}`,
                notFoundContent: `${t('general.elencoVuoto')}`,
                searchPlaceholder: `${t('buttons.cerca')}`,
              }}
            />
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default CreateNextYearXmatrixModal;
