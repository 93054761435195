import { MinusSquareOutlined } from '@ant-design/icons';
import { Button, Divider, Popover, Select, Spin, Switch } from 'antd';
import type { BaseOptionType } from 'antd/es/select';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiFilterLine } from 'react-icons/ri';
import XmatrixHeader from 'src/components/xMatrix/XmatrixHead';
import XmatrixOptions from 'src/components/xMatrix/XmatrixOptionMenu';
import { LS_XMATRIX_FILTERS_KEY } from 'src/components/xMatrix/xMatrix';
import type { XMatrixFilters } from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import MatrixService, { type XMatrixDtoWithOrder } from '../../services/matrix/matrixService';
import DashboardService from '../../services/pages/dashboardService';
import './matrix.scss';
import XMatrixLeftPanel from './xMatrixLeftPanel/xMatrixLeftPanel';

interface FiltersMatrixLocalProps {
  manageFilterApplied: () => void;
  manageFilterReset: () => void;
  setFilters: (filters: any) => void;
  reloadMatrix: () => void;
  selectedXmatrixInfo: any;
  isSecondLevelXmatrix: boolean;
  reloadMatrixOnEdit: () => void;
  reloadXmInfo: () => void;
  filters: XMatrixFilters;
  xmatrixData: XMatrixDtoWithOrder;
}

const FiltersMatrixLocal: React.FC<FiltersMatrixLocalProps> = ({
  reloadMatrix,
  selectedXmatrixInfo,
  setFilters,
  isSecondLevelXmatrix,
  manageFilterReset,
  reloadMatrixOnEdit,
  reloadXmInfo,
  filters,
  xmatrixData,
}) => {
  const { t } = useTranslation();
  const activeXmatrixInfo = useAppSelector((state) => state.activeXMatrix.activeXMatrix);

  const [longTermGoalsPicklist, setLongTermGoalsPicklist] = useState<BaseOptionType[]>([]);
  const [annualGoalsPicklist, setAnnualGoalsPicklist] = useState<BaseOptionType[]>([]);
  const [teamLeaderPicklist, setTeamLeaderPicklist] = useState<BaseOptionType[]>([]);
  const [divisionPicklist, setDivisionPicklist] = useState<BaseOptionType[]>([]);
  const [committiePicklist, setCommittiePicklist] = useState<BaseOptionType[]>([]);
  const [projectPicklist, setProjectPicklist] = useState<BaseOptionType[]>([]);
  const [sponsorPicklist, setSponsorPicklist] = useState<BaseOptionType[]>([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [numberOfActiveFilters, setNumberOfActiveFilters] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const xMatrixID = activeXmatrixInfo?.xMatrixID;

    if (!xMatrixID) return;

    if (isFilterOpen) {
      const getData = async () => {
        setIsLoading(true);

        Promise.all([
          retrieveLongTermGoalsPicklist(xMatrixID),
          retrieveAnnualGoalsPicklist(xMatrixID),
          retrieveDivisions(xMatrixID),
          retrieveCommittees(xMatrixID),
          retrieveProjects(xMatrixID),
          retrieveSponsors(),
          retrieveTeamLeaders(),
        ]).then(() => {
          setIsLoading(false);
        });
      };

      getData();
    }
  }, [activeXmatrixInfo, isFilterOpen]);

  useEffect(() => {
    const activeFilterCount = Object.values(filters).filter((f) => f?.length > 0)?.length;
    setNumberOfActiveFilters(activeFilterCount);
  }, [filters]);

  const retrieveLongTermGoalsPicklist = async (xmatrix: string) => {
    try {
      const response = await MatrixService.getLongTermGoalsXmatrixFilter(xmatrix);
      if (response.data.success) {
        const options =
          response.data.responseObject?.value?.map((item) => ({
            value: item.longTermGoalID,
            label: item.title,
            key: item.longTermGoalID,
          })) || [];
        setLongTermGoalsPicklist(options);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveAnnualGoalsPicklist = async (xMatrix: string) => {
    try {
      const response = await MatrixService.getAnnualGoalsXmatrixFilter(xMatrix);
      if (response.data.success) {
        const options =
          response.data.responseObject?.value?.map((item) => ({
            value: item.annualGoalId,
            label: item.description,
            key: item.annualGoalId,
          })) || [];
        setAnnualGoalsPicklist(options);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveDivisions = async (xMatrix: string) => {
    try {
      const response = await MatrixService.getDivisionXmatrixFilter(xMatrix);
      if (response.data.success) {
        const options =
          response.data.responseObject?.value?.map((item) => ({
            value: item.divisionID,
            label: item.divisionName,
            key: item.divisionID,
          })) || [];
        setDivisionPicklist(options);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveCommittees = async (xMatrix: string) => {
    try {
      const response = await MatrixService.getCommittieXmatrixFilter(xMatrix);
      if (response.data.success) {
        const options =
          response.data.responseObject?.value?.map((item) => ({
            value: item.committeeID,
            label: item.name,
            key: item.committeeID,
          })) || [];
        setCommittiePicklist(options);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveProjects = async (xmatrix: string) => {
    try {
      const response = await MatrixService.getProjectXmatrixFilter(xmatrix);
      if (response.data.success) {
        const options =
          response.data.responseObject?.value?.map((item) => ({
            value: item.projectID,
            label: item.name,
            key: item.projectID,
          })) || [];
        setProjectPicklist(options);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveTeamLeaders = async () => {
    try {
      const response = await DashboardService.getTeamLaderFilterData();
      if (response.data.success) {
        const respData = response.data.responseObject?.value || [];
        setTeamLeaderPicklist(
          respData?.map((item) => ({
            value: item.userID,
            label: item.fullName,
            key: item.userID,
            disabled: item.isDeleted,
          })),
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveSponsors = async () => {
    try {
      const response = await DashboardService.getSponsorFilterData();
      if (response.data.success) {
        const respData = response.data.responseObject?.value || [];
        setSponsorPicklist(
          respData.map((item) => ({
            value: item.userID,
            label: item.fullName,
            key: item.userID,
            disabled: item.isDeleted,
          })),
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onFilterOptionsSelect = (filterName: keyof XMatrixFilters, value: string | string[]) => {
    setFilters({ ...filters, [filterName]: value });
    localStorage.setItem(LS_XMATRIX_FILTERS_KEY, JSON.stringify({ ...filters, [filterName]: value }));
  };

  return (
    <div className="tw-flex tw-flex-col tw-w-full tw-shrink-0">
      <div className="tw-flex tw-py-2 tw-px-4 tw-border-b tw-bg-white tw-w-full tw-gap-8 tw-justify-between tw-items-center">
        <div className="tw-flex tw-gap-2 tw-items-center tw-shrink-0">
          <XMatrixLeftPanel
            activeXmatrixInfo={activeXmatrixInfo}
            reloadMatrix={reloadMatrix}
          />
          <XmatrixHeader
            reloadMatrix={reloadMatrix}
            selectedXmatrixInfo={selectedXmatrixInfo}
            isSecondLevelXmatrix={isSecondLevelXmatrix}
          />
        </div>

        <div className="tw-flex tw-items-center tw-flex-1 tw-justify-end tw-gap-2">
          <Popover
            onOpenChange={setIsFilterOpen}
            placement="bottomRight"
            content={
              <div className="tw-px-2 tw-py-2 tw-flex tw-flex-col tw-gap-4">
                <div className="tw-flex tw-justify-between tw-items-center tw-border-b tw-pb-2">
                  <h3 className="tw-font-bold tw-text-xl">{t('xMatrixPage.xmatrixFiltri')}</h3>
                  <Button
                    data-testid="clear-filters"
                    icon={<MinusSquareOutlined />}
                    onClick={() => {
                      manageFilterReset();
                      setIsFilterOpen(false);
                    }}
                  />
                </div>

                {isLoading ? (
                  <Spin />
                ) : (
                  <div className="tw-flex tw-flex-col tw-gap-2 tw-w-[400px]">
                    <Select
                      style={{ width: '100%' }}
                      value={filters.longTermGoalId || []}
                      showSearch
                      mode="multiple"
                      id="select_r9"
                      maxTagCount="responsive"
                      placeholder={t('general.obiettiviLungo')}
                      options={longTermGoalsPicklist}
                      filterOption={(input, option: any) => {
                        return option.label.toLowerCase().includes(input.toLowerCase());
                      }}
                      onChange={(selectedRowKeys) => onFilterOptionsSelect('longTermGoalId', selectedRowKeys)}
                    />
                    <Select
                      value={filters?.annualGoalId ? filters.annualGoalId : []}
                      showSearch
                      style={{ width: '100%' }}
                      mode="multiple"
                      options={annualGoalsPicklist}
                      id="select_r10"
                      maxTagCount="responsive"
                      placeholder={t('general.obbietiviAnuali')}
                      optionFilterProp="children"
                      filterOption={(input, option: any) => {
                        return option.label.toLowerCase().includes(input.toLowerCase());
                      }}
                      onChange={(selectedRowKeys) => onFilterOptionsSelect('annualGoalId', selectedRowKeys)}
                    />
                    <Select
                      value={filters?.projectID ? filters.projectID : []}
                      showSearch
                      mode="multiple"
                      id="select_r11"
                      style={{ width: '100%' }}
                      maxTagCount="responsive"
                      options={projectPicklist}
                      placeholder={t('general.progetti')}
                      optionFilterProp="children"
                      filterOption={(input, option: any) => {
                        return option.label.toLowerCase().includes(input.toLowerCase());
                      }}
                      onChange={(selectedRowKeys) => onFilterOptionsSelect('projectID', selectedRowKeys)}
                    />
                    <Select
                      style={{ width: '100%' }}
                      value={filters?.committeeId ? filters.committeeId : []}
                      showSearch
                      mode="multiple"
                      id="select_r12"
                      maxTagCount="responsive"
                      placeholder={t('general.comitato')}
                      options={committiePicklist}
                      optionFilterProp="children"
                      filterOption={(input, option: any) => {
                        return option.label.toLowerCase().includes(input.toLowerCase());
                      }}
                      onChange={(selectedRowKeys) => onFilterOptionsSelect('committeeId', selectedRowKeys)}
                    />
                    <Select
                      style={{ width: '100%' }}
                      value={filters?.divisionId ? filters.divisionId : []}
                      showSearch
                      mode="multiple"
                      id="select_r13"
                      maxTagCount="responsive"
                      placeholder={t('general.divisione')}
                      options={divisionPicklist}
                      optionFilterProp="children"
                      filterOption={(input, option: any) => {
                        return option.label.toLowerCase().includes(input.toLowerCase());
                      }}
                      onChange={(selectedRowKeys) => onFilterOptionsSelect('divisionId', selectedRowKeys)}
                    />
                    <Select
                      style={{ width: '100%' }}
                      value={filters?.teamLeaderId ? filters.teamLeaderId : []}
                      mode="multiple"
                      id="teamLeaderSelect"
                      maxTagCount="responsive"
                      placeholder={t('general.teamLeader')}
                      onChange={(selectedRowKeys) => onFilterOptionsSelect('teamLeaderId', selectedRowKeys)}
                      options={teamLeaderPicklist}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                    />
                    <Select
                      style={{ width: '100%' }}
                      value={filters?.sponsorId ? filters.sponsorId : []}
                      mode="multiple"
                      id="select_r15"
                      maxTagCount="responsive"
                      placeholder={t('general.sponsor')}
                      onChange={(selectedRowKeys) => onFilterOptionsSelect('sponsorId', selectedRowKeys)}
                      options={sponsorPicklist}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                    />

                    <Divider />

                    <div className="tw-flex tw-gap-2 tw-items-center">
                      <span>{t('xMatrixPage.includiSottoprogetti')}</span>
                      <Switch
                        className="switch"
                        checked={filters?.includeSubProject ? filters.includeSubProject : false}
                        onChange={(checked) => onFilterOptionsSelect('includeSubProject', checked ? 'true' : 'false')}
                      />
                    </div>
                  </div>
                )}
              </div>
            }
            trigger={'click'}
          >
            <Button
              data-testid="filter-button"
              className={classNames('', {
                'tw-bg-primary/10 tw-text-primary': numberOfActiveFilters > 0,
              })}
              icon={<RiFilterLine />}
            >
              {t('xMatrixPage.xmatrixFiltri')}
              {numberOfActiveFilters > 0 && (
                <div className="tw-size-6 tw-rounded-full tw-grid tw-place-content-center tw-text-primary tw-bg-primary/20">
                  {numberOfActiveFilters}
                </div>
              )}
            </Button>
          </Popover>

          <div className="tw-flex tw-items-center tw-gap-2">
            <XmatrixOptions
              reloadMatrix={reloadMatrix}
              selectedXmatrixInfo={selectedXmatrixInfo}
              isSecondLevelXmatrix={isSecondLevelXmatrix}
              reloadMatrixOnEdit={reloadMatrixOnEdit}
              reloadXmInfo={reloadXmInfo}
              xmatrixData={xmatrixData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltersMatrixLocal;
