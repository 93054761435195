import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table, Tooltip, Typography, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import UploadKpiSpecifico from 'src/components/kpiListPage/uploadKpiSpecifico';
import EditProjectKpi from 'src/components/projects/projectForm/deliverablesAndKpi/editKpi';
import NewProjectKpi from 'src/components/projects/projectForm/deliverablesAndKpi/newKpi';
import ProjectSectionTitle from 'src/components/shared/components/ProjectSectionTitle/ProjectSectionTitle';
import TableLayout from 'src/components/shared/tableLayout';
import { italianNumberFormat } from 'src/components/shared/utils/functions';
import { notifyMessages } from 'src/components/shared/utils/notifyMessages';
import { KpiDetailParentType, enumCast } from 'src/config/connectors';
import type {
  CreateKpiRequest,
  GetKPIByProjectResponseDto,
  GetProjectMembersResponseDto,
  GetProjectResponseDto,
  KpiDto,
} from 'src/connectors/backend';
import { getProjectById } from 'src/redux/slices/projectSlice';
import { useAppSelector } from 'src/redux/store';
import KpiService from 'src/services/pages/kpiService';
import DeliverableService from 'src/services/pages/projectServices/deliverableServices';
import ProgettiService from 'src/services/pages/projectServices/progettiService';
import { getSemaphore, lastMonthlyPlanning } from 'src/utils/kpi-utils';

const { Text } = Typography;

export interface ProjectTargetIndicatorsProps {
  project: GetProjectResponseDto;
  isPresentationMode: boolean;
  hasUserAccess: boolean;
}

interface IndicatoriTargetProps {
  isPresentationMode: boolean;
  hasUserAccess: boolean;
}

const ProjectTargetIndicators: React.FC<IndicatoriTargetProps> = ({ hasUserAccess, isPresentationMode }) => {
  const dispatch = useDispatch();
  const currentProject = useAppSelector((state) => state.project.currentProject);
  const companyData = useAppSelector((state) => state.companyData.companyData);

  // todo: drop me
  const [oldKpiList, setOldKpiList] = useState<GetKPIByProjectResponseDto[]>([]);

  const [kpiList, setKpiList] = useState<KpiDto[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [showNewKpiModal, setShowNewKpiModal] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [showEditKpiModal, setShowEditKpiModal] = useState<boolean>(false);
  // todo: drop me
  const [selectedRow, setSelectedRow] = useState<GetKPIByProjectResponseDto | null>(null);
  const [showUploadKpiModal, setShowUploadKpiModal] = useState<boolean>(false);
  const [projectMembers, setProjectMembers] = useState<GetProjectMembersResponseDto[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (currentProject?.projectID) {
      retrieveKPIList();
    }
  }, [currentProject]);

  useEffect(() => {
    if (showEditKpiModal) {
      getProjectMembers();
    }
  }, [showEditKpiModal]);

  const getProjectMembers = () => {
    if (!currentProject?.projectID) {
      return;
    }
    setLoadingData(true);
    ProgettiService.getProjectTeamMembers(currentProject?.projectID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setProjectMembers(resp.responseObject?.value || []);
          setLoadingData(false);
        } else {
          setLoadingData(false);
        }
      })
      .catch(() => {
        setLoadingData(false);
      });
  };

  const retrieveKPIList = async () => {
    if (!currentProject?.projectID) {
      return;
    }

    setLoadingData(true);
    try {
      const response = await KpiService.getKpiListData({
        isProjectRelated: true,
        projectId: currentProject.projectID,
        includePlannings: true,
      });
      const resp = response.data;
      setKpiList(resp || []);

      // todo: drop me
      DeliverableService.getProjectKpi(currentProject?.projectID)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            const respData = resp.responseObject?.value;

            setOldKpiList(respData || []);
          }
        });
    } finally {
      setLoadingData(false);
    }
  };

  const toggleEditKpiModal = (kpi: KpiDto) => {
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    setSelectedRow(oldKpiList.find((k) => k.kpiDetailID! === kpi.kpiDetail.id!) || null);
    setShowEditKpiModal(!showEditKpiModal);
  };

  const removeKpi = (kpiId: string) => {
    if (!currentProject?.projectID) return;

    ProgettiService.removeKpiFromProject(currentProject.projectID, kpiId)
      .then(() => {
        message.success(notifyMessages.deleteSuccess);
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        dispatch(getProjectById({ projectId: currentProject?.projectID! }));
        retrieveKPIList();
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  const updateBowlingChart = (payload: any) => {
    if (!currentProject?.projectID) return;

    DeliverableService.updateKpiBowlingChart(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          setShowEditKpiModal(false);
          setSelectedRow(null);
          dispatch(getProjectById({ projectId: currentProject?.projectID! }));
          retrieveKPIList();
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
      });
  };

  const kpiColumns: ColumnsType<KpiDto> = [
    {
      title: `${t('general.nome')}`,
      dataIndex: ['name'],
      key: 'name',
      width: '30%',
      className: 'twoRowEllipses',
      render: (text, record) => {
        return {
          props: {
            style: {
              borderLeft: `5px solid ${getSemaphore(
                record.kpiDetail,
                // biome-ignore lint/style/noNonNullAssertion: plannings are included
                record.monthlyPlannings!,
                enumCast(KpiDetailParentType, record.kpiDetail.parentType),
                new Date().getMonth(),
                new Date().getFullYear(),
                companyData,
              )}`,
            },
          },
          children: (
            <Tooltip title={text}>
              <Text>{record.kpiDetail.name}</Text>
            </Tooltip>
          ),
        };
      },
    },

    {
      title: `${t('general.jumpOffPoint')}`,
      dataIndex: ['jumpOffPoint'],
      key: 'jumpOffPoint',
      ellipsis: {
        showTitle: false,
      },
      render: (text: string, record) => <Text>{italianNumberFormat(record.kpiDetail.jumpOffPoint)}</Text>,
    },
    {
      title: `${t('kpiPage.finalTarget')}`,
      dataIndex: ['target'],
      key: 'target',
      ellipsis: {
        showTitle: false,
      },
      render: (text: string, record) => <Text>{italianNumberFormat(record.target)}</Text>,
    },
    {
      title: `${t('general.updateYtd')}`,
      dataIndex: ['updateYTD'],
      key: 'updateYTD',
      ellipsis: {
        showTitle: false,
      },
      render: (text: string, record) => <Text>{italianNumberFormat(lastMonthlyPlanning(record).actualYTD)}</Text>,
    },
    {
      title: `${t('general.targetYtd')}`,
      dataIndex: ['targetYTD'],
      key: 'targetYTD',
      ellipsis: {
        showTitle: false,
      },
      render: (text: string, record) => <Text>{italianNumberFormat(lastMonthlyPlanning(record).targetYTD)}</Text>,
    },
    {
      key: 'action',
      width: '150px',
      render: (_: string, record) => {
        return (
          <>
            <div className="tw-flex tw-items-center tw-gap-2">
              <Button
                icon={<EditOutlined />}
                onClick={() => toggleEditKpiModal(record)}
              />
              {/* <Tooltip placement="top">
                <Button
                  onClick={() => toggleUploadKpiSpecificoModal(record)}
                  icon={<UploadOutlined />}
                />
              </Tooltip> */}
              <Popconfirm
                title={t('proggetiPage.rimuoveProgettoKpi')}
                onConfirm={() => removeKpi(record.id as string)}
                okText={t('general.si')}
                cancelText={t('general.no')}
              >
                <Button
                  danger
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </div>
          </>
        );
      },
    },
  ];

  const handleNewKpiModalClose = () => {
    setShowNewKpiModal(false);
    setLoadingButton(false);
  };

  const toggleNewKpiModal = () => {
    setShowNewKpiModal(true);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const addProjectKpi = useCallback(
    async (values: { kpiId: string }) => {
      setLoadingButton(true);

      try {
        const response = await DeliverableService.insertKpiProject({
          kpiDetailID: values.kpiId,
          projectID: currentProject?.projectID ?? '',
        });

        const resp = response.data;

        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          handleNewKpiModalClose();
          retrieveKPIList();
        } else {
          message.error(notifyMessages.addFailed);
        }
      } catch (error) {
        message.error(notifyMessages.addFailed);
      } finally {
        setLoadingButton(false);
      }
    },
    [currentProject?.projectID],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const createKpi = useCallback(
    () => async (values: CreateKpiRequest) => {
      if ('kpiId' in values) {
        return;
      }

      // if (disableJumpOfPointResetGlobalKpi) {
      //   values.jumpOffPoint = 0;
      // } else {
      //   values.jumpOffPoint = +values.jumpOffPoint;
      // }

      values.isRollupKpi = false;

      try {
        const { data } = await KpiService.addKpi(values);

        if (!data.success || !data.responseObject?.value) {
          throw new Error('Creation failed');
        }

        if (data.responseObject.value.kpiDetail?.id) {
          await addProjectKpi({ kpiId: data.responseObject.value.kpiDetail?.id });
        }

        retrieveKPIList();
      } catch {
        void message.error(notifyMessages.addFailed);
      }
    },
    [],
  );

  const retrieveKpiList = useCallback(async () => {
    if (!currentProject?.projectID) {
      return [];
    }

    try {
      const { data } = await ProgettiService.getKpiProjectPicklist(currentProject?.projectID);
      if (!data.success || !data.responseObject?.value) {
        throw new Error('Fetch failed');
      }

      return data.responseObject.value.map((item) => ({
        value: item.kpiDetailID as string,
        key: item.kpiDetailID as string,
        name: item.name as string,
        calculationType: item.globaleType as string,
        parentType: item.puntualeType as string,
        isRollupKpi: item.isRollupKPI === true,
      })) as any[];
    } catch {
      void message.error(notifyMessages.retrieveFailed);
    }

    return [];
  }, [currentProject?.projectID]);

  return (
    <>
      {showEditKpiModal && (
        <EditProjectKpi
          showEditKpiModal={showEditKpiModal}
          handleEditKpiModalClose={() => {
            setShowEditKpiModal(false);
            setSelectedRow(null);
          }}
          handleUpdateBowlingChart={updateBowlingChart}
          projectId={currentProject?.projectID}
          kpiRowData={selectedRow}
          projectTeamMembers={projectMembers}
          currentProject={currentProject}
        />
      )}

      {showUploadKpiModal && (
        <UploadKpiSpecifico
          showUploadKpiModal={showUploadKpiModal}
          handleUploadKpiModalClose={() => {
            setShowUploadKpiModal(false);
            setSelectedRow(null);
          }}
          projectId={currentProject?.projectID}
          kpiRowData={selectedRow}
          projectName={currentProject?.name}
        />
      )}

      {!isPresentationMode && showNewKpiModal && (
        <NewProjectKpi
          showNewKpiModal={showNewKpiModal}
          handleNewKpiModalClose={handleNewKpiModalClose}
          handleKpiModalSave={addProjectKpi}
          loadingButton={loadingButton}
          projectId={currentProject?.projectID as string}
        />
      )}
      <TableLayout title={<ProjectSectionTitle title={t('general.kpiIndicators')} />}>
        <TableLayout.Actions>
          {!isPresentationMode && !hasUserAccess && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={toggleNewKpiModal}
            >
              {t('buttons.aggiungiNuovo')}
            </Button>
          )}
        </TableLayout.Actions>

        <TableLayout.Content>
          <Table
            columns={kpiColumns}
            dataSource={kpiList}
            pagination={false}
            size="small"
            loading={loadingData}
            rowKey={(obj) => obj.kpiDetail.id as string}
          />
        </TableLayout.Content>
      </TableLayout>
    </>
  );
};

export default ProjectTargetIndicators;
