import { EditOutlined } from '@ant-design/icons';
import { DndContext, type DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Button, Table, Tooltip, message } from 'antd';
import classNames from 'classnames';
import { type FC, useEffect, useRef, useState } from 'react';
import FixedSelectedAsset from 'src/components/xMatrix/FixedSelectedAsset';
import { DndKitRow, sensorOptions } from 'src/components/xMatrix/utils/x-matrix-dnd-kit-common';
import type { XMatrixDto } from 'src/connectors/backend';
import ObLongService from '../../../../services/matrix/obLongService';
import { statusClass } from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import '../../matrix.scss';
import EditLongObjectiveModal from './editLongObjectiveModal';

interface BigObjectivesProps {
  longTermGoals: XMatrixDto['longTermGoals'];
  selectedXmatrix: string;
  onRowClick: (id: string) => void;
  reloadMatrix: () => void;
  selection: 'primary' | 'secondary';
  selectedIds: string[];
  onSort: (oldIndex: number, newIndex: number) => void;
}

const BigObjectives: FC<BigObjectivesProps> = ({
  longTermGoals,
  selectedXmatrix,
  onRowClick,
  reloadMatrix,
  selection,
  selectedIds,
  onSort,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({});
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const sensors = useSensors(useSensor(PointerSensor, sensorOptions));

  const rowRefs = useRef<any[]>([]);

  useEffect(() => {
    if (selection !== 'primary') {
      setSelectedRecord(null);
    }
  }, [selection]);

  // Toggle the modal open/close and set modal data
  const toggleModal = (rowData?: any) => {
    setOpenModal((prev) => !prev);
    setModalData(rowData || {});
  };

  // Close modal handler
  const handleModalCancel = () => {
    setOpenModal(false);
    setLoadingButton(false);
  };

  // Update data handler
  const updateData = (values: any, id: any) => {
    setLoadingButton(true);
    values['longTermGoalID'] = id;
    ObLongService.updateObiettivoLungoxMartix(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          handleModalCancel();
          reloadMatrix();
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch(() => {
        message.error(notifyMessages.updateFailed);
      });
  };

  // Row select handler
  const onRowSelect = (record: any, index: number) => {
    onRowClick(record.longTermGoalID);
    setSelectedRecord(record);
    setSelectedIndex(index);
  };

  const onDragEnd = (event: DragEndEvent) => {
    if (event.active.id !== event.over?.id) {
      const items: string[] = event.active.data.current?.sortable.items;
      const activeIndex = items.findIndex((i: string) => i === event.active.id);
      const overIndex = items.findIndex((i: string) => i === event.over?.id);

      return onSort(activeIndex, overIndex);
    }
  };

  // Define table columns
  const columns = [
    {
      render: (text: any, record: any, index: number) => {
        return {
          children: (
            <div
              ref={(el) => (rowRefs.current[index] = el)}
              onClick={() => onRowSelect(record, index)}
              className={`tw-flex tw-px-1 matrix-row tw-h-[31px] matrix-row tw-items-center tw-justify-between ${classNames(
                {
                  'tw-bg-primary selected-row': selection === 'primary' && selectedIds?.includes(record.longTermGoalID),
                  'tw-bg-primary/30 selected-row':
                    selection === 'secondary' && selectedIds?.includes(record.longTermGoalID),
                },
              )}`}
            >
              <div className="tw-flex tw-items-center tw-truncate">
                <Tooltip title={record.statusDescription}>
                  <div
                    className={`xMatrix-square-label ${statusClass(record.status)}`}
                    key={index}
                  >
                    {index + 1}
                  </div>
                </Tooltip>

                <Tooltip title={record.title}>
                  <p className="tw-text-sm tw-w-full tw-truncate">{record.title}</p>
                </Tooltip>
              </div>
              <Button
                type="text"
                className="tw-shrink-0"
                icon={<EditOutlined />}
                onClick={() => toggleModal(record)}
              ></Button>
            </div>
          ),
        };
      },
    },
  ];

  if (!longTermGoals) return null;

  return (
    <>
      <EditLongObjectiveModal
        show={openModal}
        // toggle={toggleModal}
        handleModalCancel={handleModalCancel}
        data={modalData}
        handleSave={updateData}
        loadingButton={loadingButton}
        selectedXmatrix={selectedXmatrix}
      />

      {selectedRecord && selectedIndex && (
        <FixedSelectedAsset
          side="bottom"
          listOfRefs={rowRefs}
          selectedIndex={selectedIndex}
        >
          <div className={`xMatrix-square-label ${statusClass(selectedRecord.status)}`}>{selectedIndex + 1}</div>
          <span>{selectedRecord.title}</span>
        </FixedSelectedAsset>
      )}

      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={onDragEnd}
      >
        <SortableContext
          items={longTermGoals.map((i) => i.longTermGoalID!)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: { row: DndKitRow },
            }}
            className="[&_table]:tw-h-full"
            dataSource={longTermGoals}
            pagination={false}
            bordered
            size="small"
            showHeader={false}
            columns={columns}
            rowKey={'longTermGoalID'}
          ></Table>
        </SortableContext>
      </DndContext>
    </>
  );
};

export default BigObjectives;
