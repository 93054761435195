import { addYears, format } from 'date-fns';

import { Form, Spin } from 'antd';
import type React from 'react';
import { useEffect, useState } from 'react';
import CompanyService from '../../services/pages/companyService';

import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
  message,
} from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import TableLayout from 'src/components/shared/tableLayout';
import { retrieveCompanyData } from 'src/redux/slices/companySlice';
import { useAppSelector } from 'src/redux/store';
import noImage from '../../assets/noImage.jpg';
import {
  checkUserIsAdminUser,
  checkUserIsNotLenovysAdmin,
} from '../../components/shared/utils/authRolesProvilege/authRolesPrivilege';
import { budgetAlertList, companycurrencies } from '../../components/shared/utils/constants';
import { notifyMessages, requiredFields } from '../../components/shared/utils/notifyMessages';

const { TextArea } = Input;
const { Option } = Select;

const CompanyProfile: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = useAppSelector((state) => state.userData.userData);
  const companyData = useAppSelector((state) => state.companyData.companyData);

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [selectedYear, setSelectedYear] = useState<string>(format(addYears(new Date(), 1), 'yyyy'));
  const [userNextYearButton, setUserNextYearButton] = useState<boolean>(false);

  useEffect(() => {
    getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const handleImageChange = (info: any): void => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const removeUploadedFile = (): void => {
    setUploadedFile(null);
  };

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const updateCompany = (values: any): void => {
    // fixme: remove logo from values
    const data = values;
    data.logo = uploadedFile;

    CompanyService.updateCompanyData(data)
      .then((response) => response.data)
      .then((resp) => {
        if (!resp.success) {
          throw new Error();
        }

        message.success(notifyMessages.updateSuccess);
        getCompany();
      })
      .catch(() => {
        message.error(notifyMessages.updateFailed);
      });
  };

  // Retrieve company data
  const getCompany = async () => {
    dispatch(retrieveCompanyData());
  };

  const onYearChange = (year: moment.Moment | null): void => {
    if (year) {
      setSelectedYear(year.format('YYYY'));
    }
  };

  const createUserPlan = (): void => {
    const currentYear = format(addYears(new Date(), 1), 'yyyy');
    const formatedYear = selectedYear ? moment(selectedYear).format('YYYY') : currentYear;

    setUserNextYearButton(true);

    CompanyService.insertUserNextYearPlan(formatedYear)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          setUserNextYearButton(false);
        } else {
          message.error(notifyMessages.updateSuccess);
          setUserNextYearButton(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateSuccess);
        setUserNextYearButton(false);
      });
  };

  if (!companyData) {
    return (
      <div className="tw-min-h-full empty:tw-hidden tw-w-full tw-flex tw-justify-center tw-items-center">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Form
      name="company_Details"
      layout="vertical"
      onFinish={updateCompany}
      onValuesChange={() => {
        setFieldsChanged(true);
      }}
    >
      <TableLayout title={t('general.organizzatore')}>
        <TableLayout.Actions>
          <Form.Item noStyle>
            <Button
              htmlType="submit"
              type="primary"
              disabled={!fieldsChanged}
            >
              {t('buttons.salva')}
            </Button>
          </Form.Item>
        </TableLayout.Actions>

        <TableLayout.Content>
          <Row
            className="tw-mt-4 tw-gap-y-2"
            gutter={24}
            align={'bottom'}
          >
            <Col
              md={20}
              xs={24}
            >
              <Form.Item
                className="lg-label tw-mb-0"
                label={t('societaPage.nomeAzienda')}
                name="companyName"
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={companyData.companyName}
              >
                <Input disabled={checkUserIsAdminUser(userData)} />
              </Form.Item>
            </Col>

            <Col
              xs={24}
              lg={4}
            >
              <div className="tw-flex tw-gap-2 tw-items-center">
                {companyData.logo && (
                  <img
                    className="tw-rounded-md tw-aspect-square tw-size-10"
                    src={`${companyData.logo}?${+new Date()}`}
                    alt="Logo"
                  />
                )}
                {!companyData.logo && (
                  <img
                    src={noImage || '/placeholder.svg'}
                    className="tw-rounded-md tw-aspect-square tw-size-10"
                    alt="Logo"
                  />
                )}
                <Form.Item
                  name="logo"
                  className="tw-mb-0"
                >
                  <Upload
                    accept="image/*"
                    maxCount={1}
                    listType="picture"
                    onChange={handleImageChange}
                    onRemove={() => removeUploadedFile()}
                    beforeUpload={(file) => {
                      setUploadedFile(file);
                      return false;
                    }}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      disabled={checkUserIsAdminUser(userData)}
                    >
                      {t('buttons.upload')}
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
            </Col>

            <Divider />

            <Col
              className="tw-mt-4"
              xs={24}
              lg={8}
            >
              <Form.Item
                className="lg-label"
                label={t('societaPage.nostraMisione')}
                name="mission"
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={companyData.mission}
              >
                <TextArea
                  rows={4}
                  disabled={checkUserIsAdminUser(userData)}
                />
              </Form.Item>
            </Col>

            <Col
              xs={24}
              lg={8}
            >
              <Form.Item
                className="lg-label"
                label={t('societaPage.nostraVisione')}
                name="vision"
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={companyData.vision}
              >
                <TextArea
                  rows={4}
                  disabled={checkUserIsAdminUser(userData)}
                />
              </Form.Item>
            </Col>

            <Col
              xs={24}
              lg={8}
            >
              <Form.Item
                className="lg-label"
                label={t('societaPage.nostriValori')}
                name="values"
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={companyData.values}
              >
                <TextArea
                  rows={4}
                  disabled={checkUserIsAdminUser(userData)}
                />
              </Form.Item>
            </Col>

            <Divider />

            <Col span={24}>
              <Typography.Title level={4}>{t('general.alertThreshold')}</Typography.Title>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                label={t('societaPage.alertTempi')}
                name="timeAlertEdge"
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={companyData.timeAlertEdge}
              >
                <InputNumber
                  min={0}
                  max={100}
                  formatter={(value) => `${value} %`}
                  disabled={checkUserIsAdminUser(userData)}
                />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                label={t('societaPage.alertCosti')}
                name="budgetAlertEdge"
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={companyData.budgetAlertEdge}
              >
                <InputNumber
                  min={0}
                  max={100}
                  formatter={(value) => `${value} %`}
                  disabled={checkUserIsAdminUser(userData)}
                />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                label={t('societaPage.alertRisultati')}
                name="qualityAlertEdge"
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={companyData.qualityAlertEdge}
              >
                <InputNumber
                  min={0}
                  max={100}
                  formatter={(value) => `${value} %`}
                  disabled={checkUserIsAdminUser(userData)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Typography.Title level={4}>{t('general.configurazioni')}</Typography.Title>
          <Row gutter={{ lg: 24 }}>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                label={t('societaPage.valuta')}
                name="currency"
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={companyData.currency}
              >
                <Select
                  className="tw-w-full"
                  placeholder={t('general.seleziona')}
                  disabled={checkUserIsAdminUser(userData)}
                >
                  {companycurrencies.map((item) => (
                    <Option
                      value={item.key}
                      key={item.key}
                    >
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                label={t('societaPage.bidgetAlert')}
                name="budgetAlertSelection"
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={companyData?.budgetAlertSelection}
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder={t('general.seleziona')}
                  options={budgetAlertList.map((b) => ({ value: b.objectCodeListID, label: b.description }))}
                  disabled={checkUserIsAdminUser(userData)}
                />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                label={t('societaPage.ablitaInvioEmail')}
                name="sendNotification"
                tooltip={t('societaPage.ablitaInvioEmailMessage')}
                valuePropName="checked"
                initialValue={companyData.sendNotification}
              >
                <Switch disabled={checkUserIsAdminUser(userData)} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ lg: 24 }}>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Form.Item
                style={{ width: '100%' }}
                rules={[{ required: true, message: requiredFields.required }]}
                label={t('societaPage.preavvisoImplementazione')}
                name="implementationDaysOfNotice"
                help={t('companyNotificationsPage.valorePiuDiUno')}
                initialValue={companyData.implementationDaysOfNotice}
              >
                <InputNumber
                  min={1}
                  disabled={checkUserIsAdminUser(userData)}
                />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Form.Item
                style={{ width: '100%' }}
                rules={[{ required: true, message: requiredFields.required }]}
                label={t('societaPage.preavvisoProsimiPasi')}
                name="nextStepsDaysOfNotice"
                help={t('companyNotificationsPage.valorePiuDiUno')}
                initialValue={companyData.nextStepsDaysOfNotice}
              >
                <InputNumber
                  min={1}
                  disabled={checkUserIsAdminUser(userData)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Typography.Title level={4}>{t('societaPage.pianoAttivitaFuturi')}</Typography.Title>
          <Row gutter={{ lg: 24 }}>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              lg={{ span: 8 }}
            >
              <DatePicker
                disabled={checkUserIsAdminUser(userData)}
                allowClear={false}
                placeholder={t('general.anno')}
                picker="year"
                value={moment(selectedYear)}
                style={{ width: '100%' }}
                format="YYYY"
                onChange={(date) => onYearChange(date)}
              />
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              lg={{ span: 8 }}
            >
              <Button
                type="primary"
                onClick={() => createUserPlan()}
                loading={userNextYearButton}
                disabled={checkUserIsAdminUser(userData)}
              >
                <SaveOutlined /> {t('buttons.creaPiano')}
              </Button>
            </Col>
          </Row>
          <Divider />
          <Typography.Title level={4}>{t('societaPage.guidaConfigurazione')}</Typography.Title>
          <Row gutter={{ lg: 24 }}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Form.Item
                label={t('societaPage.reindirizzareLink')}
                name="redirectLink"
                initialValue={companyData.redirectLink}
              >
                <Input disabled={checkUserIsNotLenovysAdmin(userData)} />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Form.Item
                label={t('societaPage.postaSupporto')}
                name="supportMail"
                initialValue={companyData.supportMail}
              >
                <Input disabled={checkUserIsNotLenovysAdmin(userData)} />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Typography.Title level={4}>{t('societaPage.kpi')}</Typography.Title>
          <Row gutter={{ lg: 24 }}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                label={t('societaPage.kpiTolerance')}
                name="kpiTolerance"
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={companyData.kpiTolerance}
              >
                <InputNumber
                  min={0}
                  disabled={checkUserIsAdminUser(userData)}
                  formatter={(value) => `${value}%`}
                  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                  parser={(value) => value?.replace('%', '') as any}
                />
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                label={t('societaPage.startFromCurrentMonth')}
                name="startFromCurrentMonth"
                tooltip={t('societaPage.startFromCurrentMonth')}
                valuePropName="checked"
                initialValue={companyData.startFromCurrentMonth}
              >
                <Switch disabled={checkUserIsAdminUser(userData)} />
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                label={t('societaPage.resetGlobalKPI')}
                name="resetGlobalKpi"
                tooltip={t('societaPage.resetGlobalKPITooltip')}
                valuePropName="checked"
                initialValue={companyData.resetGlobalKPI}
              >
                <Switch disabled={checkUserIsAdminUser(userData)} />
              </Form.Item>
            </Col>
          </Row>
        </TableLayout.Content>
      </TableLayout>
    </Form>
  );
};

export default CompanyProfile;
