import { Form } from 'antd';
import { FC, useEffect, useState } from 'react';

import { Divider, Input, Modal, Select, Transfer } from 'antd';
import MatrixService from '../services/matrix/matrixService';
import Loader2 from '../components/shared/components/loader2/loader2';
import ModalFooterButtons from '../components/shared/components/modal/modalFooterButtons';
import { requiredFields } from '../components/shared/utils/notifyMessages';
import '../components/xMatrix/matrix.scss';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/store';
import { AnnualGoalResponse, GetXMatrixCycleResponseDto, LongTermGoalResponse, ProjectResponse, UpdateXMatrixByIDRequestDto, XMatrixDataByIDResponseDto } from 'src/connectors/backend';

const { Option } = Select;

interface EditXmatrixModalProps {
  showEditModalXmatrix: boolean;
  handleEditModalXmatrixClose: () => void;
  handleEditModalXmatrixSave: (values: UpdateXMatrixByIDRequestDto) => void;
  loadingButton: boolean;
  selectedXmatrixValue: string;
}

const EditXmatrixModal: FC<EditXmatrixModalProps> = ({
  showEditModalXmatrix,
  handleEditModalXmatrixClose,
  handleEditModalXmatrixSave,
  loadingButton,
  selectedXmatrixValue,
}) => {
  const { t } = useTranslation();

  const userData = useAppSelector((state) => state.userData.userData);

  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [loadingXmatrixData, setLoadingXmatrixData] = useState(false);
  const [xmatrixData, setXmatrixData] = useState<XMatrixDataByIDResponseDto>();
  const [strategicCycleList, setStrategicCycleList] = useState<GetXMatrixCycleResponseDto[]>([]);

  const [longTermGoals, setLongTermGoals] = useState<LongTermGoalResponse[]>([]);
  const [relatedLongTermGoalsIds, setRelatedLongTermGoalsIds] = useState<string[]>([]);

  const [annualGoals, setAnnualGoals] = useState<AnnualGoalResponse[]>([]);
  const [relatedAnnualGoalsIds, setRelatedAnnualGoalsIds] = useState<string[]>([]);

  const [relatedProjectsIds, setRelatedProjectsIds] = useState<string[]>([]);
  const [projects, setProjects] = useState<ProjectResponse[]>([]);

  useEffect(() => {
    if (userData?.lastLoggedInCompanyID) {
      void fetchXmatrixCycles(userData.lastLoggedInCompanyID);
      void fetchXmatrixData();
    }
  }, [userData, selectedXmatrixValue]);

  useEffect(() => {
    if (xmatrixData) {
      manageProjectList(xmatrixData);
      manageAnnualGoalList(xmatrixData);
      manageLongTermGoal(xmatrixData);
    }
  }, [xmatrixData]);

  const fetchXmatrixCycles = async (companyId: string) => {
    try {
      const response = await MatrixService.getXmatrixCycleList(companyId);
      const resp = response.data;

      if (resp.success) {
        setStrategicCycleList(resp.responseObject?.value ?? []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchXmatrixData = async () => {
    setLoadingXmatrixData(true);

    try {
      const response = await MatrixService.getXmatrixOnEditData(selectedXmatrixValue);
      const resp = response.data;

      if (resp.success) {
        const xmatrixData = resp.responseObject?.value;

        setXmatrixData(xmatrixData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingXmatrixData(false);
    }
  };

  const manageProjectList = (data: XMatrixDataByIDResponseDto) => {
    const projectData = data.projectData;
    const relatedProjects = projectData?.projectRelated ?? [];
    const projects = relatedProjects.concat(projectData?.projectNotRelated ?? []);

    const relatedProjectsIds = relatedProjects.map((a) => a.projectID as string);

    setProjects(projects);
    setRelatedProjectsIds(relatedProjectsIds);
  };

  const manageAnnualGoalList = (data: XMatrixDataByIDResponseDto) => {
    const annualObjData = data.annualObjData;
    const relatedAnnualGoals = annualObjData?.annualGoalRelated ?? [];
    const annualGoals = relatedAnnualGoals.concat(annualObjData?.annualGoalNotRelated ?? []);

    setAnnualGoals(annualGoals);
    setRelatedAnnualGoalsIds(relatedAnnualGoals.map((a) => a.annualGoalID as string));
  };

  const manageLongTermGoal = (data: XMatrixDataByIDResponseDto) => {
    const longTermGoalData = data.longTermGoalData;
    const relatedLongTermGoals = longTermGoalData?.longTermGoalRelated ?? [];
    const longTermGoals = relatedLongTermGoals.concat(longTermGoalData?.longTermGoalNotRelated ?? []);

    setLongTermGoals(longTermGoals);
    setRelatedLongTermGoalsIds(relatedLongTermGoals.map((a) => a.longTermGoalID as string));
  };

  const handleChangeLongTermGoal = (targetKeys: string[]) => {
    setRelatedLongTermGoalsIds(targetKeys);
    setFieldsChanged(true);
  };

  const handleChangeAnnualGoal = (targetKeys: string[]) => {
    setRelatedAnnualGoalsIds(targetKeys);
    setFieldsChanged(true);
  };

  const handleChangeProject = (newRelatedProjectsIds: string[]) => {
    setRelatedProjectsIds(newRelatedProjectsIds);
    setFieldsChanged(true);
  };

  const saveData = (values: UpdateXMatrixByIDRequestDto) => {
    handleEditModalXmatrixSave({
      ...values,
      projectIDList: relatedProjectsIds,
      longTermGoalIDList: relatedLongTermGoalsIds,
      annualGoalIDList: relatedAnnualGoalsIds,
      xMatrixID: selectedXmatrixValue,
    });
  };

  return (
    <Modal
      className="edit_xmatrix_modal"
      width={1300}
      title={t('xMatrixPage.modificaXmatrix')}
      open={showEditModalXmatrix}
      destroyOnClose={true}
      onCancel={handleEditModalXmatrixClose}
      footer={[
        <ModalFooterButtons
          disabled={!fieldsChanged}
          loadingButton={loadingButton}
          modalClose={handleEditModalXmatrixClose}
          formId={'editXmatrix'}
        />,
      ]}>
      <Form
        id="editXmatrix"
        name="edit_xmatrix"
        layout="vertical"
        onFinish={saveData}
        onValuesChange={() => {
          setFieldsChanged(true);
        }}>
        {!xmatrixData && <Loader2 />}

        {xmatrixData && (
          <>
            <div className='tw-grid tw-grid-cols-2 tw-gap-9'>
              <Form.Item
                label={t('general.nome')}
                name="xMatrixName"
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={xmatrixData.xMatrixName}>
                <Input />
              </Form.Item>

              <Form.Item
                labelAlign="left"
                label={t('xMatrixPage.xmatrixStrategy')}
                name="xMatrixCycleID"
                hasFeedback
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={xmatrixData.xMatrixCycleID}>
                <Select
                  className="tw-w-full"
                  placeholder={t('general.seleziona')}>
                  {strategicCycleList.map((item) => (
                    <Option
                      value={item.xMatrixCycleID}
                      key={item.xMatrixCycleID}>
                      {item.cycleName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div>
              <Divider plain>{t('general.obiettiviLungo')}</Divider>
              <Transfer
                dataSource={longTermGoals.map((item) => ({
                  key: item.longTermGoalID as string,
                  title: item.longTermGoalName ?? '',
                }))}
                titles={[`${t('xMatrixPage.notRelatedLongTermGoals')}`, `${t('xMatrixPage.relatedLongTermGoals')}`]}
                showSearch
                listStyle={{
                  width: '48.5%',
                  height: 300,
                }}
                filterOption={(filterValue, item) => item.title.toLowerCase().includes(filterValue.toLowerCase())}
                targetKeys={relatedLongTermGoalsIds}
                onChange={(values) => handleChangeLongTermGoal(values as string[])}
                render={(item) => item.title}
                locale={{
                  itemUnit: `${t('general.obiettiviLungo')}`,
                  itemsUnit: `${t('general.obiettiviLungo')}`,
                  notFoundContent: `${t('general.elencoVuoto')}`,
                  searchPlaceholder: `${t('buttons.cerca')}`,
                }}
              />
            </div>

            <div>
              <Divider plain>{t('general.obbietiviAnuali')}</Divider>
              <Transfer
                dataSource={annualGoals.map((item) => ({
                  key: item.annualGoalID as string,
                  title: item.annualGoalName ?? '',
                }))}
                titles={[`${t('xMatrixPage.notRelatedAnnualGoal')}`, `${t('xMatrixPage.relatedAnnualGoal')}`]}
                showSearch
                listStyle={{
                  width: '48.5%',
                  height: 300,
                }}
                filterOption={(filterValue, item) => item.title.toLowerCase().includes(filterValue.toLowerCase())}
                targetKeys={relatedAnnualGoalsIds}
                onChange={(values) => handleChangeAnnualGoal(values as string[])}
                render={(item) => item.title}
                locale={{
                  itemUnit: `${t('general.obbietiviAnuali')}`,
                  itemsUnit: `${t('general.obbietiviAnuali')}`,
                  notFoundContent: `${t('general.elencoVuoto')}`,
                  searchPlaceholder: `${t('buttons.cerca')}`,
                }}
              />
            </div>

            <div>
              <Divider plain>{t('general.progetti')}</Divider>
              <Transfer
                dataSource={projects.map((item) => ({
                  key: item.projectID as string,
                  title: `${item.projectName} (${item.projectCode})`,
                }))}
                titles={[`${t('xMatrixPage.notRelatedProjects')}`, `${t('xMatrixPage.relatedProjects')}`]}
                showSearch
                listStyle={{
                  width: '48.5%',
                  height: 300,
                }}
                filterOption={(filterValue, item) => item.title.toLowerCase().includes(filterValue.toLowerCase())}
                targetKeys={relatedProjectsIds}
                onChange={(values) => handleChangeProject(values as string[])}
                render={(item) => item.title}
                locale={{
                  itemUnit: `${t('general.progetti')}`,
                  itemsUnit: `${t('general.progetti')}`,
                  notFoundContent: `${t('general.elencoVuoto')}`,
                  searchPlaceholder: `${t('buttons.cerca')}`,
                }}
              />
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default EditXmatrixModal;
