import { EditOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Button, Table, Tooltip, message } from 'antd';
import classNames from 'classnames';
import type React from 'react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { DndKitRow, sensorOptions } from 'src/components/xMatrix/utils/x-matrix-dnd-kit-common';
import type {
  GetCommitteePicklistDto,
  GetDivisionPicklistDto,
  GetXMatrixInfoResponseDto,
  XMatrixDto,
} from 'src/connectors/backend';
import { history } from '../../../../App';
import ObProgettiService from '../../../../services/matrix/obProgettiService';
import { statusClass, updateNotificationBell } from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import FixedSelectedAsset from '../../FixedSelectedAsset';
import '../../matrix.scss';
import EditProjectModal from './editProjectModal';

interface ProjectsProps {
  selection: 'primary' | 'secondary';
  selectedIds: string[];
  comittieList: GetCommitteePicklistDto[];
  divisionsList: GetDivisionPicklistDto[];
  selectedXmatrixInfoData: GetXMatrixInfoResponseDto;
  isSecondLevelXmatrix: boolean;
  onRowClick: (id: string) => void;
  projects: XMatrixDto['projects'];
  selectedXmatrix: string;
  reloadMatrix: () => void;
  onSort: (oldIndex: number, newIndex: number) => void;
}

const Projects: React.FC<ProjectsProps> = ({
  selection,
  selectedIds,
  comittieList,
  divisionsList,
  selectedXmatrixInfoData,
  isSecondLevelXmatrix,
  onRowClick,
  projects,
  selectedXmatrix,
  reloadMatrix,
  onSort,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [loadingButtonRemove, setLoadingButtonRemove] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({});
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const rowRefs = useRef<(HTMLDivElement | null)[]>([]);

  const sensors = useSensors(useSensor(PointerSensor, sensorOptions));

  const onDragEnd = (event: DragEndEvent) => {
    if (event.active.id !== event.over?.id) {
      const items: string[] = event.active.data.current?.sortable.items;
      const activeIndex = items.findIndex((i: string) => i === event.active.id);
      const overIndex = items.findIndex((i: string) => i === event.over?.id);

      return onSort(activeIndex, overIndex);
    }
  };

  const toggleModal = (rowData?: any) => {
    setOpenModal(!openModal);
    setModalData(rowData || {});
  };

  const handleModalCancel = () => {
    setOpenModal(false);
    setLoadingButton(false);
  };

  const selectRow = (record: any, index: number) => {
    onRowClick(record.projectID);
    setSelectedRecord(record);
    setSelectedIndex(index);
  };

  useEffect(() => {
    if (selection !== 'primary') {
      setSelectedRecord(null);
    }
  }, [selection]);

  const columns = [
    {
      dataIndex: 'orderingValue',
      render: (_: any, record: any, index: number) => {
        return {
          children: (
            <XMatrixProjectRow
              ref={(el) => (rowRefs.current[index] = el)}
              key={index}
              record={record}
              index={index}
              onRowClick={() => selectRow(record, index)}
              toggleModal={toggleModal}
              selectedRowClassName={classNames({
                'tw-bg-primary selected-row': selection === 'primary' && selectedIds?.includes(record.projectID),
                'tw-bg-primary/30 selected-row': selection === 'secondary' && selectedIds?.includes(record.projectID),
              })}
            />
          ),
        };
      },
    },
  ];

  const updateData = (values: any, id: string) => {
    values['xMatrixID'] = selectedXmatrix;
    values['projectID'] = id;
    const payload = values;

    setLoadingButton(true);
    ObProgettiService.updateObProgetti(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          updateNotificationBell();
          handleModalCancel();
          reloadMatrix();
        } else {
          message.error(notifyMessages.updateFailed);
          setLoadingButton(false);
        }
      })
      .catch(() => {
        message.error(notifyMessages.updateFailed);
        setLoadingButton(false);
      });
  };

  const removeProject = async (id: string, DeleteProject: boolean) => {
    setLoadingButtonRemove(true);
    try {
      await ObProgettiService.removeProjectFromXMatrix(id, selectedXmatrix, DeleteProject);

      void message.success(notifyMessages.deleteSuccess);
      reloadMatrix();
    } catch {
      message.error(notifyMessages.deleteFailed);
    }

    handleModalCancel();
    setLoadingButtonRemove(false);
  };

  const removeProjectDraft = (id: string, DeleteProject: boolean) => {
    setLoadingButtonRemove(true);
    ObProgettiService.removeProjectDraftXmatrix(id, selectedXmatrix, DeleteProject)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setLoadingButtonRemove(false);
          message.success(notifyMessages.deleteSuccess);
          handleModalCancel();
          reloadMatrix();
        } else {
          setLoadingButtonRemove(false);
          handleModalCancel();
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch(() => {
        setLoadingButtonRemove(false);
        handleModalCancel();
        message.error(notifyMessages.deleteFailed);
      });
  };

  if (!projects) return null;

  return (
    <>
      {openModal && (
        <EditProjectModal
          show={openModal}
          toggle={toggleModal}
          handleModalCancel={handleModalCancel}
          data={modalData}
          handleSave={updateData}
          removeProject={removeProject}
          removeProjectDraft={removeProjectDraft}
          commities={comittieList}
          divisionsList={divisionsList}
          loadingButton={loadingButton}
          loadingButtonRemove={loadingButtonRemove}
          selectedXmatrixInfo={selectedXmatrixInfoData}
          isSecondLevelXmatrix={isSecondLevelXmatrix}
        />
      )}

      {selectedRecord && selectedIndex && (
        <FixedSelectedAsset
          side="top"
          listOfRefs={rowRefs}
          selectedIndex={selectedIndex}
        >
          <div className={`xMatrix-square-label ${statusClass(selectedRecord.status)}`}>{selectedIndex + 1}</div>
          <span className="tw-opacity-50">{selectedRecord.projectCode}</span>
          <span>{selectedRecord.title}</span>
        </FixedSelectedAsset>
      )}

      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={onDragEnd}
      >
        <SortableContext
          items={projects.map((i) => i.projectID!)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: { row: DndKitRow },
            }}
            className="[&_table]:tw-h-full"
            dataSource={projects}
            pagination={false}
            bordered
            size="small"
            showHeader={false}
            columns={columns}
            rowKey="projectID"
          ></Table>
        </SortableContext>
      </DndContext>
    </>
  );
};

interface XMatrixProjectRowProps {
  record: any;
  index: number;
  onRowClick: () => void;
  toggleModal: (rowData: any) => void;
  selectedRowClassName: string;
}

export const XMatrixProjectRow = forwardRef<HTMLDivElement, XMatrixProjectRowProps>(
  ({ record, index, onRowClick, toggleModal, selectedRowClassName }, ref) => {
    return (
      <div
        ref={ref}
        onDoubleClick={() => history.push(`/progetti/id/${record.projectID}`)}
        onClick={onRowClick}
        className={`tw-flex tw-px-1 tw-h-[31px] tw-w-full matrix-row tw-truncate tw-items-center tw-justify-between ${selectedRowClassName}`}
      >
        <div className="tw-flex tw-truncate tw-items-center">
          <Tooltip title={record.statusDescription}>
            <div
              className={`xMatrix-square-label ${statusClass(record.status)}`}
              key={index}
            >
              {index + 1}
            </div>
          </Tooltip>
          <Button
            type="link"
            href={`/progetti/id/${record.projectID}`}
            className=" tw-text-left tw-shrink-0 tw-min-w-[131px] tw-truncate tw-text-rest"
          >
            <span className="tw-truncate tw-text-sm tw-w-full">{record.projectCode}</span>
          </Button>
          <Tooltip title={record.title}>
            <span className="tw-truncate tw-text-sm tw-w-full">{record.title}</span>
          </Tooltip>
        </div>

        <Button
          className="tw-shrink-0"
          type="text"
          icon={<EditOutlined />}
          onClick={() => toggleModal(record)}
        ></Button>
      </div>
    );
  },
);

export default Projects;
