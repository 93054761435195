import { LeftOutlined } from '@ant-design/icons';
import { Button, Tabs, Typography, message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'src/redux/store';
import { history } from '../../App';
import CommonService from '../../services/commonService';
import DeliverablePageService from '../../services/pages/deliverableService';
import UsersService from '../../services/pages/usersService';
import { addQueryParam, getQueryParam, hasQueryParam } from '../../utils/url-utils';
import DeliverableActivities from '../deliverables/deliverableActivities/activities';
import '../projects/project.scss';
import { objectTypeUserSecurity } from '../shared/utils/constants';
import { NoAccessMesssage } from '../shared/utils/functions';
import { notifyMessages } from '../shared/utils/notifyMessages';
import ViewEditDeliverable from './deliverableForm/viewEdit';
import { GetDeliverableResponseDto } from 'src/connectors/backend';

const DeliverableDetails = () => {
  const { t } = useTranslation();

  // cmpid == company ID
  const { id, cmpid } = useParams();
  const userData = useAppSelector((state) => state.userData.userData);

  const [currentDeliverable, setCurrentDeliverable] = useState<GetDeliverableResponseDto>();
  const [checkUserAccessOnObject, setCheckUserAccessOnObject] = useState(false);
  const [selectedTab, setSelectedTab] = useState('1');

  useEffect(() => {
    if (id) {
      getDeliverableData(id);
      checkIfUserHasAccess(id);
  
      if (cmpid && cmpid.length > 2) {
        updateUserCompany(id, cmpid);
      }
  
      if (hasQueryParam('tab')) {
        setSelectedTab(getQueryParam('tab') || '1');
      } else {
        setSelectedTab('1');
      }
    }
  }, [id, cmpid]);

  const getDeliverableData = async (id: string) => {
    try {
      const response = await DeliverablePageService.getDeliverableById(id);
      const resp = response.data;

      if (resp.success) {
        const respData = resp.responseObject?.value;
        setCurrentDeliverable(respData);
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
    }
  };

  const checkIfUserHasAccess = async (objectId: string) => {
    try {
      const response = await CommonService.chaeckUserAccessOnObject({ 
        type: objectTypeUserSecurity.deliverable, 
        objectID: objectId 
      });
      const resp = response.data;

      if (resp.success) {
        const respData = resp.responseObject?.value;
        setCheckUserAccessOnObject(respData?.hasPrivilege === 1);
      }
    } catch (error) {
      // ignored
    }
  };

  const updateUserCompany = async (id: string, companyId: string) => {
    const payload = {
      userID: userData?.userId,
      companyID: companyId,
    };

    if (userData?.hasOwnProperty('userId')) {
      const lastLogInCompany = (userData?.userCompanies ?? []).find((item) => item.isLastLoggedInCompany === 1);

      if (lastLogInCompany?.lastLoggedInCompany !== companyId.toLowerCase()) {
        try {
          const response = await UsersService.updateUserLogedInCompany(payload);
          const resp = response.data;

          if (resp.success) {
            window.location.replace(`/deliverables/id/${id}/${companyId}`);
            await getDeliverableData(id);
          }
        } catch (error) {
          // ignored
        }
      }
    }
  };

  return (
    <div className="project-wrapper edit-deliverable edit-deliverable-wrapper">
      {currentDeliverable && (
        <>
          {!checkUserAccessOnObject && <NoAccessMesssage />}

          {checkUserAccessOnObject && (
            <>
              <div className="tw-bg-white tw-overflow-hidden tw-shrink-0 tw-gap-8 tw-border-b tw-flex tw-items-center tw-justify-between tw-min-h-6 tw-max-h-14 tw-py-2 tw-px-3">
                <div className="tw-flex tw-gap-2 tw-items-center tw-shrink-0">
                  <Button
                    onClick={() => history.go(-1)}
                    type="text"
                    icon={<LeftOutlined />}
                  ></Button>
                  <Typography.Text className="tw-text-xl tw-font-semibold">
                    {currentDeliverable.deliverableName}
                  </Typography.Text>
                </div>
              </div>

              <Tabs
                className="tw-px-4"
                onChange={(key) => {
                  addQueryParam('tab', key);
                  setSelectedTab(key);
                }}
                defaultActiveKey="1"
                activeKey={selectedTab}
                items={[
                  {
                    label: <>{t('deliverablePage.details')}</>,
                    key: '1',
                    children: (
                      <ViewEditDeliverable
                        t={t}
                        deliverableId={currentDeliverable.deliverableID}
                        deliverableData={currentDeliverable}
                        reloadData={() => {
                          if (id) {
                            getDeliverableData(id);
                          }
                        }}
                      />
                    ),
                  },
                  {
                    label: <>{t('general.toDo')}</>,
                    key: '2',
                    children: (
                      <DeliverableActivities
                        t={t}
                        deliverableId={currentDeliverable.deliverableID}
                        deliverableName={currentDeliverable.deliverableName}
                        deliverableData={currentDeliverable}
                      />
                    ),
                  },
                ]}
              ></Tabs>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DeliverableDetails;
