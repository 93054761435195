import { trackPromise } from 'react-promise-tracker';
import {
  AnnualGoalApi,
  type InsertLongTermGoalRequestDto,
  KPIApi,
  LongTermGoalApi,
  type UpdateAnnualOrLongTermGoalRequestDto,
  type UpdateLongTermGoalRequestDto,
  authenticatedApi,
} from 'src/config/connectors';
import i18n from 'src/i18n';

const ObiettiviLongService = {
  // get kpi list data
  getObiettiviLongList: async (xmatrixId: string) => {
    const api = await authenticatedApi(LongTermGoalApi);
    return trackPromise(api.longTermGoalGetLongTermGoals(xmatrixId));
  },

  // get goal types list data
  getGoalTypes: async () => {
    const api = await authenticatedApi(LongTermGoalApi);
    const currentLng = i18n.language;
    return trackPromise(api.longTermGoalGetGoalTypePicklist(currentLng));
  },

  // get kpi data by id
  getObiettiviLongByiD: async (id: string, selectedXmatrix: string) => {
    const api = await authenticatedApi(LongTermGoalApi);
    return trackPromise(api.longTermGoalGetLongTermGoalById(id, selectedXmatrix));
  },

  // add long goal
  addLongTermlGoal: async (payload: InsertLongTermGoalRequestDto) => {
    const api = await authenticatedApi(LongTermGoalApi);
    return trackPromise(api.longTermGoalInsertLongTermGoal(payload));
  },

  // update long term objective
  updateLongTerm: async (payload: UpdateLongTermGoalRequestDto) => {
    const api = await authenticatedApi(LongTermGoalApi);
    return trackPromise(api.longTermGoalUpdateLongTermGoal(payload));
  },

  // delete long term objective
  deleteLongTermObjective: async (longTermGoalID: string) => {
    const api = await authenticatedApi(LongTermGoalApi);
    return trackPromise(api.longTermGoalDeleteLongTermGoal(longTermGoalID));
  },

  // update comment or name field
  updateCommentOrNameField: async (payload: UpdateAnnualOrLongTermGoalRequestDto) => {
    const api = await authenticatedApi(AnnualGoalApi);
    return trackPromise(api.annualGoalUpdateAnnualOrLongTermGoal(payload));
  },
};

export default ObiettiviLongService;
