import { useTranslation } from 'react-i18next';
import ProjectSectionTitle from 'src/components/shared/components/ProjectSectionTitle/ProjectSectionTitle';
import { useAppSelector } from 'src/redux/store';
import ProjectGoalEditor from '../projectGoalEditor/goalEditor';
import ReasonToAct from '../projectReasonToAct/reasonToAct';

interface ProjectObjectivesProps {
  hasUserAccess: boolean;
}

const ProjectObjective: React.FC<ProjectObjectivesProps> = ({ hasUserAccess }) => {
  const project = useAppSelector((state) => state.project.currentProject);

  const { t } = useTranslation();

  return (
    <div>
      <ProjectSectionTitle title={t('a3Page.motivoPerAgire')} />

      {project && (
        <div className="tw-grid tw-mt-4 lg:tw-grid-cols-2 tw-gap-8">
          <ReasonToAct
            isEditable={!hasUserAccess}
            isPresentationMode={false}
            key={'groundToAct'}
          />
          <ProjectGoalEditor
            isEditable={!hasUserAccess}
            isPresentationMode={false}
            key={'projectGoal'}
          />
        </div>
      )}
    </div>
  );
};

export default ProjectObjective;
