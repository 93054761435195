import {
  DeleteFilled,
  EditFilled,
  PlusOutlined,
  PlusSquareOutlined,
  SearchOutlined,
  SelectOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Input, Popconfirm, Space, Table, Typography, message } from 'antd';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import type { ColumnType, ColumnsType } from 'antd/lib/table';
import { format, parse, roundToNearestMinutes } from 'date-fns';
import moment from 'moment';
import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TreeXmatrixFilter from 'src/components/shared/components/treeXmatrixFilter/treeXmatrixFilter';
import type {
  AgendaItemDto,
  CommitteeDto,
  GetCommitteeSessionsResponseDto,
  GetXMatrixPicklistResponseDto,
  InsertUpdateCommitteeSessionRequestDto,
  UpsertAgendaRequest,
} from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import MatrixService from 'src/services/matrix/matrixService';
import { AgendaService } from 'src/services/pages/agendaService';
import { addQueryParam, getQueryParam, hasQueryParam } from 'src/utils/url-utils';
import UpsertSessionModal from '../../../../dialogs/UpsertSessionModal';
import CommitieService from '../../../../services/pages/commitieServices';
import TableLayout from '../../../shared/tableLayout';
import { isUserAdmin } from '../../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { formatOfDate, formatOfHHMM } from '../../../shared/utils/constants';
import { delayFuncCall } from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import AgendaModal from '../../agendaModal';

const { Text, Link } = Typography;

const convertTime = (time: string) => {
  const parsedTime = parse(time, 'HH:mm:ss', new Date());
  const roundedTime = roundToNearestMinutes(parsedTime, { nearestTo: 15 });

  return format(roundedTime, formatOfHHMM);
};

const CommitteeSessions: FC<{
  committee: CommitteeDto;
  userHasAccess: boolean;
}> = ({ committee, userHasAccess }) => {
  const { t } = useTranslation();
  const userData = useAppSelector((state) => state.userData.userData);
  const activeXmatrixInfo = useAppSelector((state) => state.activeXMatrix.activeXMatrix);

  const [selectedSessionData, setSelectedSessionData] = useState<GetCommitteeSessionsResponseDto>();
  const [selectedXmatrixId, setSelectedXmatrixId] = useState<string>();
  const [sessions, setSessions] = useState<GetCommitteeSessionsResponseDto[]>([]);
  const [xmatrixPicklist, setXmatrixPicklist] = useState<GetXMatrixPicklistResponseDto[]>([]);

  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingSaveAgenda, setLoadingSaveAgenda] = useState(false);
  const [loadingSaveClose, setLoadingSaveClose] = useState(false);
  const [loadingSendMail, setLoadingSendMail] = useState(false);
  const [loadingSessions, setLoadingSessions] = useState(false);

  const [agendaModal, setAgendaModal] = useState(false);
  const [newSessionModal, setNewSessionModal] = useState(false);
  const [standardAgendaModal, setStandardAgendaModal] = useState(false);
  const [updateSessionModal, setUpdateSessionModal] = useState(false);

  const [lastAgendaItem, setLastAgendaItem] = useState<AgendaItemDto>();

  useEffect(() => {
    void fetchXmatrixPicklist();
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    void getCommitteeSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeXmatrixInfo, selectedXmatrixId]);

  useEffect(() => {
    if (activeXmatrixInfo) {
      if (
        hasQueryParam('xMatrix') &&
        getQueryParam('xMatrix') !== undefined &&
        getQueryParam('xMatrix') !== 'undefined'
      ) {
        setSelectedXmatrixId(getQueryParam('xMatrix'));
      } else {
        setSelectedXmatrixId(activeXmatrixInfo?.xMatrixID ?? undefined);
      }
    }
  }, [activeXmatrixInfo]);

  const fetchXmatrixPicklist = async () => {
    const response = await MatrixService.getXmatrixPicklist();
    const resp = response.data;

    if (resp.success && resp.responseObject?.value) {
      setXmatrixPicklist(resp.responseObject.value);
    }
  };

  const getCommitteeSessions = async (createdSessionId?: string) => {
    if (!selectedXmatrixId) {
      return;
    }

    setLoadingSessions(true);

    try {
      const { data } = await CommitieService.getCommitieSessions(committee.id, selectedXmatrixId);

      if (!data.success || !data.responseObject?.value) {
        throw new Error('Fetch failed');
      }

      const sessions = data.responseObject.value;
      setSessions(sessions);

      if (createdSessionId) {
        const createdSessionData = sessions.find((session) => session.sessionID === createdSessionId);
        createdSessionData && toggleAgendaModal(createdSessionData);
      }

      setNewSessionModal(false);
    } catch {}

    setLoadingSessions(false);
  };

  const getColumnSearchProps = (
    dataIndex: keyof GetCommitteeSessionsResponseDto,
  ): Partial<ColumnType<GetCommitteeSessionsResponseDto>> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          placeholder={'Search'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters?.();
              confirm();
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      !!record[dataIndex]?.toString().toLowerCase().includes(`${value}`.trim().toLowerCase()),
  });

  const handleModalClose = () => {
    setNewSessionModal(false);
    setUpdateSessionModal(false);
  };

  const handleSession = async (values: InsertUpdateCommitteeSessionRequestDto) => {
    setLoadingSave(true);

    try {
      const { data } = await CommitieService.insertUpdateSession(values);

      if (!data.success || !data.responseObject?.value) {
        throw new Error('Insert failed');
      }

      if (newSessionModal) {
        const createdSessionId = data.responseObject.value.sessionID;
        createdSessionId && getCommitteeSessions(createdSessionId.toLowerCase());
      } else {
        handleModalClose();
        getCommitteeSessions();
      }
    } catch {
      void message.error(notifyMessages.addFailed);
    }

    setLoadingSave(false);
  };

  function formatDuration(totalMinutes: number) {
    const roundedMinutes = Math.round(totalMinutes / 15) * 15;
    const hours = Math.floor(roundedMinutes / 60);
    const minutes = Math.round(roundedMinutes % 60);

    if (hours === 0) {
      return `${minutes} min`;
    }
    if (minutes === 0) {
      return `${hours} h`;
    }
    return `${hours} h ${minutes} min`;
  }

  const onRemoveSession = (id: string) => {
    CommitieService.deleteSession(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          getCommitteeSessions();
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  const sessionsColumns: ColumnsType<GetCommitteeSessionsResponseDto> = [
    {
      title: `${t('comitatiPage.code')}`,
      dataIndex: 'codice',
      width: '30%',
      sorter: (a, b) => {
        return (a.codice ?? '').localeCompare(b.codice ?? '');
      },
      showSorterTooltip: false,
      ...getColumnSearchProps('codice'),
    },
    {
      title: `${t('comitatiPage.date')}`,
      // agendaStatus: 'date', // TODO
      defaultSortOrder: 'ascend',
      sorter: (a, b) => (a.date ?? '').localeCompare(b.date ?? ''),
      showSorterTooltip: false,
      render: (_, record) => moment(record.date).format(formatOfDate),
    },
    {
      title: `${t('comitatiPage.startTime')}`,
      dataIndex: 'startTime',
      sorter: (a, b) => (a.startTime ?? '').localeCompare(b.startTime ?? ''),
      showSorterTooltip: false,
      render: (_, record) => convertTime(record.startTime),
    },
    {
      title: `${t('comitatiPage.endTime')}`,
      dataIndex: 'endTime',
      sorter: (a, b) => (a.endTime ?? '').localeCompare(b.endTime ?? ''),
      showSorterTooltip: false,
      render: (_, { agendaID, endTime }) => {
        if (lastAgendaItem?.output && lastAgendaItem.agendaHeaderId === agendaID) {
          return lastAgendaItem.output;
        }

        return convertTime(endTime);
      },
    },
    {
      title: `${t('proggetiPage.durata')}`,
      dataIndex: 'duration',
      render: (_, { duration }) => formatDuration(duration),
    },
    {
      title: `${t('comitatiPage.agenda')}`,
      dataIndex: 'agendaStatus',
      render: (_, record) => {
        return (
          <Text>
            {' '}
            {record.agendaStatus}{' '}
            {userHasAccess ? (
              <PlusSquareOutlined
                onClick={() => toggleAgendaModal(record)}
                style={{ fontSize: '15px', marginLeft: '10px' }}
              />
            ) : (
              ''
            )}
          </Text>
        );
      },
    },
    {
      title: `${t('general.stato')}`,
      sorter: (a, b) => (a.stateName ?? '').localeCompare(b.stateName ?? ''),
      showSorterTooltip: false,
      dataIndex: 'stateName',
    },
    {
      key: 'action',
      width: '30px',
      render: (_, record) => {
        if (userHasAccess) {
          return (
            <Popconfirm
              key="remuve"
              title={t('comitatiPage.removeSession')}
              icon={<WarningOutlined />}
              okText={t('general.si')}
              cancelText={t('general.no')}
              onConfirm={() => onRemoveSession(record.sessionID)}
            >
              <DeleteFilled title={t('comitatiPage.removeSession')} />
            </Popconfirm>
          );
        }
      },
    },
    {
      key: 'action',
      width: '30px',
      render: (_, record) => {
        if (userHasAccess) {
          return (
            <Space>
              <EditFilled
                onClick={() => {
                  setSelectedSessionData(record);
                  setUpdateSessionModal(true);
                }}
              />
            </Space>
          );
        }
      },
    },
  ];

  const toggleAgendaModal = (data: GetCommitteeSessionsResponseDto) => {
    setSelectedSessionData(data);
    setAgendaModal(true);
  };

  const addAgenda = async (
    values: UpsertAgendaRequest,
    closeModal: boolean,
    agendaComitieId: string,
    agendaId: string,
    sendMail: boolean,
    agendaSesionId?: string,
  ) => {
    if (!closeModal) {
      setLoadingSaveAgenda(true);
    }
    if (closeModal) {
      setLoadingSaveClose(true);
    }

    try {
      await AgendaService.upsertAgenda({
        ...values,
        committeeID: agendaComitieId,
        sessionID: agendaSesionId,
        agendaID: agendaId,
      });

      void message.success(notifyMessages.addSuccess);
      getCommitteeSessions();

      if (sendMail) {
        sendAgendaEmail();
      }
    } catch {
      void message.error(notifyMessages.addFailed);
    }

    setLoadingSaveAgenda(false);
    setLoadingSaveClose(false);
    setAgendaModal(false);
  };

  const sendAgendaEmail = () => {
    setLoadingSendMail(true);
    if (!selectedSessionData) {
      return;
    }

    CommitieService.sendAgendaByMail(committee.id, selectedSessionData.sessionID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.mailSendSuccess);
          reloadSessionListAfterAgendaMailSend();
          setLoadingSendMail(false);
        } else {
          message.error(notifyMessages.mailSendError);
          setLoadingSendMail(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.mailSendError);
        setLoadingSendMail(false);
      });
  };

  const reloadSessionListAfterAgendaMailSend = async () => {
    await delayFuncCall(3000);
    getCommitteeSessions();
  };

  const addDefaultAgenda = async (values: UpsertAgendaRequest, closeModal: boolean) => {
    setLoadingSave(!closeModal);
    setLoadingSaveClose(closeModal);

    try {
      await AgendaService.upsertAgenda({
        committeeID: committee.id,
        duration: values.duration,
        stateID: values.stateID,
        agendaID: values.agendaID,
        agendaItemsType: values.agendaItemsType,
        frequency: values.frequency,
        notes: values.notes,
        projects: values.projects,
        scope: values.scope,
        sessionID: null,
      });

      if (closeModal) {
        setStandardAgendaModal(false);
      }

      void getCommitteeSessions();
    } catch {
      void message.error(notifyMessages.addFailed);
    }

    setLoadingSave(false);
    setLoadingSaveClose(false);
  };

  const onTreeXmatrixChange = (values: string[]) => {
    if (values.length > 0) {
      const lastValue = values.at(-1);

      setSelectedXmatrixId(lastValue);
      addQueryParam('xMatrix', lastValue);
    }
  };

  return (
    <>
      <TableLayout title={t('comitatiPage.comitieSessions')}>
        <TableLayout.Actions>
          {newSessionModal && (
            <UpsertSessionModal
              isOpen={newSessionModal}
              onClose={handleModalClose}
              loadingSave={loadingSave}
              committee={committee}
              sessions={sessions}
              upsertSession={handleSession}
            />
          )}

          {updateSessionModal && selectedSessionData && (
            <UpsertSessionModal
              isOpen={updateSessionModal}
              onClose={handleModalClose}
              loadingSave={loadingSave}
              committee={committee}
              sessions={sessions}
              upsertSession={handleSession}
              sessionData={selectedSessionData}
            />
          )}

          {/* session agenda modal */}
          {agendaModal && committee && selectedSessionData && (
            <AgendaModal
              showModal={agendaModal}
              handleModalClose={() => setAgendaModal(false)}
              saveDefaultAgenda={addAgenda}
              loadingSave={loadingSaveAgenda}
              loadingSaveClose={loadingSaveClose}
              committee={committee}
              agendaSessionId={selectedSessionData.sessionID}
              loadingSendMail={loadingSendMail}
              data={selectedSessionData}
              setLastAgendaItem={setLastAgendaItem}
              handleSession={handleSession}
            />
          )}

          {/* default agenda modal */}
          {standardAgendaModal && committee && (
            <AgendaModal
              showModal={standardAgendaModal}
              handleModalClose={() => setStandardAgendaModal(false)}
              saveDefaultAgenda={addDefaultAgenda}
              loadingSave={loadingSave}
              loadingSaveClose={loadingSaveClose}
              committee={committee}
              setLastAgendaItem={setLastAgendaItem}
            />
          )}

          {xmatrixPicklist.length > 0 && selectedXmatrixId && !CommitieService.isOperational(committee.type) && (
            <TreeXmatrixFilter
              mode="single"
              showSearch={false}
              allowClear={false}
              includeChildren={false}
              selectedXmatrixIds={[selectedXmatrixId]}
              xmatrixPicklist={xmatrixPicklist}
              onTreeXmatrixChange={onTreeXmatrixChange}
            />
          )}

          {isUserAdmin(userData) && (
            <Button icon={<SelectOutlined />}>
              <Link onClick={() => setStandardAgendaModal(true)}> {t('comitatiPage.defaultAgendda')}</Link>
            </Button>
          )}

          {committee.agendaHeader && userHasAccess && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setNewSessionModal(true)}
            >
              {t('buttons.aggiungiNuovo')}
            </Button>
          )}
        </TableLayout.Actions>
        <TableLayout.Content>
          <Table<GetCommitteeSessionsResponseDto>
            columns={sessionsColumns}
            dataSource={sessions}
            size="small"
            rowKey={(obj) => obj.sessionID}
            loading={loadingSessions}
            pagination={false}
          />
        </TableLayout.Content>
      </TableLayout>
    </>
  );
};

export default CommitteeSessions;
