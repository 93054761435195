import { EyeInvisibleOutlined, EyeOutlined, PlusOutlined, ZoomInOutlined } from '@ant-design/icons';
import { Button, Dropdown, Tooltip, Typography, message } from 'antd';
import { format } from 'date-fns';
import moment from 'moment';
import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { goalStatusList, projectStatusList } from 'src/components/shared/utils/constants';
import { enumCast } from 'src/config/connectors';
import {
  type AnnualGoal,
  AnnualGoalStatus,
  type CreateAnnualGoalRequest,
  type CreateLongTermGoalRequest,
  type CreateProjectRequest,
  type GetCommitteePicklistDto,
  type GetDivisionPicklistDto,
  type GetXMatrixInfoResponseDto,
  type LongTermGoal,
  type Project,
  ProjectType,
} from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import type { WithOrder } from 'src/services/matrix/matrixService';
import KpiService from 'src/services/pages/kpiService';
import AddAnnualGoalModal from '../../dialogs/AddAnnualGoalModal';
import AddLongTermGoalModal from '../../dialogs/AddLongTermGoalModal';
import ObAnnoService from '../../services/matrix/obAnnoService';
import ObKpiService from '../../services/matrix/obKpiService';
import ObLongService from '../../services/matrix/obLongService';
import ObProgettiService from '../../services/matrix/obProgettiService';
import { isTeamleader, updateNotificationBell } from '../shared/utils/functions';
import { notifyMessages } from '../shared/utils/notifyMessages';
import AddKpiModal from './data_tables/center/addObKpiModal';
import AddProgettiModal from './data_tables/center/addObProgettiModal';
import QuickAddProgettiModal from './data_tables/center/addQuickProgettiModal';
import ZoomDataModal from './data_tables/center/zoomDataModal';

const { Text } = Typography;

interface CenterProps {
  selectedXmatrix: GetXMatrixInfoResponseDto;
  isKpiSectionVisible: boolean;
  isUsersSectionVisible: boolean;
  selectedProjectIds?: string[];
  selectedAnnualObjectiveIds?: string[];
  selectedLongTermObjectiveIds?: string[];
  selectedKpiIds?: string[];
  isSecondLevelXmatrix: boolean;
  annualGoalList: WithOrder<AnnualGoal>[];
  longTermGoalList: WithOrder<LongTermGoal>[];
  projectList: WithOrder<Project>[];
  divisionsList: GetDivisionPicklistDto[];
  committeeList: GetCommitteePicklistDto[];
  isSecondaryProject: boolean;
  onChange: () => void;
  toggleKpiSection: () => void;
  toggleUsersSection: () => void;
}

const XmatrixCenter: FC<CenterProps> = ({
  selectedXmatrix: selectedXmatrixInfo,
  onChange: reloadMatrix,
  toggleKpiSection,
  toggleUsersSection,
  isKpiSectionVisible,
  isUsersSectionVisible,
  selectedProjectIds,
  selectedAnnualObjectiveIds,
  selectedLongTermObjectiveIds,
  selectedKpiIds,
  annualGoalList: annualGoals,
  longTermGoalList: longTermGoals,
  isSecondLevelXmatrix,
  divisionsList,
  committeeList: comittieList,
  projectList: projects,
  isSecondaryProject,
}) => {
  const { t } = useTranslation();
  const companyData = useAppSelector((state) => state.companyData.companyData);
  const userData = useAppSelector((state) => state.userData.userData);

  const [showObLungo, setShowObLungo] = useState(false);
  const [showObAnno, setShowObAnno] = useState(false);
  const [showZoomModal, setShowZoomModal] = useState(false);
  const [showQuickObProgetti, setShowQuickObProgetti] = useState(false);
  const [showObProgetti, setShowObProgetti] = useState(false);
  const [showObKpi, setShowObKpi] = useState(false);
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const [modalData, setModalData] = useState<{ data: any; area: any; label: any }>();

  const addObLungo = async (values: CreateLongTermGoalRequest) => {
    try {
      const { data } = await ObLongService.addObiettivoLungoxMartix({
        status: values.status,
        title: values.title,
        xMatrixId: selectedXmatrixInfo.xMatrixID as string,
      });
      if (!data.success) throw new Error('Insert failed');
      message.success(notifyMessages.addSuccess);
      setShowObLungo(false);
      reloadMatrix();
    } catch {
      message.error(notifyMessages.addFailed);
    }
  };

  const toggleObLungoModal = () => {
    setShowObLungo(!showObLungo);
  };
  const handleObLungoClose = () => {
    setShowObLungo(false);
  };

  // Add obittivo lungo anno modal
  const addObAnno = async (values: CreateAnnualGoalRequest) => {
    try {
      const { data } = await ObAnnoService.addObiettivoAnnoxMartix({
        ...values,
        status: enumCast(AnnualGoalStatus, values.status) ?? AnnualGoalStatus.Draft,
        xMatrixId: selectedXmatrixInfo.xMatrixID as string,
      });

      if (!data.success || !data.responseObject?.value) {
        throw new Error('Update failed');
      }

      void message.success(notifyMessages.addSuccess);
      handleObAnnoClose();
      reloadMatrix();
    } catch {
      void message.error(notifyMessages.addFailed);
    }
  };

  const toggleObAnnoModal = () => {
    setShowObAnno(!showObAnno);
  };

  const handleObAnnoClose = () => {
    setShowObAnno(false);
  };

  // Add progetti modal
  const addObProgetti = async (values: CreateProjectRequest) => {
    try {
      const { data } = await ObProgettiService.addObProgetti({
        ...values,
        name: values.name,
        startDate: format(new Date(values.startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(values.endDate), 'yyyy-MM-dd'),
        code: values.code,
        secondaryProject: isSecondaryProject,
        xMatrixId: selectedXmatrixInfo.xMatrixID as string,
        type: ProjectType.NonOperational,
        fastCreation: false,
      });

      if (data.success && data.responseObject?.value) {
        void message.success(notifyMessages.addSuccess);
        updateNotificationBell();

        handleObProgettiClose();
        reloadMatrix();
      } else {
        void message.error(notifyMessages.addFailed);
      }
    } catch (err) {
      console.error(err);
      void message.error(notifyMessages.addFailed);
    }
  };

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const addQuickProgetti = async (values: any) => {
    try {
      const { data } = await ObProgettiService.addObProgetti({
        name: values.name,
        xMatrixId: selectedXmatrixInfo.xMatrixID as string,
        secondaryProject: isSecondaryProject,
        fastCreation: true,
        hasExternalReview: false,
        startDate: moment(selectedXmatrixInfo.referencePeriod).startOf('year').format('YYYY-MM-DD'),
        endDate: moment(selectedXmatrixInfo.referencePeriod).endOf('year').format('YYYY-MM-DD'),
        code: values.code ?? values.code,
        type: ProjectType.NonOperational,
        status: values.status,
      });

      if (!data.success || !data.responseObject?.value) {
        throw new Error('Insert failed');
      }

      void message.success(notifyMessages.addSuccess);
      handleQuickObProgettiClose();
      reloadMatrix();
    } catch {
      void message.error(notifyMessages.addFailed);
    }
  };

  const toggleObProgettiModal = () => {
    setShowObProgetti(!showObProgetti);
  };
  const toggleQuickObProgettiModal = () => {
    setShowQuickObProgetti(!showQuickObProgetti);
  };
  const handleObProgettiClose = () => {
    setShowObProgetti(false);
  };

  const handleQuickObProgettiClose = () => {
    setShowQuickObProgetti(false);
  };

  const projectDropdownMenu = [
    { label: <Text onClick={toggleObProgettiModal}>{t('xMatrixPage.addProject')}</Text>, key: 1 },
    { label: <Text onClick={toggleQuickObProgettiModal}>{t('xMatrixPage.quickAddProject')}</Text>, key: 2 },
  ];

  // Add KPI modal
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const addObKpi = async (values: any, isNewKpi: boolean) => {
    try {
      await (isNewKpi
        ? KpiService.createKpi({ ...values, xMatrixId: selectedXmatrixInfo.xMatrixID as string })
        : ObKpiService.addObKpi(selectedXmatrixInfo.xMatrixID as string, values.kpiId));

      void message.success(notifyMessages.addSuccess);
      setShowObKpi(false);
      reloadMatrix();
    } catch {
      void message.error(notifyMessages.addFailed);
    }
  };

  const toggleObKpiModal = () => {
    setShowObKpi(!showObKpi);
  };
  const handleObKpiClose = () => {
    setShowObKpi(false);
  };

  const handleZoomModalClose = () => {
    setShowZoomModal(false);
  };

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const toggleZoomModal = (data: any, area: any, label: any) => {
    setShowZoomModal(true);
    setModalData({ data, area, label });
  };

  const isRoleTeamLeader = userData && Object.keys(userData).length > 0 ? isTeamleader(userData) : false;

  return (
    <>
      {showObLungo && (
        <AddLongTermGoalModal
          showObLungo={showObLungo}
          handleObLungoClose={handleObLungoClose}
          handleObLungoSave={addObLungo}
          statusPickList={goalStatusList}
        />
      )}

      {showObAnno && (
        <AddAnnualGoalModal
          showObAnno={showObAnno}
          handleObAnnoClose={handleObAnnoClose}
          handleObAnnoSave={addObAnno}
          loadingButton={false}
          statusPickList={goalStatusList}
          annualGoals={annualGoals}
        />
      )}

      {showObProgetti && (
        <AddProgettiModal
          showObProgetti={showObProgetti}
          handleObProgettiClose={handleObProgettiClose}
          handleObProgettiSave={addObProgetti}
          commitiePickList={comittieList}
          divisionsList={divisionsList}
          statusPickList={projectStatusList}
          isSecondLevelXmatrix={isSecondLevelXmatrix}
          selectedXmatrixInfo={selectedXmatrixInfo}
          t={t}
        />
      )}

      {showQuickObProgetti && (
        <QuickAddProgettiModal
          showQuickObProgetti={showQuickObProgetti}
          handleModalClose={handleQuickObProgettiClose}
          handleSave={addQuickProgetti}
          loadingButton={false}
        />
      )}

      {showObKpi && (
        <AddKpiModal
          showObKpi={showObKpi}
          handleObKpiClose={handleObKpiClose}
          handleObKpiSave={addObKpi}
          selectedXmatrix={selectedXmatrixInfo.xMatrixID as string}
          loadingButton={false}
        />
      )}

      {showZoomModal && (
        <ZoomDataModal
          showZoomModal={showZoomModal}
          handleZoomModalClose={handleZoomModalClose}
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
          modalData={modalData as any}
          xMatrixName={selectedXmatrixInfo.xMatrixName || ''}
          xMatrixCycleName={selectedXmatrixInfo.xMatrixCycleName || ''}
        />
      )}

      <div className="center-section tw-relative">
        <div className="center-grid-container">
          <div className="center-grid-item horizontal-text" />

          <div className="tw-flex tw-justify-center tw-gap-2">
            <Tooltip title={t('xMatrixPage.expandList')}>
              <Button
                className="tw-bg-zinc-100 tw-text-rest !tw-rounded-t-none"
                icon={<ZoomInOutlined />}
                onClick={() => toggleZoomModal(projects, 'projects', t('headerMenu.progetti'))}
              />
            </Tooltip>
            <Dropdown
              key="more"
              placement="bottomCenter"
              menu={{ items: projectDropdownMenu }}
              overlayClassName="test-proejcts-dropdown"
            >
              <Button
                className="tw-text-rest !tw-rounded-t-none"
                data-testid="add-projects-btn"
                type="dashed"
                icon={<PlusOutlined />}
              >
                {t('headerMenu.progetti')}
                {selectedProjectIds?.length ? (
                  <div className="tw-size-6 tw-rounded-full tw-grid tw-place-content-center tw-text-primary tw-bg-primary/20">
                    {selectedProjectIds?.length}
                  </div>
                ) : null}
              </Button>
            </Dropdown>
          </div>

          <div className="center-grid-item horizontal-text" />

          <div className="tw-flex vertical-text tw-gap-2">
            <Tooltip title={t('xMatrixPage.expandList')}>
              <Button
                icon={<ZoomInOutlined />}
                className="tw-bg-zinc-100 tw-rotate-180 tw-text-rest !tw-rounded-l-none"
                onClick={() => toggleZoomModal(annualGoals, 'annualGoal', t('xMatrixPage.objAnno'))}
              />
            </Tooltip>
            {!isRoleTeamLeader && (
              <Button
                className="tw-h-fit tw-w-10 tw-rounded-r-none tw-bg-zinc-100 tw-text-rest"
                icon={<PlusOutlined />}
                onClick={toggleObAnnoModal}
                type="dashed"
              >
                {t('xMatrixPage.objAnno')}
                {selectedAnnualObjectiveIds?.length ? (
                  <div className="tw-size-6 tw-rounded-full tw-grid tw-place-content-center tw-text-primary tw-bg-primary/20">
                    {selectedAnnualObjectiveIds?.length}
                  </div>
                ) : null}
              </Button>
            )}
          </div>

          <div className="center-grid-item horizontal-text">
            {companyData ? (
              <img
                src={`${companyData.logo}`}
                width="150px"
                className="tw-rounded-lg"
                alt="Logo"
              />
            ) : null}
          </div>

          <div className="tw-flex tw-justify-center tw-gap-2 vertical-text">
            <Button
              onClick={toggleObKpiModal}
              className="tw-rounded-l-none tw-h-fit tw-bg-zinc-100 tw-text-rest"
              icon={<PlusOutlined />}
              type="dashed"
            >
              {t('xMatrixPage.metriche')}
              <button
                type="button"
                className="tw-rotate-90 tw-text-primary"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleKpiSection();
                }}
              >
                {isKpiSectionVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              </button>
              {selectedKpiIds?.length ? (
                <div className="tw-size-6 tw-rounded-full tw-grid tw-place-content-center tw-text-primary tw-bg-primary/20">
                  {selectedKpiIds?.length}
                </div>
              ) : null}
            </Button>

            <Button
              className="tw-rounded-l-none tw-h-fit tw-bg-zinc-100 tw-text-rest"
              type="dashed"
            >
              {t('xMatrixPage.utenti')}

              <button
                type="button"
                className="tw-rotate-90 tw-text-primary"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleUsersSection();
                }}
              >
                {isUsersSectionVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              </button>
            </Button>
          </div>

          <div className="center-grid-item horizontal-text" />

          <div className="tw-flex tw-absolute tw-text-xl tw-left-1/2 tw-bottom-[50px] -tw-translate-x-1/2 tw-text-center tw-bg-slate-100 tw-py-2 tw-px-4 tw-rounded-lg">
            <p className="tw-line-clamp-2">{selectedXmatrixInfo.xMatrixName}</p>
          </div>

          <div className="tw-flex horizontal-text tw-gap-2">
            {!isRoleTeamLeader ? (
              <>
                <Tooltip title={t('xMatrixPage.expandList')}>
                  <Button
                    icon={<ZoomInOutlined />}
                    className="tw-bg-zinc-100 tw-text-rest !tw-rounded-b-none"
                    onClick={() =>
                      toggleZoomModal(longTermGoals, 'longTermGoals', t('xMatrixPage.strategiaLungoPeriodo'))
                    }
                  />
                </Tooltip>
                <Button
                  className="tw-rounded-b-none tw-h-fit tw-bg-zinc-100 tw-text-rest"
                  icon={<PlusOutlined />}
                  onClick={toggleObLungoModal}
                >
                  {t('xMatrixPage.strategiaLungoPeriodo')}
                  {selectedLongTermObjectiveIds?.length ? (
                    <div className="tw-size-6 tw-rounded-full tw-grid tw-place-content-center tw-text-primary tw-bg-primary/20">
                      {selectedLongTermObjectiveIds?.length}
                    </div>
                  ) : null}
                </Button>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default XmatrixCenter;
