import { Input, Modal, Table, TableColumnType, Tooltip } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { randomGenKey, statusClass } from '../../../shared/utils/functions';
import '../../matrix.scss';

type ModalData = { data: any[]; area: string; label: string; }

interface ZoomDataModalProps {
  showZoomModal: boolean;
  handleZoomModalClose: () => void;
  modalData: ModalData;
  xMatrixCycleName: string;
  xMatrixName: string;
}

const ZoomDataModal: FC<ZoomDataModalProps> = ({ showZoomModal, handleZoomModalClose, modalData, xMatrixCycleName, xMatrixName }) => {
  const { t } = useTranslation();

  const [filteredData, setFilteredData] = useState<any[]>();

  useEffect(() => {
    if (modalData.data) {
      setFilteredData(modalData.data);
    }
  }, [modalData.data]);

  const generateObjectLink = (data: ModalData, record: any) => {
    if (data.area === 'projects' || data.area === 'childProjects') {
      return (
        <Link
          to={{ pathname: `/progetti/id/${record.projectID}` }}
          target="_blank"
          style={{ color: 'black' }}
        >
          {record.title}
        </Link>
      );
    }
    if (data.area === 'annualGoal') {
      return (
        <Link
          to={{ pathname: `/obiettiviannuali/details/id/${record.annualGoalID}` }}
          target="_blank"
          style={{ color: 'black' }}
        >
          {record.title}
        </Link>
      );
    }
    if (data.area === 'longTermGoals' || data.area === 'longTermGoalsL2') {
      return (
        <Link
          to={{ pathname: `/obiettivialungoperiodo/details/id/${record.longTermGoalID}` }}
          target="_blank"
          style={{ color: 'black' }}
        >
          {record.title}
        </Link>
      );
    }
    return '-';
  };

  const modalTitle = (modalData: ModalData, cycleName: string) => {
    if (
      (modalData.area === 'longTermGoals' || modalData.area === 'longTermGoalsL2') &&
      cycleName !== '' &&
      cycleName !== null
    ) {
      return `${modalData.label} - ${xMatrixName}` || '-';
    }

    return modalData.label;
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const columns = useMemo(
    () => {
      const columns = [
        {
          dataIndex: 'orderingValue',
          width: '50px',
          render: (text, record, index) => {
            return {
              children: (
                <Tooltip title={record.statusDescription}>
                  <div
                    className={`xMatrix-square-label ${statusClass(record.status)}`}
                    key={index}
                  >
                    {index + 1}
                  </div>
                </Tooltip>
              ),
            };
          },
        },
        {
          title: `${t('general.nome')}`,
          dataIndex: 'title',
          ellipsis: {
            showTitle: true,
          },
          width: '100%',
          showSorterTooltip: [true],
          render: (text, record, index) =>
            // <Tooltip placement="bottom" title={title}>{title}</Tooltip>
            generateObjectLink(modalData, record),
        },
      ] as TableColumnType<any>[];

      if (modalData.area === 'projects' || modalData.area === 'childProjects') {
        columns.splice(1, 0, {
          title: `${t('proggetiPage.codice')}`,
          dataIndex: 'projectCode',
          width: '10%',
          className: 'firstCol',
          ellipsis: {
            showTitle: false,
          },
          render: (projectCode) => <Tooltip title={projectCode}>{projectCode}</Tooltip>,
        });
      }

      return columns;
    },
    [modalData],
  );

  const handleSearch = (value: string) => {
    const query = value.toLowerCase().trim();
    if (query) {
      const filtered = modalData.data.filter((item) => item.title.toLowerCase().includes(query));
      setFilteredData(filtered);
    } else {
      setFilteredData(modalData.data);
    }
  };

  return (
    <Modal
      width={'80%'}
      title={modalTitle(modalData, xMatrixCycleName)}
      open={showZoomModal}
      footer={null}
      onCancel={handleZoomModalClose}
    >
      <div className="tw-mb-2 tw-flex">
        <Input.Search
          className="tw-w-1/3"
          allowClear
          placeholder={t('buttons.cerca')}
          onSearch={handleSearch}
        />
      </div>
      <Table
        className="tw-pb-4"
        id="zoomModalTable"
        size="small"
        columns={columns}
        dataSource={filteredData || modalData.data}
        rowKey={randomGenKey}
        pagination={false}
      />
    </Modal>
  );
};

export default ZoomDataModal;
