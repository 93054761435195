import {
  CheckOutlined,
  DeleteFilled,
  EditFilled,
  PlusOutlined,
  SearchOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Col, Input, Popconfirm, Row, Space, Table, Tabs, Tooltip, Typography, message } from 'antd';
import BraftEditor from 'braft-editor';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/store';
import { addQueryParam, getQueryParam, hasQueryParam } from 'src/utils/url-utils';
import CommonService from '../../../../services/commonService';
import MatrixService from '../../../../services/matrix/matrixService';
import ActivityService from '../../../../services/pages/activitiesServices';
import CommitieService from '../../../../services/pages/commitieServices';
import FeedService from '../../../../services/pages/projectServices/feedServices';
import '../../../projects/project.scss';
import TableLayout from '../../../shared/tableLayout';
import {
  RichTextEditorControls,
  activityStatus,
  feedRegardingObjectType,
  formatOfDate,
  sessionStatus,
  statusPickListCodes,
} from '../../../shared/utils/constants';
import { checkMyActivitiesStatus, handleDateConvert } from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import UpdateComitieActivity from './editAcitvityModal';
import NewComitieActivity from './newActivityModal';

const { Text } = Typography;

const ActionPlan = ({ comitieId, comitieDetails, userHasAccess, reloadCommitie }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const { t } = useTranslation();
  const userData = useAppSelector((state) => state.userData.userData);
  const defaultCommentTitle = `${t('comitatiPage.verbaleDel') + ': ' + comitieDetails.name + ' ../../20..'}`;

  const [activities, setActivities] = useState(null);
  const [statusListFilter, setStatusListFilter] = useState([]);
  const [loadingActivities, setLoadingActivities] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState([`${t('status.draft')}`, `${t('status.attivo')}`]);
  const [editActivityModal, setEditActivityModal] = useState(false);
  const [newActivityModal, setNewActivityModal] = useState(false);
  const [selectedActivityData, setSelectedActivityData] = useState(null);
  const [loadingActivitiesByDays, setLoadingActivitiesByDays] = useState(false);
  const [activitiesByDays, setActivitiesByDays] = useState(null);
  const [loadingArchive, setLoadingArchive] = useState(false);
  const [loadingSaveComment, setLoadingSaveComment] = useState(false);
  const [comentEditiorValue, setComentEditiorValue] = useState(comitieDetails.comment ? comitieDetails.comment : '');
  const [comittieCommentChanged, setComittieCommentChanged] = useState(false);
  const [commentTitle, setCommentTitle] = useState(
    comitieDetails.commentTitle ? comitieDetails.commentTitle : defaultCommentTitle,
  );

  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState(null);
  const [selectedXmatrixValue, setSelectedXmatrixValue] = useState(null);
  const [sessions, setSessions] = useState(null);
  const [loadingSessions, setLoadingSessions] = useState([]);

  useEffect(() => {
    getActiveXmatrix();
    getComitiesActivities();
    getComitiesActivitiesClodesByDay();
    retrieveStatusList();
  }, []);

  useEffect(() => {
    getComitieSesions();

    if (selectedXmatrixValue) {
      addQueryParam('xMatrix', selectedXmatrixValue);
    }
  }, [activeXmatrixInfo, selectedXmatrixValue]);

  const getComitieSesions = () => {
    if (activeXmatrixInfo) {
      const xmatrix = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;
      setLoadingSessions(true);
      CommitieService.getCommitieSessions(comitieId, xmatrix)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            const respData = resp.responseObject.value;

            const palnnedComities = respData.filter((item) => {
              return item.stateID === sessionStatus.planned;
            });

            const sortedPlannedcomities = palnnedComities.sort((a, b) => new Date(a.date) - new Date(b.date));

            setSessions(sortedPlannedcomities.slice(0, 2));
            setLoadingSessions(false);
          } else {
            setLoadingSessions(false);
          }
        })
        .catch((error) => {
          setLoadingSessions(false);
        });
    } else {
    }
  };

  const getActiveXmatrix = async () => {
    if (hasQueryParam('xMatrix')) {
      MatrixService.getSelectedXmatrixInfo(getQueryParam('xMatrix'))
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            setActiveXmatrixInfo(resp.responseObject.value);
            setSelectedXmatrixValue(getQueryParam('xMatrix'));
          }
        });

      return;
    }

    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          setActiveXmatrixInfo(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    clearFilters();
    setSearchText('');
    handleSearch('', confirm, dataIndex);
  };

  const retrieveStatusList = async () => {
    const objectCode = statusPickListCodes.activityStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          const obj = [];
          respData.map((item) => {
            obj.push({ text: item.statusDescription, value: item.statusDescription });
          });
          setStatusListFilter(obj);
        } else {
        }
      })
      .catch((error) => {});
  };

  const getComitiesActivities = () => {
    const onlyClosedActivities = false;
    setLoadingActivities(true);
    CommitieService.getComitieActivities(comitieId, onlyClosedActivities)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          setActivities(respData);
          setLoadingActivities(false);
        } else {
          message.error(notifyMessages.retrieveFailed);
          setLoadingActivities(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        setLoadingActivities(false);
      });
  };

  const getComitiesActivitiesClodesByDay = () => {
    const onlyClosedActivities = true;
    setLoadingActivitiesByDays(true);
    CommitieService.getComitieActivitiesByDays(comitieId, onlyClosedActivities)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          setActivitiesByDays(respData);
          setLoadingActivitiesByDays(false);
        } else {
          setLoadingActivitiesByDays(false);
        }
      })
      .catch((error) => {
        setLoadingActivitiesByDays(false);
      });
  };

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters.statusDescription);
  };

  const toggleEditAtivityModal = (rowData) => {
    setSelectedActivityData(rowData);
    setEditActivityModal(true);
  };

  const handleModalClose = () => {
    setEditActivityModal(false);
    setNewActivityModal(false);
  };

  const ActivityName = ({ record, title }) => {
    return (
      <Text
        style={{ cursor: 'pointer' }}
        onClick={() => toggleEditAtivityModal(record)}
      >
        {title}
      </Text>
    );
  };

  const onRemoveActivity = (id) => {
    ActivityService.removeActivity(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          getComitiesActivities();
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  const handleFastCloseActivity = (record) => {
    const values = {};

    values['activityID'] = record.activityID;
    values['description'] = record.description;
    values['endDate'] = handleDateConvert(record.endDate);
    values['ownerID'] = record.ownerID;
    values['startDate'] = handleDateConvert(record.startDate);
    values['status'] = activityStatus.closed;
    values['subject'] = record.subject;

    ActivityService.updateActivity(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          handleModalClose();
          getComitiesActivities();
          getComitiesActivitiesClodesByDay();
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
      });
  };

  const activitiesColums = [
    {
      title: `${t('leMieAttivitaPage.soggetto')}`,
      dataIndex: 'subject',
      overflowWrap: 'break-word',
      sorter: (a, b) => {
        a = a.subject || '';
        b = b.subject || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [false],
      ...getColumnSearchProps('subject'),

      render(title, record) {
        return {
          props: {
            style: {
              borderLeft: '7px solid' + checkMyActivitiesStatus(record),
              borderRadius: '5px 0 0 5px',
            },
          },
          children: (
            <>
              {userHasAccess && (
                <ActivityName
                  title={title}
                  record={record}
                />
              )}

              {!userHasAccess && <>{title}</>}
            </>
          ),
        };
      },
    },
    {
      title: `${t('general.responsabile')}`,
      dataIndex: 'ownerName',
      width: '10%',
      sorter: (a, b) => {
        a = a.ownerName || '';
        b = b.ownerName || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [false],
      // render: (title, record) => (
      //     <Popover content={<ResponsiblePopoverContent data={record} t={t} />} title={title}>
      //         <span style={record.isGenericResponsabile == true ? { color: "#f2883b" } : {}} >{title}</span>
      //     </Popover>
      // )
    },
    {
      title: `${t('general.stato')}`,
      dataIndex: 'statusDescription',
      width: '14ch',
      key: 'statusDescription',
      sorter: (a, b) => {
        a = a.statusDescription || '';
        b = b.statusDescription || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [true],
      filters: statusListFilter,
      defaultFilteredValue: filteredInfo || null,
      onFilter: (value, record) => (record.statusDescription ? record.statusDescription.includes(value) : ''),
      render(text, record) {
        return {
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: `${t('general.progetto')}`,
      dataIndex: 'projectName',
      width: '10%',
      sorter: (a, b) => {
        a = a.projectName || '';
        b = b.projectName || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [false],
    },
    {
      title: `${t('general.start')}`,
      dataIndex: 'startDate',
      width: '16ch',
      sorter: (a, b) => {
        a = a.startDate || '';
        b = b.startDate || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [false],
      render: (text, record) => moment(text).format(formatOfDate),
    },
    {
      title: `${t('general.end')}`,
      dataIndex: 'endDate',
      width: '16ch',
      sorter: (a, b) => {
        a = a.endDate || '';
        b = b.endDate || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [false],
      render: (text, record) => moment(text).format(formatOfDate),
    },
    {
      key: 'action',
      width: '30px',
      render: (_, record) => {
        if (userHasAccess) {
          return (
            <EditFilled
              onClick={() => toggleEditAtivityModal(record)}
              title={t('buttons.modificaAttivita')}
            />
          );
        }
      },
    },
    {
      key: 'action',
      width: '30px',
      render: (_, record) => {
        const isClosed = record.status === activityStatus.closed ? true : false;
        if (userHasAccess) {
          if (isClosed) {
            return;
          } else {
            return (
              <Popconfirm
                key="remuve"
                title={t('buttons.rimuoveAttivita')}
                icon={<WarningOutlined />}
                okText={t('general.si')}
                cancelText={t('general.no')}
                onConfirm={() => onRemoveActivity(record.activityID)}
              >
                <DeleteFilled title={t('buttons.rimuoveAttivita')} />
              </Popconfirm>
            );
          }
        }
      },
    },
    {
      key: 'action',
      width: '30px',
      render: (_, record) => {
        const quickClose =
          record.status === activityStatus.active || record.status === activityStatus.draft ? true : false;

        if (userHasAccess) {
          if (!quickClose) {
            return;
          } else {
            return (
              <Popconfirm
                key="close"
                placement="topRight"
                title={t('leMieAttivitaPage.chiudiQuestaAttivita')}
                icon={<WarningOutlined />}
                okText={t('general.si')}
                cancelText={t('general.no')}
                onConfirm={() => handleFastCloseActivity(record)}
              >
                <CheckOutlined
                  title={t('buttons.chiusuraRapida')}
                  style={{ color: '#16a34a' }}
                />
              </Popconfirm>
            );
          }
        }
      },
    },
  ];

  const activitiesByDaysColumns = [
    {
      title: `${t('leMieAttivitaPage.soggetto')}`,
      dataIndex: 'subject',
      overflowWrap: 'break-word',
      sorter: (a, b) => {
        a = a.subject || '';
        b = b.subject || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [false],
      ...getColumnSearchProps('subject'),

      render(title, record) {
        return {
          props: {
            style: {
              borderLeft: '7px solid' + checkMyActivitiesStatus(record),
              borderRadius: '5px 0 0 5px',
            },
          },
          children: (
            <>
              {userHasAccess && (
                <ActivityName
                  title={title}
                  record={record}
                />
              )}

              {!userHasAccess && <>{title}</>}
            </>
          ),
        };
      },
    },
    {
      title: `${t('general.responsabile')}`,
      dataIndex: 'ownerName',
      width: '10%',
      sorter: (a, b) => {
        a = a.ownerName || '';
        b = b.ownerName || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [false],
    },
    {
      title: `${t('general.stato')}`,
      dataIndex: 'statusDescription',
      width: '14ch',
      key: 'statusDescription',
      render: (title, record) => {
        return title;
      },
    },
    {
      title: `${t('general.progetto')}`,
      dataIndex: 'projectName',
      width: '10%',
      sorter: (a, b) => {
        a = a.projectName || '';
        b = b.projectName || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [false],
    },
    {
      title: `${t('general.start')}`,
      dataIndex: 'startDate',
      width: '16ch',
      sorter: (a, b) => {
        a = a.startDate || '';
        b = b.startDate || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [false],
      render: (text, record) => moment(text).format(formatOfDate),
    },
    {
      title: `${t('general.end')}`,
      dataIndex: 'endDate',
      width: '16ch',
      sorter: (a, b) => {
        a = a.endDate || '';
        b = b.endDate || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [false],
      render: (text, record) => moment(text).format(formatOfDate),
    },
  ];

  const saveActivity = (values) => {
    values['committeeID'] = comitieId;
    values.startDate = handleDateConvert(values.startDate);
    values.endDate = handleDateConvert(values.endDate);

    setLoadingSave(true);
    ActivityService.insertActivity(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          handleModalClose();
          setLoadingSave(false);
          message.success(notifyMessages.addSuccess);
          getComitiesActivities();
        } else {
          message.error(notifyMessages.addFailed);
          setLoadingSave(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        setLoadingSave(false);
      });
  };

  const updateActivity = (values, activityId) => {
    values['activityID'] = activityId;
    values.startDate = handleDateConvert(values.startDate);
    values.endDate = handleDateConvert(values.endDate);

    setLoadingSave(true);
    ActivityService.updateActivity(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          handleModalClose();
          setLoadingSave(false);
          message.success(notifyMessages.updateSuccess);
          getComitiesActivities();

          if (values.status === activityStatus.closed) {
            getComitiesActivitiesClodesByDay();
          }
        } else {
          message.error(notifyMessages.updateFailed);
          setLoadingSave(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        setLoadingSave(false);
      });
  };

  const archiveComment = (event) => {
    event.stopPropagation();
    const dataObj = {};

    dataObj['createdBy'] = userData.userId;
    const editorContentToHtml = BraftEditor.createEditorState(comentEditiorValue).toHTML();
    dataObj['description'] = editorContentToHtml;
    dataObj['priority'] = false;
    dataObj['regardingObjectID'] = comitieId;
    dataObj['regardingObjectTypeCode'] = feedRegardingObjectType.commitie;
    dataObj['subject'] = commentTitle;

    setLoadingArchive(true);
    FeedService.addFeedComment(dataObj)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setLoadingArchive(false);
          setComentEditiorValue('');
          setCommentTitle(defaultCommentTitle);
          saveCommitieComment(true);
          setComittieCommentChanged(false);
        } else {
          setLoadingArchive(false);
        }
      })
      .catch((error) => {
        setLoadingArchive(false);
      });
  };

  const saveCommitieComment = (isArchive) => {
    const payload = {};
    payload['committeeID'] = comitieId;
    const editorContentToHtml = BraftEditor.createEditorState(comentEditiorValue).toHTML();
    payload['comment'] = isArchive ? '' : editorContentToHtml;
    payload['commentTitle'] = isArchive ? '' : commentTitle;

    setLoadingSaveComment(true);
    CommitieService.updateComitieComment(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          setLoadingSaveComment(false);
          setComittieCommentChanged(false);
          reloadCommitie();
        } else {
          message.error(notifyMessages.updateFailed);
          setLoadingSaveComment(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        setLoadingSaveComment(false);
      });
  };

  const handleCommentEditorChange = (editorState) => {
    setComittieCommentChanged(true);
    setComentEditiorValue(editorState);
  };

  const checkSessionDate = (date) => {
    const currentDate = moment();
    if (currentDate > moment(date)) {
      return '#ff5555'; //red
    }
  };

  const onRemoveSession = (id) => {
    CommitieService.deleteSession(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          getComitieSesions();
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  const onFastCloseSession = (record) => {
    record['committeeID'] = comitieId;
    record['state'] = sessionStatus.confirmed;

    CommitieService.insertUpdateSession(record)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          getComitieSesions();
        } else {
          message.error(notifyMessages.addFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
      });
  };

  const handleCommentTitleChange = (e) => {
    setComittieCommentChanged(true);
    setCommentTitle(e.target.value);
  };

  const sessionsColumns = [
    {
      title: `${t('comitatiPage.code')}`,
      dataIndex: 'codice',
      width: '30%',
      showSorterTooltip: [false],
      render(title, record) {
        return {
          props: {
            style: {
              borderLeft: '7px solid' + checkSessionDate(record.date),
              borderRadius: '5px 0 0 5px',
            },
          },
          children: <>{title}</>,
        };
      },
    },
    {
      title: `${t('comitatiPage.date')}`,
      agendaStatus: 'date',
      defaultSortOrder: 'ascend',
      showSorterTooltip: [false],
      render: (text, record) => moment(record.date).format(formatOfDate),
    },
    {
      title: `${t('comitatiPage.startTime')}`,
      dataIndex: 'startTime',
      showSorterTooltip: [false],
      render: (text, record) => (record.startTime ? record.startTime.slice(0, 5) : ''),
    },
    {
      title: `${t('comitatiPage.endTime')}`,
      dataIndex: 'endTime',
      showSorterTooltip: [false],
      render: (text, record) => (record.endTime ? record.endTime.slice(0, 5) : ''),
    },
    {
      title: `${t('proggetiPage.durata')}`,
      dataIndex: 'duration',
    },
    {
      title: `${t('general.stato')}`,
      showSorterTooltip: [false],
      dataIndex: 'stateName',
    },
    {
      key: 'action',
      width: '30px',
      render: (_, record) => {
        if (userHasAccess) {
          return (
            <Popconfirm
              key="remuve"
              title={t('comitatiPage.removeSession')}
              icon={<WarningOutlined />}
              okText={t('general.si')}
              cancelText={t('general.no')}
              onConfirm={() => onRemoveSession(record.sessionID)}
            >
              <DeleteFilled title={t('comitatiPage.removeSession')} />
            </Popconfirm>
          );
        }
      },
    },
    {
      key: 'action',
      width: '30px',
      render: (_, record) => {
        if (userHasAccess) {
          return (
            <Popconfirm
              key="close"
              placement="topRight"
              title={t('comitatiPage.closeSession')}
              icon={<WarningOutlined />}
              okText={t('general.si')}
              cancelText={t('general.no')}
              onConfirm={() => onFastCloseSession(record)}
            >
              <CheckOutlined
                title={t('comitatiPage.closeSession')}
                style={{ color: '#16a34a' }}
              />
            </Popconfirm>
          );
        }
      },
    },
  ];

  const activitiesTabs = [
    {
      key: '1',
      label: t('general.opened'),
      children: (
        <Table
          tableLayout="fixed"
          columns={activitiesColums}
          dataSource={activities}
          size="small"
          rowKey={(obj) => obj.activityID}
          loading={loadingActivities}
          onChange={handleChange}
          pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
        />
      ),
    },
    {
      key: '2',
      label:
        t('comitatiPage.closedActivitiesByDays') +
        (comitieDetails ? comitieDetails.activitiesDuration : '') +
        ' ' +
        t('comitatiPage.days'),
      children: (
        <Table
          tableLayout="fixed"
          columns={activitiesByDaysColumns}
          dataSource={activitiesByDays}
          size="small"
          rowKey={(obj) => obj.activityID}
          loading={loadingActivitiesByDays}
          onChange={handleChange}
          pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
        />
      ),
    },
  ];

  return (
    <>
      {newActivityModal && comitieDetails && (
        <NewComitieActivity
          showModal={newActivityModal}
          handleModalClose={handleModalClose}
          saveActivity={saveActivity}
          committeeID={comitieId}
          loadingSave={loadingSave}
          comitieDetails={comitieDetails}
        />
      )}

      {editActivityModal && comitieDetails && selectedActivityData && (
        <UpdateComitieActivity
          showModal={editActivityModal}
          handleModalClose={handleModalClose}
          updateActivity={updateActivity}
          committeeID={comitieId}
          loadingSave={loadingSave}
          comitieDetails={comitieDetails}
          selectedActivityData={selectedActivityData}
        />
      )}

      <TableLayout
        title={'Action Plan'}
        grow={false}
      >
        <TableLayout.Content>
          <TableLayout
            title={
              <Typography.Title
                className="!tw-mb-0"
                level={4}
              >
                {t('comitatiPage.openSession')}
              </Typography.Title>
            }
          >
            <TableLayout.Content>
              <Table
                size="small"
                columns={sessionsColumns}
                dataSource={sessions}
                rowKey={(obj) => obj.sessionID}
                loading={loadingSessions}
                pagination={false}
              />
            </TableLayout.Content>
          </TableLayout>

          <TableLayout
            className="tw-mt-8"
            title={
              <Typography.Title
                className="!tw-mb-0"
                level={4}
              >
                {t('general.verbal')}
              </Typography.Title>
            }
          >
            <TableLayout.Content>
              <Row gutter={{ lg: 24 }}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <Input
                    className="tw-mb-2"
                    value={commentTitle}
                    placeholder={t('general.title')}
                    onChange={(e) => {
                      handleCommentTitleChange(e);
                    }}
                  />
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <BraftEditor
                    contentFormat="html"
                    language="en"
                    id="editor-text"
                    controls={RichTextEditorControls}
                    value={BraftEditor.createEditorState(comentEditiorValue)}
                    onChange={handleCommentEditorChange}
                  />

                  <div className="tw-flex tw-flex-row tw-gap-2 tw-w-full tw-justify-end tw-mt-2">
                    <Button
                      onClick={(event) => {
                        event.stopPropagation();
                        saveCommitieComment(false);
                      }}
                      loading={loadingSaveComment}
                      type="dashed"
                      disabled={!comittieCommentChanged}
                    >
                      {t('buttons.salva')}
                    </Button>

                    {comentEditiorValue && comentEditiorValue !== '' && (
                      <Popconfirm
                        placement="bottomRight"
                        key={2}
                        title={t('comitatiPage.acrhciveComitieComment')}
                        onConfirm={(event) => archiveComment(event)}
                        onCancel={(event) => {
                          event.stopPropagation();
                        }}
                        okText={t('general.si')}
                        cancelText={t('general.no')}
                      >
                        <Tooltip title={t('comitatiPage.archive')}>
                          <Button
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            loading={loadingArchive}
                            type="primary"
                          >
                            {t('comitatiPage.createReport')}
                          </Button>
                        </Tooltip>
                      </Popconfirm>
                    )}
                  </div>
                </Col>
              </Row>
            </TableLayout.Content>
          </TableLayout>

          <TableLayout
            className="tw-mt-16"
            title={
              <Typography.Title
                className="!tw-mb-0"
                level={4}
              >
                {t('general.activities')}
              </Typography.Title>
            }
          >
            <TableLayout.Actions>
              {userHasAccess && (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setNewActivityModal(true)}
                >
                  {t('buttons.newActivity')}
                </Button>
              )}
            </TableLayout.Actions>
            <TableLayout.Content>
              <Tabs items={activitiesTabs}></Tabs>
            </TableLayout.Content>
          </TableLayout>
        </TableLayout.Content>
      </TableLayout>
    </>
  );
};

export default ActionPlan;
