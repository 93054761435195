import { BorderOuterOutlined, DesktopOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { Button, Menu, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { history } from '../../../App';
import CommonService from '../../../services/commonService';
import ProgettiService from '../../../services/pages/projectServices/progettiService';
import UsersService from '../../../services/pages/usersService';
import { addQueryParam, getQueryParam, hasQueryParam } from '../../../utils/url-utils';
import {
  hasUserProjectCostAccess,
  userHasAccessProjectFields,
} from '../../shared/utils/authRolesProvilege/authRolesPrivilege';
import {
  feedObjectType,
  feedRegardingObjectType,
  objectTypeUserSecurity,
  projectStatusAsNumber,
} from '../../shared/utils/constants';
import { NoAccessMesssage } from '../../shared/utils/functions';
import '../project.scss';
import ProjectDataHeader from '../projectDataHeader';
import ProjectGoalEditor from '../projectGoalEditor/goalEditor';
import ReasonToAct from '../projectReasonToAct/reasonToAct';
import ChildProjects from './childProjects';
import ProjectKpi from './deliverablesAndKpi/projectKpi';
import ProjectDeliverable from './deliverablesAndKpi/projectReviewSystem';
import SettingsAndTeam from './mainInfo/settingsAndTeam';
import ProjectActivities from './projectActivities/projectActivities';
import ProjectCosts from './projectCosts/projectCost';
import ProjectFeed from './projectFeed/projectFeed';
import ProjectFiles from './projectFiles/projectFiles';
import ProjectObjectivesIndex from './projectObjectives/index';
import ProjectResourceWorkload from './projectResourceWorload/projectResourceWorkload';
import RiskAndPrecautions from './riskAndPrecaution/riskAndPrecaution';

import AttivitaSvolte from '../../a3/section3/attivitaSvolte';

import KpiDashboard from '../../kpiDashboard/kpiDashboard';
import ProjectMeetings from './projectMeetings/projectMeetings';

import ButtonGroup from 'antd/es/button/button-group';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import {
  RiCheckboxLine,
  RiCheckboxMultipleBlankLine,
  RiFile3Line,
  RiFileLine,
  RiFileList3Line,
  RiFileTextLine,
  RiFileWarningLine,
  RiFocus2Line,
  RiFocusLine,
  RiLinksLine,
  RiListOrdered,
  RiMenu2Fill,
  RiMenuLine,
  RiMessageLine,
  RiMoneyEuroBoxLine,
  RiNotification2Line,
  RiOpenSourceLine,
  RiQuestionLine,
  RiSettingsLine,
  RiSpeedLine,
  RiStackLine,
  RiTeamLine,
  RiTodoLine,
  RiZoomInLine,
  RiZoomOutLine,
} from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import ProssimiPassi from 'src/components/a3/section4/prossimiPassi';
import ProjectHanseiPage from 'src/components/projects/projectForm/ProjectHanseiPage';
import ProjectTargetIndicators from 'src/components/projects/projectForm/ProjectTargetIndicatorsPage';
import { useFullscreen } from 'src/hooks/FullScreen';
import { usePresentationZoom } from 'src/hooks/PresentationZoom';
import { getProjectById } from 'src/redux/slices/projectSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import ProjectMasterPlan from './ProjectMasterPlanPage';
import ProjectObjective from './ProjectObjectivePage';
import ProjectTeamAndRoutines from './projectTeamAndRoutines/projectTeamAndRoutines';

const ProjectIndex = () => {
  const { t } = useTranslation();
  const userData = useAppSelector((state) => state.userData.userData);
  const dispatch = useAppDispatch();
  const currentProject = useAppSelector((state) => state.project.currentProject);
  const companyData = useAppSelector((state) => state.companyData.companyData);
  const { enterFullscreen, exitFullscreen, isFullscreen } = useFullscreen();
  const { zoomIn, zoomOut } = usePresentationZoom();

  const { id, cmpid } = useParams();

  const [activeTab, setActiveTab] = useState<string>('1');
  const [checkUserAccessOnObject, setCheckUserAccessOnObject] = useState(false);
  const [projectCostUserAccessSP, setProjectCostUserAccessSP] = useState(false);
  const [projectCostUserAccess, setProjectCostUserAccess] = useState<number | null>();
  const [projectTeamMembers, setProjectTeamMembers] = useState<string[]>([]);
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const [projectTeamMembersList, setProjectTeamMembersList] = useState<any[]>([]);
  const [isPresentationMode, setIsPresentationMode] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [hasUserAccess, setHasUserAccess] = useState(false);

  useEffect(() => {
    if (!id) {
      history.replace('/progetti');
      return;
    }

    getProject();

    getUserProjectCostAccess(id);
    getTeamMembers(id);
    checkIfUserHasAccess(id);

    if (hasQueryParam('presentationMode') && getQueryParam('presentationMode') === 'true') {
      setIsPresentationMode(true);
    }

    if (hasQueryParam('tab')) {
      setActiveTab(getQueryParam('tab') || '1');
    } else {
      setActiveTab('1');
    }

    if (cmpid && cmpid.length > 2) {
      updateUserCompany(id, cmpid);
    }
    if (cmpid && cmpid.length <= 2) {
      setActiveTab(cmpid);
    }
  }, [id, cmpid]);

  useEffect(() => {
    if (currentProject && userData) {
      setHasUserAccess(
        !(
          userHasAccessProjectFields(userData, currentProject?.teamLeaderID, currentProject?.sponsorID) ||
          currentProject?.status !== projectStatusAsNumber.completed
        ),
      );

      const access = hasUserProjectCostAccess(
        userData,
        currentProject?.teamLeaderID,
        currentProject?.sponsorID,
        projectCostUserAccessSP,
        projectTeamMembers,
      );

      setProjectCostUserAccess(access);
    }
  }, [currentProject, projectCostUserAccessSP, projectTeamMembers, userData]);

  const getProject = () => {
    if (!id) return;

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    dispatch(getProjectById({ projectId: id }) as any);
  };

  const getTeamMembers = async (id: string) => {
    if (projectTeamMembersList.length > 0) {
      return;
    }

    try {
      const response = await ProgettiService.getProjectTeamMembers(id);
      const resp = response.data;
      if (resp.success) {
        const respData = resp.responseObject?.value;
        const usr: { value: string; label: string; key: string; disabled: boolean }[] = [];

        setProjectTeamMembers(respData?.map((a) => a.userID as string) || []);

        respData?.map((item) => {
          usr.push({
            value: item.userID as string,
            label: item.fullName as string,
            key: item.userID as string,
            disabled: item.isDeleted,
          });
        });

        setProjectTeamMembersList(usr);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserProjectCostAccess = async (projectId: string) => {
    try {
      const response = await ProgettiService.getProjectCostUserAccess(projectId);
      const resp = response.data;
      if (resp.success) {
        setProjectCostUserAccessSP(resp.responseObject?.value?.hasAccess === 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateUserCompany = async (id: string, companyId: string) => {
    if (userData && 'userId' in userData) {
      const lastLogInCompany = userData.userCompanies?.filter((item) => {
        return item.isLastLoggedInCompany === 1;
      });

      if (lastLogInCompany && lastLogInCompany[0].lastLoggedInCompany !== companyId.toLowerCase()) {
        try {
          const response = await UsersService.updateUserLogedInCompany({
            companyID: companyId,
            userID: userData.userId,
          });

          const resp = response.data;
          if (resp.success) {
            window.location.replace(`/progetti/id/${id}/${companyId}`);
            getProject();
          } else {
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const checkIfUserHasAccess = async (objectId: string) => {
    const obj = {
      type: objectTypeUserSecurity.project,
      objectID: objectId,
    };

    try {
      const response = await CommonService.chaeckUserAccessOnObject(obj);
      const { success, responseObject } = response.data;

      if (success) {
        const value = responseObject?.value || {};
        setCheckUserAccessOnObject(value.hasPrivilege === 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setCurrentTab = (key: string) => {
    addQueryParam('tab', key);
    setActiveTab(key);
  };

  const handlePresentationMode = () => {
    addQueryParam('presentationMode', 'true');
    setIsPresentationMode(true);
    setCurrentTab('15');
  };

  const handleFullScreenMode = () => {
    if (isFullscreen) {
      exitFullscreen();
    } else {
      enterFullscreen();
    }
  };

  const renderComponent = () => {
    switch (activeTab) {
      case '1':
        return <ProjectObjective hasUserAccess={hasUserAccess} />;

      case '40':
        return (
          <ProjectMasterPlan
            showGantt
            id={currentProject?.projectID!}
            currentProject={currentProject}
          />
        );

      case '41':
        return (
          <ProjectTargetIndicators
            isPresentationMode={isPresentationMode}
            hasUserAccess={hasUserAccess}
          />
        );

      case '42':
        return <ProjectHanseiPage hasUserAccess={hasUserAccess} />;

      case '2':
        return (
          <ProjectDeliverable
            hasUserAccess={hasUserAccess}
            isPresentationMode={isPresentationMode}
          />
        );

      case '3':
        return <RiskAndPrecautions hasUserAccess={hasUserAccess} />;

      case '4':
        return (
          <ProjectActivities
            userData={userData}
            t={t}
            projectId={currentProject?.projectID}
            projectName={currentProject?.name}
            currentProject={currentProject}
            hasUserAccess={hasUserAccess}
          />
        );

      case '5':
        return (
          <ProjectResourceWorkload
            userData={userData}
            t={t}
            hasUserAccess={hasUserAccess}
            projectId={currentProject?.projectID}
            projectName={currentProject?.name}
            reloadProject={() => getProject()}
          />
        );

      case '6':
        return <ChildProjects projectId={currentProject?.projectID as string} />;

      case '7':
        return (
          <ProjectCosts
            t={t}
            projectCostUserAccessProp={projectCostUserAccess}
            hasUserAccess={hasUserAccess}
            projectId={currentProject?.projectID}
            projectName={currentProject?.name}
            currentProject={currentProject}
            reloadProject={() => getProject()}
          />
        );

      case '8':
        return (
          <ProjectKpi
            isPresentationMode={isPresentationMode}
            projectTeamMembers={projectTeamMembersList}
            t={t}
            hasUserAccess={hasUserAccess}
            currentProject={currentProject}
            projectId={currentProject?.projectID!}
            projectName={currentProject?.name!}
            history={history}
          />
        );

      case '11':
        return (
          <ProjectFeed
            objectId={currentProject?.projectID}
            userData={userData}
            isProject={1}
            hasUserAccess={hasUserAccess}
            objectType={feedObjectType.projectFeed}
            feedRegardingObjType={feedRegardingObjectType.project}
            showFeed={true}
          />
        );

      case '12':
        return (
          <ProjectFiles
            userData={userData}
            defaultCheckedFeedType={3}
            feedRegardingObjType={feedRegardingObjectType.project}
            objectId={currentProject?.projectID}
            hasUserAccess={hasUserAccess}
            t={t}
            projectId={currentProject?.projectID}
            currentProject={currentProject}
          />
        );

      case '13':
        return (
          <SettingsAndTeam
            hasUserAccess={hasUserAccess}
            projectId={currentProject?.projectID as string}
            currentProject={currentProject!}
          />
        );

      case '14':
        return <ProjectObjectivesIndex />;

      case '15':
        return (
          <ReasonToAct
            isEditable={!hasUserAccess}
            key={'groundToAct'}
            isPresentationMode={isPresentationMode}
          />
        );

      case '16':
        return (
          <ProjectGoalEditor
            isEditable={!hasUserAccess}
            isPresentationMode={isPresentationMode}
            key={'projectGoal'}
          />
        );

      case '17':
        return (
          <ProjectTeamAndRoutines
            hasUserAccess={hasUserAccess}
            isPresentationMode={false}
          />
        );

      case '19':
        return (
          <ProjectDeliverable
            isPresentationMode={true}
            hasUserAccess={hasUserAccess}
          />
        );

      case '20':
        return (
          <AttivitaSvolte
            projectId={currentProject?.projectID}
            isPresentationMode={isPresentationMode}
          />
        );

      case '21':
        return (
          <KpiDashboard
            isPresentationMode={isPresentationMode}
            presentationProjectId={currentProject?.projectID as string}
          />
        );

      case '22':
        return (
          <ProssimiPassi
            currentProject={currentProject}
            projectId={currentProject?.projectID}
            companyData={companyData}
          />
        );

      case '23':
        return <RiskAndPrecautions hasUserAccess={hasUserAccess} />;

      case '25':
        return (
          <ProjectFiles
            isPresentationMode={true}
            t={t}
            feedRegardingObjType={feedRegardingObjectType.file}
            projectId={currentProject?.projectID}
            currentProject={currentProject}
          />
        );

      case '26':
        return (
          <ProjectFeed
            isPresentationMode={true}
            objectId={currentProject?.projectID}
            userData={userData}
            isProject={1}
            hasUserAccess={hasUserAccess}
            objectType={feedObjectType.projectFeed}
            feedRegardingObjType={feedRegardingObjectType.feed}
            showFeed={false}
          />
        );

      case '27':
        return (
          <ProjectMeetings
            objectId={currentProject?.projectID}
            userData={userData}
            isProject={1}
            hasUserAccess={hasUserAccess}
            objectType={feedObjectType.projectFeed}
            feedRegardingObjType={feedRegardingObjectType.meeting}
            showFeed={true}
          />
        );

      default:
        return null;
    }
  };

  const menuItemsPresentationMode = [
    {
      key: '15',
      icon: <RiQuestionLine />,
      label: t('proggetiPage.motivoAgire'),
    },
    {
      key: '16',
      icon: <RiFocusLine />,
      label: t('proggetiPage.expectedResult'),
    },
    {
      key: '17',
      icon: <RiTeamLine />,
      label: t('proggetiPage.team'),
    },
    {
      key: '21',
      icon: <RiSpeedLine />,
      label: t('proggetiPage.kpiStatus'),
    },
    {
      key: '40',
      icon: <RiFileList3Line />,
      label: t('proggetiPage.masterplan'),
    },
    {
      key: '19',
      icon: <RiCheckboxMultipleBlankLine />,
      label: t('proggetiPage.projectReviewSystem'),
    },
    {
      key: '20',
      icon: <RiCheckboxLine />,
      label: t('proggetiPage.attivitaSvolte'),
    },
    {
      key: '22',
      icon: <RiListOrdered />,
      label: t('proggetiPage.prossimiPassi'),
    },
    {
      key: '23',
      icon: <RiFileWarningLine />,
      label: t('proggetiPage.criticitaRischi'),
    },
    {
      key: '42',
      icon: <RiFileList3Line />,
      label: t('proggetiPage.hensei'),
    },
    {
      key: '25',
      icon: <RiFileLine />,
      label: t('proggetiPage.file'),
    },
    {
      key: '26',
      icon: <RiMessageLine />,
      label: t('proggetiPage.minutes'),
    },
  ];

  const menuItemsDefaultMode = [
    {
      key: '1',
      icon: <RiSpeedLine />,
      label: t('proggetiPage.obiettivoProgetto'),
    },
    {
      key: '17',
      icon: <RiTeamLine />,
      label: t('proggetiPage.team'),
    },
    {
      key: '14',
      icon: <RiLinksLine />,
      label: t('proggetiPage.linkToStrategy'),
    },
    {
      key: '41',
      icon: <RiFocus2Line />,
      label: t('general.kpiIndicators'),
    },
    {
      key: '40',
      icon: <RiFile3Line />,
      label: t('proggetiPage.masterplan'),
    },
    {
      key: '2',
      icon: <RiCheckboxMultipleBlankLine />,
      label: t('proggetiPage.projectReviewSystem'),
    },
    {
      key: '3',
      icon: <RiFileWarningLine />,
      label: t('proggetiPage.criticitaRischi'),
    },
    {
      key: '4',
      icon: <RiTodoLine />,
      label: t('proggetiPage.toDoProject'),
    },
    {
      key: '11',
      icon: <RiFileTextLine />,
      label: t('feed.feed'),
    },
    {
      key: '42',
      icon: <RiFileList3Line />,
      label: t('proggetiPage.hensei'),
    },
    {
      key: '7',
      icon: <RiMoneyEuroBoxLine />,
      label: t('proggetiPage.costiProgetto'),
    },
    {
      key: '5',
      icon: <RiOpenSourceLine />,
      label: t('proggetiPage.caricoRisorse'),
    },
    {
      key: '6',
      icon: <RiStackLine />,
      label: t('proggetiPage.progetttoFigli'),
    },
    {
      key: '27',
      icon: <RiNotification2Line />,
      label: t('proggetiPage.notificeRiunioni'),
    },
    {
      key: '12',
      icon: <RiFileLine />,
      label: t('proggetiPage.file'),
    },
    {
      key: '13',
      icon: <RiSettingsLine />,
      label: t('general.settings'),
    },
  ];

  return (
    <div>
      {currentProject && (
        <>
          {!checkUserAccessOnObject && <NoAccessMesssage />}

          {checkUserAccessOnObject && (
            <div className="tw-flex tw-flex-col tw-overflow-hidden tw-absolute tw-inset-0 tw-size-full">
              <ProjectDataHeader
                presentationModeTemplate={
                  <div>
                    <div>
                      {!isPresentationMode && (
                        <Button
                          onClick={handlePresentationMode}
                          icon={<DesktopOutlined className="editIcon printIcon" />}
                          type="primary"
                        >
                          {t('a3Page.presenta')}
                        </Button>
                      )}

                      {isPresentationMode && (
                        <div className="tw-flex tw-items-center tw-gap-2">
                          <ButtonGroup>
                            <Button
                              icon={<RiZoomInLine className="tw-text-2xl" />}
                              onClick={zoomIn}
                            />
                            <Button
                              icon={<RiZoomOutLine className="tw-text-2xl" />}
                              onClick={zoomOut}
                            />
                          </ButtonGroup>

                          <Tooltip
                            placement="bottom"
                            title={isFullscreen ? t('a3Page.closeFullscreen') : t('a3Page.fullscreenPage')}
                          >
                            <Button
                              type="primary"
                              onClick={handleFullScreenMode}
                              icon={<BorderOuterOutlined />}
                            />
                          </Tooltip>
                          <Tooltip
                            placement="bottom"
                            title={t('a3Page.chiuderePresentazioneA3')}
                          >
                            <Button
                              type="primary"
                              icon={<FullscreenExitOutlined />}
                              onClick={() => {
                                addQueryParam('presentationMode', 'false');

                                setIsPresentationMode(false);
                                setActiveTab('1');
                              }}
                            >
                              Esci
                            </Button>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </div>
                }
                isPresentationMode={isPresentationMode}
              />

              <div className="tw-flex tw-size-full tw-overflow-y-hidden">
                <div className="tw-flex tw-flex-col tw-h-full">
                  <Button
                    shape="circle"
                    onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
                    className={'tw-ml-6 tw-mt-4 tw-mb-2'}
                    icon={isSidebarCollapsed ? <RiMenu2Fill /> : <RiMenuLine />}
                  />
                  <Menu
                    onClick={({ key }) => setCurrentTab(key)}
                    className="[&_svg]:!tw-scale-125 tw-max-w-[250px] !tw-overflow-auto"
                    inlineCollapsed={isSidebarCollapsed}
                    mode="inline"
                    items={isPresentationMode ? menuItemsPresentationMode : menuItemsDefaultMode}
                    defaultSelectedKeys={[activeTab]}
                    selectedKeys={[activeTab]}
                  />
                </div>
                <div className={classNames('tw-size-full zoom-container tw-p-8 tw-overflow-auto tw-h-full', {})}>
                  {renderComponent()}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProjectIndex;
